import { Button, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { formatDates } from '../../lib/util/dateUtil';
import { accountFormat } from '../../lib/util/numberUtil';
import CloseIcon from '../../img/close.png';

class CardListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      orderIdx: '',
      frIdx: '',
      // selectedRow: null,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let orderIdx = this.props.orderIdx;
    let frIdx = this.props.frIdx;
    httpGet(httpUrl.cardPaymentDetail, [orderIdx, frIdx], {}).then((res) => {
      console.log('res');
      console.log(res);
      this.setState({
        list: res.data.cardpayments,
      });
    });
  };

  deleteCardPayment = (row) => {
    let self = this;
    Modal.confirm({
      title: '삭제 요청',
      content: '해당 결제 내역을 정말 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.deleteCardPayment, [], {
          idx: row.idx,
        }).then((res) => {
          console.log('res');
          console.log(res);
          if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
            self.getList();
          } else if (res.result === 'SUCCESS' && res.data === 'FAIL') {
            Modal.warn({
              title: '삭제 실패',
              content: '관리자에게 문의하세요.',
            });
          } else {
            Modal.warn({
              title: '삭제 실패',
              content: '관리자에게 문의하세요.',
            });
          }
        });
      },
    });
  };

  render() {
    const { data, close } = this.props;

    const columns = [
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'table-column-center',
      },
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDates(data)}</div>,
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '카드번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).cardNo}</div>,
      },
      {
        title: '승인번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).authNum}</div>,
      },
      {
        title: '카드명',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).message1}</div>,
      },
      {
        title: 'trxId',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).trxId}</div>,
      },
      {
        title: '결제코드',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).PurchaseCode}</div>,
      },
      {
        title: '인증날짜',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        render: (data, row) => <div>{JSON.parse(data).authdate}</div>,
      },
      {
        title: '고객전화번호',
        dataIndex: 'custPhone',
        className: 'table-column-center',
      },
      {
        title: '요금',
        dataIndex: 'price',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(row.price + row.tax)}</div>,
      },
      {
        title: '삭제',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={() => this.deleteCardPayment(row)}>삭제</Button>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div
          className="taskWork-Dialog2"
          style={{ textAlign: 'left', right: '600px' }}>
          <div className="taskWork-content">
            {/* 타이틀 */}
            <div className="taskWork-title2">
              <span>카드 결제 목록</span>
              <img
                //style={{marginLeft: "50px"}}
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="taskWork-close"
                alt="close"
              />
            </div>

            <Table dataSource={this.state.list} columns={columns} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CardListModal;
