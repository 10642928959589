import { Button, DatePicker, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { paymentMethod, paymentStatus } from '../../lib/util/codeUtil';
import { accountFormat } from '../../lib/util/numberUtil';
import CardListModal from './CardListModal';
import RegisterOrderByHand from './RegisterOrderByHand';
import CloseIcon from '../../img/close.png';
import moment from 'moment';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class RegisterDuplicateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        current: 1,
        pageSize: 5,
      },
      orderIdx: '',
      frIdx: '',
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      registOrderModalOpen: false,
      cardListModalOpen: false,
      remainAmount: 0,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pagination = this.state.pagination;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let orderIdx = this.state.orderIdx;
    httpGet(
      httpUrl.orderList,
      [pagination.current, pagination.pageSize, startDate, endDate, orderIdx],
      {}
    ).then((res) => {
      console.log(res.data.orders);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.orders,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
          },
        });
      }
    });
  };

  // 주문번호 검색
  onSearchOrderIdx = (value) => {
    this.setState(
      {
        orderIdx: value,
        pagination: {
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  render() {
    const { data, close } = this.props;

    const expandedRowRender = (record) => {
      console.log('subColumn!!!' + JSON.stringify(record));
      const dropColumns = [
        {
          title: '결제번호',
          dataIndex: 'orderPayments',
          className: 'table-column-center',
          width: '15%',
          render: (data, row) => (
            <>
              {data.map((item) => (
                <div
                //  style={{borderBottom: "1px solid black"}}
                >
                  {item.idx}
                </div>
              ))}
            </>
          ),
        },
        {
          title: '결제방법',
          dataIndex: 'orderPayments',
          className: 'table-column-center',
          width: '10%',
          render: (data, row) => (
            <>
              {data.map((item) => (
                <div>{paymentMethod[item.paymentMethod]}</div>
              ))}
            </>
          ),
        },
        {
          title: '결제금액',
          dataIndex: 'orderPayments',
          className: 'table-column-center',
          width: '30%',
          render: (data, row) => (
            <>
              {data.map((item) => (
                <div>{accountFormat(item.paymentAmount)}</div>
              ))}
            </>
          ),
        },
        {
          title: '결제상태',
          dataIndex: 'orderPayments',
          className: 'table-column-center',
          width: '10%',
          render: (data, row) => (
            <>
              {data.map((item) => (
                <div>{paymentStatus[item.paymentStatus]}</div>
              ))}
            </>
          ),
        },
        {
          title: '남은금액',
          dataIndex: 'orderPayments',
          className: 'table-column-center',
          width: '20%',
          render: (data, row) => (
            <>
              {data.map((item) => (
                <div>{accountFormat(item.remainAmount)}</div>
              ))}
            </>
          ),
        },
      ];

      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    const columns = [
      {
        title: '주문번호',
        dataIndex: 'idx',
        className: 'table-column-center',
        width: '5%',
      },
      {
        title: '결제수단',
        dataIndex: 'orderPayments',
        className: 'table-column-center',
        width: '5%',
        render: (data, row) => (
          <>
            {data.length === 1
              ? paymentMethod[data.map((item) => item.paymentMethod)]
              : '복합'}
          </>
        ),
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: '15%',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        width: '10%',
        render: (data, row) => <div>{data}</div>,
      },
      {
        title: '날짜',
        dataIndex: 'orderDate',
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => <div>{data}</div>,
      },
      {
        title: '도착지',
        // dataIndex: "custAddr1",
        className: 'table-column-center',
        width: '25%',
        render: (data, row) => (
          <div className="table-column-left">
            {row.destAddr1 + ' ' + row.destAddr2}
          </div>
        ),
      },
      {
        title: '주문금액',
        className: 'table-column-center',
        width: '5%',
        render: (data, row) => <div>{accountFormat(row.orderPrice)}</div>,
      },
      {
        title: '등록/조회',
        dataIndex: 'orderPayments',
        className: 'table-column-center',
        width: '35%',
        render: (data, row) => (
          <>
            <Button
              style={{ marginLeft: 10 }}
              type="primary"
              className="tabBtn taskWork-btn"
              onClick={() =>
                this.setState({
                  registOrderModalOpen: true,
                  orderIdx: row.idx,
                  frIdx: row.frIdx,
                  remainAmount:
                    data.filter((item) => item.paymentMethod === 1).length !== 0
                      ? data.filter((item) => item.paymentMethod === 1)[0]
                          .remainAmount
                      : 0,
                })
              }>
              등록
            </Button>

            <Button
              onClick={() =>
                this.setState({
                  cardListModalOpen: true,
                  orderIdx: row.idx,
                  frIdx: row.frIdx,
                })
              }>
              카드목록조회
            </Button>
          </>
        ),
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWork-Dialog2">
          <div className="taskWork-content">
            {/* 타이틀 */}
            <div className="taskWork-title2">
              <span>카드중복결제 수동등록</span>
              <img
                //style={{marginLeft: "50px"}}
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="taskWork-close"
                alt="close"
              />
            </div>

            <Search
              placeholder="주문번호 검색"
              enterButton
              allowClear
              onChange={(e) => this.setState({ orderIdx: e.target.value })}
              onSearch={this.onSearchOrderIdx}
              style={{
                width: 220,
                // marginLeft: 20,
                marginBottom: 10,
              }}
            />

            <RangePicker
              style={{ width: 300, float: 'right' }}
              placeholder={['시작일', '종료일']}
              value={[
                moment(this.state.startDate, 'YYYY-MM-DD'),
                moment(this.state.endDate, 'YYYY-MM-DD'),
              ]}
              onChange={(_, dateStrings) => {
                if (dateStrings[(0, 1)]) {
                  this.setState(
                    {
                      startDate: dateStrings[0],
                      endDate: dateStrings[1],
                      pagination: {
                        current: 1,
                        pageSize: 5,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                } else {
                  // console.log('test')
                  this.setState(
                    {
                      startDate: '',
                      endDate: '',
                      pagination: {
                        current: 1,
                        pageSize: 5,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                }
              }}
            />
            {this.state.registOrderModalOpen && (
              <RegisterOrderByHand
                orderIdx={this.state.orderIdx}
                frIdx={this.state.frIdx}
                remainAmount={this.state.remainAmount}
                close={() =>
                  this.setState(
                    {
                      registOrderModalOpen: false,
                      orderIdx: null,
                      frIdx: null,
                    },
                    () => this.getList()
                  )
                }
              />
            )}
            {this.state.cardListModalOpen && (
              <CardListModal
                orderIdx={this.state.orderIdx}
                frIdx={this.state.frIdx}
                close={() =>
                  this.setState({
                    cardListModalOpen: false,
                    orderIdx: null,
                    frIdx: null,
                  })
                }
              />
            )}

            <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.list}
              columns={columns}
              pagination={false}
              onChange={this.handleTableChange}
              expandedRowRender={expandedRowRender}
              expandRowByClick={true}
            />
            <div className="page-btn-modal">
              <Button
                style={{ margin: '10px' }}
                onClick={() => {
                  if (this.state.pagination.current <= 1) {
                    Modal.confirm({
                      title: '이전페이지 없음',
                      content: '이전 페이지가 존재하지 않습니다.',
                    });
                    return;
                  }

                  const pagination = { ...this.state.pagination };
                  pagination.current = pagination.current - 1;
                  this.setState({ pagination }, this.getList);
                }}>
                이전
              </Button>
              <Button
                style={{ margin: '10px' }}
                onClick={() => {
                  if (this.state.list.length < this.state.pagination.pageSize) {
                    Modal.confirm({
                      title: '다음페이지 없음',
                      content: '다음 페이지가 존재하지 않습니다.',
                    });
                    return;
                  }

                  const pagination = { ...this.state.pagination };
                  pagination.current = pagination.current + 1;
                  this.setState({ pagination }, this.getList);
                }}>
                다음
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegisterDuplicateOrder;
