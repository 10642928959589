import { Button, Image, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpDelete, httpGet, httpUrl, imageUrl } from '../../api/httpClient';
import RegistMallProductDialog from '../../components/dialog/mall/RegistMallProductDialog';
import '../../css/main.css';
import { mallProductStatusCode } from '../../lib/util/codeUtil';

const Product = ({ props }) => {
  const [list, setList] = useState([]);
  const [registMallProductOpen, setRegistMallProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(async () => {
    await getList();
  }, []);

  useEffect(() => {
    if (selectedProduct) setRegistMallProductOpen(true);
  }, [selectedProduct]);

  const getList = async () => {
    const res = await httpGet(httpUrl.getMallProductList, [], {});
    setList(res.data);
  };

  const closeRegistMallProduct = () => {
    setRegistMallProductOpen(false);
    setSelectedProduct(null);
  };

  const confirmDeleteProduct = (row) => {
    Modal.confirm({
      title: '상품삭제',
      content: `${row.name} 상품을 삭제하시겠습니까?`,
      onOk: async () => await onDeleteProduct(row.idx),
    });
  };

  const onDeleteProduct = async (productIdx) => {
    try {
      const res = await httpDelete(httpUrl.deleteProduct, [], productIdx);
      if (res.data) {
        Modal.info({
          title: '삭제성공',
          content: '상품 삭제에 성공했습니다.',
        });
        await getList();
      } else
        Modal.warn({
          title: '삭제실패',
          content: '상품 삭제에 실패했습니다.',
        });
    } catch (e) {
      console.error(e);
    }
  };

  const columns = [
    {
      title: '상품명',
      dataIndex: 'name',
      className: 'table-column-center',
    },
    {
      title: '가격',
      dataIndex: 'price',
      className: 'table-column-center',
    },
    {
      title: '분류',
      dataIndex: 'category',
      className: 'table-column-center',
    },
    {
      title: '상태',
      dataIndex: 'status',
      className: 'table-column-center',
      render: (data) => mallProductStatusCode[data],
    },
    {
      title: '사이즈',
      dataIndex: 'productSizes',
      className: 'table-column-center',
      render: (data) => {
        if (data.length > 0)
          return data.map((ms, idx) => (idx === 0 ? ms : ' / ' + ms));
        else return '-';
      },
    },
    {
      title: '이미지',
      dataIndex: 'imageFileIdx',
      className: 'table-column-center',
      render: (data) => <Image width={120} src={imageUrl(data)} />,
    },
    {
      title: '수정',
      dataIndex: '',
      className: 'table-column-center',
      title: '수정',
      dataIndex: '',
      className: 'table-column-center',
      render: (data, row) => (
        <Button onClick={() => setSelectedProduct(row)}>상품수정</Button>
      ),
    },
    {
      title: '삭제',
      dataIndex: 'idx',
      className: 'table-column-center',
      render: (data, row) => (
        <Button onClick={() => confirmDeleteProduct(row)}>삭제</Button>
      ),
    },
  ];

  return (
    <>
      {registMallProductOpen && (
        <RegistMallProductDialog
          selectedProduct={selectedProduct}
          close={() => closeRegistMallProduct()}
          getProductList={getList}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 20,
        }}>
        <Button onClick={() => setRegistMallProductOpen(true)}>
          몰상품추가
        </Button>
      </div>

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default Product;
