import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpPost, httpUrl, httpGet } from '../../api/httpClient';
import '../../css/main.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;

class SendBikeRepairDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRiderOpen: false,
      riderList: [],
      riderName: '',
      riderIdx: 0,
      riderId: '',
      riderDataCurrent: 0,
      riderDataEnd: false,
      selectedRider: null,
      searchName: '',
      riderName2: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  // 라이더 검색
  onSerchRider = (value) => {
    this.setState(
      {
        riderList: [],
        searchName: value,
        riderDataCurrent: 0,
        riderDataEnd: false,
      },
      () => {
        this.getRiderList();
      }
    );
  };

  // 라이더 리스트 조회 api
  getRiderList = () => {
    if (this.state.riderDataEnd) return;
    let riderName = this.state.searchName;
    let pageNum = this.state.riderDataCurrent + 1;
    let pageSize = 20;
    let branchIdx = this.props.branchIdx;
    httpGet(
      httpUrl.riderList,
      [branchIdx, riderName, '', pageNum, pageSize],
      {}
    ).then((res) => {
      if (res.data.riders.length === 0) {
        this.setState({
          riderDataEnd: true,
          riderList: this.state.riderList,
        });
        return;
      }
      if (this.state.riderDataCurrent === res.data.currentPage) {
        res.riders = [];
      }
      this.setState({
        riderList: this.state.riderList.concat(res.data.riders),
        riderDataCurrent: res.data.currentPage,
      });
    });
  };

  // 라이더 선택
  onSelectRider = (rider) => {
    console.log(rider);
    this.setState({ selectedRider: rider }, () => {
      const rider = this.state.selectedRider;
      this.setState({
        riderName: rider.riderName,
        riderIdx: rider.userIdx,
        riderId: rider.userId,
      });
    });
  };

  sendByRepairSubmit = () => {
    console.log(this.state.riderId);
    console.log(this.state.riderIdx);
    httpPost(httpUrl.sendBikeRepair, [], {
      userId: this.state.riderId,
      userIdx: this.state.riderIdx,
      ncashAmount: this.formRef.current.getFieldsValue().ncashAmount,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '지급 완료',
          content: '바이크 수리비 지급을 완료했습니다.',
        });
        this.props.close();
      } else if (res.data === 'SEND_USER_NOT_EXISTS') {
        Modal.warn({
          title: '지급 실패',
          content: '해당 라이더가 존재하지 않습니다.',
        });
      } else if (res.data === 'NOT_ENOUGH_NCASH') {
        Modal.warn({
          title: '지급 실패',
          content: '해당 라이더의 예치금이 충분하지 않습니다.',
        });
      }
    });
  };

  handleClear = () => {
    this.formRef.current.setFieldsValue({
      ncashAmount: undefined,
    });
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />

        <div className="bikeRepair-Dialog">
          <div className="deposit-title">수리비 지급</div>
          <img
            onClick={close}
            style={{ display: 'inline' }}
            src={CloseIcon}
            className="dialog-close"
            alt="img"
          />
          <div className="bikeRepair-inner">
            <Form ref={this.formRef} onFinish={this.sendByRepairSubmit}>
              <div className="bikeRepair-contentBlock">
                <div className="mainTitle">라이더명</div>
                <div>
                  <Search
                    placeholder={'라이더 이름 입력'}
                    enterButton={'조회'}
                    onFocus={() =>
                      this.setState(
                        { searchRiderOpen: true },
                        this.getRiderList(),
                        this.handleClear()
                      )
                    }
                    onChange={(e) =>
                      this.setState({ riderName: e.target.value })
                    }
                    onSearch={this.onSerchRider}
                    onBlur={(e) => {
                      if (!e.relatedTarget) {
                        this.setState({ searchRiderOpen: false });
                      }
                    }}
                    value={this.state.riderName}
                    style={{ width: '280px' }}
                  />

                  {this.state.searchRiderOpen && (
                    <div tabIndex={'0'} id="rider-list" className="rider-list">
                      <InfiniteScroll
                        className="rider-list-wrap"
                        dataLength={this.state.riderList.length}
                        height={'12em'}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'absolute',
                          zIndex: 99,
                          backgroundColor: '#fff',
                        }}
                        next={this.getRiderList}
                        hasMore={!this.riderDataEnd}
                        scrollableTarget="rider-list">
                        {this.state.riderList.map((row, index) => {
                          return (
                            <div
                              key={row.userIdx}
                              className="riderIdTag"
                              onClick={() => {
                                this.onSelectRider(row);
                                this.setState({ searchRiderOpen: false });
                              }}>
                              <div>
                                {row.riderName}({row.userId})
                              </div>
                            </div>
                          );
                        })}
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>

              <div className="bikeRepair-contentBlock">
                <div className="mainTitle">지급금액</div>
                <div>
                  <FormItem
                    name="ncashAmount"
                    className="selectItem"
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: '금액을 입력해주세요',
                      },
                    ]}>
                    <Input
                      placeholder="금액을 입력해주세요."
                      className="override-input"
                      style={{ width: '280px' }}
                    />
                  </FormItem>
                </div>
              </div>

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: '100%',
                  marginTop: '50px',
                }}>
                지급하기
              </Button>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};

export default connect(mapStateToProps, null)(SendBikeRepairDialog);
