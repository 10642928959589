import { DatePicker, Input, Table } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';

const TaxSupport = () => {
  const df = 'YYYY-MM-DD';
  const [category, setCategory] = useState(['TAX_PAY_ADDED', 'TAX_PAY_CLEAR']);
  const [riderName, setRiderName] = useState('');
  const [list, setList] = useState([]);
  const [startDate, setStartDate] = useState(new moment().format(df));
  const [endDate, setEndDate] = useState(new moment().format(df));
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(() => getList(), [pagination.current, startDate, endDate]);

  const getList = async () => {
    const res = await httpGet(
      httpUrl.getTaxSupportLog,
      [
        pagination.current,
        pagination.pageSize,
        category,
        riderName,
        startDate,
        endDate,
      ],
      {}
    );
    console.log(res);
    if (res.data && res.data.logList) {
      setList(res.data.logList);
      setPagination({
        ...pagination,
        total: res.data.totalCount,
      });
    }
  };

  const onChangeDate = (dateString) => {
    setStartDate(
      dateString != null ? moment(dateString[0]).format('YYYY-MM-DD 00:00') : ''
    );
    setEndDate(
      dateString != null ? moment(dateString[1]).format('YYYY-MM-DD 23:59') : ''
    );
  };

  const columns = [
    {
      title: '구분',
      dataIndex: 'memo',
      className: 'table-column-center',
    },
    {
      title: '아이디',
      dataIndex: 'userId',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '기사명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '지급금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '지급이전',
      dataIndex: 'beforeNcash',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '지급이후',
      dataIndex: 'afterNcash',
      className: 'table-column-center',
      render: (data) => comma(data),
    },
    {
      title: '일자',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => formatDate(data),
    },
  ];

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input.Search
          placeholder="라이더 검색"
          enterButton
          allowClear
          onChange={(e) => setRiderName(e.target.value)}
          onSearch={getList}
          style={{
            width: 220,
          }}
        />
        <DatePicker.RangePicker
          placeholder={['시작일', '종료일']}
          value={[moment(startDate, df), moment(endDate, df)]}
          onChange={onChangeDate}
          style={{ width: 350 }}
        />
      </div>

      <Table
        rowKey={(record) => record}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </>
  );
};

export default TaxSupport;
