import React, { useEffect, useState } from "react";
import { kindString } from "../../lib/util/codeUtil";
import { Button, Modal, Table } from "antd";
import { httpGet, httpUrl } from "../../api/httpClient";
import moment from "moment";
import { comma } from "../../lib/util/numberUtil";
import LoanAuraWithdrawDialog from "./LoanAuraWithdrawDialog";

const columns = [
  {
    title: "일시",
    dataIndex: "createDate",
    className: "table-column-center",
    render: (data) => moment(data).format("YYYY-MM-DD")
  },
  {
    title: "아이디",
    dataIndex: "userId",
    className: "table-column-center"
  },
  {
    title: "출금은행",
    dataIndex: "bank",
    className: "table-column-center"
  },
  {
    title: "출금계좌",
    dataIndex: "bankAccount",
    className: "table-column-center"
  },
  {
    title: "예금주",
    dataIndex: "depositor",
    className: "table-column-center"
  },
  {
    title: "출금이전잔액",
    dataIndex: "beforeNcash",
    className: "table-column-center"
  },
  {
    title: "출금이후잔액",
    dataIndex: "afterNcash",
    className: "table-column-center"
  },
  {
    title: "출금액",
    dataIndex: "withdrawReqAmount",
    className: "table-column-center"
  },
  {
    title: "예치금차감액(수수료포함)",
    dataIndex: "ncashDelta",
    className: "table-column-center"
  }
];
const now = new moment();
const dateFormat = "YYYY-MM-DD";
const LoanAuraWithdraw = () => {
  const pageSize = 10;
  const [pageNum, setPageNum] = useState(1);
  const [balance, setBalance] = useState(0);
  const [startDate, setStartDate] = useState(now);
  const [endDate, setEndDate] = useState(now);

  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);

  const [list, setList] = useState([]);
  const getBalance = async () => {
    const res = await httpGet(httpUrl.getAuraBalance, [], {});
    console.log(res);
    setBalance(res.data);
  };

  const getList = async (withPagination) => {
    try {
      const res = await httpGet(httpUrl.getAuraWithdrawLogs, [
        startDate.clone().format(dateFormat),
        endDate.clone().format(dateFormat),
        pageSize,
        pageNum,
        withPagination
      ], {});

      if (withPagination) setList(res.data.logs);
      else return res.data.logs;
    } catch (e) {
      Modal.warn({
        title: "조회실패",
        content: "서버 오류로 조회에 실패했습니다."
      });
    }
  };

  useEffect(async () => {
    await getList(true);
    await getBalance();
  }, []);

  return <div>
    { withdrawDialogOpen && <LoanAuraWithdrawDialog balance={balance} getList={() => getList(true)} getBalance={getBalance} close={() => setWithdrawDialogOpen(false)} />  }
    <div style={{ display: "flex", marginBottom: 20, justifyContent: "space-between" }}>
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "bold", fontSize: "1rem" }}>{`예치금 잔액 : ${comma(balance)}`}</div>
        <Button style={{marginLeft:10}} onClick={() => setWithdrawDialogOpen(true)}>예치금 출금</Button>
      </div>
      <div>
        {/*<Button>엑셀 다운로드</Button>*/}
      </div>
    </div>
    <Table
      rowKey={(record) => record.idx}
      dataSource={list}
      columns={columns}
      pagination={false}
    />
  </div>;
};

export default LoanAuraWithdraw;
