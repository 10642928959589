import { Button, Table, Typography, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { kindString, StaticCategory } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import { connect } from 'react-redux';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const { Text } = Typography;

class StaticsTableCharge extends Component {
  constructor(props) {
    super(props);
    const data = this.props;
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
      },
      startDate: data.startDate,
      endDate: data.endDate,
      changeFilter: data.changeFilter,
      selectBranch: data.selectBranch,
      businessNumber: data.businessNumber,
      frName: data.frName,
      riderName: data.riderName,
      registrationNumber: data.registrationNumber,

      copyList: [],
    };

    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
    this.columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'tableCellCenter',
        render: (data) => <>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</>,
        width: 160,
      },
      {
        title: '관리지점',
        dataIndex: 'branchName',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'tableCellCenter',
        width: 140,
        render: (data) => <>{StaticCategory[data]}</>,
      },
      {
        title: '비고',
        className: 'statics-table-border tableCellCenter',
        dataIndex: 'adminId',
        width: 100,
        render: (data, row) => {
          if (
            row.category === 'NCASH_SEND_TO_FR_BY_ADMIN' ||
            row.category === 'NCASH_SEND_TO_RIDER_BY_ADMIN'
          ) {
            return (
              <Button
                style={{ border: 'none' }}
                onClick={() => {
                  this.openMemo(row.memo);
                }}>
                <ExclamationCircleOutlined />
                {data ? `변경자 : ${data}` : ''}
              </Button>
            );
          } else return <div>{data ? `변경자 : ${data}` : ''}</div>;
        },
      },
      {
        title: '가맹점',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '가맹점명',
                dataIndex: 'frName',
                className: 'tableCellCenter',
                width: 150,
              },
              {
                title: '사업자번호',
                dataIndex: 'businessNumber',
                className: 'tableCellCenter',
                width: 100,
              },
            ],
          },
          {
            title: '배달건별',
            children: [
              {
                title: '배달비 공금가액',
                children: [
                  {
                    title: '기본',
                    width: 100,
                    className: 'tableCellRight',
                  },
                  {
                    title: '할증',
                    className: 'tableCellRight',
                    width: 100,
                  },
                  {
                    title: '계',
                    className: 'tableCellRight',
                    width: 100,
                  },
                ],
              },
              {
                title: '부가세',
                width: 100,
                className: 'tableCellRight',
              },
              {
                title: '배달비합계',
                width: 100,
                className: 'tableCellRight',
              },
            ],
          },
          {
            title: '가맹비(월)',
            children: [
              {
                title: '공급가액',
                className: 'tableCellRight',
                width: 100,
              },
              {
                title: '부가세',
                className: 'tableCellRight',
                width: 100,
              },
              {
                title: '합계',
                className: 'tableCellRight',
                width: 100,
              },
            ],
          },
          {
            title: '프로모션',
            className: 'tableCellRight',
            width: 100,
          },
          {
            title: '선충전',
            dataIndex: 'frChargePrice',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => <div> {row.frName && comma(data)} </div>,
          },
          {
            title: '기타',
            className: 'statics-table-border tableCellRight',
            width: 100,
          },
        ],
      },
      {
        title: '라이더',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '라이더명',
                dataIndex: 'riderName',
                className: 'tableCellCenter',
                width: 140,
              },
              {
                title: '주민번호',
                dataIndex: 'registrationNumber',
                className: 'tableCellCenter',
                width: 130,
                render: (data, row) => {
                  if (row.riderName && data) {
                    const regiNum1 = data.substring(0, 6);
                    const regiNum2 = data.substring(6, data.length);
                    return <div>{regiNum1 + '-' + regiNum2}</div>;
                  }
                },
              },
              {
                title: '기사수수료 일차감여부',
                dataIndex: 'dailyBatch',
                className: 'tableCellCenter',
                width: 90,
                render: (data) => <div>{data > 0 ? 'O' : ''}</div>,
              },
            ],
          },
          {
            title: '배달건별',
            children: [
              {
                title: '지급대상액 배달비 (기본+할증)',
                dataIndex: 'deliveryPrice',
                className: 'tableCellRight',
                width: 90,
              },
              {
                title: '제외',
                children: [
                  {
                    title: '기사수수료',
                    className: 'tableCellRight',
                    width: 100,
                  },
                  {
                    title: '고용보험',
                    className: 'tableCellRight',
                    width: 100,
                  },
                ],
              },
              {
                title: '배달 프로모션',
                // dataIndex: 'memo',
                className: 'tableCellRight',
                width: 100,
                // render: (data, row) => <div> {data.includes("프로모션") ? "-"+comma(row.ncashDelta) : ""} </div>,
              },
              {
                title: '실지급액',
                dataIndex: 'riderPayout',
                className: 'tableCellRight',
                width: 100,
              },
            ],
          },
          {
            title: '배달 페널티',
            className: 'tableCellRight',
            width: 100,
          },
          {
            title: '라이더 일차감',
            children: [
              {
                title: '렌트비',
                dataIndex: 'dailyBikeRentFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '대여금',
                dataIndex: 'dailyRentFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '산재보험',
                dataIndex: 'dailyInsuranceFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '수리비(특수)',
                dataIndex: 'dailyFixFeeExcep',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
            ],
          },
          {
            title: '라이더 건별차감',
            className: 'statics-table-border',
            children: [
              {
                title: '프로모션',
                dataIndex: 'promotionFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '수리비',
                dataIndex: 'riderFixFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '리스비',
                dataIndex: 'riderLeaseFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '산재보험',
                dataIndex: 'riderIndustrialInsuranceFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '상해보험',
                dataIndex: 'riderAccidentInsuranceFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '과태료',
                dataIndex: 'riderFineFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '물품대',
                dataIndex: 'riderBuyProduct',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '기타',
                dataIndex: 'etcFee',
                className: 'tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
              {
                title: '합계',
                dataIndex: 'totalDeducted',
                className: 'statics-table-border tableCellRight',
                width: 100,
                render: (data, row) => (
                  <div> {row.riderName && data && comma(data)} </div>
                ),
              },
            ],
          },
        ],
      },
      {
        title: '본사 예치금',
        className: 'statics-table-orange2',
        width: 100,
        // rowspan: 2,
        onCell: (_, index) => {
          if (index === 0) {
            return { rowSpan: 2 };
          }
          return {};
        },
        children: [
          {
            title: '입금',
            dataIndex: '',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              const inputFrData = row.frChargePrice + row.etcFee;
              const inputRiderData = row.totalDeducted;
              if (row.frName && inputFrData > 0) {
                return <div>{comma(Math.abs(inputFrData))}</div>;
              } else if (row.riderName && inputRiderData > 0) {
                return <div>{comma(Math.abs(inputRiderData))}</div>;
              } else return <></>;
            },
          },
          {
            title: '출금',
            dataIndex: '',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              const inputFrData = row.frChargePrice + row.etcFee;
              const inputRiderData = row.totalDeducted;
              if (row.frName && inputFrData < 0) {
                return <div>{comma(Math.abs(inputFrData))}</div>;
              } else if (row.riderName && inputRiderData < 0) {
                return <div>{comma(Math.abs(inputRiderData))}</div>;
              } else return <></>;
            },
          },
          {
            title: '잔액',
            dataIndex: 'c9Ncash',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => <div>{comma(data)}</div>,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    console.log('선충전');
    this.getList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.changeFilter !== this.props.changeFilter ||
      prevProps.selectBranch !== this.props.selectBranch ||
      prevProps.businessNumber !== this.props.businessNumber ||
      prevProps.frName !== this.props.frName ||
      prevProps.riderName !== this.props.riderName ||
      prevProps.registrationNumber !== this.props.registrationNumber
    ) {
      this.setState(
        {
          list: [],
          copyList: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({ pagination: pager }, () => this.getList());
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'charge';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.stCost,
          copyList: res.data.stCost,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
      if (this.state.changeFilter > 0) {
        this.selectList(this.state.changeFilter);
      }
    });
  };

  selectList = (type) => {
    const selectType =
      type === 1 ? this.state.change_deliveryPrice : this.state.change_fr;
    let changeList = this.state.setList.filter((x) =>
      selectType.includes(x.category)
    );
    this.setState({ copyList: changeList });
  };
  openMemo = (memo) => {
    Modal.info({
      title: '내용',
      content: <div> {memo} </div>,
      onOk() {},
    });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ dispaly: 'flex', alignItems: 'center' }}>
          {/* <Button
                        className="download-btn"
                        style={{ width: 160}}
                        onClick={() => this.onDownload()}>
                        <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                        엑셀 다운로드
                    </Button>    */}
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.copyList}
          columns={this.columns}
          className="staticsCost-table"
          pagination={false}
          onChange={this.handleTableChange}
          bordered
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              const pagination = { ...this.state.pagination };
              if (this.state.list.length < pagination.pageSize) {
                alert('마지막 페이지입니다.');
                return;
              }
              pagination.current = pagination.current + 1;
              this.setState({ pagination }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(StaticsTableCharge);
