import { Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import { SearchOutlined } from '@ant-design/icons';

const AgencySelectBranchDialog = (props) => {
  const [branchList, setBranchList] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 5,
  });

  useEffect(async () => {
    await getData();
  }, []);
  const getData = async (page) => {
    let current = pagination.current;
    if (page > 0) current = page;
    const res = await httpGet(httpUrl.getAgencyBranchList, [search, pagination.pageSize, current], {});
    if (res) {
      setBranchList(res.data);
      setPagination({
        ...pagination,
        current: current,
        total: res.data.totalCount,
      });
    }
  };
  const onSelect = (data) => {
    if (props.callback) {
      props.callback(data);
    }
    props.close();
  };
  const columns = [
    {
      title: '대리점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
      render: (data, row) => (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onSelect(row);
          }}>
          {data}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="Dialog-overlay" onClick={props.close} />
      <div className="deposit-Dialog">
        <div className="deposit-content">
          <div>
            <div className="deposit-title">대리점 조회</div>
            <img
              onClick={props.close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="deposit-close"
              alt="닫기"
            />
          </div>
          <div className={'ncl-input-box'} style={{ width: 300, marginTop: 16 }}>
            <Input
              onPressEnter={()=> {
                getData(1);
              }}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={'대리점명을 검색해주세요'}
              style={{
                border: 'none',
                paddingLeft: 10,
                width: 260,
              }}
            />
            <SearchOutlined style={{ padding: '0 10px', cursor: 'pointer' }} />
          </div>
          <div className={'ncl-table'}>
            <Table
              className="searchRider-table"
              rowKey={(record) => record.idx}
              dataSource={branchList}
              columns={columns}
              pagination={pagination}
              onChange={(pagination) =>
                getData(pagination.current)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgencySelectBranchDialog;
