import { Button, Form, Input, Modal } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;

class LoginLimitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      isModifyMode: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // this.getList();
    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data, password: '' });
      this.setState({
        isModifyMode: true,
      });
    }
  }

  handleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue());
    httpPost(
      httpUrl.releaseLoginLimit,
      [],
      this.formRef.current.getFieldsValue()['userId']
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        Modal.info({
          title: '해제성공',
          content: '해당 유저의 접속제한을 해제했습니다.',
        });
        this.props.close();
      } else if (res.data === 'ID_NOT_FOUND') {
        Modal.warn({
          title: '해제실패',
          content: '존재하지 않는 아이디입니다.',
        });
      } else {
        Modal.warn({
          title: '해제실패',
          content: '접속제한 해제에 실패했습니다.',
        });
      }
    });
  };

  render() {
    const { close } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="systemKey-title">접속제한 해제</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />

            <div className="systemKey-inner">
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                className="create-control-form">
                <div className="create-control-form-item-title">아이디</div>
                <FormItem
                  name="userId"
                  className="create-control-form-item"
                  rules={[
                    {
                      required: true,
                      message: '아이디를 입력해주세요',
                    },
                  ]}>
                  <Input
                    className="create-control-input"
                    placeholder="아이디"
                  />
                </FormItem>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-control-submit-btn"
                  style={{ width: '100%' }}>
                  해제하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginLimitDialog;
