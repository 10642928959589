import {
  Form,
  Table,
  Typography
} from 'antd';
import React, { Component } from 'react';
import {httpGet, httpUrl} from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import {comma} from "../../lib/util/numberUtil";

class BranchSettlementHistoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        hideOnSinglePage: true,
        pageSize: 20,
        current: 1,
      },
      isSales: true,
      list: [],
      branchSettlementIdx: 0,
      type: 0,
    };
    this.formRef = React.createRef();

  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        branchSettlementIdx: this.props.data.branchSettlementIdx,
        type: this.props.data.type,
      }, () => {
        this.getList();
      });
    };
  }
  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const branchSettlementIdx = this.state.branchSettlementIdx;
    const type = this.state.type;


    httpGet(httpUrl.getDetailHistory, [branchSettlementIdx, type, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);

      this.setState({
        pagination: {
          ...this.state.pagination,
          total: res.data.totalCount,
        },
        list: res.data.list,
        branchSettlementIdx: res.data.branchSettlementIdx,
      });
    });

  };

  render() {
    const columns = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      {
        title: '변경 전',
        dataIndex: 'beforeAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '변경 후',
        dataIndex: 'afterAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '변경 값',
        dataIndex: 'amountDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '작성자',
        dataIndex: 'adminId',
        className: 'table-column-center',
        render: (data) => <div>{data == null ? '' : data}</div>,
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
      }
    ];


    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div
          className="systemKey-Dialog">
          <div className="systemKey-content" style={{ height: '100%' }}>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="exit"
            />
            <div className="systemKey-inner2" style={{ height: '90%' }}>
              <div className="settlement-container">
                <Table
                    className="searchSettlement-table"
                    dataSource={this.state.list}
                    columns={columns}
                    pagination={this.state.pagination}
                    style={{ border: '1px solid black'}}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BranchSettlementHistoryDialog;
