import { Button, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { httpGet, httpGetWithObjectParam, httpPost, httpUrl } from "../../api/httpClient";
import NoticeFormDialog from "../../components/dialog/notice/NoticeFormDialog";
import { noticeCategoryCode } from "../../lib/util/codeUtil";

const newNotice = {
  idx: null,
  branchIdx: null,
  category: null,
  title: null,
  createDate: null,
  sortOrder: null,
}

const BranchListSelectBox = ({ branchList, branchIdx, setBranchIdx }) => {
  return <Select
    style={{
      width: 200,
    }}
    value={branchIdx}
    onChange={value => setBranchIdx(value)}
  >
    {branchList.map((branch) => {
      return (
        <Select.Option key={branch.idx} value={branch.idx}>
          {branch.branchName}
        </Select.Option>
      );
    })}
  </Select>
}

export default function NoticeManagement() {
  const [branchList, setBranchList] = useState([]);
  const [branchIdx, setBranchIdx] = useState(0);
  const [category, setCategory] = useState(null);
  const [selectedNotice, setSelectedNotice] = useState(null);

  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(async () => {
    const branchList = await getAllBranchList();
    if (branchList == null) {
      Modal.warn({
        title: '지점 목록 조회 실패',
        content: '지점 목록 조회에 실패했습니다.',
      });
    } else {
      branchList.unshift({
        idx: 0,
        branchName: '전체',
      });
      setBranchList(branchList);
    }
  }, []);

  useEffect(async () => {
    await getNoticeList();
  }, [category, branchIdx, pagination.current, pagination.pageSize]);

  const getAllBranchList = async () => {
    try {
      const res = await httpGet(httpUrl.getBranchListAll, []);
      return res.data;
    } catch (e) {
      console.error('Error while getAllBranchList', e);
      return null;
    }
  };

  const getNoticeList = async () => {
    try {
      const res = await httpGetWithObjectParam(
        httpUrl.noticeAdminList,
        {
          category,
          branchIdx,
          pageNum: pagination.current,
          pageSize: pagination.pageSize,
        },
        {}
      );
      if (res?.data && res?.data?.result && res?.data?.notices) {
        if (res?.data?.result !== 'SUCCESS') {
          Modal.warn({
            title: '조회실패',
            content: `${res?.data?.resultMsg}`,
          });
        } else {
          setList(res?.data?.notices);
          setPagination({
            ...pagination,
            total: res?.data?.totalCount,
          });
        }
      } else {
        Modal.warn({
          title: '조회실패',
          content: '공지목록 조회에 실패했습니다. 관리자에게 문의하세요.',
        });
      }
    } catch (e) {
      console.error('Error while getNoticeList', e);
      Modal.warn({
        title: '조회실패',
        content: '공지목록 조회에 실패했습니다. 관리자에게 문의하세요.',
      });
    }
  };

  const deleteNotice = async (idx) => {
    try {
      const res = await httpPost(httpUrl.deleteAdminNotice, [], { idx });

      if (
        res?.data
      ) {
        if (res?.data === 'SUCCESS') {
          Modal.info({
            title: '공지사항 삭제 성공',
            content: '공지사항 삭제에 성공했습니다.',
          });

          await getNoticeList();
          setSelectedNotice(null);
        } else {
          Modal.warn({
            title: '공지사항 수정 실패',
            content: '공지사항 삭제에 실패했습니다.',
          });
        }
      } else {
        console.error("Error while udpate notice / res.data is null : ", res);

        Modal.warn({
          title: "공지사항 수정 실패",
          content: '공지사항 삭제에 실패했습니다.',
        });
      }
    } catch (e) {
      console.error("Error while udpate notice : ", e);
      Modal.warn({
        title: "공지사항 수정 실패",
        content: '공지사항 삭제에 실패했습니다.',
      });
    }
  }


  const columns = [
    {
      title: '지점',
      dataIndex: 'branchIdx',
      className: 'table-column-center',
      render: (data) => {
        const target = branchList.find((branch) => branch.idx === data);
        if (target === null) return <></>;
        else return <>{target?.branchName}</>;
      },
    },
    {
      title: '구분',
      dataIndex: 'category',
      className: 'table-column-center',
      render: (data) => {
        const target = Object.entries(noticeCategoryCode).find(([key, value]) => value === data);
        if (target === null) return <></>;
        else return <>{target[0]}</>;
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      className: 'table-column-center',
    },
    {
      title: '등록일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
    },
    {
      title: '우선순위',
      dataIndex: 'sortOrder',
      className: 'table-column-center',
    },
    {
      title: '',
      dataIndex: '',
      className: 'table-column-center',
      render: (data, row) => <Button style={styles.button} onClick={() => setSelectedNotice(row)} disabled={row.category === 1}>수정</Button>
    },
    {
      title: '',
      dataIndex: 'idx',
      className: 'table-column-center',
      render: (data) => (
        <Button onClick={async () => {
          Modal.confirm({
            title: '공지사항 삭제',
            content: '해당 공지사항을 삭제하시겠습니까?',
            onOk: async () => {
              await deleteNotice(data)
            },
          })
          }} style={styles.redButton}>
          삭제
        </Button>
      ),
    },
  ];

  const styles = {
    button: {
      borderRadius: 30,
      borderWidth: 2,
      borderColor: 'black',
      fontWeight: 'bold',
      width: 100,
    },
    redButton: {
      borderRadius: 30,
      borderWidth: 2,
      borderColor: 'red',
      color: 'red',
      fontWeight: 'bold',
      width: 100,
    },
  };

  return (
    <>
      {
        selectedNotice && <NoticeFormDialog
          getNoticeList={getNoticeList}
          BranchListSelectBox={BranchListSelectBox}
          branchList={branchList}
          selectedNotice={selectedNotice}
          setSelectedNotice={setSelectedNotice}
        />
      }
      <div
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          padding: 10,
        }}>
        공지사항 관리
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}>
        <div>
          <BranchListSelectBox
            branchList={branchList}
            branchIdx={branchIdx}
            setBranchIdx={setBranchIdx}
          />
          <Select value={category} style={{ marginLeft: 10, width: 200 }} onChange={value => setCategory(value)}>
            {Object.entries(noticeCategoryCode).map(([key, value]) => {
              if (value === 1 || value === 5) return <></>;
              return (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div>
          <Button
            style={styles.button}
            onClick={() => setSelectedNotice(newNotice)}
          >
            등록하기
          </Button>
        </div>
      </div>
      <Table
        style={{
          marginTop: 20,
        }}
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(value) => setPagination(value)}
      />
    </>
  );
}
