import { Button, Input, Modal, Table } from 'antd';
import moment from 'moment';
import 'moment/locale/ko';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import RegistAffiliationBranchDialog from '../../components/dialog/branch/RegistAffiliationBranchDialog';
import { comma } from '../../lib/util/numberUtil';
import BranchHeadOfficeHistoryDialog from '../../components/dialog/branch/BranchHeadOfficeHistoryDialog';
import BranchHeadOfficeReqWithdrawDialog from '../../components/dialog/branch/BranchHeadOfficeReqWithdrawDialog';
import AgencyFeeSettingDialog from '../../components/agencyDialog/AgencyFeeSettingDialog';

moment.locale('ko');

const BranchHeadOfficeInfo = () => {
  const [list, setList] = useState([]);
  const [registModalOpen, setRegistModalOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [agencyFeeModalOpen, setAgencyFeeModalOpen] = useState(false);
  const [selectRow, setSelectRow] = useState(null);
  const [branchHeadOfficeFee, setBranchHeadOfficeFee] = useState(0);
  const [branchIdx, setBranchIdx] = useState(null);
  const [reqWithdraw, setReqWithdraw] = useState(false);
  const [ncash, setNcash] = useState(0);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  const getList = async () => {
    const pageSize = pagination.pageSize;
    const pageNum = pagination.current;
    const res = await httpGet(
      httpUrl.getBranchHeadOfficeInfo,
      [pageNum, pageSize],
      {}
    );
    if (!res) {
      console.log(`getListAsync failed. result: ${res.result}`);
      return;
    }
    console.log(res);
    setList(res.data.list);
    setBranchHeadOfficeFee(res.data.branchHeadOfficeFee);
    setBranchIdx(res.data.branchIdx);
    setNcash(res.data.ncash);
    setPagination({
      ...pagination,
      current: res.data.currentPage,
      total: res.data.totalCount,
    });
  };

  const handleImmediateWithdrawalSubmit = (value, data) => {
    const statusText = value == true ? '승인' : '승인 취소';
    Modal.confirm({
      title: '즉시 출금 가능 여부',
      content: <div>{statusText} 하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateBranchHeadOffice, [], {
          branchIdx: data.branchIdx,
          immediateWithdrawalAbailable: value,
          withdrawalRequestStatus: null,
        }).then((res) => {
          if (res) {
            Modal.info({
              title: statusText + ' 완료',
              content:
                '즉시 출금 가능 여부 ' + statusText + '을(를) 완료했습니다.',
            });
            getList();
          } else {
            Modal.info({
              title: statusText + ' 실패',
              content:
                '즉시 출금 가능 여부 ' +
                statusText +
                '을(를) 실패했습니다. 관리자에게 문의해주세요.',
            });
          }
        });
      },
    });
  };
  const handleWithdrawalRequestSubmit = (data) => {
    Modal.confirm({
      title: '출금 요청 승인',
      content: <div>승인 하시겠습니까?</div>,
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.updateBranchHeadOffice, [], {
          branchIdx: data.branchIdx,
          immediateWithdrawalAbailable: null,
          withdrawalRequestStatus: false,
        }).then((res) => {
          if (res) {
            Modal.info({
              title: '출금 요청 승인 완료',
              content: '출금 요청 승인을 완료했습니다.',
            });
            getList();
          } else {
            Modal.info({
              title: '출금 요청 승인 실패',
              content:
                '출금 요청 승인에 실패했습니다. 관리자에게 문의해주세요.',
            });
          }
        });
      },
    });
  };

  const handleTableChange = (page) => {
    setPagination({
      ...pagination,
      current: page.current,
    });
  };

  const handleRegistModal = (value) => {
    getList();
    setRegistModalOpen(value);
  };
  const handleAgencyFeeModal = (value) => {
    getList();
    setAgencyFeeModalOpen(value);
  };
  const handleReqWithdrawModal = (value) => {
    setReqWithdraw(value);
    getList();
  };

  const columns = [
    {
      title: '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '대리점잔액',
      dataIndex: 'ncash',
      className: 'table-column-center',
    },
    {
      title: '즉시 출금 가능 여부',
      dataIndex: 'immediateWithdrawalAbailable',
      className: 'table-column-center',
      render: (data, row) => {
        return (
          <>
            <div>{data == true ? '즉시 출금 가능' : '즉시 출금 불가능'}</div>
            <Button
              onClick={() => {
                handleImmediateWithdrawalSubmit(true, row);
              }}
              style={{ marginRight: 6 }}
              disabled={data === true}>
              승인
            </Button>
            <Button
              onClick={() => {
                handleImmediateWithdrawalSubmit(false, row);
              }}
              style={{ marginLeft: 6 }}
              disabled={data === false}>
              승인 취소
            </Button>
          </>
        );
      },
    },
    {
      title: '출금승인여부(즉시 출금 불가능 지점)',
      dataIndex: 'withdrawalRequestStatus',
      className: 'table-column-center',
      render: (data, row) => {
        return (
          <>
            <div>
              {data == true ? (
                <div>
                  {' '}
                  {comma(row.withdrawalAmount) + ' 원 요청중'}
                  <div style={{ marginRight: 6 }}></div>
                  <Button
                    onClick={() => {
                      handleWithdrawalRequestSubmit(row);
                    }}
                    style={{ marginRight: 6 }}>
                    승인
                  </Button>
                </div>
              ) : (
                '-'
              )}
            </div>
          </>
        );
      },
    },
    {
      title: '히스토리',
      className: 'table-column-center',
      render: (data, row) => {
        return (
          <>
            <Button
              onClick={() => {
                setHistoryOpen(true);
                setSelectRow(row);
              }}
              style={{ marginRight: 6 }}>
              보기
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getList();
  }, [pagination.current]);

  return (
    <div>
      {registModalOpen && (
        <RegistAffiliationBranchDialog
          callback={setRegistModalOpen}
          close={() => handleRegistModal(false)}
        />
      )}
      {agencyFeeModalOpen && (
        <AgencyFeeSettingDialog
          callback={getList}
          branchIdx={branchIdx}
          fee={branchHeadOfficeFee}
          close={() => handleAgencyFeeModal(false)}
        />
      )}
      {historyOpen && (
        <BranchHeadOfficeHistoryDialog
          data={selectRow}
          callback={setHistoryOpen}
          close={() => setHistoryOpen(false)}
        />
      )}
      {reqWithdraw && (
        <BranchHeadOfficeReqWithdrawDialog
          callback={setReqWithdraw}
          close={() => handleReqWithdrawModal(false)}
        />
      )}
      <h1 className={'agency-page-title'}>출금 승인</h1>
      <div className={'ncl-ctg-box'}>
        <div
          className="ncl-batch-btn active"
          onClick={() => {
            handleRegistModal(true);
          }}>
          관리 지점 등록
        </div>
        <div
          className="ncl-batch-btn"
          onClick={() => {
            handleAgencyFeeModal(true);
          }}>
          대리점 수수료 설정
        </div>
        <div
          className="ncl-batch-btn"
          onClick={() => {
            handleReqWithdrawModal(true);
          }}>
          출금
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 16,
        }}>
        <p>
          본사 대리점 수수료:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {comma(branchHeadOfficeFee)}
          </span>{' '}
          원 (건당)
        </p>
        <p style={{ margin: '0 16px' }}>|</p>
        <p>
          본사 잔액: <span style={{ fontWeight: 'bold' }}>{comma(ncash)}</span>{' '}
          원
        </p>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default BranchHeadOfficeInfo;
