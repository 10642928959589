import { Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import { formatDate } from '../../lib/util/dateUtil';
import CloseIcon from '../../img/close.png';

class RiderAccountModifyRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
    };
  }

  componentDidMount() {
    console.log('넘겨받음!!!!');
    console.log(this.props.data);

    this.getList();
  }

  getList = () => {
    const { data } = this.props;
    const pagination = this.state.pagination;

    httpGet(
      httpUrl.riderAccountModifyList,
      [data.userIdx, pagination.current, pagination.pageSize],
      {}
    ).then((res) => {
      console.log('ggggggggggg');
      console.log(res);
      if (res.result === 'SUCCESS') {
        res.data.logList.forEach(item => {
          if (item.userId == null) {
            if (this.props.data.userId != null && this.props.data.userId.length > 0) {
              item.userId = this.props.data.userId;
            }
          }
        });
        this.setState({
          list: res.data.logList,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  render() {
    const { data, close } = this.props;

    const columns = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '구분',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data) => <div>{data === 1 ? '관리자' : '관제'}</div>,
      },
      {
        title: 'idx',
        dataIndex: 'userIdx',
        className: 'table-column-center',
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
      },
      {
        title: 'ID',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '변경내용',
        dataIndex: 'updateContent',
        className: 'table-column-center',
      },
      {
        title: '변경자ID',
        dataIndex: 'adminId',
        className: 'table-column-center',
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="registRider-Dialog2">
          <div className="registRider-content">
            <div className="registRider-title">주요수정이력</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="registRider-close"
              alt="close"
            />
            <div className="registRiderLayout">
              <div className="registRiderWrapper">
                <Table
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                  style={{ marginTop: '10px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderAccountModifyRecord;
