import { Button, DatePicker, Input, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import { httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import { bankCharge } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import ExcelIcon from '../../img/excel.png';

const RangePicker = DatePicker.RangePicker;
const dateFormat = 'YYYY/MM';
const Option = Select.Option;
const Search = Input.Search;

const initialPaginationExcel = {
  total: null,
  totalRes: [],
  current: null,
};

class BankChargeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },

      list: [],
      category: '',
      startDate: moment(),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let startDate = this.state.startDate.format('YYYY-MM-DD');
    let endDate = this.state.endDate.format('YYYY-MM-DD');
    let category = this.state.category;
    console.log('startdate ' + startDate);
    httpPost(httpUrl.bankCharge, [], {
      pageNum,
      pageSize,
      startDate,
      endDate,
      category,
    }).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.bankCharges,
        pagination,
      });
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        category: value === 'NONE' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };
  onDownload = async () => {
    let pageNum = 1;
    let pageSize = 1000000;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let category = this.state.category;
    httpPost(httpUrl.bankCharge, [], {
      pageNum,
      pageSize,
      startDate,
      endDate,
      category,
    }).then((res) => {
      const excelJson = this.parseExcelJson(res.data.bankCharges);
      const ws = XLSX.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = XLSX.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][0] = { width: 20 };
      ws['!cols'][1] = { width: 20 };
      ws['!cols'][2] = { width: 20 };
      ws['!cols'][3] = { width: 20 };
      ws['!cols'][4] = { width: 30 };
      XLSX.utils.book_append_sheet(wb, ws, 'sheet1');
      XLSX.writeFile(wb, '은행수수료내역.xlsx');
    });
  };
  parseExcelJson = (data) => {
    let result = [
      {
        createDate: '일시',
        frName: '상점명',
        ownerName: '대표자명',
        businessNumber: '사업자번호',
        roadAddress: '도로명주소',
        jibunAddress: '지번주소',
        email: '이메일',
        riderName: '라이더명',
        registrationNumber: '주민번호',
        chargeAmount: '징수금액',
        pg: 'PG 수수료',
        ncashDelta: '본사손익',
      },
    ];

    data.forEach((item) => {
      result.push({
        createDate: item.createDate,
        frName: item.frName,
        ownerName: item.ownerName,
        businessNumber: item.businessNumber,
        roadAddress: item.addr1 + item.addr2,
        jibunAddress: item.addr3 + item.addr2,
        email: item.email,
        riderName: item.riderName,
        registrationNumber: item.registrationNumber,
        chargeAmount: 300,
        pg: item.category === 'NCASH_CHARGE_PROC' ? 300 : 110,
        ncashDelta: item.category === 'NCASH_CHARGE_PROC' ? 0 : 190,
      });
    });
    return result;
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '카테고리',
        dataIndex: 'category',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{bankCharge[data]}</div>,
      },
      {
        title: '상점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '대표자명',
        dataIndex: 'ownerName',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '주소',
        dataIndex: '',
        className: 'table-column-center',
        width: 100,
        render: (data, row) => <div>{row.addr1 + row.addr2}</div>,
      },
      {
        title: '이메일',
        dataIndex: 'email',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '주민번호',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{data}</div>,
      },
      {
        title: '징수금액',
        dataIndex: 'category',
        className: 'table-column-center',
        width: 100,
        render: () => <div>{300}</div>,
      },
      {
        title: 'PG수수료',
        dataIndex: 'category',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{
          data === 'NCASH_CHARGE_PROC' ? 300 : 110
        }</div>,
      },
      {
        title: '본사손익',
        dataIndex: 'category',
        className: 'table-column-center',
        width: 100,
        render: (data) => <div>{
          data === 'NCASH_CHARGE_PROC' ? 0 : 190
        }</div>,
      },
    ];
    return (
      <>
        <SelectBox
          placeholder={'전체'}
          style={{ width: 200, marginBottom: 20 }}
          value={bankCharge[this.state.category]}
          code={Object.keys(bankCharge)}
          codeString={bankCharge}
          onChange={(value) => {
            if (parseInt(value) !== this.state.category) {
              this.onChangeStatus(value);
            }
          }}
        />
        <Button
          className="download-btn"
          style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
          onClick={async () => await this.onDownload()}>
          <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
          엑셀 다운로드
        </Button>
        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          value={[this.state.startDate, this.state.endDate]}
          onChange={(date) => {
            if (date[(0, 1)]) {
              this.setState(
                {
                  startDate: date[0],
                  endDate: date[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            } else {
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default BankChargeHistory;
