import React, { Component } from 'react';
import { Form, Input, Table, Select } from 'antd';
import {
  ownerType,
  bikeLocationType,
  bikeHistoryType,
} from '../../lib/util/codeUtil';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { formatDates } from '../../lib/util/dateUtil';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

class BikeHistoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      bikeLocation: {},
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.setState(
      {
        isModifyMode: true,
      },
      () => this.getList()
    );
  }

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = async () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let bikeIdx =
      this.props.data.idx === undefined
        ? this.props.data.bikeIdx
        : this.props.data.idx;
    await httpGet(
      httpUrl.getBikeHistoryList,
      [bikeIdx, pageNum, pageSize],
      {}
    ).then((res) => {
      console.log(res);
      this.setState({
        list: res.data.bikeHistory,
        pagination: {
          ...this.state.pagination,
          total: res.data.totalCount,
        },
      });
    });
    await httpPost(httpUrl.bikeLocationInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.map((item) => (bikeLocationType[item.idx] = item.branchName));

        this.setState(
          {
            bikeLocation: bikeLocationType,
          },
          () => {
            console.log('bikeLocation');
            console.log(this.state.bikeLocation);
          }
        );
      }
    });
  };

  sequenceNumber = (data) => {
    let seqNumber = data;
    return seqNumber;
  };

  render() {
    const columns = [
      {
        title: '순서',
        dataIndex: 'rowNum',
        className: 'table-column-center',
      },
      {
        title: '타입',
        dataIndex: 'historyType',
        calssName: 'table-column-center',
        render: (data) => bikeHistoryType[data],
      },
      {
        title: '미배정 바이크 위치',
        dataIndex: 'unassignBikeLocation',
        className: 'table-column-center',
        render: (data) => this.state.bikeLocation[data],
      },
      {
        title: '배정된 지점',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '배정된 라이더',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '변경일',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => formatDates(data),
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">바이크 히스토리</div>
              <br />
              차종 : {this.props.data.modelName} &nbsp;&nbsp; 차량번호 :{' '}
              {this.props.data.bikeNumber} &nbsp;&nbsp; 연식 :{' '}
              {this.props.data.modelYear} &nbsp;&nbsp; 차대번호 :{' '}
              {this.props.data.viNumber} &nbsp;&nbsp; 명의자 :{' '}
              {ownerType[this.props.data.owner]}
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              <Table
                className="searchRider-table"
                rowKey={(record) => record}
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BikeHistoryDialog;
