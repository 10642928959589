import { Button, Checkbox, Form, Input, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import axios from 'axios';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl, makeUrl } from '../../../api/httpClient';
import SelectBox from '../../../components/input/SelectBox';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';
import {
  skinPopupCategoryCode,
  skinPopupStatusCode,
} from '../../../lib/util/codeUtil';

class RegistSkinDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      branchList: [],
      category: 0,
      file: null,
      imageFileChanged: false,
      deleted: 0,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      const { data } = this.props;
      this.setState(
        {
          isModifyMode: true,
          idx: data.idx,
          branchName: data.branchName,
          category: data.category,
          title: data.title,
          deleted: data.deleted,
        });
    } else {
      this.getBranchList();
    }
  }

  getBranchList = async () => {
    let array = [];
    let object = { label: '전체', value: 0 };
    const res = await httpGet(httpUrl.getBranchList, [1, 1000], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data.branch);
        res.data.branch.map((item) => {
          object['label'] = item.branchName;
          object['value'] = item.idx;
          array.push({ ...object });
        });
        this.setState(
          {
            branchList: array,
          },
          () => {
            console.log('branchList');
            console.log(this.state.branchList);
          }
        );
      }
  };

  handleImageFile = async () => {
    if (!this.state.file) return;
    var formData = new FormData();
    formData.append('file', this.state.file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      console.log(res);
      return res;
    } else {
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  handleSubmit = async () => {
    const { data, close } = this.props;
    const formData = this.formRef.current.getFieldsValue();
    /** selectbox에 initialValue 가 문자열로 걸려있어서 onChange 안되어 있으면 다시 숫자로 파싱*/
    if (isNaN(formData['category'])) {
      formData['category'] === '가맹점로그인스킨'
        ? (formData['category'] = 1)
        : formData['category'] === '가맹점로고스킨'
        ? (formData['category'] = 2)
        : formData['category'] === '가맹점로고배경스킨'
        ? (formData['category'] = 3)
        : formData['category'] === '가맹점배달현황스킨'
        ? (formData['category'] = 4)
        : formData['category'] === '가맹점접수요청스킨'
        ? (formData['category'] = 5)
        : formData['category'] === '가맹점예치금내역스킨'
        ? (formData['category'] = 6)
        : formData['category'] === '가맹점설정스킨'
        ? (formData['category'] = 7)
        : formData['category'] === '가맹점로그아웃스킨'
        ? (formData['category'] = 8)
        : formData['category'] === '가맹점배너배경스킨'
        ? (formData['category'] = 9)
        : formData['category'] === '가맹점팝업'
        ? (formData['category'] = 10)
        : formData['category'] === '라이더로그인스킨'
        ? (formData['category'] = 11)
        : formData['category'] === '라이더주문상단스킨'
        ? (formData['category'] = 12)
        : formData['category'] === '라이더메뉴상단스킨'
        ? (formData['category'] = 13)
        : (formData['category'] = 14)
    }
    if (isNaN(formData['deleted'])) {
      formData['deleted'] === '노출'
        ? (formData['deleted'] = 0)
        : (formData['deleted'] = 1);
    }
    if (this.state.imageFileChanged) {
      const uploadRes = await this.handleImageFile();

      if (uploadRes.data.result === 'SUCCESS') {
        formData['uploadFileIdx'] = uploadRes.data.data.idx;
        console.log(typeof formData.uploadFileIdx);
      } else {
        Modal.warn({
          title: '등록실패',
          content: '파일업로드에 실패했습니다.',
        });
        return;
      }
    }
    formData['idx'] = data ? data.idx : null;
    console.log({ formData });

    const res = await httpPost(
      this.state.isModifyMode
        ? httpUrl.updateSkinPopup
        : httpUrl.registSkinPopup,
      [],
      formData
    );
    console.log({ res });

    if (res.data) {
      Modal.info({
        title: '등록/수정 성공',
        content: '등록/수정이 성공적으로 완료되었습니다.',
      });
      this.props.getList();
      close();
    } else if (res.data === 'INVALID_REQUEST') {
      Modal.warn({
        title: '등록실패',
        content: '요청을 한번 더 확인해주세요.',
      });
      return;
    } else if (res.data === 'NO_SELECTED_BRANCH') {
        Modal.warn({
          title: '등록실패',
          content: '적용지점을 확인해주세요.',
        });
        return;
      } else {
      Modal.warn({
        title: '등록실패',
        content: '상품등록에 실패했습니다.',
      });
      return;
    }
  };

  onChangeStatus = (value) => {
    this.setState({
        deleted: parseInt(value),
      });
  };

  onChangeCategory = (value) => {
    this.setState({
        category: parseInt(value),
      });
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">
                {data ? data.branchName + ' 스킨/팝업 수정' : '스킨/팝업 등록'}
              </div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
              <div className="systemKey-inner">
                <Form
                  ref={this.formRef}
                  onFinish={this.handleSubmit}
                  className="create-control-form">
                  <div className="regist-warp">
                    <div className="regist-title2">제목</div>
                    <div className="regist-input2">
                      <FormItem
                        name="title"
                        className="create-control-form-item"
                        rules={[
                          {
                            required: true,
                            message: '제목을 입력해주세요',
                          },
                        ]}
                        initialValue={data ? data.title : ''}
                        style={{ width: 250 }}>
                        <Input placeholder="제목을 입력해주세요." />
                      </FormItem>
                    </div>
                  </div>
                  {data ? (
                    <div className="regist-warp">
                      <div className="regist-title2">상태</div>
                      <div>
                        <FormItem
                          className="create-control-form-item"
                          name="deleted"
                          rules={[
                            {
                              required: true,
                              message: '상태를 선택해주세요',
                            },
                          ]}
                          initialValue={
                            data && skinPopupStatusCode[data.deleted]
                          }
                          style={{ width: 250 }}>
                          <SelectBox
                            style={{ width: '120px' }}
                            value={this.state.deleted}
                            code={Object.keys(skinPopupStatusCode)}
                            codeString={skinPopupStatusCode}
                            onChange={(value) => {
                              if (parseInt(value) !== this.state.deleted) {
                                this.onChangeStatus(value);
                              }
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                  ) : (
                    <div className="regist-warp">
                      <div className="regist-title2">적용지점</div>
                      <div className="regist-input2">
                        <FormItem
                          name="branchList"
                          className="create-control-form-item"
                          rules={[
                            {
                              required: true,
                              message: '적용지점을 입력해주세요',
                            },
                          ]}
                          initialValue={data ? data.title : ''}>
                          <Checkbox.Group
                            options={this.state.branchList}
                            value={this.state.branchList}
                            onChange={(value) => {
                              this.formRef.current.setFieldsValue(
                                'branchList',
                                value
                              );
                            }}
                          />
                        </FormItem>
                      </div>
                    </div>
                  )}
                  <div className="regist-warp">
                    <div className="regist-title2">분류</div>
                    <div>
                      <FormItem
                        className="create-control-form-item"
                        name="category"
                        rules={[
                          {
                            required: true,
                            message: '카테고리를 선택해주세요',
                          },
                        ]}
                        initialValue={
                          data && skinPopupCategoryCode[data.category]
                        }
                        style={{ width: 250 }}>
                        <SelectBox
                          style={{ width: '160px' }}
                          value={this.state.category}
                          code={Object.keys(skinPopupCategoryCode)}
                          codeString={skinPopupCategoryCode}
                          onChange={(value) => {
                            if (parseInt(value) !== this.state.category) {
                              this.onChangeCategory(value);
                            }
                          }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="regist-warp">
                    <div className="regist-title2">파일첨부</div>
                    <div className="regist-input2">
                      <FormItem
                        className="create-control-form-item"
                        rules={[
                          {
                            required: true,
                            message: '제목을 입력해주세요',
                          },
                        ]}
                        style={{ width: 250 }}>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            this.setState({
                              file: e.target.files[0],
                              imageFileChanged: true,
                            });
                          }}
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="registBike-btn">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        backgroundColor: 'black',
                        borderColor: 'black',
                        color: 'white',
                        width: 100,
                        height: 40,
                        fontSize: 16,
                        marginTop: 5,
                      }}>
                      저장
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistSkinDialog;
