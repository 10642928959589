import React, { Component } from 'react';
import { Table } from 'antd';
import { branchHeadOfficeHistoryType} from '../../../lib/util/codeUtil';
import { httpGet, httpPost, httpUrl } from '../../../api/httpClient';
import '../../../css/main.css';
import CloseIcon from '../../../img/close.png';
import { comma } from '../../../lib/util/numberUtil';

class BranchHeadOfficeHistoryDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      bikeLocation: {},
      branchNameList: { 0: '전체지점' },
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    try {
      const res = await httpPost(httpUrl.branchListInfo, [], {});
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
            (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState({
          branchNameList: branchNameList,
        });
      }
    } catch (error) {
      console.warn('getBranchInfo error: {}', error);
    }
  };

  getList = async () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.data.branchIdx;
    await httpGet(
      httpUrl.getBranchHeadOfficeHistory,
      [branchIdx, pageNum, pageSize],
      {}
    ).then((res) => {
      console.log(res);
      this.setState({
        list: res.data.list,
        pagination: {
          ...this.state.pagination,
          total: res.data.totalCount,
        },
      });
    });
  };


  render() {
    const columns = [
      {
        title: '날짜',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      {
        title: '대리점 본사',
        dataIndex: 'headBranchIdx',
        calssName: 'table-column-center',
        render: (data) => this.state.branchNameList[data],
      },
      {
        title: '대리점',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
        render: (data) => this.state.branchNameList[data],
      },
      {
        title: '타입',
        dataIndex: 'historyType',
        className: 'table-column-center',
        render: (data) => branchHeadOfficeHistoryType[data],
      },
      {
        title: '승인 된 출금 내역',
        dataIndex: 'withdrawalAmount',
        className: 'table-column-center',
        render: (data) => <div>{ data ? comma(data) + ' 원' : '-'}</div>,
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">히스토리</div>
              <br />
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              <Table
                className="searchRider-table"
                rowKey={(record) => record}
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BranchHeadOfficeHistoryDialog;
