import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const RdnrList = () => {
  const formRef = React.createRef();
  const today = new Date();
  const [list, setList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  useEffect(async () => {
    getBranchList();
  }, []);
  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize]);

  const getList = async () => {
    console.log('formRef.current.getFieldValue("statDate")');
    console.log(formRef.current.getFieldValue('statDate'));
    console.log({
      branchIdx: formRef.current.getFieldValue('branchIdx'),
      statDate: formRef.current.getFieldValue('statDate')
        ? formRef.current.getFieldValue('statDate').format('YYYY-MM-DD')
        : moment(today).format('YYYY-MM-DD'),
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
      searchName: formRef.current.getFieldValue('searchName'),
    });
    const res = await httpPost(httpUrl.getRdnrList, [], {
      branchIdx: formRef.current.getFieldValue('branchIdx'),
      statDate: formRef.current.getFieldValue('statDate')
        ? formRef.current.getFieldValue('statDate').format('YYYY-MM-DD')
        : moment(today).format('YYYY-MM-DD'),
      pageSize: pagination.pageSize,
      pageNum: pagination.current,
      searchName: formRef.current.getFieldValue('searchName'),
    });

    setList(res.data.riderDailyNcashResultList);
    setPagination({ ...pagination, total: res.data.totalCount });
  };

  const getBranchList = async () => {
    const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
    setbranchList([{ idx: null, branchName: '전체' }, ...res.data.branch]);
  };

  const columns = [
    {
      title: '기사명',
      dataIndex: 'name',
      className: 'table-column-center',
    },
    {
      title: '일자',
      dataIndex: 'statDate',
      className: 'table-column-center',
    },
    {
      title: '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '잔액',
      dataIndex: 'ncashResult',
      className: 'table-column-center',
      render: (data) => <div>{comma(data)}원</div>,
    },
  ];
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item style={{ width: 200 }} name="branchIdx">
            <Select onChange={() => getList()} defaultValue={null}>
              {branchList.map((branch) => {
                return (
                  <Select.Option value={branch.idx}>
                    {branch.branchName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="searchName">
            <Input
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  getList();
                }
              }}
              placeholder={'기사명 검색'}
            />
          </Form.Item>
          <Form.Item defaultValue={new moment()} name="statDate">
            <DatePicker onChange={() => getList()} placeholder={'검색일자'} />
          </Form.Item>
        </Form>
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            //   onClick={() => onDownload()}
          >
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
      </div>

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(pagination) => setPagination(pagination)}
      />
    </>
  );
};

export default RdnrList;
