import { DatePicker, Form, Input, Modal, Radio, Select } from "antd";
import { Line } from "@ant-design/charts";
import moment from "moment";
import React, { Component } from "react";
import {httpGet, httpPost, httpUrl} from "../../api/httpClient";
import {joinExitStaticsType, kindString, searchType} from "../../lib/util/codeUtil";
import CloseIcon from "../../img/close.png";
import SelectBox from "../input/SelectBox";

const FormItem = Form.Item;
const today = new Date();
const { RangePicker } = DatePicker;


class StaticsJoinExitGraphDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(today).add(-1, "y").format("YYYY-MM-DD"),
      endDate: moment(today).add(1, "y").format("YYYY-MM-DD"),
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      branchNameList: {0: "전체지점"},
      branchNameValue: 0,
      userTypeList: {0: "없음", 1: "라이더", 2: "가맹점"},
      list: [],
      dateRange: 1,
      userType: 1,
      searchType: joinExitStaticsType[1],
      finalList: [],
    };
    this.formRef = React.createRef();
  }
  componentDidMount() {
    let { data } = this.props;
    data &&
      this.setState({
        startDate: data.startDate,
        endDate: data.endDate,
        branchNameValue: data.branchNameValue,
        dateRange: data.dateRange,
      });
    this.getBranchInfo();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
      if (res.result === "SUCCESS") {
        console.log("res");
        console.log(res.data);
        res.data.branch.map((item) => (branchNameList[item.idx] = item.branchName));
        this.setState(
          {
            branchNameList: branchNameList,
          },
          () => {
            console.log("branchNameList");
            console.log(this.state.branchNameList);
          }
        );
        this.getList();
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      this.getBranchInfo()
    );
  };

  getList = async () => {
    let branchIdx = this.state.branchNameValue;
    let dateRange = this.state.dateRange;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let userType = this.state.userType;

    const res = await httpGet(httpUrl.getJoinExitStatList, [dateRange, branchIdx, userType, startDate, endDate], {});
    if (!res) {
      console.log(`getListAsync failed. result: ${res.result}`);
      return;
    }
    console.log(res);
    this.setState({
      list: res.data.list,
    });

    let tempFianlList = [];

    if (this.state.branchNameValue == 0) {
      for (let i = 0; i < res.data.list.length; i++) {
        let obj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: 0,
          searchType: 0,
        };

        if (this.state.searchType == joinExitStaticsType[1]) {
          // 총원
          obj.searchType = joinExitStaticsType[1];
          obj.count = res.data.list[i].totalMemberCount;
        } else if (this.state.searchType == joinExitStaticsType[2]) {
          // 신규
          obj.searchType = joinExitStaticsType[2];
          obj.count = res.data.list[i].joinCount;
        } else if (this.state.searchType == joinExitStaticsType[3]) {
          // 탈퇴
          obj.searchType = joinExitStaticsType[3];
          obj.count = res.data.list[i].exitCount;
        } else if (this.state.searchType == joinExitStaticsType[4]) {
          // 정지
          obj.searchType = joinExitStaticsType[4];
          obj.count = res.data.list[i].stopCount;
        } else if (this.state.searchType == joinExitStaticsType[5]) {
          // 복귀
          obj.searchType = joinExitStaticsType[5];
          obj.count = res.data.list[i].comebackCount;
        } else if (this.state.searchType == joinExitStaticsType[6]) {
          // 사고
          obj.searchType = joinExitStaticsType[6];
          obj.count = res.data.list[i].accidentCount;
        }

        tempFianlList.push(obj);
      }
    } else {
      for (let i = 0; i < res.data.list.length; i++) {
        if (res.data.list[i].branchIdx != this.state.branchNameValue) continue;

        let totalobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].totalMemberCount,
          searchType: joinExitStaticsType[1],
        };
        tempFianlList.push(totalobj);
        let joinobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].joinCount,
          searchType: joinExitStaticsType[2],
        };
        tempFianlList.push(joinobj);
        let exitobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].exitCount,
          searchType: joinExitStaticsType[3],
        }
        tempFianlList.push(exitobj);
        let stopobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].stopCount,
          searchType: joinExitStaticsType[4],
        }
        tempFianlList.push(stopobj);
        let combackobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].comebackCount,
          searchType: joinExitStaticsType[5],
        }
        tempFianlList.push(combackobj);
        let accidentobj = {
          branchName: this.state.branchNameList[res.data.list[i].branchIdx],
          statDate: res.data.list[i].statDate,
          count: res.data.list[i].accidentCount,
          searchType: joinExitStaticsType[6],
        }
        tempFianlList.push(accidentobj);
      }
    }

    tempFianlList.sort(function (a, b) {
      return a.statDate < b.statDate ? -1 : a.statDate > b.statDate ? 1: 0;
    });

    this.state.finalList = tempFianlList;
    this.setState({
      finalList: tempFianlList,
    });

  };

  onChangeDate = (dateString) => {
    this.setState({
      startDate:
        dateString != null
          ? moment(dateString[0]).format("YYYY-MM-DD")
          : "",
      endDate:
        dateString != null
          ? moment(dateString[1]).format("YYYY-MM-DD")
          : "",
    },
      () => {
        this.getList();
      });
  };

  onChangeDateRange = (e) => {
    this.setState({
      dateRange: e.target.value,
    },
      () => {
        this.state.dateRange === 1
          ? this.setState(
            {
              startDate: moment(today).add(-6, "d").format("YYYY-MM-DD"),
              endDate: moment(today).format("YYYY-MM-DD"),
            },
            () => {
              this.getList();
            }
          )
          : this.state.dateRange === 2
            ? this.setState(
              {
                startDate: moment(today).add(-10, "W").format("YYYY-MM-DD"),
                endDate: moment(today).format("YYYY-MM-DD"),
              },
              () => {
                this.getList();
              }
            )
            : this.setState(
              {
                startDate: moment(today)
                  .add(-1, "Y")
                  .format("YYYY-MM-DD"),
                //.concat("-01"),
                endDate: moment(today).format("YYYY-MM-DD"),
              },
              () => {
                this.getList();
              }
            );

      });
  };

  onChangeUserType = (e) => {
    this.setState({
        userType: e.target.value,
      },
      () => {
        this.getList();
      });
  };

  onChangeSearchType = (e) => {
    this.setState({
        searchType: joinExitStaticsType[e.target.value],
      },
      () => {
        this.getList();
      });
  };

  render() {
    const { close, data } = this.props;
    const {branchNameList} = this.state;

    const config = {
      data: this.state.finalList,
      xField: "statDate",
      yField: "count",
    };

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWork-Dialog">
          <div className="stats-graph-content">
            <Form ref={this.formRef}>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="taskWork-close"
                alt="close"
              />
              <div className="stats-graph-inner">
                <div className="taskWork-list">
                  <div className="twl taskWork-list-01">
                    <div className="twl-text">기간 구분</div>
                    <FormItem
                      name="dateRange"
                      className="selectItem"
                    >
                      <Radio.Group onChange={this.onChangeDateRange} defaultValue={data ? data.dateRange : 1}>
                        <Radio value={1}>일별</Radio>
                        <Radio value={2}>주별</Radio>
                        <Radio value={3}>월별</Radio>
                      </Radio.Group>
                    </FormItem>
                  </div>
                  <div className="twl taskWork-list-02">
                    <div className="twl-text">기간</div>
                    <RangePicker
                      placeholder={["시작일", "종료일"]}
                      value={[
                        moment(this.state.startDate, "YYYY-MM-DD"),
                        moment(this.state.endDate, "YYYY-MM-DD"),
                      ]}
                      onChange={this.onChangeDate}
                      style={{ width: 350 }}
                    />
                  </div>
                  <div className="twl taskWork-list-01">
                    <div className="twl-text">유형 구분</div>
                    <FormItem
                      name="userType"
                      className="selectItem"
                      initialValue={1}
                    >
                      <Radio.Group onChange={this.onChangeUserType} defaultValue={this.state.userType}>
                        <Radio value={1}>라이더</Radio>
                        <Radio value={2}>가맹점</Radio>
                      </Radio.Group>
                    </FormItem>
                  </div>
                  <div className="twl taskWork-list-01">
                    <div className="twl-text">지점</div>
                    <SelectBox
                      value={branchNameList[this.state.branchNameValue]}
                      code={Object.keys(branchNameList)}
                      codeString={branchNameList}
                      onChange={(value) => {
                        if (parseInt(value) !== this.state.branchNameValue) {
                          this.setState(
                            {
                              branchNameValue: parseInt(value),
                            },
                            () => this.getList()
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="twl taskWork-list-04">
                    <div className="taskWork-place1">
                      <FormItem
                        name="type"
                        className="selectItem"
                        initialValue={1}
                      >
                        <Radio.Group onChange={this.onChangeSearchType} defaultValue={this.state.searchType} disabled={this.state.branchNameValue>0}>
                          <Radio value={1}>총원</Radio>
                          <Radio value={2}>신규</Radio>
                          <Radio value={3}>탈퇴</Radio>
                          <Radio value={4}>정지</Radio>
                          <Radio value={5}>복귀</Radio>
                          <Radio value={6}>사고</Radio>
                        </Radio.Group>
                      </FormItem>
                    </div>
                  </div>

                  <div className="twl taskWork-list-05">
                    <div className="stats-graph">
                      <div className="stats-line">
                        <Line seriesField={this.state.branchNameValue > 0 ? "searchType" : "branchName"} {...config} />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StaticsJoinExitGraphDialog;
