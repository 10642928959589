import { Button, DatePicker, Input, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import DeleteDeliveryPriceBasic from '../../components/dialog/DeleteDeliveryPriceBasic';
import RegistDeliveryPriceBasic from '../../components/dialog/RegistDeliveryPriceBasic';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class DeliveryFeeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      branchName: '',
      groupIdx: 0,
      deliveryPriceBasicOpen: false,
      deleteDPBOpen: false,
      data: null,
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let branchName = this.state.branchName;
    let groupIdx = this.state.groupIdx;
    const res = httpGet(
      httpUrl.getDeliveryPriceBasic,
      [branchName, groupIdx],
      {}
    ).then((res) => {
      console.log('res');
      console.log(res);
      this.setState({ list: res.data.list });
    });
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        branchName: value,
      },
      () => this.getList()
    );
  };

  onSearch2 = (value) => {
    this.setState(
      {
        groupIdx: value,
      },
      () => this.getList()
    );
  };

  //배달비등록닫기
  closeDeliveryPriceBasic = () => {
    this.setState({ deliveryPriceBasicOpen: false, data: null }, () =>
      this.getList()
    );
  };

  //배달비삭제닫기
  closeDeleteDPB = () => {
    this.setState({ deleteDPBOpen: false, data: null }, () => this.getList());
  };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '거리별(M)',
        dataIndex: 'distance',
        className: 'table-column-center',
      },
      {
        title: '요금',
        dataIndex: 'price',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '지점번호',
        dataIndex: 'branchIdx',
        className: 'table-column-center',
      },
      {
        title: '그룹번호',
        dataIndex: 'groupIdx',
        className: 'table-column-center',
        render: (data, row) => <div>{data}</div>,
      },
    ];

    return (
      <>
        {this.state.deliveryPriceBasicOpen && (
          <RegistDeliveryPriceBasic
            data={this.state.data}
            close={this.closeDeliveryPriceBasic}
          />
        )}
        {this.state.deleteDPBOpen && (
          <DeleteDeliveryPriceBasic close={this.closeDeleteDPB} />
        )}
        <Search
          placeholder="지점명 검색"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />
        <Search
          placeholder="그룹번호 검색"
          enterButton
          allowClear
          onSearch={this.onSearch2}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />
        <Button
          style={{ float: 'right', marginBottom: 20 }}
          onClick={() =>
            this.setState({
              deleteDPBOpen: true,
            })
          }>
          배달비삭제
        </Button>
        <Button
          style={{ float: 'right', marginBottom: 20 }}
          onClick={() =>
            this.setState({
              deliveryPriceBasicOpen: true,
            })
          }>
          배달비등록
        </Button>

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default DeliveryFeeInfo;
