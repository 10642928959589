import { Button, Table, Modal } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl, httpPost } from '../../api/httpClient';
import { customError } from '../../api/Modals';
import RegistBranchDialog from '../../components/dialog/RegistBranchDialog';
import '../../css/main.css';
import {
  deliveryStatShow,
  distShowSetting,
  addrUpdateEnabled,
  sortOrder,
  distType,
  riderLevel,
  BranchVisible,
} from '../../lib/util/codeUtil';
import {comma} from "../../lib/util/numberUtil";
import moment from "moment"

class BranchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      registBranchModalOpen: false, //지점등록
      modifyBranchModalOpen: false, //지점정보변경
      rowBranchList: [],
    };
    this.columns = [
      {
        title: '지점번호',
        dataIndex: 'idx',
        className: 'table-column-center',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '위도',
        dataIndex: 'latitude',
        className: 'table-column-center',
        render: (data) => Math.round(data * 100) / 100,
      },
      {
        title: '경도',
        dataIndex: 'longitude',
        className: 'table-column-center',
        render: (data) => Math.round(data * 100) / 100,
      },
      {
        title: '전화번호',
        dataIndex: 'tel',
        className: 'table-column-center',
      },
      {
        title: '시작시간',
        dataIndex: 'startTime',
        className: 'table-column-center',
      },
      {
        title: '종료시간',
        dataIndex: 'endTime',
        className: 'table-column-center',
      },
      {
        title: '가맹점 배달현황 통계 노출여부',
        dataIndex: 'deliveryStatShow',
        className: 'table-column-center',
        render: (data) => <>{deliveryStatShow[data]}</>,
      },
      {
        title: '최대배달수수료',
        dataIndex: 'maxDeliveryFee',
        className: 'table-column-center',
      },
      {
        title: '라이더 취소 기본 페널티',
        dataIndex: 'riderCancelPenalty',
        className: 'table-column-center',
      },
      {
        title: '기본배달비',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
      },
      {
        title: '라이더앱 거리설정 노출여부',
        dataIndex: 'distShowSetting',
        className: 'table-column-center',
        render: (data) => <>{distShowSetting[data]}</>,
      },
      {
        title: '배차완료 후 도착지 수정가능여부',
        dataIndex: 'addrUpdateEnabled',
        className: 'table-column-center',
        render: (data) => <>{addrUpdateEnabled[data]}</>,
      },
      {
        title: '정렬방식',
        dataIndex: 'sortOrder',
        className: 'table-column-center',
        render: (data) => <>{sortOrder[data]}</>,
      },
      {
        title: '거리요금방식',
        dataIndex: 'distType',
        className: 'table-column-center',
        render: (data) => <>{distType[data]}</>,
      },
      {
        title: '스테이션장',
        dataIndex: 'stationDirectorName',
        className: 'table-column-center',
        render: (data) => <>{data? data :'-'}</>,
      },
      {
        title: '스테이션장 직급',
        dataIndex: 'stationDirectorLevel',
        className: 'table-column-center',
        render: (data) => <>{data? riderLevel[data] :'-'}</>,
      },
      {
        title: '영업이익',
        dataIndex: 'branchProfitPercent',
        className: 'table-column-center',
        render: (data) => <>{parseInt(data * 100) + '(%)'}</>,
      },
      {
        title: '회사ID',
        dataIndex: 'companyId',
        className: 'table-column-center',
      },
      {
        title: '지점 사용여부',
        dataIndex: 'visible',
        className: 'table-column-center',
        render: (data) => <>{BranchVisible[data]}</>,
      },
      {
        title: '지점수정',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            <Button
              className="tabBtn"
              onClick={() => {
                this.openModifyBranchModal(row);
              }}>
              수정
            </Button>
          </div>
        ),
      },
      {
        title: '프로그램 사용료',
        dataIndex: 'agencyFee',
        className: 'table-column-center',
        render: (data) => <>{comma(data) + "원"}</>,
      },
      {
        title: '프로그램 사용료 적용시작일',
        dataIndex: 'agencyFeeStartDate',
        className: 'table-column-center',
        render: (data) => <>{data ? moment(data).format('YYYY-MM-DD') : "-"}</>,
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  // 리스트 조회 api
  getList = () => {
    const pageSize = this.state.pagination.pageSize; //221108 hms
    const pageNum = this.state.pagination.current; //221108 hms
    httpGet(httpUrl.getBranchListSetting, [pageNum, pageSize], {})
      .then((res) => {
        if (res.result === 'SUCCESS') {
          this.setState({
            list: res.data.branch,
            pagination: {
              ...this.state.pagination,
              current: res.data.currentPage,
              total: res.data.totalCount,
            },
          });
        }
      })
      .catch((e) => {
        customError('목록 에러', '에러가 발생하여 목록을 불러올수 없습니다.');
      });
  };

  // 페이징
  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };
  //지점 정보 등록 modal 221109 hms
  openCreateBranchModal = (row) => {
    this.setState({ registBranchModalOpen: true });
  };
  closeCreateBranchModal = () => {
    this.setState({ registBranchModalOpen: false }, () => {
      this.getList();
    });
  };

  //지점 정보 변경 modal 221110 hms
  openModifyBranchModal = (row) => {
    console.log(row);
    this.setState({ modifyBranchModalOpen: true, rowBranchList: row });
  };
  closeModifyBranchModal = () => {
    this.setState({ modifyBranchModalOpen: false }, () => {
      this.getList();
    });
  };

  render() {
    return (
      <>
        {/* 지점등록모달 */}
        {this.state.registBranchModalOpen && (
          <RegistBranchDialog close={this.closeCreateBranchModal} />
        )}
        {/* 지점정보수정모달 */}
        {this.state.modifyBranchModalOpen && (
          <RegistBranchDialog
            data={this.state.rowBranchList}
            close={this.closeModifyBranchModal}
          />
        )}

        <Button
          className="tabBtn"
          onClick={() => {
            this.openCreateBranchModal();
          }}
          style={{
            marginBottom: 20,
          }}>
          지점 등록
        </Button>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default BranchList;
