import { Button, Checkbox, DatePicker, Form, Input, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import xlsx from 'xlsx';
import { httpGet, httpGetWithoutLoading, httpUrl } from '../../api/httpClient';
import DeliveryPriceFeeModifyDialog from '../../components/dialog/DeliveryPriceFeeModifyDialog';
import ProgressBar from '../../components/ProgressBar';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import { extCode, paymentMethod, riderLevel } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const FormItem = Form.Item;
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;
const today = new moment();

const initialPaginationExcel = {
  total: Number.MAX_VALUE,
  current: 1,
  pageSize: 1000,
};

class DeliveryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: initialPaginationExcel,
      frName: '',
      riderName: '',
      frPhone: '',
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
      extCode: '',
      orderIdx: '',
      extCodeFilter: [],

      paymentMethod: '',

      deliveryPriceFeeModifyDialogOpen: false,
      deliveryPriceFeeModifyOrder: null,
      branchName: ''
    };
    this.formRef = React.createRef();
    this.yesterday = new moment().clone().add('-1', 'days').format('YYYY-MM-D');
    this.lastMonth = new moment().clone().add('-1', 'months').format('YYYY-MM');
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pagination = this.state.pagination;
    let branchIdx = this.props.branchIdx;
    console.log(branchIdx);
    httpGet(
      httpUrl.deliveryList,
      [
        branchIdx,
        this.state.frName,
        this.state.frPhone,
        pagination.current,
        pagination.pageSize,
        this.state.riderName,
        '',
        this.state.startDate,
        this.state.endDate,
        this.state.extCode,
        this.state.extCodeFilter,
        this.state.orderIdx,
        false,
        this.state.branchName
      ],
      {}
    ).then((res) => {
      console.log(res.data.orders);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.orders,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => {
        this.getList();
      }
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        {
          this.getList();
        }
      }
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        idx: '주문번호',
        branchName: '지점명',
        frName: '가맹점명',
        frIdx: '가맹점idx',
        riderName: '라이더명',
        riderIdx: '라이더idx',
        riderLevel: '직급',
        fullTimeEmployee: '정직원 여부',
        orderDate: '접수일시',
        assignDate: '배차일시',
        pickupDate: '픽업일시',
        completeDate: '완료일시',
        destAddr: '도착지',
        distance: '거리',
        paymentMethod: '결제수단',
        orderPrice: '금액',
        basicDeliveryPrice: '기본배달료',
        extraDeliveryPrice: '할증배달료',
        deliveryPrice: '총배달요금',
        deliveryTax: '배달부가세',
        deliveryPriceFee: '배달수수료',
        riderCancelPenaltyAmount: '취소페널티',
        duesDeliveryPrice: '건별가맹비',
        businessNumber: '사업자번호',
        ownerName: '대표자명',
        addr: '주소',
        email: '이메일',
        registrationNumber: '주민번호',
        frPhone: '연락처',
        ftOrderCode: '외부주문코드',
      },
    ];
    data.forEach((item) => {
      console.log(
        '11232134234234 ' + JSON.stringify(this.state.startDate, null, 4)
      );
      console.log('sdafdasfasd ' + JSON.stringify(item.orderPayments, null, 4));
      let orderPayments =
        item.orderPayments.length > 0
          ? ''.concat(
              paymentMethod[item.orderPayments[0].paymentMethod],
              '(',
              comma(item.orderPayments[0].paymentAmount),
              ')원'
            )
          : '-';
      for (let i = 1; i < item.orderPayments.length; i++) {
        item.orderPayments.length > 1 &&
          (orderPayments = orderPayments.concat(
            ', ',
            paymentMethod[item.orderPayments[i].paymentMethod],
            '(',
            comma(item.orderPayments[i].paymentAmount),
            ')원'
          ));
      }
      result.push({
        idx: item.idx,
        branchName: item.branchName,
        frName: item.frName,
        frIdx: item.frIdx,
        riderName: item.riderName,
        riderIdx: item.riderIdx,
        riderLevel: riderLevel[item.riderLevel],
        fullTimeEmployee: item.fullTimeEmployee ? '정직원' : '',
        orderDate: item.orderDate,
        assignDate: item.assignDate,
        pickupDate: item.pickupDate,
        completeDate: item.completeDate,
        destAddr: item.destAddr1 + ' ' + item.destAddr2,
        distance: item.distance,
        paymentMethod: orderPayments,
        orderPrice: item.orderPrice,
        basicDeliveryPrice: item.basicDeliveryPrice,
        extraDeliveryPrice: item.extraDeliveryPrice,
        deliveryPrice: item.deliveryPrice,
        deliveryTax: parseInt(item.deliveryPrice * 0.1),
        deliveryPriceFee: item.deliveryPriceFee,
        riderCancelPenaltyAmount: item.riderCancelPenaltyAmount,
        duesDeliveryPrice: item.duesDeliveryPrice,
        businessNumber: item.businessNumber,
        ownerName: item.ownerName,
        addr: item.addr1,
        email: item.email,
        registrationNumber: item.registrationNumber,
        frPhone: item.frPhone,
        ftOrderCode: item.ftOrderCode
          ? item.ftOrderCode
          : item.bmOrderCode
          ? item.bmOrderCode
          : '-',
      });
      orderPayments = '';
    });

    return result;
  };

  onDownload = async () => {
    const data = await this.getExcelList();

    if (!data) return;

    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '배달목록.xlsx');
  };

  getExcelList = async () => {
    let branchIdx = this.props.branchIdx;

    this.progressBarFlag = true;

    const loopGetList = async () => {
      const res = await httpGetWithoutLoading(
        httpUrl.deliveryList,
        [
          branchIdx,
          this.state.frName,
          this.state.frPhone,
          this.state.paginationExcel.current,
          this.state.paginationExcel.pageSize,
          this.state.riderName,
          '',
          this.state.startDate,
          this.state.endDate,
          this.state.extCode,
          this.state.extCodeFilter,
          this.state.orderIdx,
          this.state.paginationExcel.current === 1 ? true : false,
          this.state.branchName
        ],
        {}
      );
      console.log(res);
      return res;
    };

    let totalRes = [];
    let tryCnt = 1;

    let failedPages = [];

    this.setState({
      progressBarOn: true,
    });

    while (this.progressBarFlag) {
      if (this.state.paginationExcel.total < this.state.paginationExcel.current)
        break;

      const loopRes = await loopGetList();

      if (loopRes.data) {
        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
            total:
              this.state.paginationExcel.current === 1
                ? loopRes.data.totalPage
                : this.state.paginationExcel.total,
          },
        });

        totalRes = totalRes.concat(loopRes.data.orders);
      } else if (tryCnt <= 3) {
        tryCnt += 1;
      } else {
        failedPages.push(this.pageNum);

        this.setState({
          paginationExcel: {
            ...this.state.paginationExcel,
            current: this.state.paginationExcel.current + 1,
          },
        });

        tryCnt = 1;
      }
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    return this.progressBarFlag ? totalRes : null;
  };

  handleFilterToggle = (e, key) => {
    if (key === 'all' && e.target.checked) {
      this.setState(
        {
          extCodeFilter: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
      return;
    }
    if (e.target.checked) {
      this.setState(
        {
          extCodeFilter: this.state.extCodeFilter.concat(key),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    } else {
      this.setState(
        {
          extCodeFilter: this.state.extCodeFilter.filter(
            (code) => code !== key
          ),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  openDeliveryPriceFeeModifyDialog = (order) => {
    this.setState({
      deliveryPriceFeeModifyDialogOpen: true,
      deliveryPriceFeeModifyOrder: order,
    });
  };
  closeDeliveryPriceFeeModifyDialog = () => {
    this.setState({
      deliveryPriceFeeModifyDialogOpen: false,
      deliveryPriceFeeModifyOrder: null,
    });
  };

  render() {
    const columns = [
      {
        title: '주문번호',
        dataIndex: 'idx',
        className: 'table-column-center',
        width: '5%',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
        width: '5%',
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: '10%',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        width: '8%',
      },
      {
        title: '직급',
        dataIndex: 'riderLevel',
        className: 'table-column-center',
        width: '5%',
        render: (data) => <div>{riderLevel[data]}</div>,
      },
      {
        title: '정직원 여부',
        dataIndex: 'fullTimeEmployee',
        className: 'table-column-center',
        width: '5%',
        render: (data) => <div>{data ? '정직원' : ''}</div>,
      },
      {
        title: '접수일시',
        dataIndex: 'orderDate',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '완료일시',
        dataIndex: 'completeDate',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '도착지',
        // dataIndex: "destAddr1",
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => (
          <div className="table-column-left">
            {row.destAddr1 + ' ' + row.destAddr2}
          </div>
        ),
      },
      {
        title: '결제수단',
        dataIndex: 'orderPayments',
        className: 'table-column-center',
        width: '8%',
        render: (data) => {
          console.log('orderPayments           ' + JSON.stringify(data));
          return (
            // <div>{paymentMethod[data[0] ? data[0]["paymentMethod"] : 0]}</div>
            data.map((payment) => {
              return (
                <div
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: 'grey',
                    marginBottom: 2,
                  }}>
                  {paymentMethod[payment.paymentMethod]} (
                  {comma(payment.paymentAmount)}원)
                </div>
              );
            })
          );
        },
      },
      {
        title: '금액',
        dataIndex: 'orderPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '기본배달요금',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '할증배달요금',
        dataIndex: 'extraDeliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '총배달요금',
        dataIndex: 'deliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '배달부가세',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{comma(parseInt(row.deliveryPrice * 0.1))}원</div>
        ),
      },
      {
        title: '배달수수료',
        dataIndex: 'deliveryPriceFee',
        className: 'table-column-center',
        render: (data, row) => (
          <>
            <div>{comma(data)}원</div>
            <div>
              <Button
                onClick={() => this.openDeliveryPriceFeeModifyDialog(row)}>
                수수료변경
              </Button>
            </div>
          </>
        ),
      },
      {
        title: '취소페널티',
        dataIndex: 'riderCancelPenaltyAmount',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '건별가맹비',
        dataIndex: 'duesDeliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
    ];
    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: '가맹점명',
          dataIndex: 'frName',
          className: 'table-column-center',
        },
        {
          title: '사업자번호',
          dataIndex: 'businessNumber',
          className: 'table-column-center',
        },
        {
          title: '대표자명',
          dataIndex: 'ownerName',
          className: 'table-column-center',
        },
        {
          title: '가맹점번호',
          dataIndex: 'frPhone',
          className: 'table-column-center',
        },
        {
          title: '주소',
          dataIndex: 'addr1',
          className: 'table-column-center',
          // render: (data) => <div>{data}</div>,
        },
        {
          title: '이메일',
          dataIndex: 'email',
          className: 'table-column-center',
        },
        {
          title: '라이더명',
          dataIndex: 'riderName',
          className: 'table-column-center',
        },
        {
          title: '라이더 주민번호',
          dataIndex: 'registrationNumber',
          className: 'table-column-center',
        },
        {
          title: '라이더 연락처',
          dataIndex: 'riderPhone',
          className: 'table-column-center',
        },
        {
          title: '외부주문코드',
          dataIndex: 'ftOrderCode',
          className: 'table-column-center',
          render: (data, row) => (
            <div>{data ? data : row.bmOrderCode ? row.bmOrderCode : '='}</div>
          ),
        },
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <>
        {this.state.progressBarOn && (
          <ProgressBar
            current={this.state.paginationExcel.current}
            total={this.state.paginationExcel.total}
            quit={this.quitProgressBar}
          />
        )}
        {this.state.deliveryPriceFeeModifyDialogOpen && (
          <DeliveryPriceFeeModifyDialog
            order={this.state.deliveryPriceFeeModifyOrder}
            close={this.closeDeliveryPriceFeeModifyDialog}
            refreshList={this.getList}
          />
        )}

        <Search
          placeholder="지점 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ branchName: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
          }}
        />
        <Search
          placeholder="가맹점 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ frName: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        <Search
          placeholder="라이더명 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ riderName: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        <Search
          placeholder="주문번호 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ orderIdx: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        <Search
          placeholder="외부주문코드 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ extCode: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        {/* <Search
          placeholder="전화번호 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ frPhone: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        /> */}

        {Const.excelEnable && (
          <>
            <Button
              className="download-btn"
              style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
              onClick={() => this.onDownload()}>
              <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
              엑셀 다운로드
            </Button>
          </>
        )}
        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          defaultValue={[today,today]}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            } else {
              // console.log('test')
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />
        <div className="delivery-list-ext-filter-wrapper">
          <div className="delivery-list-ext-filter-title">
            외부주문코드 필터
          </div>
          {Object.keys(extCode).map((key) => {
            if (key === 'all') {
              return (
                <Checkbox
                  key={key}
                  checked={this.state.extCodeFilter.length === 0}
                  onChange={(e) => this.handleFilterToggle(e, key)}
                  className="delivery-list-ext-filter-checkbox">
                  {extCode[key]}
                </Checkbox>
              );
            }
            return (
              <Checkbox
                key={key}
                checked={this.state.extCodeFilter.includes(key)}
                onChange={(e) => this.handleFilterToggle(e, key)}
                className="delivery-list-ext-filter-checkbox">
                {extCode[key]}
              </Checkbox>
            );
          })}
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={false}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
          // expandRowByClick={true}
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.list.length == 0) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current + 1;
              this.setState({ pagination }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(DeliveryList);
