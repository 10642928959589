import {
  Button,
  Form,
  Table,
} from 'antd';
import React, { Component } from 'react';
import {httpGet, httpUrl} from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';
import { comma } from '../../lib/util/numberUtil';
import Const from "../../const";
import ExcelIcon from "../../img/excel.png";
import xlsx from "xlsx";

class BranchSettlementDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        hideOnSinglePage: true,
        pageSize: 20,
        current: 1,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 100000,
      },
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      branchList: [],
      startDate: '',
      endDate: '',
      branchIdx: 0,
      branchProfitPercent: 0,
    };
    this.formRef = React.createRef();

  }


  componentDidMount() {
    if (this.props.data) {
      this.setState({
        startDate: this.props.data.startDate,
        endDate: this.props.data.endDate,
        branchIdx: this.props.data.branchIdx,
      }, () => this.getList())
    };
    this.getBranchList();

  }

  getBranchList = async () => {
    const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
    this.setState({
      branchList: [{ idx: null, branchName: '지점 선택' }, ...res.data.branch],
    })
  };

  parseExcelJson = (data) => {

    const tempList = [
      {
        column1: '총 매출',
        column2: '',
        column3: data.totalSales ? data.totalSales : 0,
        column4: '',
      },
      {
        column1: '매출 상세',
        column2: '배달비 * 11.7%',
        column3: data.deliveryPrice ? data.deliveryPrice : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '라이더 수수료',
        column3: data.deliveryFeeForRider ? data.deliveryFeeForRider : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '가맹비',
        column3: data.franchiseFee ? data.franchiseFee : 0,
        column4: '',
      },
      {
        column1: '총 비용',
        column2: '',
        column3: data.totalCost ? data.totalCost : 0,
        column4: '',
      },
      {
        column1: '비용 상세',
        column2: '보조금',
        column3: data.subsidizeCost ? data.subsidizeCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '산재',
        column3: data.industrialAccidentCost ? data.industrialAccidentCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '고용',
        column3: data.employmentInsuranceCost ? data.employmentInsuranceCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '임차료',
        column3: data.hireCost ? data.hireCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '관리비',
        column3: data.maintenanceCost ? data.maintenanceCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '렌탈료',
        column3: data.rentalCost ? data.rentalCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '영업인센티브',
        column3: data.salesIncentivesCost ? data.salesIncentivesCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '소모품비',
        column3: data.consumableCost ? data.consumableCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '바이크 관리',
        column3: data.bikeMaintenanceCost ? data.bikeMaintenanceCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '음식 변상',
        column3: data.refundFood ? data.refundFood : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '명함',
        column3: data.businessCardCost ? data.businessCardCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '명찰',
        column3: data.nameTagCost ? data.nameTagCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '리플렛',
        column3: data.leafletCost ? data.leafletCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '선충전 미회수',
        column3: data.prechargeUncollectedCost ? data.prechargeUncollectedCost : 0,
        column4: '',
      },
      {
        column1: '',
        column2: '기타',
        column3: data.etcCost ? data.etcCost : 0,
        column4: '',
      },
      {
        column1: '스테이션 손익',
        column2: '',
        column3: data.totalSales - data.totalCost,
        column4: '매출-비용',
      },
      {
        column1: '스테이션장 수익',
        column2: '',
        column3: data.branchManagerProfit ? data.branchManagerProfit : 0,
        column4: '(매출-비용)*직급별 영업이익율',
      },
      {
        column1: '실정산 금액',
        column2: '',
        column3: data.branchManagerProfit ? data.branchManagerProfit  - data.monthlyPaymentByPromotion - data.monthlyPaymentByEtc2 : 0,
        column4: '스테이션장 수익 - 프로모션 차감 된 금액',
      },
      {
        column1: '스테이션 실입금액',
        column2: '',
        column3: data.branchDepositAmount ? data.branchDepositAmount : 0,
        column4: '[보존금액 > (실정산금액 - 법인카드) 일때 "실입금액=보존금액"] 또는 [보존금액 <= (실정산금액 - 법인카드) 일때 "실입금액=(실정산금액 - 법인카드)"]'
      },
      {
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
      {
        column1: '구분',
        column2: '분류',
        column3: '금액',
        column4: '비고',
      },
      {
        column1: '기타2',
        column2: '기타2 이월 된 금액',
        column3: data.remainingEtc2Amount ? data.remainingEtc2Amount : 0,
        column4: '',
      },
      {
        column1: '기타2',
        column2: '기타2 차감된 금액',
        column3: data.monthlyPaymentByEtc2 ? data.monthlyPaymentByEtc2 : 0,
        column4: '월 스테이션장 수익 * 30%',
      },
      {
        column1: '구분',
        column2: '분류',
        column3: '금액',
        column4: '비고',
      },
      {
        column1: '(이월)프로모션',
        column2: '(1)[기타2] 제외한 이월 된 모든 금액',
        column3: data.promotionBeforeRemainingAmount ? data.promotionBeforeRemainingAmount : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션',
        column2: '지점 인수 금액',
        column3: data.promotionBranchBuy ? data.promotionBranchBuy : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션',
        column2: '(2)라이더 예치금 지급',
        column3: data.amounts.paymentAmountForRider ? data.amounts.paymentAmountForRider : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션',
        column2: '(3)가맹점 예치금 지급',
        column3: data.amounts.paymentAmountForFranchise ? data.amounts.paymentAmountForFranchise : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션',
        column2: '(4)라이더 프로모션',
        column3: data.amounts.deliveryFeeForRider ? data.amounts.deliveryFeeForRider : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션 부담 금액',
        column2: '[(2)+(3)+(4) * 40%]',
        column3: data.promotionBranchBurdenTotal ? data.promotionBranchBurdenTotal : 0,
        column4: '',
      },
      {
        column1: '(당월)프로모션',
        column2: '차감 한도(30%)',
        column3: data.promotionBranchManagerPayment ? data.promotionBranchManagerPayment : 0,
        column4: '월 스테이션장 수익 * 30%',
      },
      {
        column1: '(당월)프로모션',
        column2: '차감 된 금액',
        column3: data.monthlyPaymentByPromotion ? data.monthlyPaymentByPromotion : 0,
        column4: '',
      },
    ];


    let result = [
      {
        column1: '구분',
        column2: '분류',
        column3: '금액',
        column4: '비고',
      },
    ];


    tempList.forEach((item) => {
      result.push({
        column1: item.column1,
        column2: item.column2,
        column3: item.column3,
        column4: item.column4,
      });
    });


    return result;
  };

  onDownload = () => {
    const dataRange = 0;
    httpGet(httpUrl.getAutoCalcBranchSettlement, [this.state.startDate, this.state.endDate, dataRange, this.state.branchIdx, 1, 100],
        {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.info);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][0] = { width: 15 };
      ws['!cols'][1] = { width: 25 };
      ws['!cols'][2] = { width: 25 };
      ws['!cols'][3] = { width: 100 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '지점정산표.xlsx');
    });
  };


  getList = () => {
    const { pagination } = this.state;
    const pageNum = pagination.current;
    const pageSize = pagination.pageSize;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const branchIdx = this.state.branchIdx;
    const dataRange = 0;
    httpGet(httpUrl.getAutoCalcBranchSettlement, [startDate, endDate, dataRange, branchIdx, pageNum, pageSize], {}).then((res) => {
      console.log('res');
      console.log(res);
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      let tempList1 = [
        {
          type: '총 매출',
          detailType: '',
          money: res.data.info.totalSales,
          memo: '',
        },
        {
          type: '매출 상세',
          detailType: '배달비 * 11.7%',
          money: res.data.info.deliveryPrice,
          memo: '',
        },
        {
          //type: '매출 상세',
          detailType: '라이더 수수료',
          money: res.data.info.deliveryFeeForRider,
          memo: '',
        },
        {
          //type: '매출 상세',
          detailType: '가맹비',
          money: res.data.info.franchiseFee,
          memo: '',
        },
        {
          type: '총 비용',
          detailType: '',
          money: res.data.info.totalCost,
          memo: '',
        },
        {
          type: '비용 상세',
          detailType: '보조금',
          money: res.data.info.subsidizeCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '산재',
          money: res.data.info.industrialAccidentCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '고용',
          money: res.data.info.employmentInsuranceCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '임차료',
          money: res.data.info.hireCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '관리비',
          money: res.data.info.maintenanceCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '렌탈료',
          money: res.data.info.rentalCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '영업인센티브',
          money: res.data.info.salesIncentivesCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '소모품비',
          money: res.data.info.consumableCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '바이크 관리',
          money: res.data.info.bikeMaintenanceCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '음식 변상료',
          money: res.data.info.refundFood,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '명함',
          money: res.data.info.businessCardCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '명찰',
          money: res.data.info.nameTagCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '리플렛',
          money: res.data.info.leafletCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '선충전 미회수',
          money: res.data.info.prechargeUncollectedCost,
          memo: '',
        },
        {
          //type: '비용 상세',
          detailType: '기타',
          money: res.data.info.etcCost,
          memo: '',
        },
      ];

      let tempList2 = [
        {
          type: '스테이션 손익',
          detailType: '',
          money: res.data.info.totalSales - res.data.info.totalCost,
          memo: '매출-비용',
        },
        {
          type: '스테이션장 수익',
          detailType: '',
          money: res.data.info.branchManagerProfit,
          memo: '(매출-비용)*직급별 영업이익율',
        },
        {
          type: '실정산 금액',
          detailType: '',
          money: res.data.info.branchManagerProfit - res.data.info.monthlyPaymentByPromotion - res.data.info.monthlyPaymentByEtc2,
          memo: '스테이션장 수익 - 프로모션 차감 된 금액',
        },
        {
          type: '스테이션 실입금액',
          detailType: '',
          money: res.data.info.branchDepositAmount,
          memo: '[보존금액 > (실정산금액 - 법인카드) 일때 "실입금액=보존금액"] 또는 [보존금액 <= (실정산금액 - 법인카드) 일때 "실입금액=(실정산금액 - 법인카드)"]',
        },
      ];

      let tempList3 = [
        {
          type: '기타2',
          detailType: '기타2 이월 된 금액',
          money: res.data.info.remainingEtc2Amount,
          memo: '',
        },
        {
          type: '기타2',
          detailType: '기타2 차감된 금액',
          money: res.data.info.monthlyPaymentByEtc2,
          memo: '월 스테이션장 수익 * 30%',
        },
      ];
      let tempList4 = [
        {
          type: '(이월)프로모션',
          detailType: '(1)[기타2] 제외한 이월 된 모든 금액',
          money: res.data.info.promotionBeforeRemainingAmount,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '지점 인수 금액',
          money: res.data.info.promotionBranchBuy,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(2)라이더 예치금 지급',
          money: res.data.info.amounts.paymentAmountForRider,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(3)가맹점 예치금 지급',
          money: res.data.info.amounts.paymentAmountForFranchise,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '(4)라이더 프로모션',
          money: res.data.info.amounts.deliveryFeeForRider,
          memo: '',
        },
        {
          type: '(당월)프로모션 부담 금액',
          detailType: '[(2)+(3)+(4) * 40%]',
          money: res.data.info.promotionBranchBurdenTotal,
          memo: '',
        },
        {
          type: '(당월)프로모션',
          detailType: '차감 한도(30%)',
          money: res.data.info.promotionBranchManagerPayment,
          memo: '월 스테이션장 수익 * 30%',
        },
        {
          type: '(당월)프로모션',
          detailType: '차감 된 금액',
          money: res.data.info.monthlyPaymentByPromotion,
          memo: '',
        },
      ];

      this.setState({
        branchSettlementIdx: res.data.branchSettlementIdx,
        list1: tempList1,
        list2: tempList2,
        list3: tempList3,
        list4: tempList4,
        branchProfitPercent: res.data.branchProfitPercent * 100,
      });
    });

  };

  handleSubmit = () => {

  };

  render() {
    const columns1 = [
      {
        title: '구분',
        dataIndex: 'type',
        className: 'table-column-center',
        width: '18%',
      },
      {
        title: '분류',
        dataIndex: 'detailType',
        className: 'table-column-center',
        width: '18%',
      },
      {
        title: '금액',
        dataIndex: 'money',
        calssName: 'table-column-text',
        width: '30%',
        render: (data) => <div>{comma(data)}</div>,
      },
      {
        title: '비고',
        dataIndex: 'memo',
        className: 'table-column-text',
      },
    ];
    const columns2 = [
      {
        title: '구분',
        dataIndex: 'type',
        className: 'table-column-center',
        width: '20%',
      },
      {
        title: '금액',
        dataIndex: 'money',
        className: 'table-column-left',
        width: '22%',
        render: (data) => <div>{data ? comma(data) : ''}</div>,
      },
      {
        title: '비고',
        dataIndex: 'memo',
        className: 'table-column-text',
      },
    ];


    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div
          className="systemKey-Dialog"
          style={{ height: '100%', width: '100%' }}>
          <div className="systemKey-content" style={{ height: '100%' }}>
            <div className="systemKey-title">
                지점 월 정산표
              {Const.excelEnable && (
                  <Button
                      className="download-btn"
                      style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
                      onClick={() => this.onDownload()}>
                    <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                    엑셀 다운로드
                  </Button>
              )}
            </div>
            <div style={{display:'inline-block', fontSize: '18px'}}>
              &nbsp;&nbsp;&nbsp;영업 이익율 : {this.state.branchProfitPercent + ' %'}
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="exit"
            />
            <div  className="systemKey-inner2" style={{ height: '90%' }}>
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                className="create-control-form">
              </Form>
              <div className="settlement-container" style={{}}>
                <div>
                  <Table
                      dataSource={this.state.list1}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ border: '1px solid black'}}
                  />
                  <Table
                      dataSource={this.state.list2}
                      columns={columns2}
                      pagination={this.state.pagination}
                      style={{ border: '1px solid black'}}
                  />
                  <Table
                      dataSource={this.state.list3}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ border: '1px solid black'}}
                  />
                  <Table
                      dataSource={this.state.list4}
                      columns={columns1}
                      pagination={this.state.pagination}
                      style={{ border: '1px solid black'}}
                  />
                </div>
              </div>

            </div>



          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BranchSettlementDialog;
