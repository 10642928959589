const BUILD_TARGET = {
  LOCALHOST: 'localhost',
  NNBOX_DEV: 'dev',
  NNBOX_STAGE: 'stage',
  NNBOX_QA02: 'qa02',
  NNBOX_QA03: 'qa03',
  NNBOX_LIVE_SN: 'sn', // 성남
  NNBOX_LIVE_YJ: 'yj', // 영종
  NNBOX_LIVE_GY: 'gy', // 덕양
  NNBOX_LIVE_GP: 'gp', // 김포
};
Object.freeze(BUILD_TARGET);


const getTargetInfo = (buildTarget) => {
  switch (buildTarget) {
    case BUILD_TARGET.LOCALHOST: {
      return {
        serverProtocol: 'http',
        serverIp: 'localhost',
        serverPort: '30003',
        excelEnable: true,
        branchSettlementPossibleLastDay: 30, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: true, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        // settlementUrl: 'https://nnstage.nnbox.net:8886', // 대리점 정산용 api
        settlementUrl: 'http://localhost:3001',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_DEV: {
      return {
        serverProtocol: 'http',
        serverIp: 'localhost',
        serverPort: '30003',
        excelEnable: true,
        branchSettlementPossibleLastDay: 30, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: true, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://nnstage.nnbox.net:8886', // 대리점 정산용 api
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_STAGE: {
      return {
        serverProtocol: "https",
        serverIp: "nnstage.nnbox.net",
        serverPort: "30003",
        excelEnable: true,
        branchSettlementPossibleLastDay: 30, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: true, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_QA02: {
      return {
        serverProtocol: "https",
        serverIp: "qa02adminback.nyamnyambox.com",
        serverPort: "443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 30, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: true, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_QA03: {
      return {
        serverProtocol: "https",
        serverIp: "qa03adminback.nyamnyambox.com",
        serverPort: "443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 30, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: true, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://nnstage.nnbox.net:8886',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_SN: {
      return {
        serverProtocol: "https",
        serverIp: "snadmin.nyamnyambox.com",
        serverPort: "8443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 15, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: false, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'SN', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_YJ: {
      return {
        serverProtocol: "https",
        serverIp: "yjadmin.nyamnyambox.com",
        serverPort: "8443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 15, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: false, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'YJ', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_GY: {
      return {
        serverProtocol: "https",
        serverIp: "gyadmin.nyamnyambox.com",
        serverPort: "8443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 15, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: false, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'GY', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    case BUILD_TARGET.NNBOX_LIVE_GP: {
      return {
        serverProtocol: "https",
        serverIp: "admin.nyamnyambox.com",
        serverPort: "8443",
        excelEnable: true,
        branchSettlementPossibleLastDay: 15, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
        branchSettlementViewCountingFlag: false, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중
        settlementUrl: 'https://agencyserver.nyamnyambox.com',
        region: 'GP', // 제휴본사 실시간 내역조회용 파라미터 정보
      };
    }
    default:
      return undefined;
  }
};


const buildTargetInfo = getTargetInfo(BUILD_TARGET.NNBOX_QA03);

export default {
  appName: 'nnboxAdmin',

  serverProtocol: buildTargetInfo.serverProtocol,
  serverIp: buildTargetInfo.serverIp,
  serverPort: buildTargetInfo.serverPort,
  excelEnable: buildTargetInfo.excelEnable,
  branchSettlementPossibleLastDay: buildTargetInfo.branchSettlementPossibleLastDay, // 정산 가능 한 마지막 날짜 (1일부터 branchSettlementPossibleLastDay까지 정산 가능)
  branchSettlementViewCountingFlag: buildTargetInfo.branchSettlementViewCountingFlag, // 지점 수익 정산 페이지의 "집계중" 표시 여부, true: 카운팅, false: 집계중

  settlementUrl: buildTargetInfo.settlementUrl,
  region: buildTargetInfo.region,
  ///////////////////////dr/////////////////////
  // serverProtocol: "https",
  // serverIp: "drnana.nnbox.net",
  // serverPort: "30003",
  // excelEnable: true,


  //////////////////stage-hist1////////////////////////
  // serverProtocol: "https",
  // serverIp: "nnstage.nnbox.net",
  // serverPort: "33001",
  // excelEnable: true,

  //////////////////stage-hist2////////////////////////
  // serverProtocol: "https",
  // serverIp: "nnstage.nnbox.net",
  // serverPort: "33002",
  // excelEnable: true,

  //////////////////stage-hist3////////////////////////
  // serverProtocol: "https",
  // serverIp: "nnstage.nnbox.net",
  // serverPort: "33003",
  // excelEnable: true,

  //////////////////aws1////////////////////////
  // serverProtocol: "https",
  // serverIp: "gp.back.nyamnyambox.com",
  // serverPort: "30003",
  // excelEnable: true,

  //////////////////////////////////////////
  //naver map client key
  clientId: '',
  clientSecret: '',
  serverContext: '',

  //////////////////////////////////////////
  // PollingInterval
  branchSettlementPollingInterval: 30000,
  branchSettlementDetailPollingInterval: 1000,

  //////////////////////////////////////////
  // 정산 관리 페이지 설정


  menus: [
    {
      idx: 1,
      name: 'STATICS_MANAGE',
      nameKr: '통계',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 2,
      name: 'DELIVERY_MANAGE',
      nameKr: '배달내역관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 3,
      name: 'FRANCHISE_MANAGE',
      nameKr: '가맹점관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 4,
      name: 'ADVANCE_PAYMENT_MANAGE',
      nameKr: '선지급관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 5,
      name: 'DEPOSIT_MANAGE',
      nameKr: '예치금관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 6,
      name: 'LOAN_MANAGE',
      nameKr: '대출관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 7,
      name: 'BIKE_MANAGE',
      nameKr: '바이크관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 8,
      name: 'BOARD_MANAGE',
      nameKr: '게시글관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 9,
      name: 'SYSTEM_MANAGE',
      nameKr: '시스템관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 10,
      name: 'STATICS_BRANCH',
      nameKr: '지점 매출',
      path: '/statics/StaticsBranch',
      parentIdx: 1,
    },
    {
      idx: 11,
      name: 'STATICS_RIDER',
      nameKr: '라이더 통계',
      path: '/statics/StaticsRider',
      parentIdx: 1,
    },
    {
      idx: 12,
      name: 'STATICS_FRANCHISE',
      nameKr: '가맹점 통계',
      path: '/statics/StaticsFranchise',
      parentIdx: 1,
    },
    {
      idx: 13,
      name: 'DELIVERY_LIST',
      nameKr: '배달목록',
      path: '/delivery/DeliveryList',
      parentIdx: 2,
    },
    {
      idx: 14,
      name: 'DELIVERY_HISTORY_EMPLOYEE',
      nameKr: '정직원 배달내역',
      path: '/delivery/DeliveryHistoryEmployee',
      parentIdx: 2,
    },
    {
      idx: 15,
      name: 'DELIVERY_HISTORY_RIDER',
      nameKr: '라이더 배달내역',
      path: '/delivery/DeliveryHistoryRider',
      parentIdx: 2,
    },
    {
      idx: 16,
      name: 'FRANCHISE_LIST',
      nameKr: '가맹점 목록',
      path: '/franchise/FranchiseList',
      parentIdx: 3,
    },
    {
      idx: 17,
      name: 'FRANCHISE_FEE_HISTORY',
      nameKr: '가맹비 내역',
      path: '/franchise/FranchiseFeeHistory',
      parentIdx: 3,
    },
    {
      idx: 18,
      name: 'FRANCHISE_CHARGE_HISTORY',
      nameKr: '충전내역',
      path: '/franchise/FranchiseChargeHistory',
      parentIdx: 3,
    },
    {
      idx: 19,
      name: 'ADVANCE_PAYMENT_LIST',
      nameKr: '예치금관리',
      path: '/payment/PaymentList',
      parentIdx: 4,
    },
    {
      idx: 20,
      name: 'DEPOSIT_ALL_HISTORY',
      nameKr: '예치금 내역',
      path: '/deposit/DepositAllHistory',
      parentIdx: 5,
    },
    {
      idx: 21,
      name: 'DEPOSIT_PAYMENT_HISTORY',
      nameKr: '예치금 지급',
      path: '/deposit/DepositPaymentHistory',
      parentIdx: 5,
    },
    // {
    //   idx: 22,
    //   name: "DEPOSIT_WITHDRAW_HISTORY",
    //   nameKr: "예치금 출금",
    //   path: "/deposit/DepositWithdrawHistory",
    //   parentIdx: 5,
    // },
    {
      idx: 23,
      name: 'LOAN_RIDER',
      nameKr: '라이더 대출목록',
      path: '/loan/LoanRider',
      parentIdx: 6,
    },
    {
      idx: 24,
      name: 'LOAN_FRANCHISE',
      nameKr: '가맹점 대출목록',
      path: '/loan/LoanFranchise',
      parentIdx: 6,
    },
    {
      idx: 25,
      name: 'BIKE_LIST',
      nameKr: '바이크 전체목록',
      path: '/bike/BikeList',
      parentIdx: 7,
      submenuOrder: 1,
    },
    {
      idx: 26,
      name: 'BOARD_INQUIRY',
      nameKr: '홈페이지문의',
      path: '/board/Inquiry',
      parentIdx: 8,
    },
    {
      idx: 27,
      name: 'BOARD_NOTICE',
      nameKr: '홈페이지공지',
      path: '/board/Notice',
      parentIdx: 8,
    },
    {
      idx: 28,
      name: 'OPERATOR_MANAGE',
      nameKr: '운영자 관리',
      path: '/system/OperatorManage',
      parentIdx: 9,
    },
    {
      idx: 29,
      name: 'CONTROL_AGENT_MANAGE',
      nameKr: '관제요원 관리',
      path: '/system/ControlAgentManage',
      parentIdx: 9,
    },
    {
      idx: 30,
      name: 'SETTLEMENT_MANAGE',
      nameKr: '정산관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 39,
      name: 'NCASH_DAILY',
      nameKr: '일차감 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 40,
      name: 'NCASH_DAILY_LIST',
      nameKr: '일차감 내역',
      path: '/ncash/NcashDailyList',
      parentIdx: 39,
    },
    {
      idx: 42,
      name: 'DEPOSIT_CONNECT_HISTORY',
      nameKr: '예치금내역(본사)',
      path: '/deposit/DepositConnectHistory',
      parentIdx: 5,
    },
    {
      idx: 43,
      name: 'FRANCHISE_VAN_REGIST_LIST',
      nameKr: 'VAN등록요청목록',
      path: '/franchise/RegistVANList',
      parentIdx: 3,
    },
    {
      idx: 44,
      name: 'NCASH_LIST',
      nameKr: '일차감 목록',
      path: '/ncash/NCashList',
      parentIdx: 39,
    },
    {
      idx: 45,
      name: 'BIKE_REPAIR_COST',
      nameKr: '바이크 수리비징수',
      path: '/bike/BikeRepair',
      parentIdx: 7,
      submenuOrder: 3,
    },
    {
      idx: 46,
      name: 'DELIVERY_CANCEL_LIST',
      nameKr: '배달 취소 목록',
      path: '/delivery/DeliveryCancelList',
      parentIdx: 2,
    },
    {
      idx: 47,
      name: 'RIDER_MANAGE',
      nameKr: '라이더 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 48,
      name: 'RIDER_LIST',
      nameKr: '라이더 목록',
      path: '/rider/RiderList',
      parentIdx: 47,
    },

    {
      idx: 49,
      name: 'AGGREGATION',
      nameKr: '일자별 지점 정산',
      path: '/settlement/Aggregation',
      parentIdx: 30,
    },
    {
      idx: 50,
      name: 'EMPLOYEE_DAILY_STAT',
      nameKr: '일자별 정직원 정산',
      path: '/settlement/EdsList',
      parentIdx: 30,
    },
    {
      idx: 51,
      name: 'EMPLOYEE_MONTHLY_STAT',
      nameKr: '월별 정직원 정산',
      path: '/settlement/EmsList',
      parentIdx: 30,
    },
    {
      idx: 52,
      name: 'RIDER_DAILY_NCASH_RESULT',
      nameKr: '일자별 라이더 예치금 잔액',
      path: '/ncash/RdnrList',
      parentIdx: 5,
    },
    {
      idx: 53,
      name: 'FRANCHISE_DAILY_NCASH_RESULT',
      nameKr: '일자별 가맹점 예치금 잔액',
      path: '/ncash/FdnrList',
      parentIdx: 5,
    },
    {
      idx: 54,
      name: 'DELIVERY_CARD_PAYMENT_HISTORY',
      nameKr: '카드 결제 내역',
      path: '/delivery/DeliveryCardPaymentHistory',
      parentIdx: 2,
    },
    {
      idx: 55,
      name: 'DEPOSIT_PRECHARGE',
      nameKr: '예치금 선충전',
      path: '/deposit/DepositPrecharge',
      parentIdx: 5,
    },
    {
      idx: 56,
      name: 'DEPOSIT_NOT_RECOVERED',
      nameKr: '미회수 목록',
      path: '/deposit/DepositNotRecovered',
      parentIdx: 5,
    },
    {
      idx: 57,
      name: 'REMAIN_AMOUNT_MINUS',
      nameKr: '카드 중복결제 관리',
      path: '/settlement/RemainAmountMinus',
      parentIdx: 30,
    },
    {
      idx: 58,
      name: 'DAILY_ORDER_STAT',
      nameKr: '일자별 주문 정산',
      path: '/settlement/DailyOrderStat',
      parentIdx: 30,
    },
    {
      idx: 59,
      name: 'BOARD_CENTER',
      nameKr: '홈페이지 지점',
      path: '/board/Center',
      parentIdx: 8,
    },
    {
      idx: 60,
      name: 'DEPOSIT_NOT_RECOVERED_MONGO',
      nameKr: '미회수 목록 MONGO',
      path: '/deposit/DepositNotRecoveredMongo',
      parentIdx: 5,
    },
    {
      idx: 61,
      name: 'MALL_ADMINISTRATION',
      nameKr: '몰 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 62,
      name: 'MALL_PRODUCT',
      nameKr: '몰 상품관리',
      path: '/mall/Product',
      parentIdx: 61,
    },
    {
      idx: 63,
      name: 'TAX_SUPPORT_LOG',
      nameKr: '세금지원금 내역',
      path: '/rider/TaxSupport',
      parentIdx: 47,
    },
    {
      idx: 64,
      name: 'FRANCHISE_DUES_MANAGE',
      nameKr: '가맹비 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 65,
      name: 'RENTFEE_LIST',
      nameKr: '렌트비 설정',
      path: '/bike/RentFeeList',
      parentIdx: 7,
      submenuOrder: 4,
    },
    {
      idx: 66,
      name: 'FRANCHISE_DUES_HISTORY',
      nameKr: '가맹비 내역',
      path: '/franchiseDues/FranchiseDuesHistory',
      parentIdx: 64,
    },
    {
      idx: 67,
      name: 'FRANCHISE_DUES_AGGREGATION',
      nameKr: '가맹비 집계',
      path: '/franchiseDues/FranchiseDuesAggregation',
      parentIdx: 64,
    },
    {
      idx: 68,
      name: 'BRANCH_INFO',
      nameKr: '지점관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 69,
      name: 'BRANCH_INFO',
      nameKr: '지점설정관리',
      path: '/branch/list',
      parentIdx: 68,
    },
    {
      idx: 70,
      name: 'BIKE_RELEASE',
      nameKr: '바이크 출고',
      path: '/bike/BikeReleaseList',
      parentIdx: 7,
      submenuOrder: 2,
    },
    {
      idx: 71,
      name: 'DELIVERY_FEE_INFO',
      nameKr: '배달비관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 72,
      name: 'DELIVERY_FEE_INFO',
      nameKr: '배달비목록',
      path: '/branch/DeliveryFeeInfo',
      parentIdx: 71,
    },
    {
      idx: 73,
      name: 'FRANCHISE_DUES_SETTING',
      nameKr: '가맹비 설정',
      path: '/franchiseDues/FranchiseDuesSetting',
      parentIdx: 64,
    },
    {
      idx: 74,
      name: 'BRANCH_APPROVAL',
      nameKr: '지점승인관리',
      path: '/branch/branchApproval',
      parentIdx: 68,
    },
    {
      idx: 75,
      name: 'STATICS_COST_STAT',
      nameKr: '매출/비용 정산 (경영지원 전용)',
      path: '/settlement/StaticsCostStat',
      parentIdx: 30,
    },
    {
      idx: 76,
      name: 'RIDER_GROUP_MANAGE',
      nameKr: '기사 그룹 관리',
      path: '/rider/RiderGroupManagement',
      parentIdx: 47,
    },
    {
      idx: 77,
      name: 'RIDER_ACCIDENT_INSURANCE',
      nameKr: '상해보험 가입/해지',
      path: '/rider/RiderAccidentInsurance',
      parentIdx: 47,
    },
    {
      idx: 78,
      name: 'RIDER_LEVEL_MANAGEMENT',
      nameKr: '라이더 레벨 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 79,
      name: 'RIDER_LEVEL_LIST',
      nameKr: '전체 레벨항목 내역',
      path: '/riderLevel/RiderLevelList',
      parentIdx: 78,
    },
    {
      idx: 80,
      name: 'RIDER_WORK_WHILE_RAINING',
      nameKr: '우천 배달 수행 내역',
      path: '/riderLevel/RiderWorkWhileRaining',
      parentIdx: 78,
    },
    {
      idx: 81,
      name: 'RIDER_LEVEL_SUPPORT',
      nameKr: '지원금 지급 내역',
      path: '/riderLevel/RiderLevelSupport',
      parentIdx: 78,
    },
    {
      idx: 83,
      name: 'STATICS_NCASH',
      nameKr: '예치금통계',
      path: '/statics/StaticsNcash',
      parentIdx: 1,
    },
    {
      idx: 84,
      name: 'BANK_CHARGE_HISTORY',
      nameKr: '은행 수수료내역',
      path: '/deposit/BankChargeHistory',
      parentIdx: 5,
    },
    {
      idx: 85,
      name: 'STATICS_DUES',
      nameKr: '가맹비통계',
      path: '/statics/StaticsDues',
      parentIdx: 1,
    },
    {
      idx: 86,
      name: 'DELIVERY_PRICE_EXTRA_RAIN',
      nameKr: '우천콜 모니터링',
      path: '/branch/DeliveryPriceExtraRain',
      parentIdx: 68,
    },
    {
      idx: 87,
      name: 'REQUEST_WITHDRAW_APPROVAL',
      nameKr: '예치금 출금 요청',
      path: '/deposit/RequestWithdrawApproval',
      parentIdx: 5,
    },
    {
      idx: 88,
      name: 'WITHDRAW_APPROVAL',
      nameKr: '출금승인',
      path: '/deposit/WithdrawApprove',
      parentIdx: 5,
    },
    {
      idx: 89,
      name: 'WITHDRAW_HISTORY',
      nameKr: '예치금 출금 내역',
      path: '/deposit/DepositWithdrawHistory',
      parentIdx: 5,
    },
    {
      idx: 90,
      name: "STATICS_JOIN_EXIT",
      nameKr: "신규/탈퇴 통계",
      path: "/statics/StaticsJoinExit",
      parentIdx: 1,
    },
    {
      idx: 91,
      name: 'SKIN_POPUP',
      nameKr: '라이더/가맹점 스킨/팝업',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 92,
      name: 'SKIN_POPUP_CHILD',
      nameKr: '스킨/팝업 등록',
      path: '/skin/SkinPopup',
      parentIdx: 91,
    },
    {
      idx: 93,
      name: 'BRANCH_SETTLEMENT_LIST',
      nameKr: '지점 정산 등록',
      path: '/branch/BranchSettlementList',
      parentIdx: 68,
    },
    {
      idx: 94,
      name: 'BRANCH_SETTLEMENT_INFO',
      nameKr: '지점 수익 정산',
      path: '/branch/BranchSettlementInfo',
      parentIdx: 68,
    },
    {
      idx: 95,
      name: 'MANUAL_DW',
      nameKr: '수동입출금',
      path: '/deposit/ManualDw',
      parentIdx: 5,
    },
    {
      idx: 96,
      name: 'MANUAL_MAPPING_MENU',
      nameKr: '수동매핑메뉴',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 97,
      name: 'MANUAL_MAPPING',
      nameKr: '수동매핑',
      path: '/manual-mapping',
      parentIdx: 96,
    },
    {
      idx: 98,
      name: 'BRANCH_SETTLEMENT_ALL_LIST',
      nameKr: '지점별 당월 매출',
      path: '/branch/BranchSettlementAllList',
      parentIdx: 68,
    },
    {
      idx: 99,
      name: 'ADJ_DUES_DAY',
      nameKr: '일별 가맹비',
      path: '/settlement/AdjDuesDay',
      parentIdx: 30,
    },
    {
      idx: 100,
      name: 'ADJ_DUES_MONTH',
      nameKr: '월별 가맹비',
      path: '/settlement/AdjDuesMonth',
      parentIdx: 30,
    },
    {
      idx: 101,
      name: 'BRANCH_AUTHENTICATION_STATUS',
      nameKr: '인증현황',
      path: '/branch/BranchAuthenticationStatus',
      parentIdx: 68,
    },
    {
      idx: 102,
      name: 'ADJ_DELIVERY_DAY',
      nameKr: '일별 배달비',
      path: '/settlement/AdjDeliveryDay',
      parentIdx: 30,
    },
    {
      idx: 103,
      name: 'ADJ_DELIVERY_MONTH',
      nameKr: '월별 배달비',
      path: '/settlement/AdjDeliveryMonth',
      parentIdx: 30,
    },
    {
      idx: 104,
      name: 'ADJ_BALANCE_DAY',
      nameKr: '일자별 잔액',
      path: '/settlement/AdjBalanceDay',
      parentIdx: 30,
    },
    {
      idx: 105,
      name: 'NCASH_LOAN_SUB_BRAND',
      nameKr: '일차감(대부)',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 106,
      name: 'LOAN_AURA_SUB_BRAND',
      nameKr: '대출(대양)',
      path: '/ncashLoanSubBrand/LoanAuraForSubBrand',
      parentIdx: 105,
    },
    {
      idx: 107,
      name: 'LOAN_AURA_WITDRAW',
      nameKr: '입출금관리(대양)',
      path: '/ncashLoanSubBrand/LoanAuraWithdraw',
      parentIdx: 105,
    },
    {
      idx: 108,
      name: 'BRANCH_HEAD_OFFICE_INFO',
      nameKr: '지점통합관리',
      path: '/branch/BranchHeadOfficeInfo',
      parentIdx: 68,
    },
    {
      idx: 109,
      name: 'NOTICE_MANAGEMENT',
      nameKr: '공지사항 관리',
      path: '/system/notice',
      parentIdx: 9,
    },
    {
      idx: 110,
      name: 'BRANCH_DAILY_ORDER_STAT',
      nameKr: '지점배달정산',
      path: '/branch/BranchDailyOrderStat',
      parentIdx: 68,
    },
    {
      idx: 111,
      name: 'BRANCH_DEPOSIT_HEAD_OFFICE_HISTORY',
      nameKr: '지점예치금내역',
      path: '/branch/DepositHeadOfficeHistory',
      parentIdx: 68,
    },
    {
      idx: 121,
      name: 'BRANCH_TAX_BOOK_REQUEST',
      nameKr: '위수탁 세금내역',
      path: '/branch/BranchTaxInvoiceDownload',
      parentIdx: 68,
    },
    {
      idx: 123,
      name: 'RIDER_LOCATION_ACCESS_LOG',
      nameKr: '위치 정보 접근 이력',
      path: '/system/RiderLocationAccess',
      parentIdx: 9,
    },
    {
      idx: 124,
      name: 'RIDER_LOCATION_PROVIDE_LOG',
      nameKr: '위치 정보 이용제공 이력',
      path: '/system/RiderLocationProvide',
      parentIdx: 9,
    },
  ],
  // 제휴본사용으로 필수 메뉴인 경우 추가해두면 로그인할때 아래 메뉴는 바로 권한이 부여됨
  // 현재는 필요한 메뉴만 열어둘 수 있도록 수정(24.03.25)
  agency_menu : [
    {
      idx: 112,
      name: 'AGENCY_SETTELMENT_MANAGE',
      nameKr: '정산 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 113,
      name: 'AGENCY_SETTELMENT',
      nameKr: '정산 내역',
      path: '/agency/AgencyDashboard',
      parentIdx: 112,
    },
    {
      idx: 114,
      name: 'AGENCY_MANAGE',
      nameKr: '제휴본사 관리',
      path: null,
      parentIdx: 0,
    },
    {
      idx: 115,
      name: 'AGENCY_DETAIL_LIST',
      nameKr: '내역 조회',
      path: '/agency/AgencyNcashList',
      parentIdx: 114,
    },
    {
      idx: 116,
      name: 'AGENCY_BATCH_WORK',
      nameKr: '일차감 등록',
      path: '/agency/AgencyBatchWork',
      parentIdx: 114,
    },
    {
      idx: 117,
      name: 'AGENCY_BATCH_WORK_LIST',
      nameKr: '일차감 내역',
      path: '/agency/AgencyBatchWorkList',
      parentIdx: 114,
    },
    {
      idx: 118,
      name: 'AGENCY_WITHDRAW_APPROVAL',
      nameKr: '출금 승인',
      path: '/branch/BranchHeadOfficeInfo',
      parentIdx: 114,
    },
    {
      idx: 119,
      name: 'AGENCY_FR_MANAGE',
      nameKr: '가맹점 관리',
      path: '/agency/AgencyFrManage',
      parentIdx: 114,
    },
    {
      idx: 120,
      name: 'AGENCY_ORDER_SETTELMENT',
      nameKr: '주문 통계',
      path: '/agency/AgencyOrderSettlement',
      parentIdx: 114,
    },
    {
      idx: 122,
      name: 'AGENCY_TAX_RETURN',
      nameKr: '세금 내역',
      path: '/agency/AgencyTaxReturn',
      parentIdx: 112,
    },
  ],
};
