import React, { useEffect, useRef, useState } from "react";
import { Button, DatePicker, Input, Select, Table } from 'antd';
import moment from 'moment/moment';
import { comma } from '../../lib/util/numberUtil';
import {
  agencyNcashAllForAgency,
  agencyNcashAllForBranch,
  agencyNcashDeliveryColumns,
  agencyTaxReturnForDeliveryColumns,
  agencyTaxReturnForDuesColumns,
  allBranchList,
} from '../../lib/util/agencyUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { SearchOutlined } from '@ant-design/icons';
import axios from 'axios';
import Const from '../../const';

const AgencyTaxReturn = () => {
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const today = moment().format('YYYY-MM-DD');
  const [branches] = useState(allBranchList());
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [list, setList] = useState([]);
  const [totalVat, setTotalVat] = useState(0);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('delivery');
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [belongBranch, pagination.current, type]);
  const getData = async (paging) => {
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let branch = [...branches];
    let companyId = [];
    // 제휴본사일 경우 소속 대리점 모두 파라미터로
    if (belongBranch === loginInfo.branch.companyId || belongBranch === '대리점 전체') {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId)
        companyId.push(value.branchName);
      });
    } else companyId = [belongBranch];
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: loginInfo.branch.companyId,
      frBranchName: companyId.join(),
      search: search,
      page: currPage,
      size: pagination.pageSize,
    };
    const datas = await getTaxReturnDate(customParams);
    if (datas) {
      setList(() => {
        return datas.data.contents;
      });
      setPagination({
        ...pagination,
        current: currPage,
        total: datas.data.totalCount,
      });
      setTotalVat(datas.data.totalVat);
      setIsLoading(false);
    }
    if (source.current === null) {
      setIsLoading(false);
    }
  };
  const getTaxReturnDate = async (customParams) => {
    const deliveryUrl = '/tax-history/delivery-price-vat/agency';
    const duesUrl = '/tax-history/dues-vat/agency';
    const url = type === 'delivery' ? deliveryUrl : duesUrl;
    if (source.current !== null) {
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      setIsLoading(false);
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };
  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const columns =
    type === 'delivery'
      ? agencyTaxReturnForDeliveryColumns
      : agencyTaxReturnForDuesColumns;

  return (
    <div>
      <h1 className={'agency-page-title'}>세금내역</h1>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-menu-totalsum'}>
          <span>총 부가세 합계: {comma(totalVat)}</span>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value) => {
              setList([]);
              setBelongBranch(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}>
            {branches.map((value) => {
              return (
                <Select.Option key={value.branchName} value={value.branchName}>
                  {value.branchName}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
      </div>
      <div className={'ncl-ctg-box'}>
        <div
          onClick={() => {
            setType('delivery');
            setPagination({
              ...pagination, current:1
            })
          }}
          className={
            type === 'delivery' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'
          }>
          배달비 부가세
        </div>
        <div
          onClick={() => {
            setType('dues');
            setPagination({
              ...pagination, current:1
            })
          }}
          className={type === 'dues' ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'}>
          가맹비 부가세
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'사업자번호/가맹점명'}
            style={{
              border: 'none',
              paddingLeft: 10,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onClick={() => getData(true)}
          />
        </div>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyTaxReturn;
