import { Button, DatePicker, Input, Radio, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';

const Search = Input.Search;

class DepositNotRecoveredMongo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 50000,
      },
      userId: '',
      list: [],
      searchType: 1,
    };
    this.columns = [
      {
        title: '지점',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '구분',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data) => <div>{data === 1 ? '라이더' : '가맹점'}</div>,
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '이름',
        dataIndex: 'frName',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{row.userType === 1 ? row.riderName : data}</div>
        ),
      },
      {
        title: '미회수금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    let branchIdx = this.state.branchIdx;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userId = this.state.userId;
    let userType = this.state.searchType;
    httpGet(
      httpUrl.depositNotRecoveredMongoList,
      [branchIdx, pageNum, pageSize, userId, userType],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.withdraws,
        pagination,
      });
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchId = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChange = (e) => {
    this.setState(
      {
        searchType: e.target.value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  render() {
    return (
      <>
        <Radio.Group
          defaultValue={1}
          onChange={this.onChange}
          style={{ marginTop: 5 }}>
          <Radio value={1}>라이더</Radio>
          <Radio value={2}>가맹점</Radio>
        </Radio.Group>

        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ userId: e.target.value })}
          onSearch={this.onSearchId}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(DepositNotRecoveredMongo);
