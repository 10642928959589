import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Input, Modal, Select, Table, Tooltip } from 'antd';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import FileSaver from 'file-saver';
import Const from '../../const';
import {
  agencyNcashDeliveryColumns,
  allBranchList,
} from '../../lib/util/agencyUtil';

const AgencyNcashDeliveryTbl = (props) => {
  const loginInfo = props.loginInfo;
  const [branches] = useState(allBranchList());
  const today = moment().format('YYYY-MM-DD');
  const Option = Select.Option;

  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [list, setList] = useState([]);
  const [belongBranch, setBelongBranch] = useState(loginInfo.branch.companyId);
  const [search, setSearch] = useState(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [exceldownloading, setExceldownloading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  useEffect(() => {
    getData();
  }, [belongBranch, pagination.current]);
  useEffect(() => {
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, []);
  const getNcashtDate = async (customParams) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    setIsLoading(true);
    try {
      return await axios.get('deposit/delivery-list', {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
    } catch (e) {
      setIsLoading(false);
      console.error('getting ncash-deposit-list is fail: ' + e);
    }
  };
  const getData = async (paging) => {
    if (exceldownloading) return;
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let branch = [...branches];
    let companyId = [];
    // 제휴본사일 경우 소속 대리점 모두 파라미터로
    if (belongBranch === loginInfo.branch.companyId) {
      branch.forEach((value, index) => {
        if (value.companyId !== loginInfo.branch.companyId) {
          companyId.push(value.companyId);
        }
      });
    } else companyId = [belongBranch];
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: companyId.join(),
      search: search,
      page: currPage,
      size: pagination.pageSize,
    };
    const datas = await getNcashtDate(customParams);
    if (datas) {
      setList(() => {
        return datas.data.results;
      });
      setPagination({
        ...pagination,
        current: currPage,
        total: datas.data.totalCount,
      });
      setIsLoading(false);
    }
    if (source.current === null) {
      setIsLoading(false);
    }
  };
  const downloadExcelApi = async (customParamsForExcel) => {
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      return await axios.get('deposit/delivery-list/excel', {
        baseURL: Const.settlementUrl,
        params: customParamsForExcel,
        cancelToken: source.current.token,
        responseType: 'arraybuffer',
      });
    } catch (e) {
      console.error('ncashCompanyList-execl-downloading is failed: ' + e);
    }
  };
  const onDownloadExcel = async () => {
    let branch = [...branches];
    let companyId = [];
    // 제휴본사일 경우 소속 대리점 모두 파라미터로
    if (belongBranch === loginInfo.branch.companyId) {
      branch.forEach((value, index) => {
        companyId.push(value.companyId);
      });
    } else companyId = [belongBranch];
    let customParamsForExcel = {
      startDate: startDate,
      endDate: endDate,
      companyId: companyId.join(),
      search: search,
      isAdmin: true,
    };
    if (isLoading) return;
    setIsLoading(true);
    setExceldownloading(true);
    const response = await downloadExcelApi(customParamsForExcel);
    if (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(
        blob,
        '예치금-배달내역-' + `${moment(startDate).format('YYYY-MM-DD')}.xlsx`
      );
      setIsLoading(false);
      setExceldownloading(false);
      Modal.success({
        content: '엑셀파일 다운로드가 완료되었습니다.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    } else {
      Modal.error({
        content:
          '다운로드에 실패했습니다. 검색 기간을 줄여서 다시 시도하시거나 관리자에게 문의해주세요.',
      });
      setIsLoading(false);
      setExceldownloading(false);
    }
  };

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
      const temp = moment(date).add(30, 'd').format('YYYY-MM-DD');
      if (moment(endDate).isAfter(temp)) setEndDate(temp);
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };

  const disabledDate = (current, from) => {
    if (startDate) {
      return Math.abs(current.diff(startDate, 'days')) > 30;
    }
    return false;
  };
  return (
    <div>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value) => {
              setList([]);
              setBelongBranch(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}>
            {branches.map((value) => {
              return (
                <Option key={value.branchName} value={value.companyId}>
                  {value.branchName}
                </Option>
              );
            })}
          </Select>
        </div>

        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            disabledDate={disabledDate}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
      </div>
      <div className={"ncl-ctg-box"}>
        <div
          onClick={() => props.callback("all")}
          className={props.type === "all" ? "ncl-ctn-btn active" : "ncl-ctn-btn"}>
          전체 내역
        </div>
        <div
          onClick={() => props.callback("delivery")}
          className={props.type === "all" ? "ncl-ctn-btn" : "ncl-ctn-btn active"}>
          배달 내역
        </div>
        <div className={"ncl-input-box"} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={"주문번호/라이더명/가맹점명/사업자번호"}
            style={{
              border: "none",
              paddingLeft: 10,
              width: 200
            }}
          />
          <SearchOutlined
            style={{ padding: "0 10px", cursor: "pointer" }}
            onChange={() => getData(true)}
          />
        </div>
        <div className={"ncl-excel-box"}>
          <Tooltip
            title="기간 설정은 한달 단위로 선택이 가능합니다."
            placement="topRight">
            <div className={"ncl-excel-btn"} onClick={onDownloadExcel}>
              엑셀다운로드
              <VerticalAlignBottomOutlined />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={"ncl-table"}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={agencyNcashDeliveryColumns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && "ncl-table-row"}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyNcashDeliveryTbl;
