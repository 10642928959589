import { Button, DatePicker, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import xlsx from 'xlsx';
import { customAlert, customError } from '../../api/Modals';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import ChargeDialog from '../../components/dialog/ChargeDialog';
import FranFeeDialog from '../../components/dialog/FranFeeDialog';
import ModifyFranDialog from '../../components/dialog/ModifyFranDialog';
import RegistVANDialog from '../../components/dialog/RegistVANDialog';
import FrOrderAgencyDialog from '../../components/dialog/frachise/FrOrderAgencyDialog';
import RegistAccountDialog from '../../components/dialog/frachise/RegistAccountDialog';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import {
  dwServiceTypeCode,
  mapPaystoryVacntResultCd,
  statusString,
} from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class FranchiseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      franFeeDialog: false, //가맹비내역
      modifyFranDialog: false, //수정
      chargeDialog: false, //충전내역
      registVANdialogOpen: false, //VAN등록요청
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 100000,
      },
      frName: '',
      branchName: '',
      businessNumber: '',
      franchiseData: [], //가맹점 수정 데이터

      frOrderAgencyDialogOpen: false,
      frOrderAgencyDialogUserIdx: null,
    };
    this.registAccountDialogData = null;
  }

  componentDidMount() {
    console.log(this.props.loginInfo);
    this.getList();
    // this.getExcelList();
  }

  setDate = (date) => {
    console.log(date);
  };

  // 수정 dialog
  openModifyFranDialogModal = (row) => {
    this.setState({
      modifyFranDialogOpen: true,
      franchiseData: row,
    });
  };
  closeModifyFranDialogModal = () => {
    this.setState({ modifyFranDialogOpen: false });
  };
  //가맹비내역 dialog
  openFranFeeDialogModal = () => {
    this.setState({ franFeeDialogOpen: true });
  };
  closeFranFeeDialogModal = () => {
    this.setState({ franFeeDialogOpen: false });
  };
  //충전내역 dialog
  openChargeDialogModal = () => {
    this.setState({ chargeDialogOpen: true });
  };
  closeChargeDialogModal = () => {
    this.setState({ chargeDialogOpen: false });
  };
  // VAN등록요청 dialog
  openVANdialogModal = () => {
    this.setState({ registVANdialogOpen: true });
  };
  closeVANdialogModal = () => {
    this.setState({ registVANdialogOpen: false });
  };
  openRegistAccountModal = (row) => {
    this.registAccountDialogData = row;
    this.setState({ registAccountDialogOpen: true });
  };
  closeRegistAccountModal = () => {
    this.registAccountDialogData = null;
    this.setState({ registAccountDialogOpen: false });
  };

  vanWatingAlert() {
    alert('준비중입니다.');
  }

  registPG = (userIdx) => {
    let self = this;
    Modal.confirm({
      title: 'PG 지갑 등록',
      content:
        'PG사에 가맹점 지갑을 등록합니다. 등록 시 가상계좌도 발급됩니다. 실제 계좌가 발급되니 무분별한 등록은 자제해주세요. 정말 등록하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.registFranchisePG, [userIdx], {})
          .then((result) => {
            if (
              result.result === 'SUCCESS' &&
              result.data.resultCd === '0000'
            ) {
              customAlert('완료', 'PG에 가맹점이 등록되었습니다..');
            } else {
              customError(result.data.resultMsg, result.data.advanceMsg);
            }
            self.getList();
          })
          .catch((error) => {
            customError(
              '등록오류',
              '에러가 발생하였습니다. 다시 시도해주세요.'
            );
          });
      },
    });
  };

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.franchiseList,
      [
        this.props.branchIdx,
        this.state.branchName,
        this.state.frName,
        this.state.businessNumber,
        pagination.current,
        pagination.pageSize,
      ],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.franchises,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };
  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearchFranchise = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  onSearchBranch = (value) => {
    this.setState(
      {
        branchName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  onSearchBusinessNumber = (value) => {
    this.setState(
      {
        businessNumber: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  // getExcelList = () => {
  //   const pagination = this.state.paginationExcel;
  //   httpGet(
  //     httpUrl.franchiseList,
  //     [
  //       this.state.branchName,
  //       this.state.frName,
  //       pagination.current,
  //       pagination.pageSize,
  //     ],
  //     {}
  //   ).then((res) => {
  //     if (res.result === "SUCCESS") {
  //       this.setState({
  //         listExcel: res.data.franchises,
  //         pagination: {
  //           ...this.state.pagination,
  //           current: res.data.currentPage,
  //           total: res.data.totalCount,
  //         },
  //       });
  //     }
  //   });
  // };

  parseExcelJson = (data) => {
    let result = [
      {
        userIdx: '순번',
        userStatus: '상태',
        branchName: '지점명',
        frName: '가맹점명',
        frNameExtra: '실가맹점명',
        businessNumber: '사업자번호',
        frPhone: '가맹점번호',
        addr1: '가맹점주소',
        addr2: '상세주소',
        ownerName: '대표자명',
        userEmail: '이메일',
        tidNormal: 'VAN',
        tidPrepay: 'PG',
        tidNormalRate: 'PG사용',
        walletId: 'PG지갑',
        vaccountBank: '가상계좌은행',
        vaccountNumber: '가상계좌번호',
        simplifiedTaxPayer: '간이사업자여부',
        basicDeliveryPrice: '기본배달료',
        useBasicDeliveryPrice: '기본배달료사용',


  },
    ];
    data.forEach((item) => {
      result.push({
        userIdx: item.userIdx,
        userStatus: statusString[item.userStatus],
        branchName: item.branchName,
        frName: item.frName,
        frNameExtra: item.frNameExtra,
        businessNumber: item.businessNumber,
        frPhone: item.frPhone,
        addr1: item.addr1,
        addr2: item.addr2,
        ownerName: item.ownerName,
        userEmail: item.userEmail,
        tidNormal: item.tidNormal,
        tidPrepay: item.tidPrepay,
        tidNormalRate: item.tidNormalRate == 100 ? '미사용' : '사용',
        walletId: item.walletId,
        vaccountBank: item.vaccountBank,
        vaccountNumber: item.vaccountNumber,
        simplifiedTaxPayer:
          item.companyId !== 'connect9' && item.simplifiedTaxPayer === true
            ? '간이사업자'
            : '일반사업자',
        basicDeliveryPrice: !item.useBasicDeliveryPrice
          ? item.basicDeliveryPrice
          : item.frBasicDeliveryPrice,
        useBasicDeliveryPrice: !item.useBasicDeliveryPrice ? '미사용' : '사용',

      });
    });

    return result;
  };

  onDownload = () => {
    const pagination = this.state.paginationExcel;
    httpGet(
      httpUrl.franchiseList,
      [
        this.props.branchIdx,
        this.state.branchName,
        this.state.frName,
        this.state.businessNumber,
        pagination.current,
        pagination.pageSize,
      ],
      {}
    ).then((res) => {
      debugger;
      const excelJson = this.parseExcelJson(res.data.franchises);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      ws['!cols'] = [];
      ws['!cols'][2] = { width: 20 };
      ws['!cols'][3] = { width: 15 };
      ws['!cols'][4] = { width: 20 };
      ws['!cols'][5] = { width: 15 };
      ws['!cols'][8] = { width: 20 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '가맹점목록.xlsx');
    });
  };

  mapPaystoryVacnt = (fr) => {
    console.log(fr);
    Modal.confirm({
      title: '가상계좌발급',
      content: `${fr.frName} 가맹점에 가상계좌를 발급하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.mapPaystoryVacnt, [fr.userIdx], {});

        console.log(res);

        if (res.data.result !== 'SUCCESS') {
          Modal.warn({
            title: '가상계좌 발급 실패',
            content: mapPaystoryVacntResultCd[res.data.result],
          });
        } else {
          Modal.info({
            title: '가상계좌 발급 성공',
            content: mapPaystoryVacntResultCd[res.data.result],
            afterClose: () => {
              console.log('result info afterClose');
            },
          });
        }
        this.getList();
      },
    });
  };

  openFrOrderAgencyDialog = (userIdx) => {
    this.setState({
      frOrderAgencyDialogOpen: true,
      frOrderAgencyUserIdx: userIdx,
    });
  };

  closeFrOrderAgencyDialog = () => {
    this.setState({
      frOrderAgencyDialogOpen: false,
      frOrderAgencyUserIdx: null,
    });
  };

  expandedRowRender = (record) => {
    const dropColumns = [
      {
        title: '출금계좌',
        dataIndex: 'bank',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => this.openRegistAccountModal(row)}>
            {data ? (
              `${data.split(',')[0]} ${row.bankAccount}`
            ) : (
              <Button onClick={() => this.openRegistAccountModal(row)}>
                계좌등록
              </Button>
            )}
          </div>
        ),
      },
      {
        title: '기본배달비',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
        render: (data, row) => {
          if (!row.useBasicDeliveryPrice) {
            return (
              <div className="c-p-10">
                {comma(data)}
              </div>
            );
          } else {
            return <div className="c-p-10">{comma(row.frBasicDeliveryPrice)}</div>;
          }
        },
      },
      {
        title: '가맹비내역',
        dataIndex: 'franFeeList',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={this.openFranFeeDialogModal}>가맹비내역</Button>
        ),
      },
      {
        title: '충전내역',
        dataIndex: 'chargeList',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={this.openChargeDialogModal}>충전내역</Button>
        ),
      },
      {
        title: '수정',
        dataIndex: 'update',
        className: 'table-column-center',
        render: (data, row) => (
          <Button onClick={() => this.openModifyFranDialogModal(row)}>
            수정
          </Button>
        ),
      },
      {
        title: 'PG지갑',
        dataIndex: 'walletId',
        className: 'table-column-center',
        render: (data, row) => {
          if (row.dwServiceType === dwServiceTypeCode.PAYSTORY)
            return <div>페이스토리 이용 지점</div>;
          return data ? (
            <div>{data}</div>
          ) : (
            <Button
              onClick={() => {
                console.log(row);
                this.registPG(row.userIdx);
              }}>
              PG등록
            </Button>
          );
        },
      },
      {
        title: '가상계좌발급',
        dataIndex: 'vaccountNumber',
        className: 'table-column-center',
        render: (data, row) => {
          if (row.dwServiceType === dwServiceTypeCode.KWANGWON)
            return <div>광원 이용지점</div>;

          return data ? (
            <div>{`${row.vaccountBank} / ${data}`}</div>
          ) : (
            <Button
              onClick={() => {
                this.mapPaystoryVacnt(row);
              }}>
              가상계좌발급
            </Button>
          );
        },
      },
      {
        title: '매장연동설정',
        dataIndex: 'userIdx',
        className: 'table-column-center',
        render: (data) => (
          <Button onClick={() => this.openFrOrderAgencyDialog(data)}>
            매장연동설정
          </Button>
        ),
      },
    ];
    return (
      <Table
        rowKey={(record) => `record: ${record.idx}`}
        columns={dropColumns}
        dataSource={[record]}
        pagination={false}
      />
    );
  };

  getTotalMisuFromUser = (user) => {
    return (
      (user.misu ? user.misu : 0) +
      (user.misu2 ? user.misu2 : 0) +
      (user.misu3 ? user.misu3 : 0)
    );
  };

  render() {
    const columns = [
      {
        title: '순번',
        dataIndex: 'userIdx',
        className: 'table-column-center',
      },
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data) => <div>{statusString[data]}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },

      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
      },
      {
        title: '가맹점번호',
        dataIndex: 'frPhone',
        className: 'table-column-center',
      },
      {
        title: '사업자번호',
        dataIndex: 'businessNumber',
        className: 'table-column-center',
      },

      {
        title: '가맹점주소',
        // dataIndex: "franAddr",
        className: 'table-column-center',
        render: (data, row) => <div>{row.addr1 + ' ' + row.addr2}</div>,
      },
      {
        title: '가상계좌은행',
        dataIndex: 'vaccountBank',
        className: 'table-column-center',
      },
      {
        title: '가상계좌번호',
        dataIndex: 'vaccountNumber',
        className: 'table-column-center',
      },
      {
        title: '예치금잔액',
        dataIndex: 'ncash',
        className: 'table-column-center',
        render: (data) => <div>{data ? comma(data) : '-'}</div>,
      },
      {
        title: '미수금',
        dataIndex: 'misu',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{comma(this.getTotalMisuFromUser(row))}</div>
        ),
      },
      {
        title: 'VAN',
        dataIndex: 'tidNormal',
        className: 'table-column-center',
      },
      {
        title: 'PG',
        dataIndex: 'tidPrepay',
        className: 'table-column-center',
      },
      {
        title: 'PG 사용',
        dataIndex: 'tidNormalRate',
        className: 'table-column-center',
        render: (data) => <div>{data == 100 ? '미사용' : '사용'}</div>,
      },
    ];

    return (
      <>
        <Search
          placeholder="지점명 검색"
          enterButton
          allowClear
          onSearch={this.onSearchBranch}
          style={{
            width: 220,
          }}
        />
        <Search
          placeholder="가맹점 검색"
          enterButton
          allowClear
          onSearch={this.onSearchFranchise}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        <Search
          placeholder="사업자번호 검색"
          enterButton
          allowClear
          onSearch={this.onSearchBusinessNumber}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        {this.state.registVANdialogOpen && (
          <RegistVANDialog
            close={this.closeVANdialogModal}
            getList={this.getList}
          />
        )}
        <Button
          style={{ marginBottom: 20, marginLeft: 20 }}
          // onClick={this.openVANdialogModal}
          onClick={this.vanWatingAlert}>
          VAN 등록요청
        </Button>
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        {this.state.frOrderAgencyDialogOpen && (
          <FrOrderAgencyDialog
            userIdx={this.state.frOrderAgencyUserIdx}
            close={this.closeFrOrderAgencyDialog}
          />
        )}

        {this.state.modifyFranDialogOpen && (
          <ModifyFranDialog
            data={this.state.franchiseData}
            close={this.closeModifyFranDialogModal}
            getList={this.getList}
          />
        )}

        {this.state.franFeeDialogOpen && (
          <FranFeeDialog close={this.closeFranFeeDialogModal} />
        )}
        {this.state.chargeDialogOpen && (
          <ChargeDialog close={this.closeChargeDialogModal} />
        )}
        {this.state.registAccountDialogOpen && (
          <RegistAccountDialog
            data={this.registAccountDialogData}
            close={this.closeRegistAccountModal}
            getList={this.getList}
          />
        )}

        <Table
          rowKey={(record) => record.userIdx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={this.expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(FranchiseList);
