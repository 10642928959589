import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, Modal, Select, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { comma } from '../../lib/util/numberUtil';
import AgencyRegistBatchWorkDialog from '../../components/agencyDialog/AgencyRegistBatchWorkDialog';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { httpDelete, httpGet, httpPost, httpUrl } from '../../api/httpClient';
import {
  batchWorkCategoryForAgency,
  batchWorkCategoryForAgencyCode,
} from '../../lib/util/codeUtil';

const AgencyBatchWork = () => {
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const [kind, setKind] = useState(999);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const [registBatchWorkModalOpen, setRegistBatchWorkModalOpen] =
    useState(false);
  const [checklist, setCheckList] = useState([]);
  const [propsData, setPropsData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  const getData = async (paging) => {
    if (isLoading) return;
    setIsLoading(true);
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    const res = await httpGet(
      httpUrl.getAgencyBatchWorkList,
      [currPage, pagination.pageSize, kind, search],
      {}
    );
    if (res.data) {
      console.log(res.data)
      setList(res.data.riderBatchWorkList);
      setPagination({
        ...pagination,
        current: currPage,
        total: res.data.totalCount,
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [kind, pagination.current]);

  const onCofirmModal = async () => {
    if (checklist.length < 1) return;
    Modal.confirm({
      title: '일차감 삭제',
      content:
        '총 ' + `${checklist.length}` + ' 건의 일차감을 삭제하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        deleteBatchWork();
      },
    });
  };
  const deleteBatchWork = async () => {
    const res = await httpPost(httpUrl.deletedAgencyBatchWorkNcash, [], {
      batchWorkNcashIdx: checklist,
    });
    if (res.data === 'SUCCESS') {
      setCheckList([]);
      getData();
    }
  };
  const onCheckList = (value) => {
    let list = [...checklist];
    if (list.includes(value)) {
      const filterList = list.filter((x) => x !== value);
      setCheckList(filterList);
    } else {
      list.push(value);
      setCheckList(list);
    }
  };
  const columns = [
    {
      title: '선택',
      dataIndex: 'idx',
      className: 'table-column-center',
      render: (data) => <Checkbox onClick={() => onCheckList(data)} />,
    },
    {
      title: '기간제한 시작일',
      dataIndex: 'startDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '기간제한 종료일',
      dataIndex: 'endDate',
      className: 'table-column-center',
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '구분',
      dataIndex: 'kind',
      className: 'table-column-center',
      render: (data) => batchWorkCategoryForAgencyCode[data],
    },
    {
      title: '대상명',
      dataIndex: 'userName',
      className: 'table-column-center',
    },
    {
      title: '차감명',
      dataIndex: 'title',
      className: 'table-column-center',
    },
    {
      title: '차감금액',
      dataIndex: 'ncashDelta',
      className: 'table-column-center',
      render: (data) => (
        <div style={{ textAlign: 'right', paddingRight: 10 }}>
          {comma(data)}
        </div>
      ),
    },
    {
      title: '수정',
      dataIndex: 'idx',
      className: 'table-column-center',
      render: (data, row) => (
        <Button
          onClick={() => {
            setRegistBatchWorkModalOpen(true);
            setPropsData(row);
          }}>
          수정
        </Button>
      ),
    },
  ];

  const handelModal = (value) => {
    setRegistBatchWorkModalOpen(value);
  };

  return (
    <div>
      <h1 className={'agency-page-title'}>일차감 등록</h1>
      <div className={"ncl-menu-wrap"}>
        <div className={"ncl-select-box"}>
          <Select
            defaultValue={batchWorkCategoryForAgency[0]}
            options={batchWorkCategoryForAgency}
            onChange={(value) => {
              setKind(value);
              setPagination({
                ...pagination,
                current: 1
              })
            }}
            style={{ flex: 1, border: "none" }}
          />
        </div>
        <div className={"ncl-input-box"} style={{ width: 240 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={"대상명을 입력해주세요"}
            style={{
              border: "none",
              paddingLeft: 10,
              fontSize: 12,
              width: 200
            }}
          />
          <SearchOutlined style={{ padding: "0 10px", cursor: "pointer" }} onClick={() => getData(true)} />
        </div>
        <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
          <div
            onClick={() => {
              setRegistBatchWorkModalOpen(true);
              setPropsData(null);
            }}
            className={"ncl-ncash-btn"} >
            일차감 등록
          </div>
          <div onClick={onCofirmModal} className={"ncl-ncash-btn"} style={{backgroundColor:'#fff', marginLeft: 10}}>
            일차감 삭제
          </div>
        </div>
      </div>
      <div className={"ncl-table"}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && "ncl-table-row"}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
      {registBatchWorkModalOpen && (
        <AgencyRegistBatchWorkDialog
          close={() => handelModal(false)}
          loginInfo={loginInfo}
          data={propsData}
          callback={() => {
            getData(true);
          }}
        />
      )}
    </div>
  );
};
export default AgencyBatchWork;
