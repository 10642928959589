import {Table} from 'antd';
import React, {Component} from 'react';
import {httpGet, httpPost, httpUrl} from '../../api/httpClient';
import '../../css/main.css';

class BranchAuthenticationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                total: 0,
                current: 1,
                pageSize: 30,
            },
            list: [],
        };
    }

    async componentDidMount() {
        await this.getBranchInfo();
        await this.getList();
    }

    getBranchInfo = async () => {
        let branchNameList = {};
        try {
            const res = await httpPost(httpUrl.branchListInfo, [], {});
            if (res.result === 'SUCCESS') {
                res.data.branch.map(
                    (item) => (branchNameList[item.idx] = item.branchName)
                );
                this.setState({
                    branchNameList: branchNameList,
                });
            }
        } catch (error) {
            console.warn('getBranchInfo error: {}', error);
        }
    };

    getList = async () => {
        const {pagination} = this.state;
        const pageNum = pagination.current;
        const pageSize = pagination.pageSize;
        const res = await httpGet(httpUrl.getFrRiderAuthStatus, [pageNum, pageSize], {});
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        if (res.result === 'SUCCESS') {
            this.setState({
                list: res.data.list,
                pagination: pagination,
            });
        }
    };


    handleTableChange = (pagination) => {
        const pager = {
            ...this.state.pagination,
        };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState(
            {
                pagination: pager,
            },
            () => this.getList()
        );
    };

    render() {
        const {branchNameList} = this.state;
        const columns = [
            {
                title: '지점명',
                dataIndex: 'branchIdx',
                className: 'table-column-center',
                render: (data) => <div>{branchNameList[data]}</div>,
            },
            {
                title: '총가맹점수',
                dataIndex: 'openFrCnt',
                className: 'table-column-center',
            },
            {
                title: '인증된가맹점수',
                dataIndex: 'nameValidFrCnt',
                className: 'table-column-center',
                render: (data) => <div>{data == null || undefined ? 0 : data}</div>
            },
            {
                title: '가맹점인증률(%)',
                className: 'table-column-center',
                render: (row) =>
                    /**소수점 둘째짜리까지 표시
                     * USER_STATUS = 1인 가맹점이 0이면 나누기하면 안됨.*/
                    <div>{row.openFrCnt == 0 ? "-" : Math.round(row.nameValidFrCnt / row.openFrCnt * 10000) / 100 + "%"}</div>
            },
            {
                title: '총라이더수',
                dataIndex: 'openRiderCnt',
                className: 'table-column-center',
            },
            {
                title: '인증된라이더수',
                dataIndex: 'nameValidRiderCnt',
                className: 'table-column-center',
                render: (data) => <div>{data == null || undefined ? 0 : data}</div>
            },
            {
                title: '라이더인증률(%)',
                className: 'table-column-center',
                render: (row) =>
                    <div>{row.openRiderCnt == 0 ? "-" : Math.round(row.nameValidRiderCnt / row.openRiderCnt * 10000) / 100 + "%"}</div>
            },
        ];

        return (
            <Table
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
            />
        );
    }
}

export default BranchAuthenticationStatus;
