import { Button, DatePicker, Form, Input, Modal, Radio, Select } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import { batchCategory, kindString } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;
const Option = Select.Option;
const today = new Date();
const { RangePicker } = DatePicker;

class RegistBatchWorkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRiderOpen: false,
      selectedGroup: null,
      list: [],
      startDate: moment(today).format('YYYY-MM-DD 00:00'),
      endDate: moment(today).add(1, 'M').format('YYYY-MM-DD 23:59'),
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      batchSearchGrp: null,
      disabled: false,
      kind: 1,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    let { data } = this.props;
    data &&
      this.setState({
        startDate: data.startDate,
        endDate: data.endDate,
      });

    // this.getList()
  }

  setDate = (date) => {
    console.log(date);
  };

  onSearchRider = (value) => {
    this.setState({ searchName: value }, () => {
      this.getList();
    });
  };

  onChangeDate = (dateString) => {
    this.setState({
      startDate:
        dateString != null
          ? moment(dateString[0]).format('YYYY-MM-DD 00:00')
          : '',
      endDate:
        dateString != null
          ? moment(dateString[1]).format('YYYY-MM-DD 23:59')
          : '',
    });
  };

  handleSubmit = () => {
    let { data } = this.props;
    let self = this;
    console.log({
      ...self.formRef.current.getFieldsValue(),
      startDate: self.state.startDate,
      endDate: self.state.endDate,
      memo: '',
    });
    Modal.confirm({
      title: <div> {data ? '일/월차감 수정' : '일/월차감 등록'}</div>,
      content: (
        <div>
          {data
            ? '일/월차감 정보를 수정하시겠습니까?'
            : '일/월차감 정보를 등록하시겠습니까?'}
        </div>
      ),
      okText: '확인',
      cancelText: '취소',
      onOk() {
        data
          ? httpPost(httpUrl.riderBatchWorkUpdate, [], {
              ...self.formRef.current.getFieldsValue(),
              idx: data.idx,
              startDate: self.state.startDate,
              endDate: self.state.endDate,
            })
              .then((result) => {
                if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                  customAlert(
                    '일/월차감 수정',
                    '일/월차감 수정이 완료되었습니다.'
                  );
                  self.props.close();
                  self.props.callback();
                } else
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
              })
              .catch((error) => {
                if (self.state.startDate === '')
                  customError('추가 오류', '기간 제한을 입력해 주십시오.');
                else
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
              })
          : httpPost(httpUrl.riderBatchWorkCreate, [], {
              ...self.formRef.current.getFieldsValue(),
              startDate: self.state.startDate,
              endDate: self.state.endDate,
              memo: '',
            })
              .then((res) => {
                console.log(res);
                console.log(
                  'aaa ' + JSON.stringify(res.data.riderBatchWorkList, null, 4)
                );
                if (res.data === 'SUCCESS' && res.result === 'SUCCESS') {
                  customAlert(
                    '일/월차감 등록',
                    '일/월차감 등록이 완료되었습니다.'
                  );
                  self.props.close();
                  self.props.callback();
                } else
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
              })
              .catch((error) => {
                if (self.state.startDate === '')
                  customError('추가 오류', '기간제한을 입력해 주십시오.');
                else
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
              });
      },
    });
  };

  render() {
    const { data, close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="taskWork-Dialog">
          <div className="taskWork-content">
            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="taskWork-title">
                {data ? '일/월차감 수정' : '일/월차감 등록'}
              </div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="taskWork-close"
                alt="close"
              />
              <div className="taskWork-title-sub">일/월차감 등록정보</div>
              <div className="taskWork-inner">
                <div className="taskWork-list">
                  <div className="twl taskWork-list-01">
                    <div className="twl-text">일/월 구분</div>
                    <FormItem
                      name="category"
                      className="selectItem"
                      initialValue={data ? data.category : 1}
                      rules={[
                        {
                          required: true,
                          message: '일/월 구분을 선택해주세요',
                        },
                      ]}>
                      <Radio.Group disabled={this.props.subBrand ? true : false}>
                        {batchCategory.map((category, i) => {
                          if (i === 0) return;
                          return <Radio value={i}>{category}</Radio>;
                        })}
                      </Radio.Group>
                    </FormItem>
                  </div>

                  <div className="twl taskWork-list-01">
                    <div className="twl-text">구분</div>
                    <FormItem
                      name="kind"
                      className="selectItem"
                      initialValue={this.props.subBrand ? 16 : data ? data.kind : ''}
                      rules={[
                        { required: true, message: '구분을 선택해주세요' },
                      ]}>
                      <Select
                        disabled={this.props.subBrand ? true : false}
                        onChange={(value) => this.setState({ kind: value })}>
                        {kindString.map((v, index) => {
                          if (index === 0) return <></>;
                          return <Option value={index}>{v}</Option>;
                        })}
                      </Select>
                    </FormItem>
                  </div>
                  <div className="twl taskWork-list-02">
                    <div className="twl-text">차감명</div>
                    <div className="taskWork-place1">
                      <div className="inputBox inputBox-taskWork sub">
                        <FormItem
                          name="title"
                          initialValue={data ? data.title : ''}
                          rules={[
                            {
                              required: true,
                              message: '차감명을 입력해주세요.',
                            },
                          ]}>
                          <Input style={{ width: 200, float: 'left' }} />
                        </FormItem>
                      </div>
                    </div>
                  </div>
                  <div className="twl taskWork-list-04">
                    <div className="twl-text">차감금액</div>
                    <div className="taskWork-place1">
                      <div className="inputBox inputBox-taskWork sub">
                        <FormItem
                          name="ncashDelta"
                          initialValue={data ? data.ncashDelta : ''}
                          rules={[
                            { required: true, message: '금액을 입력해주세요.' },
                          ]}>
                          <Input style={{ width: 250, float: 'left' }} />
                        </FormItem>
                      </div>
                    </div>
                  </div>

                  <div className="twl taskWork-list-05">
                    <div className="twl-text">기간제한</div>
                    <RangePicker
                      placeholder={['시작일', '종료일']}
                      value={[
                        moment(this.state.startDate, 'YYYY-MM-DD'),
                        moment(this.state.endDate, 'YYYY-MM-DD'),
                      ]}
                      // showTime={{ format: 'HH:mm' }}
                      onChange={this.onChangeDate}
                      disabled={this.state.disabled}
                      style={{ width: 350 }}
                    />
                  </div>
                  <div
                    style={{
                      lineHeight: 1,
                      color: '#ccc',
                      textAlign: 'center',
                      marginLeft: -25,
                    }}>
                    * 선택한 기간동안 새벽 5시에 차감됩니다
                  </div>
                </div>
              </div>

              <div className="taskWork-btn-01">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="tabBtn taskWork-btn">
                  {data ? '수정' : '등록'}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistBatchWorkDialog;
