import { Button, Table, Typography } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { kindString, StaticCategory } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

const { Text } = Typography;

class StaticsTablePro extends Component {
  constructor(props) {
    super(props);
    const data = this.props;
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 15,
      },
      startDate: data.startDate,
      endDate: data.endDate,
      changeFilter: data.changeFilter,
      selectBranch: data.selectBranch,
      businessNumber: data.businessNumber,
      frName: data.frName,
      riderName: data.riderName,
      registrationNumber: data.registrationNumber,

      copyList: [],
    };
    this.change_fr = ['DUES_PAY_RESTORE', 'DELIVERY_PRICE_RECEIVE'];
    this.change_deliveryPrice = ['DELIVERY_PRICE_SEND'];
    this.columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'tableCellCenter',
        render: (data) => <>{moment(data).format('YYYY-MM-DD HH:mm:ss')}</>,
        width: 160,
      },
      {
        title: '관리지점',
        dataIndex: 'branchName',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'tableCellCenter',
        width: 100,
      },
      {
        title: '분류',
        dataIndex: 'category',
        className: 'tableCellCenter',
        width: 140,
        render: (data) => <>{StaticCategory[data]}</>,
      },
      {
        title: '비고',
        className: 'statics-table-border tableCellCenter',
        dataIndex: 'adminId',
        render: (data) => <>{data ? `변경자 : ${data}` : ''}</>,
        width: 100,
      },
      {
        title: '가맹점',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '가맹점명',
                dataIndex: 'frName',
                className: 'tableCellCenter',
                width: 150,
              },
              {
                title: '사업자번호',
                dataIndex: 'businessNumber',
                className: 'tableCellCenter',
                width: 100,
              },
            ],
          },
          {
            title: '프로모션',
            dataIndex: 'promotionFee',
            className: 'statics-table-border tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {data && row.userType === 2 ? comma(data) : ''} </div>
            ),
          },
        ],
      },
      {
        title: '라이더',
        className: 'statics-table-orange statics-table-border',
        children: [
          {
            title: '정보',
            children: [
              {
                title: '라이더명',
                dataIndex: 'riderName',
                className: 'tableCellCenter',
                width: 140,
              },
              {
                title: '주민번호',
                dataIndex: 'registrationNumber',
                className: 'tableCellCenter',
                width: 130,
                render: (data, row) => {
                  if (row.riderName && data) {
                    const regiNum1 = data.substring(0, 6);
                    const regiNum2 = data.substring(6, data.length);
                    return <div>{regiNum1 + '-' + regiNum2}</div>;
                  }
                },
              },
            ],
          },
          {
            title: '프로모션',
            dataIndex: 'promotionFee',
            className: 'statics-table-border tableCellRight',
            width: 100,
            render: (data, row) => (
              <div> {row.riderName && data && comma(data)} </div>
            ),
          },
        ],
      },
      {
        title: '본사 예치금',
        className: 'statics-table-orange2',
        width: 100,
        children: [
          {
            title: '입금',
            dataIndex: 'promotionFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (row.frName && data > 0) {
                return <div>{comma(Math.abs(data))}</div>;
              } else if (row.riderName && data > 0) {
                return <div>{comma(Math.abs(data))}</div>;
              } else return <></>;
            },
          },
          {
            title: '출금',
            dataIndex: 'promotionFee',
            className: 'tableCellRight',
            width: 100,
            render: (data, row) => {
              if (row.frName && data < 0) {
                return <div>{comma(Math.abs(data))}</div>;
              } else if (row.riderName && data < 0) {
                return <div>{comma(Math.abs(data))}</div>;
              } else return <></>;
            },
          },
        ],
      },
    ];
  }

  componentDidMount() {
    console.log('프로모션');
    this.getList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.endDate !== this.props.endDate ||
      prevProps.changeFilter !== this.props.changeFilter ||
      prevProps.selectBranch !== this.props.selectBranch ||
      prevProps.businessNumber !== this.props.businessNumber ||
      prevProps.frName !== this.props.frName ||
      prevProps.riderName !== this.props.riderName ||
      prevProps.registrationNumber !== this.props.registrationNumber
    ) {
      this.setState(
        {
          list: [],
          copyList: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  }

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({ pagination: pager }, () => this.getList());
  };
  getList = async () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let branchIdx = this.props.selectBranch;
    let businessNumber = this.props.businessNumber;
    let category = 'pro';
    let frName = this.props.frName;
    let riderName = this.props.riderName;
    let registrationNumber = this.props.registrationNumber;
    let startDate = this.props.startDate;
    let endDate = moment(this.props.endDate).add(1, 'd').format('YYYY-MM-DD');
    const res = await httpGet(
      httpUrl.staticsCostList,
      [
        pageNum,
        pageSize,
        branchIdx,
        businessNumber,
        category,
        frName,
        riderName,
        registrationNumber,
        startDate,
        endDate,
      ],
      {}
    );
    if (res.result === 'SUCCESS') {
      console.log(res);
      this.setState({
        list: res.data.stCost,
        copyList: res.data.stCost,
        pagination: {
          ...this.state.pagination,
          current: res.data.currentPage,
          total: res.data.totalCount,
        },
      });
    }
    if (this.state.changeFilter > 0) {
      this.selectList(this.state.changeFilter);
    }
  };

  selectList = (type) => {
    const selectType =
      type === 1 ? this.state.change_deliveryPrice : this.state.change_fr;
    let changeList = this.state.setList.filter((x) =>
      selectType.includes(x.category)
    );
    this.setState({ copyList: changeList });
  };

  render() {
    return (
      <React.Fragment>
        <div style={{ dispaly: 'flex', alignItems: 'center' }}>
          {/* <Button
                            className="download-btn"
                            style={{ width: 160}}
                            onClick={() => this.onDownload()}>
                            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                            엑셀 다운로드
                        </Button>    */}
        </div>
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.copyList}
          columns={this.columns}
          className="staticsCost-table"
          pagination={false}
          // onChange={this.handleTableChange}
          bordered
          summary={(pageData) => {
            let totalFrPromotion = 0;
            let totalPromotionFee = 0;
            let totalC9Deposit = 0;
            let totalC9Withdrawal = 0;
            pageData.forEach(({ frName, riderName, promotionFee }) => {
              if (frName) {
                totalFrPromotion += promotionFee;
              }
              if (riderName) {
                totalPromotionFee += promotionFee;
              }
              if (promotionFee > 0) {
                totalC9Deposit += promotionFee;
              } else {
                totalC9Withdrawal += promotionFee;
              }
            });
            return (
              <>
                <Table.Summary.Row
                  style={{
                    backgroundColor: '#cbedfa',
                    color: '#000',
                    fontWeight: 'bold',
                    borderColor: '#000',
                  }}>
                  <Table.Summary.Cell
                    index={1}
                    colSpan={7}
                    style={{ textAlign: 'center' }}>
                    합계
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalFrPromotion)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalPromotionFee)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">{comma(totalC9Deposit)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <Text type="danger">
                      {comma(Math.abs(totalC9Withdrawal))}
                    </Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};
export default connect(mapStateToProps, null)(StaticsTablePro);
