import { Layout } from 'antd';
import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Aggregation,
  BankChargeHistory,
  BikeList,
  BikeReleaseList,
  BikeRepair,
  BranchSettlementInfo,
  BranchSettlementList,
  Center,
  ControlAgentManage,
  DeliveryCancelList,
  DeliveryCardPaymentHistory,
  DeliveryFeeInfo,
  DeliveryHistoryEmployee,
  DeliveryHistoryRider,
  DeliveryList,
  DeliveryPriceExtraRain,
  DepositAllHistory,
  DepositConnectHistory,
  DepositHeadOfficeHistory,
  DepositNotRecovered,
  DepositNotRecoveredMongo,
  DepositPaymentHistory,
  DepositPrecharge,
  DepositWithdrawHistory,
  FranchiseChargeHistory,
  FranchiseDuesHistory,
  FranchiseList,
  Inquiry,
  LoanFranchise,
  LoanRider,
  MyInfo,
  NCashDailyList,
  NCashList,
  Notice,
  OperatorManage,
  PaymentList,
  Product,
  RdnrList,
  RegistVANList,
  RemainAmountMinus,
  RentFeeList,
  RiderAccidentInsurance,
  RiderGroupManagement,
  RiderLevelList,
  RiderLevelSupport,
  RiderList,
  RiderWorkWhileRaining,
  SkinPopup,
  StaticsBranch,
  StaticsCostStat,
  StaticsDues,
  StaticsFranchise,
  StaticsNcash,
  StaticsRider,
  TaxSupport,
  StaticsJoinExit,
  BranchSettlementAllList,
  BranchAuthenticationStatus,
} from '../pages';
import BranchApproval from '../pages/branch/BranchApproval';
import BranchList from '../pages/branch/BranchList';
import ManualDw from '../pages/deposit/ManualDw';
import RequestWithdrawApproval from '../pages/deposit/RequestWithdrawApproval';
import WithdrawApprove from '../pages/deposit/WIthdrawApprove';
import FranchiseDuesAggregation from '../pages/franchiseDues/FranchiseDuesAggregation';
import FranchiseDuesSetting from '../pages/franchiseDues/FranchiseDuesSetting';
import ManualMapping from '../pages/manual/ManualMapping';
import FdnrList from '../pages/ncash/FdnrList';
import DailyOrderStat from '../pages/settlement/DailyOrderStat';
import BranchDailyOrderStat from '../pages/branch/BranchDailyOrderStat';
import EdsList from '../pages/settlement/EdsList';
import EmsList from '../pages/settlement/EmsList';
import AdjDuesDay from '../pages/settlement/AdjDuesDay';
import AdjDuesMonth from '../pages/settlement/AdjDuesMonth';
import AdjDeliveryDay from '../pages/settlement/AdjDeliveryDay';
import AdjDeliveryMonth from '../pages/settlement/AdjDeliveryMonth';
import AdjBalanceDay from '../pages/settlement/AdjBalanceDay';
import LoanAuraForSubBrand from '../pages/ncashLoanSubBrand/LoanAuraForSubBrand';
import LoanAuraWithdraw from '../pages/ncashLoanSubBrand/LoanAuraWithdraw';
import BranchHeadOfficeInfo from '../pages/branch/BranchHeadOfficeInfo';
import NoticeManagement from '../pages/system/NoticeManagement';
import AgencyDashboard from '../pages/agency/AgencyDashboard';
import AgencyNcashList from '../pages/agency/AgencyNcashList';
import AgencyBatchWork from '../pages/agency/AgencyBatchWork';
import BranchTaxInvoiceDownload from '../pages/branch/BranchTaxInvoiceDownload';
import AgencyBatchWorkList from '../pages/agency/AgencyBatchWorkList';
import AgencyFrManage from '../pages/agency/AgencyFrManage';
import AgencyOrderSettlement from '../pages/agency/AgencyOrderSettlement';
import AgencyTaxReturn from '../pages/agency/AgencyTaxReturn';
import RiderLocationAccess from "../pages/system/RiderLocationAccess";
import RiderLocationProvide from "../pages/system/RiderLocationProvide";

// import  from "../pages/franchise/RegistVANList";

export default class Content extends Component {
  render() {
    return (
      <Layout.Content>
        <div
          style={{
            padding: 24,
            background: '#fff',
            paddingBottom: 50,
            height: '100%',
          }}>
          <Switch>
            <Route
              exact
              path="/statics/StaticsBranch"
              component={StaticsBranch}
            />
            <Route
              exact
              path="/statics/StaticsRider"
              component={StaticsRider}
            />
            <Route
              exact
              path="/statics/StaticsFranchise"
              component={StaticsFranchise}
            />

            <Route
              exact
              path="/statics/StaticsNcash"
              component={StaticsNcash}
            />

            <Route exact path="/statics/StaticsDues" component={StaticsDues} />

            <Route
              exact
              path="/statics/StaticsJoinExit"
              component={StaticsJoinExit}
            />

            <Route
              exact
              path="/delivery/DeliveryList"
              component={DeliveryList}
            />
            <Route
              exact
              path="/delivery/DeliveryHistoryEmployee"
              component={DeliveryHistoryEmployee}
            />
            <Route
              exact
              path="/delivery/DeliveryHistoryRider"
              component={DeliveryHistoryRider}
            />
            <Route
              exact
              path="/delivery/DeliveryCancelList"
              component={DeliveryCancelList}
            />
            <Route
              exact
              path="/delivery/DeliveryCardPaymentHistory"
              component={DeliveryCardPaymentHistory}
            />

            <Route
              exact
              path="/franchise/FranchiseList"
              component={FranchiseList}
            />
            <Route
              exact
              path="/franchise/FranchiseChargeHistory"
              component={FranchiseChargeHistory}
            />
            {/* <Route
              exact
              path="/franchise/FranchiseFeeHistory"
              component={FranchiseFeeHistory}
            /> */}
            <Route
              exact
              path="/franchise/RegistVANList"
              component={RegistVANList}
            />
            <Route
              exact
              path="/franchiseDues/FranchiseDuesHistory"
              component={FranchiseDuesHistory}
            />
            <Route
              exact
              path="/franchiseDues/FranchiseDuesAggregation"
              component={FranchiseDuesAggregation}
            />

            <Route exact path="/payment/PaymentList" component={PaymentList} />

            <Route
              exact
              path="/deposit/DepositAllHistory"
              component={DepositAllHistory}
            />
            <Route
              exact
              path="/deposit/DepositConnectHistory"
              component={DepositConnectHistory}
            />
            <Route
              exact
              path="/branch/DepositHeadOfficeHistory"
              component={DepositHeadOfficeHistory}
            />
            <Route
              exact
              path="/deposit/DepositPaymentHistory"
              component={DepositPaymentHistory}
            />
            <Route
              exact
              path="/deposit/DepositWithdrawHistory"
              component={DepositWithdrawHistory}
            />
            <Route
              exact
              path="/deposit/DepositPrecharge"
              component={DepositPrecharge}
            />
            <Route
              exact
              path="/deposit/DepositNotRecovered"
              component={DepositNotRecovered}
            />
            <Route
              exact
              path="/deposit/DepositNotRecoveredMongo"
              component={DepositNotRecoveredMongo}
            />
            <Route
              exact
              path="/deposit/BankChargeHistory"
              component={BankChargeHistory}
            />
            <Route exact path="/loan/LoanRider" component={LoanRider} />
            <Route exact path="/loan/LoanFranchise" component={LoanFranchise} />

            <Route exact path="/bike/BikeList" component={BikeList} />
            <Route exact path="/bike/RentFeeList" component={RentFeeList} />
            <Route
              exact
              path="/bike/BikeReleaseList"
              component={BikeReleaseList}
            />

            <Route exact path="/board/Inquiry" component={Inquiry} />
            <Route exact path="/board/Notice" component={Notice} />
            <Route exact path="/board/Center" component={Center} />

            <Route
              exact
              path="/system/OperatorManage"
              component={OperatorManage}
            />
            <Route
              exact
              path="/system/ControlAgentManage"
              component={ControlAgentManage}
            />

            <Route
              exact
              path="/settlement/Aggregation"
              component={Aggregation}
            />
            <Route exact path="/settlement/EdsList" component={EdsList} />
            <Route exact path="/settlement/EmsList" component={EmsList} />
            <Route
              exact
              path="/settlement/RemainAmountMinus"
              component={RemainAmountMinus}
            />
            <Route
              exact
              path="/settlement/DailyOrderStat"
              component={DailyOrderStat}
            />
            <Route
              exact
              path="/branch/BranchDailyOrderStat"
              component={DailyOrderStat}
            />
            <Route
              path="/settlement/StaticsCostStat"
              component={StaticsCostStat}
            />
            <Route path="/settlement/AdjDuesDay" component={AdjDuesDay} />
            <Route path="/settlement/AdjDuesMonth" component={AdjDuesMonth} />
            <Route
              path="/settlement/AdjDeliveryDay"
              component={AdjDeliveryDay}
            />
            <Route
              path="/settlement/AdjDeliveryMonth"
              component={AdjDeliveryMonth}
            />
            <Route path="/settlement/AdjBalanceDay" component={AdjBalanceDay} />
            <Route exact path="/ncash/NCashList" component={NCashList} />

            <Route
              exact
              path="/ncash/NCashDailyList"
              component={NCashDailyList}
            />
            <Route exact path="/ncash/RdnrList" component={RdnrList} />
            <Route exact path="/ncash/FdnrList" component={FdnrList} />
            <Route exact path="/MyInfo" component={MyInfo} />
            <Route exact path="/bike/BikeRepair" component={BikeRepair} />
            <Route exact path="/rider/riderList" component={RiderList} />
            <Route exact path="/rider/TaxSupport" component={TaxSupport} />
            <Route
              exact
              path="/rider/RiderGroupManagement"
              component={RiderGroupManagement}
            />
            <Route
              exact
              path="/rider/RiderAccidentInsurance"
              component={RiderAccidentInsurance}
            />
            <Route exact path="/mall/Product" component={Product} />

            <Route exact path="/branch/list" component={BranchList} />
            <Route
              exact
              path="/branch/DeliveryFeeInfo"
              component={DeliveryFeeInfo}
            />
            <Route
              exact
              path="/branch/branchApproval"
              component={BranchApproval}
            />
            <Route
              exact
              path="/branch/DeliveryPriceExtraRain"
              component={DeliveryPriceExtraRain}
            />
            <Route
              exact
              path="/branch/BranchSettlementList"
              component={BranchSettlementList}
            />
            <Route
              exact
              path="/branch/BranchSettlementInfo"
              component={BranchSettlementInfo}
            />
            <Route
              exact
              path="/branch/BranchAuthenticationStatus"
              component={BranchAuthenticationStatus}
            />
            <Route
              exact
              path="/franchiseDues/FranchiseDuesSetting"
              component={FranchiseDuesSetting}
            />

            <Route
              exact
              path="/riderLevel/RiderLevelList"
              component={RiderLevelList}
            />
            <Route
              exact
              path="/riderLevel/RiderWorkWhileRaining"
              component={RiderWorkWhileRaining}
            />
            <Route
              exact
              path="/riderLevel/RiderLevelSupport"
              component={RiderLevelSupport}
            />

            <Route
              exact
              path="/deposit/WithdrawApprove"
              component={WithdrawApprove}
            />
            <Route
              exact
              path="/deposit/RequestWithdrawApproval"
              component={RequestWithdrawApproval}
            />
            <Route exact path="/skin/SkinPopup" component={SkinPopup} />

            <Route exact path="/deposit/ManualDw" component={ManualDw} />

            <Route exact path="/manual-mapping" component={ManualMapping} />
            <Route
              exact
              path="/branch/BranchSettlementAllList"
              component={BranchSettlementAllList}
            />
            <Route
              exact
              path="/ncashLoanSubBrand/LoanAuraForSubBrand"
              component={LoanAuraForSubBrand}
            />
            <Route
              exact
              path="/ncashLoanSubBrand/LoanAuraWithdraw"
              component={LoanAuraWithdraw}
            />

            <Route
              exact
              path="/branch/BranchHeadOfficeInfo"
              component={BranchHeadOfficeInfo}
            />
            <Route exact path="/system/notice" component={NoticeManagement} />
            <Route
              exact
              path="/agency/AgencyDashboard"
              component={AgencyDashboard}
            />
            <Route
              exact
              path="/agency/AgencyNcashList"
              component={AgencyNcashList}
            />
            <Route
              exact
              path="/agency/AgencyBatchWork"
              component={AgencyBatchWork}
            />
            <Route
              exact
              path="/agency/AgencyBatchWorkList"
              component={AgencyBatchWorkList}
            />
            <Route
              exact
              path="/agency/AgencyFrManage"
              component={AgencyFrManage}
            />
            <Route
              exact
              path="/agency/AgencyOrderSettlement"
              component={AgencyOrderSettlement}
            />
            <Route
              exact
              path={'/agency/AgencyTaxReturn'}
              component={AgencyTaxReturn}
            />
            <Route
              exact
              path="/branch/BranchTaxInvoiceDownload"
              component={BranchTaxInvoiceDownload}
            />
            <Route
              exact
              path="/system/RiderLocationAccess"
              component={RiderLocationAccess}
            />
            <Route
              exact
              path="/system/RiderLocationProvide"
              component={RiderLocationProvide}
            />
          </Switch>
        </div>
      </Layout.Content>
    );
  }
}
