import { Button, Form, Input, Modal, Radio, Select } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import '../../css/main.css';
import { searchType } from '../../lib/util/codeUtil';
import SearchFranDialog from '../dialog/SearchFranDialog';
import SearchRiderDialog from '../dialog/SearchRiderDialog';
import { adminSendKindString } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class DepositDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
      },
      openSearchFranModal: false,
      selectedFran: null,
      searchType: 0,
      openSearchRiderModal: false,
      selectedRider: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  openSearchFranModal = () => {
    this.setState({ openSearchFranModal: true });
  };
  closeSearchFranModal = () => {
    this.setState({ openSearchFranModal: false });
  };

  handleSubmit = () => {
    let self = this;
    const form = this.formRef.current;
    console.log(this.formRef.current.getFieldsValue().kind);
    let searchType = this.props.preMode ? 1 : this.state.searchType;

    if (searchType === 0) {
      Modal.confirm({
        title: '예치금 지금',
        content:
          this.state.selectedRider.riderName +
          ' 라이더에 ' +
          ' 예치금 ' +
          form.getFieldValue('ncashAmount') +
          ' 원을 지급하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.depositSend, [], {
            receiveUserIdx: self.state.selectedRider.userIdx,
            ...self.formRef.current.getFieldsValue(),
            userType: 1,
          })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert(
                  '예치금 지급 완료',
                  '해당 라이더에게 예치금을 지급하였습니다.'
                );
                self.props.close();
                self.props.getList();
                self.props.balance();
              } else {
                customError(
                  '예치금 지급 에러',
                  '서버 에러로 인해 예치금 지급에 실패하였습니다'
                );
              }
            })
            .catch((e) => {
              customError(
                '예치금 지급 에러',
                '서버 에러로 인해 예치금 지급에 실패하였습니다'
              );
            });
        },
      });
    } else {
      Modal.confirm({
        title: '예치금 지금',
        content:
          self.state.selectedFran.frName +
          ' 가맹점에 ' +
          ' 예치금 ' +
          form.getFieldValue('ncashAmount') +
          ' 원을 지급하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.depositSend, [], {
            receiveUserIdx: self.state.selectedFran.userIdx,
            ...self.formRef.current.getFieldsValue(),
            userType: 2,
          })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert(
                  '예치금 지급 완료',
                  '해당 가맹점에 예치금을 지급하였습니다.'
                );
                self.props.close();
                self.props.getList();
                self.props.balance();
              } else {
                customError(
                  '예치금 지급 에러',
                  '서버 에러로 인해 예치금 지급에 실패하였습니다'
                );
              }
            })
            .catch((e) => {
              customError(
                '예치금 지급 에러',
                '서버 에러로 인해 예치금 지급에 실패하였습니다'
              );
            });
        },
      });
    }
  };

  onCheckType = (e) => {
    this.setState({ searchType: e.target.value });
  };

  openSearchRiderModal = () => {
    this.setState({ openSearchRiderModal: true });
  };
  closeSerchRiderModal = () => {
    this.setState({ openSearchRiderModal: false });
  };

  render() {
    const { close, data, preMode } = this.props;

    return (
      // 제휴본사 예치금 지급 모달(AgencyDepositDialog)도 이 모달을 기반으로 만들었기 때문에
      // 혹시 변경할 시에 제휴본사 예치금 지급쪽도 같이 변경해야하는지 고려해야함
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />

        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div className="deposit-title">예치금 지급</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />

            {this.state.openSearchRiderModal && (
              <SearchRiderDialog
                onSelect={(selectedRider) => this.setState({ selectedRider })}
                close={this.closeSerchRiderModal}
              />
            )}

            {this.state.openSearchFranModal && (
              <SearchFranDialog
                onSelect={(selectedFran) => this.setState({ selectedFran })}
                close={this.closeSearchFranModal}
              />
            )}
            <div className="deposit-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                {!preMode ? (
                  <>
                    <div className="contentBlock">
                      <Radio.Group
                        onChange={this.onCheckType}
                        value={this.state.searchType}
                        defaultValue={searchType[0]}
                        style={{ marginRight: 19 }}>
                        {Object.entries(searchType).map(([key, value]) => {
                          return (
                            <Radio key={key} value={parseInt(key)}>
                              {value}
                            </Radio>
                          );
                        })}
                      </Radio.Group>

                      {this.state.searchType === 0 ? (
                        <Button
                          style={{ marginBottom: 20, marginLeft: 77 }}
                          onClick={this.openSearchRiderModal}>
                          라이더 검색
                        </Button>
                      ) : (
                        <Button
                          style={{ marginBottom: 20, marginLeft: 77 }}
                          onClick={this.openSearchFranModal}>
                          가맹점 검색
                        </Button>
                      )}
                    </div>

                    <div className="contentBlock">
                      {/* <div className="contentBlock-inner"> */}
                      {this.state.searchType === 0 ? (
                        <div>
                          <div className="mainTitle">라이더명</div>
                          <div className="serach-input">
                            <Input
                              value={
                                this.state.selectedRider
                                  ? this.state.selectedRider.riderName
                                  : ''
                              }
                              className="override-input"
                              placeholder="검색해주세요."
                              disabled
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="mainTitle">가맹점명</div>
                          <div className="serach-input">
                            <Input
                              value={
                                this.state.selectedFran
                                  ? this.state.selectedFran.frName
                                  : ''
                              }
                              className="override-input"
                              placeholder="검색해주세요."
                              disabled
                            />
                          </div>
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mainTitle">가맹점명</div>
                    <div className="serach-input">
                      <Input
                        value={
                          this.state.selectedFran
                            ? this.state.selectedFran.frName
                            : ''
                        }
                        className="override-input"
                        placeholder="클릭해주세요."
                        onClick={this.openSearchFranModal}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </>
                )}
                <div className="contentBlock">
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">지급금액</div>
                  <div className="formItem">
                    <FormItem
                      name="ncashAmount"
                      className="selectItem"
                      style={{ marginLeft: 25, width: 230 }}
                      rules={[
                        {
                          required: true,
                          message: '금액을 입력해주세요',
                        },
                      ]}
                      initialValue={data ? data.price : ''}>
                      <Input
                        placeholder="금액을 입력해주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>
                <div className="contentBlock">
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">구분</div>
                  <div className="formItem">
                    <FormItem
                      name="kind"
                      className="selectItem"
                      initialValue={this.props.branchIdx ? 1 : 0}
                      rules={[
                        { required: true, message: '구분을 선택해주세요' },
                      ]}
                      style={{ marginLeft: 25, width: 230 }}>
                      {this.props.branchIdx ? (
                        <Select>
                          <Option value={1}>선충전 지급</Option>;
                        </Select>
                      ) : (
                        <Select>
                          {adminSendKindString.map((v, index) => {
                            return (
                              <Option key={index} value={index}>
                                {v}
                              </Option>
                            );
                          })}
                        </Select>
                      )}
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>
                <div className="contentBlock">
                  {/* <div className="contentBlock-inner"> */}
                  <div className="mainTitle">메모</div>
                  <div className="formItem">
                    <FormItem
                      name="ncashMemo"
                      className="selectItem"
                      style={{ marginLeft: 25, width: 230 }}
                      initialValue={data ? data.ncashMemo : ''}>
                      <Input
                        placeholder="메모를 입력해주세요."
                        className="override-input"
                      />
                    </FormItem>
                  </div>
                  {/* </div> */}
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ float: 'right' }}>
                  지급하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DepositDialog;
