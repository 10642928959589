import { Button, Checkbox, DatePicker, Form, Input, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { connect } from 'react-redux';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { extCode, paymentMethod } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const FormItem = Form.Item;
const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class DeliveryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      frName: '',
      riderName: '',
      frPhone: '',
      endDate: '',
      startDate: '',
      extCode: '',
      extCodeFilter: [],

      paymentMethod: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pagination = this.state.pagination;
    let branchIdx = this.props.branchIdx;
    httpGet(
      httpUrl.deliveryCancelList,
      [
        branchIdx,
        this.state.frName,
        this.state.frPhone,
        pagination.current,
        pagination.pageSize,
        this.state.riderName,
        '',
        '',
        '',
        this.state.extCode,
        this.state.extCodeFilter,
      ],
      {}
    ).then((res) => {
      console.log(res.data.orders);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.orders,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  getSearchList = () => {
    const pagination = this.state.pagination;
    let branchIdx = this.props.branchIdx;
    httpGet(
      httpUrl.deliveryCancelList,
      [
        branchIdx,
        this.state.frName,
        this.state.frPhone,
        pagination.current,
        pagination.pageSize,
        this.state.riderName,
        '',
        this.state.startDate,
        this.state.endDate,
        this.state.extCode,
        this.state.extCodeFilter,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.orders,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => {
        this.state.startDate === '' ? this.getList() : this.getSearchList();
      }
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      },
      () => {
        {
          this.state.startDate === '' ? this.getList() : this.getSearchList();
        }
      }
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        idx: '주문번호',
        branchName: '지점명',
        frName: '가맹점명',
        orderDate: '접수일시',
        cancelDate: '취소일시',
        destAddr: '도착지',
        orderPrice: '금액',
        basicDeliveryPrice: '기본배달료',
        extraDeliveryPrice: '할증배달료',
        deliveryPrice: '총배달요금',
        deliveryTax: '배달부가세',
        deliveryPriceFee: '배달수수료',
        businessNumber: '사업자번호',
        ownerName: '대표자명',
        addr: '주소',
        frPhone: '연락처',
        ftOrderCode: '외부주문코드',
      },
    ];
    data.forEach((item) => {
      result.push({
        idx: item.idx,
        branchName: item.branchName,
        frName: item.frName,
        orderDate: item.orderDate,
        cancelDate: item.cancelDate,
        destAddr: item.destAddr1 + ' ' + item.destAddr2,
        orderPrice: item.orderPrice,
        basicDeliveryPrice: item.basicDeliveryPrice,
        extraDeliveryPrice: item.extraDeliveryPrice,
        deliveryPrice: item.deliveryPrice,
        deliveryTax: parseInt(item.deliveryPrice * 0.1),
        deliveryPriceFee: item.deliveryPriceFee,
        businessNumber: item.businessNumber,
        ownerName: item.ownerName,
        addr: item.addr1 + ' ' + item.addr2,
        frPhone: item.frPhone,
        ftOrderCode: item.ftOrderCode
          ? item.ftOrderCode
          : item.bmOrderCode
          ? item.bmOrderCode
          : '-',
      });
    });

    return result;
  };

  onDownload = () => {
    httpGet(
      httpUrl.deliveryCancelList,
      [
        this.state.frName,
        this.state.frPhone,
        1,
        1000000,
        this.state.riderName,
        '',
        this.state.startDate,
        this.state.endDate,
        this.state.extCode,
        this.state.extCodeFilter,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        const excelJson = this.parseExcelJson(res.data.orders);
        const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
        xlsx.writeFile(wb, '배달목록.xlsx');
      }
    });
  };

  handleFilterToggle = (e, key) => {
    if (key === 'all' && e.target.checked) {
      this.setState(
        {
          extCodeFilter: [],
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
      return;
    }
    if (e.target.checked) {
      this.setState(
        {
          extCodeFilter: this.state.extCodeFilter.concat(key),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    } else {
      this.setState(
        {
          extCodeFilter: this.state.extCodeFilter.filter(
            (code) => code !== key
          ),
          pagination: {
            ...this.state.pagination,
            current: 1,
          },
        },
        () => this.getList()
      );
    }
  };

  render() {
    const columns = [
      {
        title: '주문번호',
        dataIndex: 'idx',
        className: 'table-column-center',
        width: '5%',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
        width: '5%',
      },
      // {
      //   title: "주문날짜",
      //   dataIndex: "orderDate",
      //   className: "table-column-center",
      //   width: "10%",
      //   render: (data) => <div>{formatDates(data)}</div>,
      // },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: '10%',
      },
      {
        title: '접수일시',
        dataIndex: 'orderDate',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '취소일시',
        dataIndex: 'cancelDate',
        className: 'table-column-center',
        render: (data) => <div>{data}</div>,
      },
      {
        title: '도착지',
        // dataIndex: "destAddr1",
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => (
          <div className="table-column-left">
            {row.destAddr1 + ' ' + row.destAddr2}
          </div>
        ),
      },
      {
        title: '결제수단',
        dataIndex: 'orderPayments',
        className: 'table-column-center',
        width: '8%',
        render: (data) => {
          return (
            // <div>{paymentMethod[data[0] ? data[0]["paymentMethod"] : 0]}</div>
            data.map((payment) => {
              return (
                <div
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: 'grey',
                    marginBottom: 2,
                  }}>
                  {paymentMethod[payment.paymentMethod]} (
                  {comma(payment.paymentAmount)}원)
                </div>
              );
            })
          );
        },
      },
      {
        title: '금액',
        dataIndex: 'orderPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '기본배달요금',
        dataIndex: 'basicDeliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '할증배달요금',
        dataIndex: 'extraDeliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '총배달요금',
        dataIndex: 'deliveryPrice',
        className: 'table-column-center',
        width: '8%',
        render: (data) => <div>{comma(data)} 원</div>,
      },
      {
        title: '배달부가세',
        className: 'table-column-center',
        render: (data, row) => (
          <div>{comma(parseInt(row.deliveryPrice * 0.1))}원</div>
        ),
      },
      {
        title: '배달수수료',
        dataIndex: 'deliveryPriceFee',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      // 수정
      // {
      //   title: "주문날짜",
      //   dataIndex: "orderDate",
      //   className: "table-column-center",
      //   width: "10%",
      //   render: (data) => <div>{formatDates(data)}</div>,
      // },
      // {
      //   title: "할증배달요금",
      //   dataIndex: "extraDeliveryPrice",
      //   className: "table-column-center",
      //   width: "8%",
      //   render: (data) => <div>{comma(data)} 원</div>,
      // },
      // {
      //   title: "총배달요금",
      //   dataIndex: "deliveryPrice",
      //   className: "table-column-center",
      //   width: "8%",
      //   render: (data) => <div>{comma(data)} 원</div>,
      // },
    ];
    const expandedRowRender = (record) => {
      const dropColumns = [
        {
          title: '가맹점명',
          dataIndex: 'frName',
          className: 'table-column-center',
        },
        {
          title: '사업자번호',
          dataIndex: 'businessNumber',
          className: 'table-column-center',
        },
        {
          title: '대표자명',
          dataIndex: 'ownerName',
          className: 'table-column-center',
        },
        {
          title: '가맹점번호',
          dataIndex: 'frPhone',
          className: 'table-column-center',
        },
        {
          title: '주소',
          className: 'table-column-center',
          render: (data, row) => <div>{row.addr1 + ' ' + row.addr2}</div>,
        },
        {
          title: '외부주문코드',
          dataIndex: 'ftOrderCode',
          className: 'table-column-center',
          render: (data, row) => (
            <div>{data ? data : row.bmOrderCode ? row.bmOrderCode : '='}</div>
          ),
        },
      ];
      return (
        <Table
          rowKey={(record) => `record: ${record.idx}`}
          columns={dropColumns}
          dataSource={[record]}
          pagination={false}
        />
      );
    };

    return (
      <>
        <Search
          placeholder="가맹점 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ frName: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
          }}
        />
        <Search
          placeholder="전화번호 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ frPhone: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />
        <Search
          placeholder="외부주문코드 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ extCode: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />

        {/* <a href="/admin_bike_templete.xlsx" download> */}
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        {/* </a> */}
        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getSearchList();
                }
              );
            } else {
              // console.log('test')
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />
        <div className="delivery-list-ext-filter-wrapper">
          <div className="delivery-list-ext-filter-title">
            외부주문코드 필터
          </div>
          {Object.keys(extCode).map((key) => {
            if (key === 'all') {
              return (
                <Checkbox
                  key={key}
                  checked={this.state.extCodeFilter.length === 0}
                  onChange={(e) => this.handleFilterToggle(e, key)}
                  className="delivery-list-ext-filter-checkbox">
                  {extCode[key]}
                </Checkbox>
              );
            }
            return (
              <Checkbox
                key={key}
                checked={this.state.extCodeFilter.includes(key)}
                onChange={(e) => this.handleFilterToggle(e, key)}
                className="delivery-list-ext-filter-checkbox">
                {extCode[key]}
              </Checkbox>
            );
          })}
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          expandedRowRender={expandedRowRender}
          expandRowByClick={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(DeliveryList);
