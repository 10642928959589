import { Button, DatePicker, Form, Input, Modal } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import {
  bikeResponseErrorCode,
  bikeStatusType,
  insuranceAgeType,
  insuranceCompanyType,
  ownerType,
  bikeLocationType,
} from '../../lib/util/codeUtil';
import { formatDates } from '../../lib/util/dateUtil';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;
const dateFormat = 'YYYY-MM-DD';
const today = new Date();

class RegistBikeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bikeList: [],
      bikeNameList: [],
      bikeYearList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      insuranceCompany: 1,
      owner: 1,
      bikeStatus: 1,
      isModifyMode: false,
      modelName: '',
      modelYear: '',
      insuranceAge: insuranceAgeType[0],
      firstBikeStatus: -1,
      bikeStatusType: bikeStatusType,
      bikeLocation: {},
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.getBikeLocation();
    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data });
      this.setState({
        ...this.props.data,
        isModifyMode: true,
        firstBikeStatus: this.props.data.bikeStatus,
      });
    }
  }

  getBikeLocation = () => {
    httpPost(httpUrl.bikeLocationInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.map((item) => {
          bikeLocationType[item.idx] = item.branchName;
        });

        this.setState(
          {
            bikeLocation: bikeLocationType,
          },
          () => {
            console.log('bikeLocation');
            console.log(this.state.bikeLocation);
          }
        );
      }
    });
  };

  getList = () => {
    httpPost(httpUrl.getBikeNameYear, [], {}).then((res) => {
      console.log('res');
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          bikeList: res.data,
          bikeNameList: _.uniqBy(res.data, 'modelName').map(
            (item) => item.modelName
          ),
        });
      }
    });
  };

  handleSubmit = () => {
    let { bikeNumber, viNumber, memo } = this.formRef.current.getFieldsValue();
    let idx = this.state.isModifyMode ? this.props.data.idx : null;
    let modelName = this.state.modelName;
    let modelYear = this.state.modelYear;
    let owner = this.state.owner;
    let insuranceCompany = this.state.insuranceCompany;
    let insuranceAge =
      this.state.insuranceAge === insuranceAgeType[0]
        ? 0
        : this.state.insuranceAge;
    let insuranceEndDate = formatDates(
      this.formRef.current.getFieldValue('insuranceEndDate2')
    );
    let bikeStatus = this.state.bikeStatus;
    let unassignBikeLocation = this.state.unassignBikeLocation;

    if (
      unassignBikeLocation === undefined ||
      unassignBikeLocation === null ||
      unassignBikeLocation === 0
    ) {
      Modal.warn({
        title: '등록 실패',
        content: '미배정 바이크위치를 선택해주세요.',
      });
      return;
    }

    httpPost(
      this.state.isModifyMode ? httpUrl.updateBike : httpUrl.createBike,
      [],
      {
        idx,
        modelName,
        bikeNumber,
        viNumber,
        modelYear,
        unassignBikeLocation,
        owner,
        insuranceCompany,
        insuranceAge,
        insuranceEndDate,
        bikeStatus,
        memo,
      }
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
        Modal.info({
          title: '등록성공',
          content: '바이크 등록에 성공했습니다.',
        });
        this.props.close();
      } else if (res.data.startsWith('INVALID')) {
        Modal.warn({
          title: '등록실패',
          content: `바이크 등록에 실패했습니다. ${
            bikeResponseErrorCode[res.data]
          }을(를) 확인해 주세요.`,
        });
      } else {
        Modal.warn({
          title: '등록실패',
          content: '바이크 등록에 실패했습니다.',
        });
      }
    });
  };

  bikeDelete = (record) => {
    let bikeIdx = record.idx;
    let bikeStatus = 9;
    httpPost(httpUrl.bikeDelete, [], {
      bikeIdx,
      bikeStatus,
    })
      .then((res) => {
        console.log(res);
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '성공',
            content: '상태 변경에 성공했습니다.',
          });
          this.props.close();
        } else if (res.data.startsWith('INVALID')) {
          Modal.warn({
            title: '실패',
            content: `바이크 삭제에 실패했습니다. ${
              bikeResponseErrorCode[res.data]
            }을(를) 확인해 주세요.`,
          });
        } else {
          Modal.warn({
            title: '실패',
            content: '바이크 삭제에 실패했습니다.',
          });
        }
      })
      .catch((error) => {
        Modal.warn({
          title: '실패',
          content: `바이크 삭제에 실패했습니다. (${error.response.data.message})`,
        });
      });
  };

  onCheckType = (value) => {
    this.setState({
      modelName: this.state.bikeNameList[value],
      bikeYearList: _.uniqBy(
        this.state.bikeList.filter(
          (item) => item.modelName === this.state.bikeNameList[value]
        ),
        'modelYear'
      ).map((item) => item.modelYear),
    });
  };

  onICCheckType = (value) => {
    this.setState({ insuranceCompany: value });
  };

  onIACheckType = (value) => {
    this.setState({ insuranceAge: insuranceAgeType[value] });
  };

  onOwnerCheckType = (value) => {
    this.setState({ owner: value });
  };

  onMYCheckType = (value) => {
    this.setState({ modelYear: this.state.bikeYearList[value] });
  };

  onBSCheckType = (value) => {
    if (this.state.firstBikeStatus !== 2 && value === '2') {
      Modal.warn({
        title: '상태변경 오류',
        content:
          '미배정/수리입고 상태에서 운행중으로는 상태를 변경할 수 없습니다.',
      });
      return;
    }
    if (this.state.firstBikeStatus === 1 && value === '3') {
      Modal.warn({
        title: '상태변경 오류',
        content: '미배정 상태에서 수리입고 로는 상태를 변경할 수 없습니다.',
      });
      return;
    }
    this.setState({ bikeStatus: value });
  };

  onUnAssignBikeLocationType = (value) => {
    this.setState({ unassignBikeLocation: value });
  };

  render() {
    const { close, data } = this.props;
    delete this.state.bikeStatusType[4]; // 바이크 등록/수정 모달에서는 "배정완료" 가 나올 필요 없음
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="systemKey-title">
              {data ? '바이크 수정' : '바이크 등록'}
              {data ? (
                <Button
                  style={{
                    backgroundColor: 'black',
                    borderColor: 'black',
                    color: 'white',
                    width: 100,
                    height: 32,
                    fontSize: 14,
                    marginLeft: 70,
                  }}
                  onClick={() => {
                    this.bikeDelete(data);
                  }}>
                  바이크삭제
                </Button>
              ) : (
                ''
              )}
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="exit"
            />
            <div className="systemKey-inner">
              <Form
                ref={this.formRef}
                onFinish={this.handleSubmit}
                className="create-control-form">
                <div className="regist-warp">
                  <div className="regist-title2">차종</div>
                  <div>
                    <SelectBox
                      name="bike"
                      codeString={this.state.bikeNameList}
                      value={this.state.modelName}
                      onChange={this.onCheckType}
                      style={{
                        width: 250,
                      }}
                    />
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">차량번호</div>
                  <div className="regist-input2">
                    <FormItem
                      name="bikeNumber"
                      className="create-control-form-item"
                      rules={[
                        {
                          required: true,
                          message: '차량번호를 입력해주세요',
                        },
                      ]}
                      style={{ width: 250 }}>
                      <Input
                        required="true"
                        placeholder="차량번호를 입력해주세요."
                      />
                    </FormItem>
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">차대번호</div>
                  <div className="regist-input2">
                    <FormItem
                      name="viNumber"
                      className="create-control-form-item"
                      rules={[
                        {
                          required: true,
                          message: '차대번호를 입력해주세요',
                        },
                      ]}
                      style={{ width: 250 }}>
                      <Input placeholder="차대번호를 입력해주세요." />
                    </FormItem>
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">연식</div>
                  <div>
                    <SelectBox
                      codeString={this.state.bikeYearList}
                      value={this.state.modelYear}
                      onChange={this.onMYCheckType}
                      style={{
                        width: 250,
                      }}
                    />
                  </div>
                </div>
                {!data ||
                (data && data.bikeStatus !== 2) ||
                this.state.bikeStatus === '1' ||
                this.state.bikeStatus === '3' ? (
                  <div className="regist-warp">
                    <div className="regist-title2">
                      미배정
                      <br />
                      바이크위치
                    </div>
                    <div>
                      <SelectBox
                        codeString={this.state.bikeLocation}
                        value={
                          this.state.unassignBikeLocation === undefined ||
                          this.state.unassignBikeLocation === null ||
                          this.state.unassignBikeLocation === 0
                            ? '선택'
                            : this.state.bikeLocation[
                                this.state.unassignBikeLocation
                              ]
                        }
                        onChange={this.onUnAssignBikeLocationType}
                        style={{
                          width: 250,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="regist-warp">
                    <div className="regist-title2">
                      사용지점
                      <br />
                      (운행중)
                    </div>
                    <div>
                      <Input
                        value={
                          this.state.bikeLocation[
                            Object.keys(this.state.bikeLocation).find(
                              (key) =>
                                this.state.bikeLocation[key] === data.branchName
                            )
                          ]
                        }
                        style={{ width: 250 }}
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div className="regist-warp">
                  <div className="regist-title2">명의자</div>
                  <div>
                    <SelectBox
                      defaultValue={ownerType[0]}
                      code={Object.keys(ownerType)}
                      codeString={ownerType}
                      value={ownerType[this.state.owner]}
                      onChange={this.onOwnerCheckType}
                      style={{
                        width: 250,
                      }}
                    />
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">보험회사</div>
                  <div>
                    <SelectBox
                      defaultValue={insuranceCompanyType[0]}
                      code={Object.keys(insuranceCompanyType)}
                      codeString={insuranceCompanyType}
                      value={insuranceCompanyType[this.state.insuranceCompany]}
                      onChange={this.onICCheckType}
                      style={{
                        width: 250,
                      }}
                    />
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">보험나이</div>
                  <div>
                    <SelectBox
                      codeString={insuranceAgeType}
                      value={
                        this.state.insuranceAge === 0
                          ? '전연령'
                          : this.state.insuranceAge
                      }
                      onChange={this.onIACheckType}
                      style={{
                        width: 250,
                      }}
                    />
                  </div>
                </div>
                <div className="regist-warp">
                  <div className="regist-title2">보험만기일</div>
                  <div>
                    <FormItem
                      name="insuranceEndDate2"
                      className="selectItem"
                      initialValue={
                        data
                          ? moment(
                              data.insuranceEndDate === null
                                ? today
                                : data.insuranceEndDate,
                              'YYYY-MM-DD'
                            )
                          : moment(today, dateFormat)
                      }>
                      <DatePicker
                        style={{ width: 250 }}
                        className="selectItem"
                        format={dateFormat}
                      />
                    </FormItem>
                  </div>
                </div>
                {data ? (
                  <div className="regist-warp">
                    <div className="regist-title2">상태</div>
                    <div>
                      <SelectBox
                        defaultValue={this.state.bikeStatusType[1]}
                        code={Object.keys(this.state.bikeStatusType)}
                        codeString={this.state.bikeStatusType}
                        value={this.state.bikeStatusType[this.state.bikeStatus]}
                        onChange={this.onBSCheckType}
                        style={{
                          width: 250,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="regist-warp">
                  <div className="regist-title2">비고</div>
                  <div>
                    <FormItem name="memo" initialValue={data && data.memo}>
                      <textarea
                        placeholder="비고 사항을 입력해 주세요."
                        style={{ width: 250, height: 80 }}
                      />
                    </FormItem>
                  </div>
                </div>
                <div className="registBike-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: 'white',
                      width: 100,
                      height: 40,
                      fontSize: 16,
                      marginTop: 5,
                    }}>
                    저장
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RegistBikeDialog;
