import {
  AutoComplete,
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Table
} from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import {
  httpGet,
  httpPost,
  httpPostWithoutLoading,
  httpUrl
} from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import ProgressBar from '../../components/ProgressBar';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import {
  adminSendKindString,
  adminSendKindStringForSearch,
  categoryStatus,
  kindString
} from '../../lib/util/codeUtil';
import { formatDate } from '../../lib/util/dateUtil';
import debouncedCallback from '../../lib/util/debouncedCallback';
import { comma } from '../../lib/util/numberUtil';

// const JSONStream = require('JSONStream');

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
const Search = Input.Search;

const initialPaginationExcel = {
  total: null,
  totalRes: [],
  current: null,
};

const today = new moment();
const dateFormat = 'YYYY-MM-DD';
const todayString = today.format(dateFormat);

class DepositAllHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: initialPaginationExcel,
      list: [],
      branchList: [],
      searchId: '',
      searchName: '',
      searchType: 1,
      category: '',
      adminSendKind: '',
      branchIdx: '',
      endDate: todayString,
      startDate: todayString,
      options: [],
      memo: '',

      progressBarOn: false,
    };
    this.progressBarFlag = true;
    this.yesterday = new moment().clone().add('-1', 'days').format('YYYY-MM-D');
    this.lastMonth = new moment().clone().add('-1', 'months').format('YYYY-MM');
  }

  componentDidMount() {
    this.getList();
    this.getAllBranchList();
  }

  getList = () => {
    let category = this.state.category;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userId = this.state.searchId;
    let adminSendKind = this.state.adminSendKind;
    let branchIdx = this.state.branchIdx;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let memo = this.state.memo;
    const withTotalPage = false;

    console.log(startDate, endDate);

    const startMoment = new moment(startDate);
    const endMoment = new moment(endDate);
    const dateDiff = moment.duration(endMoment.diff(startMoment)).asDays();
    if (dateDiff > 30)
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });

    if (!startDate || !endDate) {
      Modal.warn({
        title: '조회기간 미지정',
        content: '조회기간을 지정해주세요',
      });
      return;
    }
    console.log({
      category,
      pageNum,
      pageSize,
      userId,
      adminSendKind,
      branchIdx,
      startDate,
      endDate,
      withTotalPage,
    });

    httpPost(httpUrl.depositAllList, [], {
      category,
      pageNum,
      pageSize,
      userId,
      adminSendKind,
      branchIdx,
      startDate,
      endDate,
      withTotalPage,
      memo,
    }).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.ncash,
        pagination,
      });
    });
  };

  getAllBranchList = () => {
    httpGet(httpUrl.getBranchListAll, []).then((res) => {
      console.log(res.data);
      this.setState({
        branchList: res.data,
      });
      if (res?.data != null) {
        this.resetBranchListOptions(res?.data);
      }
    });
  };

  resetBranchListOptions = (branchList) => {
    this.setState(
      {
        options: branchList.map((branch) => {
          return { value: branch.branchName, key: branch.idx };
        }),
        branchIdx: '',
      },
      () => {
        this.getList();
      }
    );
  };

  onBranchSearch = (searchText) => {
    if (searchText) {
      this.setState({
        options: this.state.branchList
          .filter((branch) => branch.branchName.includes(searchText))
          .map((b) => {
            return {
              value: b.branchName,
              key: b.idx,
            };
          }),
      });
    } else {
      this.resetBranchListOptions(this.state.branchList);
    }
  };

  debouncedBranchSearch = debouncedCallback(this.onBranchSearch, 200);

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        searchId: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onSearchMemo = (value) => {
    this.setState(
      {
        memo: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChangeStatus = (value) => {
    if (value !== 'NCASH_SEND_BY_ADMIN') {
      this.setState(
        {
          category: value === 'NONE' ? '' : value,
          adminSendKind: '',
          pagination: {
            current: 1,
            pageSize: 10,
          },
        },
        () => {
          this.getList();
        }
      );
    } else {
      this.setState(
        {
          category: value === 'NONE' ? '' : value,
          pagination: {
            current: 1,
            pageSize: 10,
          },
        },
        () => {
          this.getList();
        }
      );
    }
  };

  getExcelList = async () => {
    let category = this.state.category;
    let userId = this.state.searchId;
    let adminSendKind = this.state.adminSendKind;
    let branchIdx = this.state.branchIdx;
    let memo = this.state.memo;

    this.progressBarFlag = true;

    const loopGetList = async () => {
      try {
        console.log({
          category,
          userId,
          startDate:
            this.state.paginationExcel.current.format('YYYY-MM-DD HH:mm'),
          endDate: this.state.paginationExcel.current
            .clone()
            .add('10', 'm')
            .format('YYYY-MM-DD HH:mm'),
          withPagination: false,
        });

        const res = await httpPostWithoutLoading(
          httpUrl.depositAllListExcel,
          [],
          {
            category,
            userId,
            adminSendKind,
            branchIdx,
            startDate:
              this.state.paginationExcel.current.format('YYYY-MM-DD HH:mm'),
            endDate: this.state.paginationExcel.current
              .clone()
              .add('10', 'm')
              .format('YYYY-MM-DD HH:mm'),
            withPagination: false,
            memo,
          }
        );
        console.log(res);
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    };

    let totalRes = [];
    let tryCnt = 0;

    let failedPages = [];

    const endDatePlus1Day = moment(this.state.endDate).clone().add('1', 'day');

    while (this.progressBarFlag) {
      if (moment(this.state.paginationExcel.current) >= endDatePlus1Day) break;

      try {
        const loopRes = await loopGetList();
        if (loopRes.data.ncash instanceof Array) {
          this.setState(
            {
              paginationExcel: {
                ...this.state.paginationExcel,
                current: this.state.paginationExcel.current
                  .clone()
                  .add('10', 'm'),
              },
            },
            () => console.log(this.state.paginationExcel.current)
          );

          totalRes = totalRes.concat(loopRes.data.ncash);
          tryCnt = 0;
        } else {
          tryCnt++;
        }
      } catch (e) {
        console.error(e);
        tryCnt++;
      }
      if (tryCnt > 5) break;
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    if (tryCnt > 5) {
      Modal.info({
        title: '오류',
        content: <div>다운로드 오류</div>,
        onOk() {},
      });
      return null;
    }
    else {
      totalRes.sort((a, b) => a.idx - b.idx);
      return totalRes;
    }
  };

  parseExcelJson = (data) => {
    let result = [
      {
        isAgency: '대리점구분',
        userType: '구분',
        userId: '아이디',
        userIdx: 'idx',
        userName: '이름',
        branchName: '지점명',
        category: '카테고리',
        memo: '메모',
        createDate: '출금일시',
        ncashDeltaWithMisu: '증감원금',
        ncashDelta: '실증감액',
        ncashWithMisu: '미수포함잔액',
        ncash: '잔액',
        ncashDeltaMisu: '미수금증감분',
        misu: '미수금',
        target: '대상',
        targetBranchName: '대상지점',
        adminId: '관리자아이디',
        orderIdx: '주문번호',
        deliveryPrice: '배달비',
        deliveryPriceVat: '부가세',
        deliveryPriceFee: '수수료',
        riderCancelPenaltyAmount: '취소페널티',
        duesDeliveryPrice: '건별가맹비',
        deliveryPriceFeeByDpBasic: '건별수수료',
        deliveryPriceFeeByDpRegion: '할증건별수수료',
        businessNumber: '사업자번호',
        maxLogIdx: '취소/복원여부',
        updateContent: '상세내용',
        // targetTmp: '뉴트랙대상'
      },
    ];

    data.forEach((item) => {
      let deliveryPrice = item.deliveryPrice ? parseInt(item.deliveryPrice) : 0;
      let deliveryPriceVat = item.deliveryPriceVat
        ? parseInt(item.deliveryPriceVat)
        : 0;
      let deliveryPriceFee = item.deliveryPriceFee ? item.deliveryPriceFee : 0;
      let riderCancelPenaltyAmount = item.riderCancelPenaltyAmount;
      let deliveryPriceFeeByDpBasic = item.deliveryPriceFeeByDpBasic;
      // if (item.userType === 2) {
      //   deliveryPriceFee = 0;
      //   riderCancelPenaltyAmount = 0;
      // }
      // if (item.userId === 'connect9') {
      //   riderCancelPenaltyAmount = 0;
      //   if (item.ncashDelta === deliveryPriceVat + deliveryPriceFee)
      //     deliveryPrice = 0; //라이더 배송 케이스
      // } else if (item.userType === 1) {
      //   deliveryPriceVat = 0;
      //   if (item.ncashDelta === riderCancelPenaltyAmount) {
      //     deliveryPriceFee = 0;
      //     deliveryPrice = 0;
      //   } //정직원 배송 케이스
      // }

      const maxLogIdx =
        (item.category === 'DELIVERY_PRICE_RECEIVE' ||
          item.category === 'DELIVERY_PRICE_SEND') &&
        item.maxLogIdx
          ? '복원'
          : item.orderStatus === 5
          ? '취소'
          : '';

      const minusFlag =
        item.category === 'DELIVERY_PRICE_RECEIVE_RESTORE' ||
        item.category === 'DELIVERY_PRICE_SEND_RESTORE'
          ? -1
          : 1;

      result.push({
        isAgency: item.companyId === 'connect9' ? '직영점' : '대리점',
        userType: item.userType === 1 ? '라이더' : item.userType === 2 ? '가맹점' : item.userType === 4 ? '관제' : '대리점',
        userId: item.userId,
        userIdx: item.userIdx,
        userName: item.userName,
        branchName: item.branchName,
        category:
          (item.category === 'RIDER_ASSIGN_CANCEL' && item.orderStatus === 5 ) ?
          categoryStatus['DELIVERY_PRICE_PENALTY']
          :
          categoryStatus[item.category] +
          (item.category === 'NCASH_SEND_BY_ADMIN'
            ? '-' + adminSendKindString[item.kind]
            : (item.category === 'BATCH_WORK_NCASH' || item.category === 'BATCH_WORK_NCASH_MONTHLY')
            ? '-' + kindString[item.kind]
            :''),
        memo:
          (item.category === 'BATCH_WORK_NCASH' || item.category === 'BATCH_WORK_NCASH_MONTHLY')
            ? item.batchTitle
              ? item.batchTitle
              : '-'
            : item.memo,
        // item.memo,
        createDate: item.createDate,
        ncashDeltaWithMisu: item.ncashDelta - item.ncashDeltaMisu,
        ncashDelta: item.ncashDelta,
        ncashWithMisu: item.afterNcash - item.misu1 - item.misu2 - item.misu3,
        ncash: item.afterNcash,
        ncashDeltaMisu: item.ncashDeltaMisu,
        misu: item.misu1+item.misu2+item.misu3,
        target: item.relationTarget ? item.relationTarget : '-',
        targetBranchName:
          item.category === 'BATCH_WORK_NCASH' ? item.targetBranchName : '-',
        adminId: item.adminId,
        orderIdx: item.orderIdx,
        deliveryPrice: deliveryPrice,
        deliveryPriceVat: deliveryPriceVat,
        deliveryPriceFee: deliveryPriceFee,
        riderCancelPenaltyAmount: minusFlag * riderCancelPenaltyAmount,
        duesDeliveryPrice: item.duesDeliveryPrice,
        deliveryPriceFeeByDpBasic: item.deliveryPriceFeeByDpBasic,
        deliveryPriceFeeByDpRegion: minusFlag*item.deliveryPriceFeeByDpRegion,
        businessNumber: item.relationBusinessNumber,
        maxLogIdx: maxLogIdx,
        updateContent: item.updateContent,
        // targetTmp: item.targetTmp
      });
    });

    return result;
  };

  onDownload = async () => {
    if (!this.state.startDate || !this.state.endDate) {
      Modal.warn({
        title: '조회기간 미지정',
        content: '조회기간을 지정해주세요',
      });
      return null;
    }

    const endDatePlus1Day = moment(this.state.endDate).clone().add('1', 'day');

    this.setState(
      {
        paginationExcel: {
          current: moment(this.state.startDate),
          total: endDatePlus1Day - moment(this.state.startDate),
        },
        progressBarOn: true,
      },
      async () => {
        const data = await this.getExcelList();

        if (!data) return;

        // let dataSplittedByDate = [];
        // let splitPointIdx = [];
        // let splitDate = moment(this.state.startDate);
        // splitDate.add('-1', 'millisecond');
        // for (let i = 0; i < data.length; i++) {
        //   const d = data[i];
        //   if (moment(d.createDate) > splitDate) {
        //     splitPointIdx.push(i);
        //     while (splitDate < moment(d.createDate)) {
        //       splitDate.add('1', 'day');
        //     }
        //   }
        // }

        // splitPointIdx.push(data.length);

        // for (let i = 0; i < splitPointIdx.length; i++) {
        //   const d = data[i];
        //   if (i === 0) continue;
        //   else
        //     dataSplittedByDate.push(
        //       data.slice(splitPointIdx[i - 1], splitPointIdx[i])
        //     );
        // }

        // for (let i = 0; i < dataSplittedByDate.length; i++) {
        // const data = dataSplittedByDate[i];
        const maxItemCount = 50000;
        const splitCount = parseInt(data.length / maxItemCount) + 1;
        console.log(splitCount);
        for (let sp = 1; sp <= splitCount; sp++) {
          // let dateString = '';
          // if (data.length > 0) {
          //   dateString = moment(data[0].createDate).format('YYYY_MM_DD');
          //   if (splitCount > 1) dateString += '_' + sp;
          // } else {
          //   dateString = '';
          // }
          const excelJson = this.parseExcelJson(data.splice(0, maxItemCount));

          console.log('excelJson');

          const ws = XLSX.utils.json_to_sheet(excelJson, {
            skipHeader: true,
          });

          console.log('ws');
          // console.log(ws);

          const wb = XLSX.utils.book_new();

          ws['!cols'] = [];
          ws['!cols'][1] = { width: 15 };
          ws['!cols'][3] = { width: 23 };
          ws['!cols'][4] = { width: 20 };

          XLSX.utils.book_append_sheet(wb, ws, `예치금내역_${sp}`);
          await XLSX.writeFile(wb, `예치금내역_${sp}.xlsx`);
        }
        // }
      }
    );
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  render() {
    const columns = [
      {
        title: '구분',
        dataIndex: 'userType',
        className: 'table-column-center',
        render: (data) => (
          <>
            {data === 1 ? (
              <div style={{ color: 'blue' }}>라이더</div>
            ) : (
              <div style={{ color: 'red' }}>가맹점</div>
            )}
          </>
        ),
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '이름',
        dataIndex: 'userName',
        className: 'table-column-center',
      },
      {
        title: '정직원 여부',
        dataIndex: 'fullTimeEmployee',
        className: 'table-column-center',
        render: (data, row) => (
            <div> {data ? '정직원' : ''} </div>
        )
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '카테고리',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {categoryStatus[data] +
              (data === 'NCASH_SEND_BY_ADMIN'
                ? '-' + adminSendKindString[row.kind]
                : '')}
          </div>
        ),
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {(row.category === 'BATCH_WORK_NCASH' || row.category === 'BATCH_WORK_NCASH_MONTHLY')
              ? row.batchTitle
                ? kindString[row.kind] + '[' + row.batchTitle + ']'
                : '-'
              : data}
          </div>
        ),
      },
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data)}원</div>,
      },
      {
        title: '잔액',
        dataIndex: 'afterNcash',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data)}원</div>,
      },
      {
        title: '미수금증감분',
        dataIndex: 'ncashDeltaMisu',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '미수금',
        dataIndex: 'misu',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(row.misu1 + row.misu2 + row.misu3)}원</div>,
      },
      {
        title: '관리자 아이디',
        dataIndex: 'adminId',
        className: 'table-column-center',
      },
      {
        title: '대상',
        dataIndex: 'relationTarget',
        className: 'table-column-center',
        render: (data, row) => {
          if (row.category === 'NCASH_SEND_BY_ADMIN' ||
              row.category === 'DELIVERY_SUBSIDY_SEND' ||
              row.category === 'DELIVERY_SUBSIDY_RECEIVE' ||
              row.category === 'DELIVERY_SUBSIDY_SEND_RESTORE' ||
              row.category === 'DELIVERY_SUBSIDY_RECEIVE_RESTORE') return <></>;
          else return <div>{data}</div>;
        },
      },
      {
        title: '비고',

        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) => {
          if (
            row.category === 'DELIVERY_PRICE_SEND' ||
            row.category === 'DELIVERY_PRICE_RECEIVE'
          ) {
            let deliveryPrice = parseInt(row.deliveryPrice);
            let deliveryPriceVat = parseInt(row.deliveryPriceVat * 0.1);
            let deliveryPriceFee = row.deliveryPriceFee;
            let riderCancelPenaltyAmount = row.riderCancelPenaltyAmount;
            let deliveryPriceFeeByDpBasic = row.deliveryPriceFeeByDpBasic;
            if (row.userType === 2) {
              deliveryPriceFee = 0;
              riderCancelPenaltyAmount = 0;
            }
            if (row.userId === 'connect9') {
              riderCancelPenaltyAmount = 0;
              if (row.ncashDelta === deliveryPriceVat + deliveryPriceFee)
                deliveryPrice = 0; //라이더 배송 케이스
            } else if (row.userType === 1) {
              deliveryPriceVat = 0;
              if (
                row.ncashDelta ===
                deliveryPriceFee + riderCancelPenaltyAmount
              )
                deliveryPrice = 0; //정직원 배송 케이스
            }

            return (
              '주문번호:' +
              row.orderIdx +
              ',배달비:' +
              deliveryPrice +
              ',부가세:' +
              deliveryPriceVat +
              ',수수료:' +
              deliveryPriceFee +
              ',페널티:' +
              riderCancelPenaltyAmount +
              ',건별가맹비:' +
              row.duesDeliveryPrice +
              ',건별수수료:' +
              (row.deliveryPriceFeeByDpBasic || 0)
            );
          }
          if (row.category === 'DUES_PAY') {
            return row.relationBusinessNumber;
          }
          if (row.category === 'BATCH_WORK_NCASH') {
            return row.targetBranchName;
          }
          if (row.category === 'DELIVERY_SUBSIDY_SEND' ||
              row.category === 'DELIVERY_SUBSIDY_RECEIVE' ||
              row.category === 'DELIVERY_SUBSIDY_SEND_RESTORE' ||
              row.category === 'DELIVERY_SUBSIDY_RECEIVE_RESTORE') {
            return ('주문번호:' + row.orderIdx);
          }
        },
      },
      {
        title: '취소/복원여부',
        dataIndex: 'maxLogIdx',
        className: 'table-column-center',
        render: (data, item) => (
          <div>
            {(item.category === 'DELIVERY_PRICE_RECEIVE' ||
              item.category === 'DELIVERY_PRICE_SEND') &&
            item.maxLogIdx
              ? '복원'
              : item.orderStatus === 5
              ? '취소'
              : ''}
          </div>
        ),
      },
      {
        title: '상세내용',
        dataIndex: 'updateContent',
        className: 'table-column-center',
      },
    ];
    return (
      <>
        {this.state.progressBarOn && (
          <ProgressBar
            current={
              (this.state.paginationExcel.current -
                moment(this.state.startDate)) /
                600000
            }
            total={this.state.paginationExcel.total / 600000}
            quit={this.quitProgressBar}
          />
        )}

        <div className="flex flex-row justify-between mb-[20px]">
          <div className="flex flex-col">
            <div className="flex mb-[10px]">
              <SelectBox
                placeholder={'전체'}
                style={{ width: 200 }}
                value={categoryStatus[this.state.category]}
                code={Object.keys(categoryStatus)}
                codeString={categoryStatus}
                onChange={(value) => {
                  this.onChangeStatus(value);
                }}
              />

              <Search
                placeholder="아이디,이름 검색"
                enterButton
                allowClear
                onSearch={this.onSearch}
                style={{
                  width: 220,
                  marginLeft: 10,
                }}
              />
            </div>

            <div className="flex">
              <SelectBox
                placeholder={'전체'}
                style={{ width: 200 }}
                value={
                  this.state.adminSendKind === '' ||
                  this.state.adminSendKind === 0
                    ? adminSendKindStringForSearch[0]
                    : adminSendKindString[this.state.adminSendKind]
                }
                // code={Object.keys(categoryStatus)}
                options={adminSendKindStringForSearch}
                disabled={this.state.category !== 'NCASH_SEND_BY_ADMIN'}
                onChange={(value) => {
                  const adminSendKindIndex = value - 1;
                  if (adminSendKindIndex === -1) {
                    this.setState(
                      {
                        adminSendKind: '',
                        pagination: {
                          current: 1,
                          pageSize: 10,
                        },
                      },
                      () => {
                        this.getList();
                      }
                    );
                  } else {
                    this.setState(
                      {
                        adminSendKind: adminSendKindIndex,
                        pagination: {
                          current: 1,
                          pageSize: 10,
                        },
                      },
                      () => {
                        this.getList();
                      }
                    );
                  }
                }}
              />

              {/*<Search
                placeholder="지점 검색"
                enterButton
                allowClear
                onSearch={this.onSearch}
                style={{
                  width: 220,
                  marginLeft: 10,
                }}
              />*/}
              <AutoComplete
                options={this.state.options}
                style={{
                  width: 220,
                  marginLeft: 10,
                }}
                onSelect={(data, option) => {
                  console.log(option);
                  this.setState(
                    {
                      branchIdx: option.key,
                      pagination: {
                        current: 1,
                        pageSize: 10,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                }}
                onSearch={this.debouncedBranchSearch}>
                <Input.Search placeholder="지점명 검색" enterButton />
              </AutoComplete>
            </div>

            <div style={{marginTop: '10px'}}>
              <Search
                placeholder="메모 검색"
                enterButton
                allowClear
                onSearch={this.onSearchMemo}
                style={{
                  width: 220,
                }}
              />
            </div>
          </div>

          <div className="flex flex-row items-center">
            <RangePicker
              style={{ width: 300, height: 32 }}
              placeholder={['시작일', '종료일']}
              defaultValue={[today, today]}
              onChange={(_, dateStrings) => {
                if (dateStrings[(0, 1)]) {
                  this.setState(
                    {
                      startDate: dateStrings[0],
                      endDate: dateStrings[1],
                      pagination: {
                        current: 1,
                        pageSize: 10,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                } else {
                  this.setState(
                    {
                      startDate: '',
                      endDate: '',
                      pagination: {
                        current: 1,
                        pageSize: 10,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                }
              }}
            />
            {Const.excelEnable && (
              <div className="flex">
                <Button
                  className="download-btn"
                  style={{ marginLeft: 10 }}
                  onClick={async () => await this.onDownload()}>
                  <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                  엑셀 다운로드
                </Button>
              </div>
            )}
          </div>
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={false}
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.list.length === 0) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current + 1;
              this.setState({ pagination }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </>
    );
  }
}

export default DepositAllHistory;
