import { Button, DatePicker, Form, Input, Modal, Radio, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import {
  deliveryStatShow,
  distShowSetting,
  addrUpdateEnabled,
  sortOrder,
  distType,
} from '../../lib/util/codeUtil';
import SelectBox from '../input/SelectBox';
import CloseIcon from '../../img/close.png';
import SearchRiderByLevelDialog from "./SearchRiderByLevelDialog";
import moment from 'moment'

const FormItem = Form.Item;
const Option = Select.Option;

class RegistBranchDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      isModifyMode: false,
      deliveryStatShowType: 1,
      distShowSettingType: 1,
      addrUpdateEnabledType: 1,
      sortOrderType: 1,
      distType: 1,
      openSearchRiderModal: false,
      selectedRider: null,
      isAgency: this.props.data ? (this.props.data.companyId === "connect9" ? 1 : 2) : 1,
      agencyFeeStartDate: (this.props.data && this.props.data.agencyFeeStartDate) ? this.props.data.agencyFeeStartDate : null
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.data) {
      this.formRef.current.setFieldsValue({ ...this.props.data });
      if (this.props.data.branchProfitPercent) {
        this.formRef.current.setFieldsValue({
              branchProfitPercent: parseInt(this.props.data.branchProfitPercent * 100),
            }
        );
      }

      this.setState({
        isModifyMode: true,
        deliveryStatShowType: this.props.data.deliveryStatShow,
        distShowSettingType: this.props.data.distShowSetting,
        addrUpdateEnabledType: this.props.data.addrUpdateEnabled,
        sortOrderType: this.props.data.sortOrder,
        distType: this.props.data.distType,
        visibleType: this.props.data.visible,
        selectedRider: {riderName: this.props.data.stationDirectorName, userIdx: this.props.data.stationDirectorIdx},
        agencyFee: this.props.data.agencyFee,
      });
    }
  }

  // 지점 생성 api
  createHandleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue().tel);
    // let agencyStartNumTmp = null
    // if(this.state.isAgency === 2) {
    //   agencyStartNumTmp = this.formRef.current.getFieldValue("agencyStartNum").replaceAll('-','')
    //   if(agencyStartNumTmp.length !== 11) {
    //     Modal.warn({
    //       title: "등록 오류",
    //       content: "대리점 이용사업개시번호 길이가 맞지 않습니다."
    //     })
    //     return
    //   }
    //   if(agencyStartNumTmp.match(/[^0-9]/g)){
    //     Modal.warn({
    //       title: "등록 오류",
    //       content: "대리점 이용사업개시번호 형식이 맞지 않습니다."
    //     })
    //     return
    //   }
    // }
    httpPost(httpUrl.createBranch, [], {
      branchName: this.formRef.current.getFieldsValue().branchName,
      latitude: this.formRef.current.getFieldsValue().latitude,
      longitude: this.formRef.current.getFieldsValue().longitude,
      tel: this.formRef.current.getFieldsValue().tel,
      startTime: this.formRef.current.getFieldsValue().startTime,
      endTime: this.formRef.current.getFieldsValue().endTime,
      companyId: this.state.isAgency === 1 ? "connect9" : this.formRef.current.getFieldsValue().companyId,
      agencyFee: parseInt(this.formRef.current.getFieldValue("agencyFee")),
      // agencyStartNum: agencyStartNumTmp,
      agencyFeeStartDate: this.state.agencyFeeStartDate
    }).then((res) => {
      console.log('branchName : ' + res.data);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '등록 성공',
          content: '지점 등록에 성공했습니다.',
        });
        this.props.close();
      } else if (res.data === 'BRANCHNAME_DUP') {
        Modal.warn({
          title: '지점명 중복',
          content: '동일한 지점명이 있습니다.',
        });
      } else {
        Modal.warn({
          title: '등록 실패',
          content: '지점 등록에 실패했습니다.',
        });
      }
    });
  };

  // 지점 정보 변경 api
  modifyHandleSubmit = () => {
    if (this.formRef.current.getFieldsValue().branchProfitPercent <= 0 || this.formRef.current.getFieldsValue().branchProfitPercent > 100) {
      Modal.warn({
        title: '영업 이익 설정 실패',
        content: '영업 이익은 1~100까지 설정 가능',
      });
      return;
    }
    const stationDirector = this.state.selectedRider.userIdx;
    httpPost(httpUrl.updateBranch, [], {
      idx: this.props.data.idx,
      branchName: this.formRef.current.getFieldsValue().branchName,
      latitude: this.formRef.current.getFieldsValue().latitude,
      longitude: this.formRef.current.getFieldsValue().longitude,
      tel: this.formRef.current.getFieldsValue().tel,
      startTime: this.formRef.current.getFieldsValue().startTime,
      endTime: this.formRef.current.getFieldsValue().endTime,
      deliveryStatShow: this.state.deliveryStatShowType,
      maxDeliveryFee: this.formRef.current.getFieldsValue().maxDeliveryFee,
      riderCancelPenalty:
        this.formRef.current.getFieldsValue().riderCancelPenalty,
      basicDeliveryPrice:
        this.formRef.current.getFieldsValue().basicDeliveryPrice,
      distShowSetting: this.state.distShowSettingType,
      addrUpdateEnabled: this.state.addrUpdateEnabledType,
      sortOrder: this.state.sortOrderType,
      distType: this.state.distType,
      branchProfitPercent: this.formRef.current.getFieldsValue().branchProfitPercent,
      stationDirectorIdx: stationDirector,
      companyId: this.state.isAgency === 1 ? "connect9" : this.formRef.current.getFieldsValue().companyId,
      visible: this.state.visibleType,
      agencyFee: parseInt(this.formRef.current.getFieldValue("agencyFee")),
      agencyFeeStartDate: this.state.agencyFeeStartDate
    }).then((res) => {
      console.log(deliveryStatShow);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '변경 성공',
          content: '지점 정보 변경에 성공했습니다.',
        });
        this.props.close();
      } else {
        Modal.warn({
          title: '변경 실패',
          content: '지점 정보 변경에 실패했습니다.',
        });
      }
    });
  };

  OnChangeDeliveryStatShow = (value) => {
    this.setState({ deliveryStatShowType: value });
  };

  OnChangeDistShowSetting = (value) => {
    this.setState({ distShowSettingType: value });
  };

  OnChangeAddrUpdateEnabled = (value) => {
    this.setState({ addrUpdateEnabledType: value });
  };

  OnChangeSortOrder = (value) => {
    this.setState({ sortOrderType: value });
  };

  OnChangeDistType = (value) => {
    this.setState({ distType: value });
  };
  openSearchRiderModal = () => {
    this.setState({ openSearchRiderModal: true });
  };
  closeSerchRiderModal = () => {
    this.setState({ openSearchRiderModal: false });
  };

  render() {
    const { close, data } = this.props;
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="systemKey-Dialog">
          <div className="systemKey-content">
            <div className="regist-header">
              <div className="systemKey-title">
                {this.state.isModifyMode ? '지점 수정' : '지점 생성'}
              </div>
              <img
                onClick={close}
                src={CloseIcon}
                className="dialog-close"
                alt="img"
              />
            </div>
            <Radio.Group
              onChange={(e)=>this.setState({isAgency: e.target.value})}
              value={this.state.isAgency}
              style={{marginTop: 10}}
              disabled={this.props.data}
            >
              <Radio value={1}>직영점</Radio>
              <Radio value={2}>대리점</Radio>
            </Radio.Group>
            <Form
              ref={this.formRef}
              onFinish={
                this.state.isModifyMode
                  ? this.modifyHandleSubmit
                  : this.createHandleSubmit
              }>
              <div className="systemKey-inner3">
                <div className="regist-warp">
                  <div className="regist-title">{this.state.isAgency === 1 ? "지점명" : "지점"}</div>
                  {this.state.isModifyMode ? (
                    <FormItem
                      name="branchName"
                      className="regist-title"
                      rules={[
                        {
                          required: true,
                          message: this.state.isAgency === 1 ? "지점명을 입력해주세요." : "지점을 입력해주세요.",
                        },
                      ]}>
                      <Input
                        className="regist-input"
                        placeholder={data.branchName}
                      />
                    </FormItem>
                  ) : (
                    <FormItem
                      name="branchName"
                      className="regist-title"
                      rules={[
                        {
                          required: true,
                          message: this.state.isAgency === 1 ? "지점명을 입력해주세요." : "지점을 입력해주세요.",
                        },
                      ]}>
                      <Input
                        className="regist-input"
                        placeholder={this.state.isAgency === 1 ? "지점명을 입력해주세요." : "지점을 입력해주세요."}
                      />
                    </FormItem>
                  )}
                </div>
                {this.state.isModifyMode ? (
                    <div className="regist-warp">
                      <div className="regist-title">
                        스테이션장
                      </div>
                      <FormItem name="stationDirector" className="regist-title">
                        <div>
                          <Input
                              style={{width: '182px'}}
                              disabled="true"
                              value={this.state.selectedRider ? this.state.selectedRider.riderName : null}
                          />
                          <Button
                              style={{ backgroundColor: '#fdcc00',
                                borderColor: '#fdcc00',
                                color: '#000', width: '80px' }}
                              onClick={this.openSearchRiderModal}>
                            조회
                          </Button>
                        </div>
                      </FormItem>
                    </div>
                ) : null}
                {this.state.openSearchRiderModal && (
                    <SearchRiderByLevelDialog
                        branchIdx={this.props.data.idx}
                        riderLevel={3}
                        userStatus={1}
                        onSelect={(selectedRider) =>
                            this.setState(
                                { selectedRider },
                                () => console.log(this.state.selectedRider)
                            )
                        }
                        close={this.closeSerchRiderModal}
                    />
                )}

                {this.state.isModifyMode ? (
                    <div className="regist-warp">
                      <div className="regist-title">
                        영업 이익 설정(%)
                      </div>
                      <FormItem name="branchProfitPercent" className="regist-title">
                        <Input
                            className="regist-input"
                            placeholder={data.branchProfitPercent}
                        />
                      </FormItem>
                    </div>
                ) : null}
                <div className="regist-warp">
                  <div className="regist-title">위도</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="latitude" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.latitude}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="latitude" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="위도를 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>
                <div className="regist-warp">
                  <div className="regist-title">경도</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="longitude" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.longitude}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="longitude" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="경도를 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>
                <div className="regist-warp">
                  <div className="regist-title">전화번호</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="tel" className="regist-title">
                      <Input className="regist-input" placeholder={data.tel} />
                    </FormItem>
                  ) : (
                    <FormItem name="tel" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="전화번호를 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>
                <div className="regist-warp">
                  <div className="regist-title">시작시간</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="startTime" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.startTime}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="startTime" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="시작시간을 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>

                <div className="regist-warp">
                  <div className="regist-title">종료시간</div>
                  {this.state.isModifyMode ? (
                    <FormItem name="endTime" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.endTime}
                      />
                    </FormItem>
                  ) : (
                    <FormItem name="endTime" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder="종료시간을 입력해주세요."
                      />
                    </FormItem>
                  )}
                </div>

                <div className="regist-warp">
                  <div className="regist-title">프로그램<br/>사용료</div>
                    <FormItem name="agencyFee" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={this.state.isModifyMode ? data.agencyFee : "프로그램 사용료를 입력해주세요."}
                      />
                    </FormItem>
                </div>

                <div className="regist-warp">
                  <div className="regist-title">프로그램<br/>사용료<br/>적용시작일</div>
                    <FormItem className="regist-title">
                      <DatePicker
                        className="regist-input w-full" 
                        placeholder={"일자를 선택해주세요."}
                        value={this.state.agencyFeeStartDate ? moment(this.state.agencyFeeStartDate, "YYYY-MM-DD") : null}
                        onChange={(value)=>this.setState({agencyFeeStartDate:moment(value).format("YYYY-MM-DD")})}
                      />
                    </FormItem>
                </div>

                { this.state.isAgency === 2 &&
                  <div className="regist-warp">
                    <div className="regist-title">회사ID</div>
                    {this.state.isModifyMode ? (
                        <FormItem
                          name="companyId"
                          className="regist-title"
                          rules={[
                            {
                              required: true,
                              message: "회사ID를 입력해주세요.",
                            },
                          ]}>
                          <Input
                              className="regist-input"
                              placeholder={data.companyId}
                          />
                        </FormItem>
                    ) : (
                        <FormItem
                          name="companyId"
                          className="regist-title"
                          rules={[
                            {
                              required: true,
                              message: "회사ID를 입력해주세요.",
                            },
                          ]}>
                          <Input
                              className="regist-input"
                              placeholder="회사ID를 입력해주세요."
                          />
                        </FormItem>
                    )}
                  </div>
                }

                { this.state.isAgency === 2 &&
                  <>
                    <div className="regist-warp-agency">
                      <div className="agency">
                        *커넥트 9 플렛폼사 등록번호 : 923-09-43866-7
                      </div>
                    </div>

                    {/* <div className="regist-warp-agency">
                      <div className="agency">
                        *대리점 이용사업개시번호
                      </div>
                      <FormItem
                        name="agencyStartNum"
                        className="regist-title-agency"
                        rules={[
                          {
                            required: true,
                            message: "대리점 이용사업개시번호를 입력해주세요.",
                          },
                        ]}>
                        <Input
                            className="regist-input"
                            placeholder='"-"없이 숫자만 입력해주세요.'
                            disabled={this.props.data}
                        />
                      </FormItem>
                    </div> */}
    
                  </>
                }

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">
                      가맹점 배달현황 통계 노출여부
                    </div>
                    <SelectBox
                      name="deliveryStatShow"
                      defaultValue={deliveryStatShow[0]}
                      code={Object.keys(deliveryStatShow)}
                      codeString={deliveryStatShow}
                      value={deliveryStatShow[this.state.deliveryStatShowType]}
                      onChange={this.OnChangeDeliveryStatShow}
                    />
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">최대배달수수료</div>
                    <FormItem name="maxDeliveryFee" className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.maxDeliveryFee}
                      />
                    </FormItem>
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">라이더 취소 기본 페널티</div>
                    <FormItem
                      name="riderCancelPenalty"
                      className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.riderCancelPenalty}
                      />
                    </FormItem>
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">기본배달비</div>
                    <FormItem
                      name="basicDeliveryPrice"
                      className="regist-title">
                      <Input
                        className="regist-input"
                        placeholder={data.basicDeliveryPrice}
                      />
                    </FormItem>
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">
                      라이더앱 거리설정 노출여부
                    </div>
                    <SelectBox
                      name="distShowSetting"
                      defaultValue={distShowSetting[0]}
                      code={Object.keys(distShowSetting)}
                      codeString={distShowSetting}
                      value={distShowSetting[this.state.distShowSettingType]}
                      onChange={this.OnChangeDistShowSetting}
                    />
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">
                      배차완료 후 도착지 수정가능여부
                    </div>
                    <SelectBox
                      name="addrUpdateEnabled"
                      defaultValue={addrUpdateEnabled[0]}
                      code={Object.keys(addrUpdateEnabled)}
                      codeString={addrUpdateEnabled}
                      value={
                        addrUpdateEnabled[this.state.addrUpdateEnabledType]
                      }
                      onChange={this.OnChangeAddrUpdateEnabled}
                    />
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                  <div className="regist-warp">
                    <div className="regist-title">정렬방식</div>
                    <SelectBox
                      name="sortOrder"
                      defaultValue={sortOrder[0]}
                      code={Object.keys(sortOrder)}
                      codeString={sortOrder}
                      value={sortOrder[this.state.sortOrderType]}
                      onChange={this.OnChangeSortOrder}
                      style={{
                        width: 150,
                      }}
                    />
                  </div>
                ) : null}

                {this.state.isModifyMode ? (
                    <div className="regist-warp">
                      <div className="regist-title">거리요금방식</div>
                      <SelectBox
                          name="distType"
                          defaultValue={distType[0]}
                          code={Object.keys(distType)}
                          codeString={distType}
                          value={distType[this.state.distType]}
                          onChange={this.OnChangeDistType}
                          style={{
                            width: 150,
                          }}
                      />
                    </div>
                ) : null}

              </div>  
              <Button
                type="primary"
                htmlType="submit"
                className="create-control-submit-btn"
                style={{ width: '100%' }}
                // onClick={() => {
                //   this.state.isModifyMode ?
                //       this.modifyHandleSubmit() : this.createHandleSubmit()
                // }}
              >
                {this.state.isModifyMode ? '수정하기' : '등록하기'}
              </Button>

            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { login } = state;
  return { login };
};

const handleChange = (value) => {
  console.log(value);
};

export default withRouter(connect(mapStateToProps, null)(RegistBranchDialog));
