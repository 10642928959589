import { Button, DatePicker, Input, Radio, Table } from 'antd';
import locale from 'antd/lib/date-picker/locale/en_US';
import moment from 'moment';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';

const { Search } = Input;
const dateFormat = 'YYYY-MM-DD';
const today = moment(new Date()).format(dateFormat);
const oneYearAgo = moment(new Date()).subtract(1, 'years').format(dateFormat);
const oneMonthAgo = moment(new Date()).subtract(1, 'months').format(dateFormat);

class RiderLevelSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        beginDate: today,
        endDate: today,
      },
      branchNameList: { 0: '전체지점' },
      branchNameValue: 0,
      sumNcash: 0,
      riderId: '',
      riderName: '',

      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 50000,
      },
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
          (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState(
          {
            branchNameList: branchNameList,
          },
          () => {
            console.log('branchNameList');
            console.log(this.state.branchNameList);
          }
        );
      }
    });
  };

  getList = () => {
    const pagination = this.state.pagination;
    let pageNum = pagination.current;
    let pageSize = pagination.pageSize;
    let beginDate = pagination.beginDate;
    let endDate = pagination.endDate;
    let branchIdx = this.state.branchNameValue;
    let riderId = this.state.riderId;
    let riderName = this.state.riderName;

    httpGet(
      httpUrl.getRiderLevelSupport,
      [pageNum, pageSize, beginDate, endDate, branchIdx, riderId, riderName],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          pagination: pagination,
          list: res.data.historyList,
          sumNcash: res.data.sumNcash,
        });
      }
    });
  };

  onRadioChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          beginDate:
            value === 1 ? oneYearAgo : value === 2 ? oneMonthAgo : today,
          endDate: today,
        },
      },
      () => this.getList()
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onChangeDatePickerStart = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.endDate) - new Date(dateString) > 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
            },
          },
          () => this.getList()
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: pagination.endDate,
              endDate: dateString,
            },
          },
          () => this.getList()
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList()
      );
    }
  };

  onChangeDatePickerEnd = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.beginDate) - new Date(dateString) < 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              endDate: dateString,
            },
          },
          () => this.getList()
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
              endDate: pagination.beginDate,
            },
          },
          () => this.getList()
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList()
      );
    }
  };

  getExcelList = () => {
    const pagination = this.state.pagination;
    let pageNum = pagination.current;
    let beginDate = pagination.beginDate;
    let endDate = pagination.endDate;
    let branchIdx = this.state.branchNameValue;
    let riderId = this.state.riderId;
    let riderName = this.state.riderName;

    httpGet(
      httpUrl.getRiderLevelSupport,
      [pageNum, 100000, beginDate, endDate, branchIdx, riderId, riderName],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState(
          {
            listExcel: res.data.historyList,
            pagination: pagination,
          },
          () => this.onDownload()
        );
      }
    });
  };

  parseExcelJson = () => {
    let result = [
      {
        createTime: '일시',
        branchName: '지점명',
        riderName: '기사명',
        ncash: '축하지원금',
        registrationNumber: '주민번호',
      },
    ];
    this.state.listExcel.forEach((item) => {
      result.push({
        createTime: item.createTime,
        branchName: item.branchName,
        riderName: item.riderName,
        ncash: item.ncash,
        registrationNumber: item.registrationNumber,
      });
    });

    return result;
  };

  onDownload = () => {
    const excelJson = this.parseExcelJson();
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { width: 20 };
    ws['!cols'][2] = { width: 15 };
    ws['!cols'][3] = { width: 15 };
    ws['!cols'][4] = { width: 20 };
    ws['!cols'][5] = { width: 20 };
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '지원금지급내역.xlsx');
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createTime',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },

      {
        title: '기사명',
        dataIndex: 'riderName',
        className: 'table-column-center',
      },
      {
        title: '주민번호',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
      },
      {
        title: '축하 지원금',
        dataIndex: 'ncash',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}</div>,
      },
    ];

    const { branchNameList, pagination, list } = this.state;
    return (
      <>
        <div>
          <SelectBox
            value={branchNameList[this.state.branchNameValue]}
            code={Object.keys(branchNameList)}
            codeString={branchNameList}
            onChange={(value) => {
              if (parseInt(value) !== this.state.branchNameValue) {
                this.setState({ branchNameValue: parseInt(value) }, () =>
                  this.getList()
                );
              }
            }}
          />
        </div>

        <div>
          <Radio.Group
            defaultValue={3}
            onChange={this.onRadioChange}
            style={{ marginTop: '15px', marginBottom: '15px' }}>
            <Radio value={1}>1년</Radio>
            <Radio value={2}>한달</Radio>
            <Radio value={3}>오늘</Radio>
          </Radio.Group>

          <DatePicker
            placeholder={'시작일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.beginDate, dateFormat)}
            onChange={this.onChangeDatePickerStart}
          />
          <DatePicker
            placeholder={'종료일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.endDate, dateFormat)}
            onChange={this.onChangeDatePickerEnd}
          />

          <Search
            placeholder="ID 명 검색"
            enterButton
            allowClear
            onChange={(e) => {
              this.setState({
                riderId: e.target.value,
              });
            }}
            onSearch={() => this.getList()}
            style={{
              width: '220px',
              padding: '4px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          />

          <Search
            placeholder="기사명 검색"
            enterButton
            allowClear
            onChange={(e) => {
              this.setState({
                riderName: e.target.value,
              });
            }}
            onSearch={() => this.getList()}
            style={{
              width: '220px',
              padding: '4px',
              marginTop: '5px',
              marginLeft: '5px',
            }}
          />
          {Const.excelEnable && (
            <Button
              className="download-btn"
              style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
              onClick={() => this.getExcelList()}>
              <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
              엑셀 다운로드
            </Button>
          )}
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
          기간한정 축하지원금 합계: {comma(this.state.sumNcash)}
        </div>
      </>
    );
  }
}

export default RiderLevelSupport;
