import React, { useEffect, useState } from 'react';
import { comma } from '../../lib/util/numberUtil';
import moment from 'moment';
import axios from 'axios';
import Const from '../../const';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import { DatePicker, Select, Button, Spin } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import AgencyDashboardAgencyTbl from './AgencyDashboardAgencyTbl';
import AgencyDashboardBranchTbl from './AgencyDashboardBranchTbl';
import { agencySelfList } from '../../lib/util/agencyUtil';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const today = moment().format('YYYY-MM-DD');
const Option = Select.Option;
const AgencyDashboard = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const [belongBranch] = useState(agencySelfList());
  const [currentYearValue, setCurrentYearValue] = useState(
    moment().format('YYYY')
  );
  const [currentMonthValue, setCurrentMonthValue] = useState(
    moment().format('MM')
  );
  const [selectStartDate, setSelectStartDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [selectEndDate, setSelectEndDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [yearSelectCode, setYearSelectCode] = useState([]);
  const [monthSelectCode, setMonthSelectCode] = useState([]);
  const [settleTotal, setSettleTotal] = useState(0);
  const [selectBranchCompanyId, setSelectBranchCompanyId] = useState(
    loginInfo.branch.companyId
  );
  const [isLoading, setIsLoading] = useState(false);
  const [agencyData, setAgencyData] = useState({});
  const [branchData, setBranchData] = useState({});
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    let monthcode = [];
    for (let m = 1; m <= 12; m++) {
      monthcode.push({
        month: m,
        price: 0,
      });
    }
    setMonthSelectCode(monthcode);
    onClickDateTypeButton('today');
    setIsPageLoaded(true);
  }, []);

  useEffect(() => {
    makeYearCode();
    makeMonthData();
  }, [currentYearValue]);

  useEffect(() => {
    getData(selectStartDate, selectEndDate);
  }, [selectStartDate, selectEndDate, selectBranchCompanyId]);

  const makeYearCode = () => {
    let yearCodeTmp = [];
    const year = parseInt(moment().format('YYYY'));
    for (let i = year; i >= 2023; i--) {
      yearCodeTmp.push(i);
    }
    setYearSelectCode(yearCodeTmp);
  };

  const getSettlementDataFromMongo = async (customParams, url) => {
    try {
      return await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
      });
    } catch (e) {
      console.error('getting settlement-month-date is fail: ' + e);
    }
  };

  const makeMonthData = async () => {
    let month_temp = [];
    let sum = 0;
    if (isLoading) return;
    setIsLoading(true);
    for (let m = 1; m <= 12; m++) {
      const date = currentYearValue + '-' + m;
      const start = moment(date).format('YYYY-MM-01');
      const end = moment(date).endOf('month').format('YYYY-MM-DD');
      const datas = await getSettleData(start, end);
      if (datas) {
        month_temp.push({
          month: m,
          price: datas.data.totalNetProfit,
        });
        if (Number(currentMonthValue) === m) {
          const batchWorkData = await getBatchWorkData(start, end);
          if (batchWorkData.data != null) {
            datas.data.batchWorkNcashMisuAmount = batchWorkData.data * -1;
          }

          if (isPageLoaded) {
            if (loginInfo.branch.companyId === selectBranchCompanyId) {
              setAgencyData(() => {
                return datas.data;
              });
            } else {
              setBranchData(() => {
                return datas.data;
              });
            }
          }

          // setSelectStartDate(start);
          // setSelectEndDate(end);
        }
        sum += datas.data.totalNetProfit;
      }
    }
    if (month_temp.length > 0) {
      setMonthSelectCode(month_temp);
    }
    setSettleTotal(sum);
    setIsLoading(false);
  };

  const getSettleData = async (start, end) => {
    // 제휴본사일 경우 소속 대리점 모두 파라미터로
    if (loginInfo.branch.companyId === selectBranchCompanyId) {
      let companyId = [];
      if (Object.keys(agencyBranch).length !== 0) {
        let branch = [...agencyBranch];
        branch.forEach((value, index) => {
          companyId.push(value.companyId);
        });
      } else {
        companyId.push(selectBranchCompanyId);
      }

      let customParams = {
        startDate: start,
        endDate: end,
        agencyHeadquarterId: loginInfo.branch.companyId,
        companyId: companyId.join(),
      };
      const url = 'headquarter-dashboard/statistics';
      return await getSettlementDataFromMongo(customParams, url);
    } else {
      const url = 'dashboard/statistics';
      let customParams = {
        startDate: start,
        endDate: end,
        companyId: selectBranchCompanyId,
      };
      return await getSettlementDataFromMongo(customParams, url);
    }
  };
  const getBatchWorkData = async (start, end) => {
    try {
      return await httpGet(
        httpUrl.getAgencyDashboardBatchWorkData,
        [
          selectBranchCompanyId,
          moment(end).add(1, 'day').format('YYYY-MM-DD'),
          start,
        ],
        {}
      );
    } catch (e) {
      console.error('getting batch-work-misu-Amount is fail: ' + e);
    }
  };

  const onChangeMonth = async (month) => {
    setAgencyData({});
    setBranchData({});
    const m = String(month).padStart(2, '0');
    setCurrentMonthValue(m);
    const tempdd = currentYearValue + '-' + m;
    setSelectStartDate(moment(tempdd).format('YYYY-MM-01'));
    const dd = moment(tempdd).endOf('month').format('YYYY-MM-DD');
    if (today < dd) {
      setSelectEndDate(moment().format('YYYY-MM-DD'));
    } else {
      setSelectEndDate(dd);
    }
    // getData(moment(tempdd).format('YYYY-MM-01'), dd);
  };

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setSelectStartDate(
        moment(currentYearValue + currentMonthValue + date).format('YYYY-MM-DD')
      );
    } else {
      setSelectEndDate(
        moment(currentYearValue + currentMonthValue + date).format('YYYY-MM-DD')
      );
    }
  };

  const disabledDate = (current) => {
    return (
      current &&
      (current <
        moment(`${currentYearValue}-${currentMonthValue}`, 'YYYY-MM').startOf(
          'month'
        ) ||
        current >
          moment(`${currentYearValue}-${currentMonthValue}`, 'YYYY-MM').endOf(
            'month'
          ))
    );
  };

  const getData = async (start, end) => {
    if (isLoading) return;
    setIsLoading(true);
    if (start === null || start === undefined) start = selectStartDate;
    if (end === null || end === undefined) end = selectEndDate;
    const datas = await getSettleData(start, end);
    const batchWorkData = await getBatchWorkData(start, end);
    if (datas.data != null) {
      if (batchWorkData.data != null) {
        datas.data.batchWorkNcashMisuAmount = batchWorkData.data * -1;
      }
      if (loginInfo.branch.companyId === selectBranchCompanyId) {
        setAgencyData(() => {
          return datas.data;
        });
      } else {
        setBranchData(() => {
          return datas.data;
        });
      }
    }
    setIsLoading(false);
  };

  const onClickDateTypeButton = async (type) => {
    if (type === 'yesterday') {
      const start = moment().add(-1, 'day').format('YYYY-MM-DD');
      const end = moment().add(-1, 'day').format('YYYY-MM-DD');
      setSelectStartDate(start);
      setSelectEndDate(end);
      // await getData(start, end);
    } else if (type === 'today') {
      setSelectStartDate(today);
      setSelectEndDate(today);
      // await getData(today, today);
    }
  };

  const onChangeBranch = async (value) => {
    setSelectBranchCompanyId(() => {
      return value;
    });
  };

  return (
    <div id={'settlement'}>
      {isLoading && (
        <div className={'spin-overlay'}>
          <Spin className={'spin'} />
        </div>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 20,
          border: '1px solid #c1c1c1',
          borderRadius: 3,
        }}>
        <div className={'selectMonthTable'}>
          <div
            style={{
              borderRight: '1px solid #c1c1c1',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div
              style={{
                backgroundColor: '#eee',
                borderBottom: '1px solid #c1c1c1',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div
                className={'ncl-select-box'}
                style={{
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  padding: 0,
                  marginLeft: 10,
                }}>
                <Select
                  style={{
                    width: '100%',
                    fontSize: 16,
                  }}
                  value={currentYearValue}
                  onChange={(value) => setCurrentYearValue(value)}>
                  {yearSelectCode.map((value) => {
                    return (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            {monthSelectCode.map((item) => {
              return (
                <div
                  key={item.month}
                  style={{
                    width: '100%',
                    backgroundColor:
                      parseInt(currentMonthValue) === item.month
                        ? 'rgba(255, 228, 93, 0.5)'
                        : '#F8F8F8',
                    color: '#000',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #c1c1c1',
                  }}
                  onClick={() => {
                    onChangeMonth(item.month);
                  }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight:
                        parseInt(currentMonthValue) === item.month
                          ? 'bold'
                          : 'normal',
                      fontSize: 16,
                    }}>
                    <div
                      style={{
                        padding: '0 10px',
                        width: '20%',
                        textAlign: 'center',
                      }}>
                      {String(item.month).padStart(2, '0')} 월
                    </div>
                    <div
                      style={{
                        padding: '0 10px',
                        width: '70%',
                        textAlign: 'center',
                      }}>
                      {item.price ? comma(item.price) + '원' : '-'}
                    </div>
                    <div
                      style={{
                        paddingRight: 10,
                        width: '10%',
                        textAlign: 'center',
                      }}>
                      <RightOutlined />
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: '#222',
                border: '1px solid #000',
              }}>
              <div
                style={{
                  color: '#fff',
                  fontSize: 18,
                  padding: '0 10px',
                  width: '20%',
                  textAlign: 'center',
                }}>
                총 합계
              </div>
              <div
                style={{
                  color: '#fff',
                  fontSize: 24,
                  fontWeight: 'bold',
                  padding: '0 10px',
                  width: '80%',
                  textAlign: 'center',
                }}>
                {comma(settleTotal)}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 4, height: '100%', padding: 20 }}>
          <div id={'settlementDetail'}>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginBottom: 10,
              }}>
              <div className={'ncl-select-box'}>
                <Select
                  defaultValue={loginInfo.branch.branchName}
                  style={{
                    width: 240,
                    fontSize: 16,
                  }}
                  onChange={(value) => onChangeBranch(value)}>
                  {belongBranch.map((value) => {
                    return (
                      <Option key={value.idx} value={value.companyId}>
                        {value.branchName}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ fontSize: 16, marginRight: 10, marginLeft: 20 }}>
                {currentYearValue + '년 ' + currentMonthValue + '월'}
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={'ncl-input-box'} style={{ width: 70 }}>
                  <DatePicker
                    style={{ border: 'none', flex: 1 }}
                    onChange={(_, date) => onChangeDate('start', _, date)}
                    value={moment(selectStartDate)}
                    format={'DD'}
                    disabledDate={disabledDate}
                    // minDate={moment(selectEndDate).startOf('month')} // antd version 5부터 지원됨
                  />
                </div>
                <span> ~ </span>
                <div
                  className={'ncl-input-box'}
                  style={{ width: 70, marginLeft: 10 }}>
                  <DatePicker
                    style={{ border: 'none', flex: 1 }}
                    onChange={(_, date) => onChangeDate('end', _, date)}
                    value={moment(selectEndDate)}
                    format={'DD'}
                    disabledDate={disabledDate}
                    // maxDate={moment(selectEndDate).endOf('month')}
                  />
                </div>
              </div>
              {/*<Button
                style={{ height: 40, borderColor: '#c1c1c1', borderRadius: 5 }}
                onClick={() => getData(selectStartDate, selectEndDate)}>
                검색
              </Button>*/}
              <div style={{ marginLeft: 20 }}>
                <Button
                  style={{
                    height: 40,
                    borderColor: '#c1c1c1',
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    ...(moment(selectStartDate).date() ===
                      moment().subtract(1, 'd').date() &&
                      moment(selectEndDate).date() ===
                        moment().subtract(1, 'd').date() && {
                        backgroundColor: '#fddc00',
                      }),
                  }}
                  onClick={() => onClickDateTypeButton('yesterday')}
                  disabled={moment(selectEndDate).isSameOrBefore(
                    moment().format('YYYY-MM-01')
                  )}>
                  전일
                </Button>
                <Button
                  onClick={() => {
                    onClickDateTypeButton('today');
                  }}
                  disabled={moment(selectEndDate).isBefore(
                    moment().format('YYYY-MM-01')
                  )}
                  style={{
                    height: 40,
                    borderColor: '#c1c1c1',
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    ...(moment(selectStartDate).date() === moment().date() &&
                      moment(selectEndDate).date() === moment().date() && {
                        backgroundColor: '#fddc00',
                      }),
                  }}>
                  당일
                </Button>
                <span style={{ marginLeft: 10 }}>
                  <ExclamationCircleOutlined /> 각 항목을 클릭 시 세부 내역을
                  확인하실 수 있습니다.
                </span>
              </div>
            </div>
            {loginInfo.branch.companyId === selectBranchCompanyId ? (
              <AgencyDashboardAgencyTbl data={agencyData} />
            ) : (
              <AgencyDashboardBranchTbl
                data={branchData}
                companyId={selectBranchCompanyId}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgencyDashboard;
