import '../../../css/modal.css';
import closeButtonIcon from '../../../img/close.png';
import React, { useEffect } from 'react';
import { Button, Form, Input, Modal, Radio, Table } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { httpPost, httpUrl } from '../../../api/httpClient';
const RiderGroupDialog = ({ onCloseDialog, data }) => {
  const [form] = Form.useForm();

  const changeFormValue = (field, value) => {
    form.setFieldValue(field, value);
  };

  const onFinishHandler = () => {
    if (!form.isFieldsTouched()) {
      Modal.info({
        title: '변경 실패',
        content: '변경된 내용이 없습니다.',
      });
      return false;
    }
    const url = data ? httpUrl.updateRiderGroup : httpUrl.createRiderGroup;
    const formData = data
      ? { ...form.getFieldsValue(), idx: data.idx }
      : form.getFieldsValue();
    const action = data ? '변경' : '생성';
    httpPost(url, [], formData)
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: `${action} 성공`,
            content: `기사그룹 ${action} 변경에 성공했습니다.`,
          });
          onCloseDialog();
        } else {
          Modal.error({
            title: `변경 실패`,
            content: `기사그룹 ${action} 변경에 실패했습니다.`,
          });
        }
      })
      .catch((e) => {
        Modal.error({
          title: `변경 실패`,
          content: `기사그룹 ${action} 변경에 실패했습니다.`,
        });
      });
  };
  return (
    <>
      <div className="Dialog-overlay" onClick={onCloseDialog} />
      <div className="dialog-container w-[600px]">
        <div className="dialog-header-wrapper">
          <div className="dialog-header-title">기사 그룹관리</div>
          <div>
            <img
              onClick={onCloseDialog}
              src={closeButtonIcon}
              className="dialog-close-icon"
              alt="img"
            />
          </div>
        </div>
        <div className="dialog-content-wrapper">
          <Form form={form} onFinish={onFinishHandler}>
            <div>
              <div className="flex mb-[10px]">
                <div className="w-[100px] leading-[32px]">그룹명</div>
                <FormItem
                  style={{ margin: 0 }}
                  name="settingGroupName"
                  initialValue={data && data.settingGroupName}
                  rules={[
                    {
                      required: true,
                      message: '배달수수료를 입력해주세요.',
                    },
                  ]}>
                  <Input
                    onChange={(e) =>
                      changeFormValue('settingGroupName', e.target.value)
                    }
                  />
                </FormItem>
              </div>
              <div className="flex mb-[10px]">
                <div className="w-[100px] leading-[32px]">배차 제한 건수</div>
                <FormItem
                  style={{ margin: 0 }}
                  name="amountPerOneTime"
                  initialValue={data && data.amountPerOneTime}
                  rules={[
                    {
                      required: true,
                      message: '배달수수료를 입력해주세요.',
                    },
                  ]}>
                  <Input
                    onChange={(e) =>
                      changeFormValue('amountPerOneTime', e.target.value)
                    }
                  />
                </FormItem>
              </div>
              <div className="flex mb-[10px]">
                <div className="w-[100px] leading-[32px]">배달수수료</div>
                <FormItem
                  name="deliveryPriceFeeAmount"
                  initialValue={data && data.deliveryPriceFeeAmount}
                  style={{ margin: 0, paddingRight: '10px' }}
                  rules={[
                    {
                      required: true,
                      message: '배달수수료를 입력해주세요.',
                    },
                  ]}>
                  <Input
                    type="number"
                    onChange={(e) =>
                      changeFormValue('deliveryPriceFeeAmount', e.target.value)
                    }
                  />
                </FormItem>
                <FormItem
                  style={{ margin: 0 }}
                  name="deliveryPriceFeeType"
                  initialValue={data ? data.deliveryPriceFeeType : 0}>
                  <Radio.Group
                    className=""
                    onChange={(e) =>
                      changeFormValue('deliveryPriceFeeType', e.target.value)
                    }>
                    <Radio value={0}>정률</Radio>
                    <Radio value={1}>정액</Radio>
                    <Radio value={2} disabled>
                      일차감
                    </Radio>
                  </Radio.Group>
                </FormItem>
              </div>
              <div className="flex mb-[10px]">
                <div className="w-[100px] leading-[32px]">고용보험료율</div>
                <FormItem
                  name="deliveryPriceInsurance"
                  style={{ margin: 0 }}
                  initialValue={data ? data.deliveryPriceInsurance : 0.5808}>
                  <Input disabled type="number" />
                </FormItem>
              </div>
              <div className="mt-[20px] flex justify-end">
                <Button
                  className={'w-[115px] ant-btn-primary'}
                  htmlType="submit">
                  {!data ? '등록하기' : '수정하기'}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RiderGroupDialog;
