import { Form, Input, Table, Button } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import {
  bikeStatusSearch,
  ownerType,
  insuranceCompanyType,
  bikeSearchType,
  bikeLocationType,
  bikeStatusType,
} from '../../lib/util/codeUtil';
import '../../css/main.css';
import SelectBox from '../../components/input/SelectBox';
import { formatDates } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import { internationalAge } from '../../lib/util/ageUtil';
import RegistBikeDialog from '../../components/dialog/RegistBikeDialog';
import BikeHistoryDialog from '../../components/dialog/BikeHistoryDialog';

const FormItem = Form.Item;
const Search = Input.Search;

class BikeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 30,
      },
      bikeStatusSearch: 0,
      searchValue: '',
      bikeSearchType: 0,
      isRegistBikeOpen: false,
      isModifyBikeOpen: false,
      isBikeHistoryOpen: false,
      selectedRow: [],
      bikeLocation: {},
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    this.getBikeLocation();
  }

  // 바이크 검색
  onSearchBike = (value) => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 30,
        },
        bikeStatusSearch: this.state.bikeStatusSearch,
      },
      () => {
        this.getList();
      }
    );
  };

  getBikeLocation = () => {
    httpPost(httpUrl.bikeLocationInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.map((item) => (bikeLocationType[item.idx] = item.branchName));

        this.setState(
          {
            bikeLocation: bikeLocationType,
          },
          () => {
            console.log('bikeLocation');
            console.log(this.state.bikeLocation);
          }
        );
      }
    });
  };

  getList = () => {
    const pagination = this.state.pagination;
    let bikeSearchType = this.state.bikeSearchType;
    httpGet(
      httpUrl.getBikeList,
      [
        pagination.current,
        pagination.pageSize,
        this.state.bikeStatusSearch,
        bikeSearchType == 1 ? this.state.searchValue : '',
        bikeSearchType == 0 ? this.state.searchValue : '',
      ],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.bikeList,
          pagination: {
            ...this.state.pagination,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  handleTableChange = (pagination) => {
    this.setState(
      {
        pagination,
      },
      () => this.getList()
    );
  };

  nullCheck = (data) => {
    return data === null || data === undefined || data === '' ? '-' : data;
  };

  // 바이크 등록 모달
  openRegistBikeDialog = () => {
    this.setState({ isRegistBikeOpen: true });
  };

  closeRegistBikeDialog = () => {
    this.setState({ isRegistBikeOpen: false }, () => {
      this.getList();
    });
  };

  openModifyBikeDialog = (row) => {
    this.setState({
      isModifyBikeOpen: true,
      selectedRow: row,
    });
  };

  closeModifyBikeDialog = () => {
    this.setState({ isModifyBikeOpen: false }, () => {
      this.getList();
    });
  };

  openBikeHistoryDialog = (row) => {
    this.setState({
      isBikeHistoryOpen: true,
      selectedRow: row,
    });
  };

  closeBikeHistoryDialog = () => {
    this.setState({ isBikeHistoryOpen: false }, () => {
      this.getList();
    });
  };

  onCheckType = (value) => {
    this.setState({ bikeStatusSearch: value }, () => this.getList());
  };

  onSearchType = (value) => {
    this.setState({ bikeSearchType: value });
  };

  render() {
    const columns = [
      {
        title: '차종',
        dataIndex: 'modelName',
        className: 'table-column-center',
      },
      {
        title: '차량번호',
        dataIndex: 'bikeNumber',
        className: 'table-column-center',
      },
      {
        title: '연식',
        dataIndex: 'modelYear',
        className: 'table-column-center',
      },
      {
        title: '차대번호',
        dataIndex: 'viNumber',
        className: 'table-column-center',
      },
      {
        title: '사용지점',
        dataIndex: 'branchName',
        className: 'table-column-center',
        render: (data) => <div> {this.nullCheck(data)} </div>,
      },
      {
        title: '미배정바이크 위치',
        dataIndex: 'unassignBikeLocation',
        className: 'table-column-center',
        width: '6%',
        render: (data) => (
          <div> {this.nullCheck(this.state.bikeLocation[data])} </div>
        ),
      },
      {
        title: '명의자',
        dataIndex: 'owner',
        className: 'table-column-center',
        render: (data) => <div> {ownerType[data]} </div>,
      },
      {
        title: '보험회사',
        dataIndex: 'insuranceCompany',
        className: 'table-column-center',
        render: (data) => (
          <div> {data === null ? '' : insuranceCompanyType[data]} </div>
        ),
      },
      {
        title: '보험나이',
        dataIndex: 'insuranceAge',
        className: 'table-column-center',
        render: (data) => (
          <div> {data === 0 ? '전연령' : '만 ' + data + '세'}</div>
        ),
      },
      {
        title: '만나이',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {bikeStatusType[row.bikeStatus] === '운행중' &&
            data !== null &&
            data !== '*'
              ? '만 ' + internationalAge(data) + '세'
              : '-'}
          </div>
        ),
      },
      {
        title: '보험만기일',
        dataIndex: 'insuranceEndDate',
        className: 'table-column-center',
        render: (data) => formatDates(data),
      },
      {
        title: '렌트비',
        dataIndex: 'rentFee',
        className: 'table-column-center',
        render: (data) => <div>{comma(data) === 0 ? '-' : comma(data)}</div>,
      },
      {
        title: '렌트일차감 면제여부',
        dataIndex: 'bikeNum',
        className: 'table-column-center',
        width: '5%',
        render: (data) => <div> {this.nullCheck(data)} </div>,
      },
      {
        title: '상태',
        dataIndex: 'bikeStatus',
        className: 'table-column-center',
        render: (data) => <div> {bikeStatusSearch[data]} </div>,
      },
      {
        title: '라이더',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {' '}
            {bikeStatusType[row.bikeStatus] === '운행중'
              ? this.nullCheck(data)
              : '-'}{' '}
          </div>
        ),
      },
      {
        title: '비고',
        dataIndex: 'memo',
        className: 'table-column-center',
      },
      {
        title: '히스토리',
        dataIndex: 'bikeHistory',
        className: 'table-column-center',
        width: '3%',
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.openBikeHistoryDialog(row);
              }}>
              보기
            </Button>
          </div>
        ),
      },
      {
        title: '수정',
        dataIndex: 'updateBike',
        className: 'table-column-center',
        width: '3%',
        render: (data, row) => (
          <div>
            <Button
              onClick={() => {
                this.openModifyBikeDialog(row);
              }}>
              수정
            </Button>
          </div>
        ),
      },
    ];

    return (
      <FormItem>
        <SelectBox
          placeholder={'전체'}
          defaultValue={bikeStatusSearch[0]}
          code={Object.keys(bikeStatusSearch)}
          codeString={bikeStatusSearch}
          value={bikeStatusSearch[this.state.bikeStatusSearch]}
          onChange={this.onCheckType}
        />

        <SelectBox
          placeholder={'차종'}
          defaultValue={bikeSearchType[0]}
          code={Object.keys(bikeSearchType)}
          codeString={bikeSearchType}
          value={bikeSearchType[this.state.bikeSearchType]}
          onChange={this.onSearchType}
          style={{
            marginLeft: 30,
          }}
        />

        <Search
          placeholder="검색어를 입력해주세요"
          enterButton
          allowClear
          onChange={(e) => this.setState({ searchValue: e.target.value })}
          onSearch={this.onSearchBike}
          style={{
            width: 250,
            marginBottom: 30,
          }}
        />

        <Button onClick={this.openRegistBikeDialog} style={{ marginLeft: 20 }}>
          바이크 등록
        </Button>

        {this.state.isRegistBikeOpen && (
          <RegistBikeDialog close={this.closeRegistBikeDialog} />
        )}

        {this.state.isModifyBikeOpen && (
          <RegistBikeDialog
            data={this.state.selectedRow}
            close={this.closeModifyBikeDialog}
          />
        )}

        {this.state.isBikeHistoryOpen && (
          <BikeHistoryDialog
            data={this.state.selectedRow}
            close={this.closeBikeHistoryDialog}
          />
        )}

        <Table
          rowKey={(record) => record}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </FormItem>
    );
  }
}

export default BikeList;
