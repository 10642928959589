import React, { Component, useState, useCallback } from 'react';
import { httpGet, httpUrl, httpPost, imageUrl } from '../../api/httpClient';
import { Image, Table, Input, Button, DatePicker, Modal, Checkbox } from 'antd';
import { reactLocalStorage } from 'reactjs-localstorage';
import ModifyCenterDialog from '../../components/dialog/ModifyCenterDialog';
import moment from 'moment';
import {
  formatDate,
  numberFormat,
  startDateFormat,
  endDateFormat,
} from '../../lib/util/dateUtil';
import SelectBox from '../../components/input/SelectBox';
import { deletedStatus } from '../../lib/util/codeUtil';
import { customAlert, customError, updateError } from '../../api/Modals';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class Center extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modifyCenterDialog: false, //수정
      isRegistCenterDialogOpen: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      list: [],
      name: '',
      showContent: 0,
      checkedDeletedCall: 0,
      centerData: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  handleToggleDeletedCall = (e) => {
    this.setState(
      {
        checkedDeletedCall: e.target.checked,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  // 등록 dialog
  openRegistCenterDialogModal = (data) => {
    this.setState({ centerData: data }, () =>
      this.setState({ isRegistCenterDialogOpen: true })
    );
  };

  closeRegistCenterDialogModal = () => {
    this.setState({ isRegistCenterDialogOpen: false });
    this.getList();
  };

  // 수정 dialog
  openModifyCenterDialogModal = (row) => {
    this.setState({
      modifyCenterDialogOpen: true,
      centerData: row,
    });
  };
  closeModifyCenterDialogModal = () => {
    this.setState({ modifyCenterDialogOpen: false });
    this.getList();
  };

  //삭제 알림창
  onDelete = (row) => {
    let self = this;
    if (row.deleted === 0) {
      Modal.confirm({
        title: '지점 삭제',
        content: '해당 지점을 삭제하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.updateCenter, [], {
            deleted: 1,
            idx: row.idx,
          })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert('완료', '해당 지점을 삭제합니다.');
              } else updateError();
              self.getList();
            })
            .catch((error) => {
              customError(
                '삭제오류',
                '에러가 발생하였습니다. 다시 시도해주세요.'
              );
            });
        },
      });
    } else {
      Modal.confirm({
        title: '지점 재등록',
        content: '해당 지점을 재등록하시겠습니까?',
        okText: '확인',
        cancelText: '취소',
        onOk() {
          httpPost(httpUrl.updateCenter, [], {
            deleted: 0,
            idx: row.idx,
          })
            .then((result) => {
              if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                customAlert('완료', '해당 지점을 재등록합니다.');
              } else updateError();
              self.getList();
            })
            .catch((error) => {
              updateError();
            });
        },
      });
    }
  };

  getList = () => {
    let deleted = this.state.checkedDeletedCall ? 1 : 0;
    let name = this.state.name;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    httpGet(httpUrl.centerList, [deleted, name, pageNum, pageSize], {}).then(
      (res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          list: res.data.centerHomepages,
          pagination,
        });
      }
    );
  };

  pressSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => this.getList()
    );
  };

  changeShowContent = (idx) => {
    if (this.state.showContent === idx) {
      this.setState({
        showContent: 0,
      });
    } else
      this.setState({
        showContent: idx,
      });
  };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'name',
        className: 'table-column-center',
      },
      {
        title: '대표사진',
        dataIndex: 'thumbnailIdx',
        className: 'table-column-center',
        render: (data, row) => {
          return (
            <div>
              {data && (
                <Image
                  src={imageUrl(data)}
                  style={{ width: 50, height: 50 }}
                  alt=""
                />
              )}
            </div>
          );
        },
      },
      {
        title: '내부사진',
        dataIndex: 'centerImage',
        className: 'table-column-center',
        width: '20%',
        render: (data, row) => {
          return (
            <div>
              {row.centerFiles.map((noticeFile) => (
                <Image
                  src={imageUrl(noticeFile.uploadFileIdx)}
                  style={{ width: 50, height: 50 }}
                  alt="새소식 사진"
                />
              ))}
            </div>
          );
        },
      },
      {
        title: '주소',
        dataIndex: 'addr',
        className: 'table-column-center',
        width: '15%',
      },
      {
        title: '지점 번호',
        dataIndex: 'tel',
        className: 'table-column-center',
        width: '10%',
      },
      // {
      //     title: "등록일",
      //     dataIndex: "createDate",
      //     className: "table-column-center",
      //     width: "15%",
      //     render: (data) => <div>{moment(data).format("YYYY-MM-DD")}</div>,
      // },
      {
        dataIndex: 'update',
        className: 'table-column-center',
        width: '6%',
        render: (data, row) => (
          <Button onClick={() => this.openModifyCenterDialogModal(row)}>
            수정
          </Button>
        ),
      },
      {
        dataIndex: 'delete',
        className: 'table-column-center',
        width: '6%',
        render: (data, row) => (
          <Button
            onClick={() => {
              this.onDelete(row);
            }}>
            {row.deleted === 0 ? <div>삭제</div> : <div>등록</div>}
          </Button>
        ),
      },
    ];
    return (
      <>
        <Search
          placeholder="지점 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ name: e.target.value })}
          onSearch={this.pressSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />
        <Button
          onClick={this.openRegistCenterDialogModal}
          style={{ marginLeft: 20 }}>
          지점 등록
        </Button>
        <Checkbox
          checked={this.state.checkedDeletedCall ? 'checked' : ''}
          style={{ float: 'right' }}
          onChange={this.handleToggleDeletedCall}>
          삭제목록
        </Checkbox>

        {this.state.isRegistCenterDialogOpen && (
          <ModifyCenterDialog close={this.closeRegistCenterDialogModal} />
        )}

        {this.state.modifyCenterDialogOpen && (
          <ModifyCenterDialog
            data={this.state.centerData}
            close={this.closeModifyCenterDialogModal}
            getList={this.getList}
          />
        )}

        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default Center;
