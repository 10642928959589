import React, { useEffect, useState } from 'react';
import { comma } from '../../lib/util/numberUtil';
import { Checkbox, Input, Select, Table } from 'antd';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { httpGet, httpUrl } from '../../api/httpClient';
import xlsx from 'xlsx';
import { allBranchList } from '../../lib/util/agencyUtil';

const AgencyFrManage = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const [branchList] = useState(allBranchList());
  // const [branchList, setBranchList] = useState([]);
  const [selectBranch, setSelectBranch] = useState([]);
  const [list, setList] = useState([]);
  const [checkType, setCheckType] = useState([1, 3]);
  const [search, setSearch] = useState('');
  const [branchNameForExcel, setBranchNameForExcel] = useState('대리점 전체');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState({
    totalFran: 0,
    totalSubscribe: 0,
    totalUnsubscribe: 0,
  });
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  useEffect(() => {}, []);

  useEffect(() => {
    getData();
  }, [selectBranch, checkType, pagination.current]);

  const checkTab = [
    { label: '사용', value: 1 },
    { label: '탈퇴', value: 3 },
  ];
  const changTab = (value) => {
    setCheckType(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const getData = async (paging) => {
    if (isLoading) return;
    setIsLoading(true);
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let branchIdx = [...selectBranch];
    if (selectBranch.includes(0)) branchIdx = [];
    const params = [
      branchIdx,
      '',
      pagination.pageSize,
      currPage,
      checkType,
      search,
    ];

    const res = await getList(params);
    console.log(res);
    if (res.data) {
      setList(res.data.franchises);
      setTotal({
        totalFran: res.data.totalFran,
        totalSubscribe: res.data.totalSubscribe,
        totalUnsubscribe: res.data.totalUnsubscribe,
      });
      setPagination({
        ...pagination,
         current: currPage,
        total: res.data.totalCount,
      });
    }
    setIsLoading(false);
  };
  const getList = async (params) => {
    return await httpGet(httpUrl.getAgencySearchFrList, params, {});
  };
  const onDownloadExcel = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const params = [selectBranch, '', 0, 0, [1, 3], ''];
    const res = await getList(params);
    if (res.data) {
      const excelJson = parseExcelJson(res.data.franchises);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, '가맹점');
      xlsx.writeFile(wb, `${branchNameForExcel}` + '_가맹점.xlsx');
    }
    setIsLoading(false);
  };
  const parseExcelJson = (data) => {
    let result = [
      {
        userStatus: '상태',
        branchName: '대리점명',
        frName: '가맹점명',
        businessNumber: '사업자번호',
        frPhone: '가맹점번호',
        addr1: '가맹점주소',
        ownerName: '대표자명',
        userEmail: '이메일',
      },
    ];
    data.forEach((item) => {
      result.push({
        userStatus: item.userStatus === 1 ? '사용' : '탈퇴',
        branchName: item.branchName,
        frName: item.frName,
        businessNumber: item.businessNumber,
        frPhone: item.frPhone,
        addr1: item.addr1 ? item.addr1 + item.addr2 : item.addr3 + item.addr2,
        ownerName: item.ownerName,
        userEmail: item.userEmail,
      });
    });
    return result;
  };
  const columns = [
    {
      title: '상태',
      dataIndex: 'userStatus',
      className: 'table-column-center',
      render: (data) => (
        <span style={{ color: data === 1 ? '#1890ff' : '#f00000' }}>
          {data === 1 ? '사용' : '탈퇴'}
        </span>
      ),
    },
    {
      title: '대리점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '가맹점번호',
      dataIndex: 'frPhone',
      className: 'table-column-center',
      render: (data, row) => data.replace(/[^0-9]/g, ''),
    },
    {
      title: '가맹점주소',
      dataIndex: 'addr1',
      className: 'table-column-center',
      render: (data, row) => (data ? data + row.addr2 : row.addr3 + row.addr2),
    },
    {
      title: '대표자명',
      dataIndex: 'ownerName',
      className: 'table-column-center',
    },
    {
      title: '이메일',
      dataIndex: 'userEmail',
      className: 'table-column-center',
    },
  ];
  return (
    <div>
      <h1 className={'agency-page-title'}>가맹점 관리</h1>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-menu-totalsum'}>
          <span style={{ marginRight: 16 }}>
            총 가맹점 수: {comma(total.totalFran)}
          </span>
          <span style={{ color: '#2036FA', marginRight: 16 }}>
            총 사용 수: {comma(total.totalSubscribe)}
          </span>
          <span style={{ color: '#FA2020' }}>
            총 탈퇴 수: {comma(total.totalUnsubscribe)}
          </span>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value, item) => {
              setSelectBranch([value]);
              setBranchNameForExcel(item.name);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}>
            {branchList.map((value) => {
              return (
                <Select.Option
                  key={value.idx}
                  value={value.idx}
                  name={value.branchName}>
                  {value.branchName}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className={'ncl-input-box'} style={{ width: 240 }}>
          <Input
            onPressEnter={() => {getData(true);
            }}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'가맹점명/사업자번호/대표자명'}
            style={{
              border: 'none',
              paddingLeft: 10,
              fontSize: 12,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onClick={() => {
              getData(true);
            }}
          />
        </div>
        <Checkbox.Group
          className={'ncl-checkbox'}
          style={{ marginLeft: 5 }}
          options={checkTab}
          defaultValue={[1, 3]}
          value={checkType}
          onChange={(value) => changTab(value)}
        />
        <div className={'ncl-excel-box'}>
          <div className={'ncl-excel-btn'} onClick={onDownloadExcel}>
            엑셀다운로드
            <VerticalAlignBottomOutlined />
          </div>
        </div>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.userIdx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyFrManage;
