import { Button, Input, Modal } from 'antd';
import React, { useRef } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';

const DeliveryPriceFeeModifyDialog = ({ order, close, refreshList }) => {
  const inputRef = useRef();
  const updateDeliveryPriceFee = async () => {
    const res = await httpPost(httpUrl.updateDeliveryPriceFee, [], {
      orderIdx: order.idx,
      userIdx: order.riderIdx,
      frIdx: order.frIdx,
      deliveryPriceFee: inputRef.current.input.value,
    });

    if (res.data.result === 'SUCCESS') {
      Modal.info({
        title: '변경성공',
        content: res.data.resultMessage,
      });
      await refreshList();
      close();
    } else {
      Modal.warn({
        title: '변경실패',
        content: res.data.resultMessage,
      });
    }
  };
  return (
    <div className="flex-modal-overlay" onClick={() => close()}>
      <div className="flex-wrapper" onClick={(e) => e.stopPropagation()}>
        <div className="flex-header">배달수수료 변경</div>
        <div className="flex-inner">
          <div style={{ display: 'flex' }}>
            <div className="mainText" style={{ width: 150 }}>
              변경금액
            </div>
            <Input
              ref={inputRef}
              type="number"
              placeholder="변경할 금액을 입력해주세요."
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 20,
            }}>
            <Button
              type="primary"
              onClick={async () => await updateDeliveryPriceFee()}>
              변경
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryPriceFeeModifyDialog;
