import { Button, Select, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet, httpUrl } from '../../api/httpClient';
import ManualDwDialog from '../../components/dialog/ManualDwDialog';
import { categoryStatus } from '../../lib/util/codeUtil';

const columns = [
  {
    title: '순번',
    dataIndex: 'idx',
    className: 'table-column-center',
  },
  {
    title: '아이디',
    dataIndex: 'userId',
    className: 'table-column-center',
  },
  {
    title: '카테고리',
    dataIndex: 'category',
    className: 'table-column-center',
    render: (data) => categoryStatus[data],
  },
  {
    title: '메모',
    dataIndex: 'memo',
    className: 'table-column-center',
  },
  {
    title: '변동이전금액',
    dataIndex: 'beforeNcash',
    className: 'table-column-center',
  },
  {
    title: '변동이후금액',
    dataIndex: 'afterNcash',
    className: 'table-column-center',
  },
  {
    title: '입출금액',
    dataIndex: 'ncashDelta',
    className: 'table-column-center',
  },
];

const ManualDw = () => {
  const searchStringRef = useRef();

  const [list, setList] = useState([]);
  const [manualDwDialogOpen, setManualDwDialogOpen] = useState(false);
  const [category, setCategory] = useState('');

  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });

  const getList = async () => {
    console.log('searchStringRef.current.value');

    const res = await httpGet(
      httpUrl.manualDwList,
      [
        pagination.current,
        pagination.pageSize,
        searchStringRef.current.input.value
          ? searchStringRef.current.input.value
          : '',
        category,
      ],
      {}
    );

    console.log(res.data);

    setList(res.data.manualDwList);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  useEffect(async () => {
    await getList();
  }, [pagination.current, category]);

  return (
    <>
      {manualDwDialogOpen && (
        <ManualDwDialog
          getList={getList}
          close={() => setManualDwDialogOpen(false)}
        />
      )}
      <div className="flex flex-row justify-between mb-[20px]">
        <div className="flex flex-col">
          <div className="flex mb-[10px]">
            <Select
              onChange={(value) => {
                setCategory(value);
              }}
              style={{ width: 100 }}
              defaultValue={''}>
              <Select.Option value={''}>전체</Select.Option>
              <Select.Option value={'NCASH_CHARGE_PROC'}>
                입금내역
              </Select.Option>
              <Select.Option value={'WITHDRAW_REQ'}>출금내역</Select.Option>
            </Select>

            <Search
              ref={searchStringRef}
              onSearch={() => getList()}
              placeholder="아이디/가맹점명/라이더명/순번 검색"
              enterButton
              allowClear
              style={{
                width: 220,
                marginLeft: 10,
              }}
            />

            <Button onClick={() => setManualDwDialogOpen(true)}>
              수동입출금
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={(value) => {
          setPagination(value);
        }}
      />
    </>
  );
};

export default ManualDw;
