import { Button, DatePicker, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpUrl } from '../../../api/httpClient';
import '../../../css/main.css';
import {
  paymentMethod,
  paymentStatus,
  rowColorName,
} from '../../../lib/util/codeUtil';
import { accountFormat } from '../../../lib/util/numberUtil';
import CloseIcon from '../../../img/close.png';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class FrTotalDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
      },
      frName: '',
    };
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    const pageNum = this.state.pagination.current;
    const pageSize = this.state.pagination.pageSize;
    const frName = this.state.frName;
    let branchIdx = this.props.data.branchIdx;
    httpGet(
      httpUrl.staticsFrTotal,
      [pageNum, pageSize, frName, branchIdx],
      {}
    ).then((res) => {
      console.log(res.data.duesPayStatistics);
      if (res.result === 'SUCCESS') {
        this.setState({
          list: res.data.duesPayStatistics,
          pagination: {
            total: res.data.totalCount,
            current: res.data.currentPage,
          },
        });
      }
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        frName: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    let { data } = this.props;
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  setRowClassName = (record) => {
    return record.misu !== 0 ? 'table-red' : rowColorName[record.exception];
  };

  render() {
    const { data, close } = this.props;

    const columns = [
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: '15%',
      },
      {
        title: '예외사유',
        dataIndex: 'duesMemo',
        className: 'table-column-center',
        width: '35%',
        render: (data, row) => {
          return <div>{row.exception === 1 ? data : ''}</div>;
        },
      },
    ];

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="frTotal-Dialog">
          <div className="frTotal-content">
            {/* 타이틀 */}
            <div className="fr-title">
              <div className="frTotal-title">
                <span>{data.branchName}의 가맹점</span>
              </div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="frTotal-close"
                alt="close"
              />
            </div>
            <div>
              <Search
                placeholder="가맹점 검색"
                enterButton
                allowClear
                onSearch={this.onSearch}
                style={{
                  width: 220,
                  marginBottom: 20,
                }}
              />
            </div>

            <Table
              rowKey={(record) => record.idx}
              dataSource={this.state.list}
              columns={columns}
              pagination={this.state.pagination}
              onChange={this.handleTableChange}
              rowClassName={(record) => this.setRowClassName(record)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FrTotalDialog;
