import { Button, Form, Input, Modal, Radio } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import CloseIcon from '../../img/close.png';
import SearchFranDialog from './SearchFranDialog';
import SearchRiderDialog from './SearchRiderDialog';

const ManualDwDialog = ({ getList, close }) => {
  const formRef = useRef();
  const handleSubmit = async () => {
    if (formRef.current.getFieldValue('dw') === 1) {
      try {
        const res = await httpPost(httpUrl.manualDeposit, [], {
          ...formRef.current.getFieldsValue(),
        });

        console.log('manualDepositRes');
        console.log(res);

        if (res.data.resultCd === 'SUCCESS') {
          Modal.info({
            title: '입금성공',
            content: res.data.resultMsg,
          });
          await getList();
          close();
        } else {
          Modal.warn({
            title: '입금실패',
            content: '입금 처리에 실패했습니다.',
          });
        }
      } catch (e) {
        Modal.warn({
          title: '입금실패',
          content: '입금 처리에 실패했습니다.',
        });
      }
    } else if (formRef.current.getFieldValue('dw') === 2) {
      try {
        const res = await httpPost(httpUrl.manualWithdraw, [], {
          ...formRef.current.getFieldsValue(),
        });

        console.log('manualDepositRes');
        console.log(res);

        if (res.data.resultCd === 'SUCCESS') {
          Modal.info({
            title: '출금성공',
            content: res.data.resultMsg,
          });
          await getList();
          close();
        } else {
          Modal.warn({
            title: '출금실패',
            content: '출금 처리에 실패했습니다.',
          });
        }
      } catch (e) {
        Modal.warn({
          title: '출금실패',
          content: '출금 처리에 실패했습니다.',
        });
      }
    } else {
      Modal.warn({
        title: '입력값 오류',
        content: '입출금구분을 선택해주세요',
      });
    }
  };

  const [searchRiderOpen, setSearchRiderOpen] = useState(false);
  const [searchFranOpen, setSearchFranOpen] = useState(false);

  const [selctedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    if (selctedUser == null) formRef.current.setFieldsValue({ userIdx: null });
    else formRef.current.setFieldsValue({ userIdx: selctedUser.userIdx });
  }, [selctedUser]);

  return (
    <>
      {searchFranOpen && (
        <SearchFranDialog
          callback={setSelectedUser}
          close={() => setSearchFranOpen(false)}
        />
      )}
      {searchRiderOpen && (
        <SearchRiderDialog
          callback={setSelectedUser}
          close={() => setSearchRiderOpen(false)}
        />
      )}

      <div className="Dialog-overlay" onClick={close} />
      <div className="manualDw-Dialog">
        <div className="systemKey-content">
          <div
            className="systemKey-title"
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            수동입출금
            <img
              style={{ float: 'right' }}
              onClick={close}
              src={CloseIcon}
              className="deposit-close"
              alt="닫기"
            />
          </div>
          <div className="systemKey-inner">
            <Form
              ref={formRef}
              onFinish={handleSubmit}
              className="create-control-form">
              <div className="regist-warp">
                <div className="regist-title2">입출금대상</div>

                <Button onClick={() => setSearchFranOpen(true)}>가맹점</Button>
                <Button onClick={() => setSearchRiderOpen(true)}>라이더</Button>
              </div>

              <div className="regist-warp">
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: '입출금대상을 선택해주세요',
                    },
                  ]}
                  className="create-control-form-item"
                  name={'userIdx'}>
                  <Input type="number" disabled />
                </Form.Item>
              </div>

              <div className="regist-warp">
                <div className="regist-title2">입출금구분</div>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: '입출금구분을 선택해주세요',
                    },
                  ]}
                  className="create-control-form-item"
                  name={'dw'}>
                  <Radio.Group>
                    <Radio value={1}>입금</Radio>
                    <Radio value={2}>출금</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <div className="regist-warp">
                <div className="regist-title2">금액</div>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: '금액을 입력해주세요',
                    },
                  ]}
                  className="create-control-form-item"
                  name={'amount'}>
                  <Input type="number" />
                </Form.Item>
              </div>

              <div className="systemKey-inner">
                * 입력한 금액만큼{' '}
                <span style={{ fontWeight: 'bold' }}>
                  해당 계정에 입금/출금
                </span>{' '}
                됩니다.
                <br />
                <br /> ex) 10000원 입금자 수수료 300원 제외하고 입금처리할때는
                금액을 9700원 입력
              </div>

              <div className="regist-warp">
                <div className="regist-title2">수수료</div>

                <Form.Item
                  className="create-control-form-item"
                  name={'commission'}>
                  <Input type="number" />
                </Form.Item>
              </div>
              <div className="systemKey-inner">
                * 입력한 수수료만큼{' '}
                <span style={{ fontWeight: 'bold' }}>
                  본사계정에서 증가/차감
                </span>
                됩니다.
                <br />
                페이스토리 실제 수수료는{' '}
                <span style={{ fontWeight: 'bold' }}>
                  충전 165원, 출금 110원
                </span>{' '}
                입니다.
                <br />
                카드취소로 출금처리하는 경우 별도 수수료가 나가지 않으니
                0원처리하면 됩니다.
                <br />
                <br /> ex1) 충전수수료 무료상점이 충전한 경우 본사가
                수수료지불하므로 -165 입력 <br />
                ex2) 상점이나 라이더가 수수료 지불한경우 본사 수익이므로 135
                입력 <br />
                ex3) 출금은 무조건 수수료를 떼므로 190 입력
              </div>

              <div className="regist-warp">
                <div className="regist-title2">메모</div>

                <Form.Item className="create-control-form-item" name={'memo'}>
                  <Input />
                </Form.Item>
              </div>

              <div className="registBike-btn" style={{ marginTop: 20 }}>
                <Button type="primary" htmlType="submit">
                  저장
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualDwDialog;
