import React, { Component } from 'react';
import { Form, Radio, Modal, Button, Input } from 'antd';
import { comma } from '../../lib/util/numberUtil';
import { cancelCategory } from '../../lib/util/codeUtil';
import '../../css/main.css';
import { httpPost, httpUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import MisOrderListModal from '../settlement/MisOrderListModal';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;

class CardDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelType: 0,
      openMisOrderListModal: false,
      selectedFran: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  openSearchMisOrderFranModal = () => {
    this.setState({ openMisOrderListModal: true });
  };
  closeSearchMisOrderFranModal = () => {
    this.setState({ openMisOrderListModal: false });
  };

  handleSubmit = () => {
    let { data } = this.props;
    let self = this;

    Modal.confirm({
      title: <div>{self.state.cancelType === 0 ? '취소완료' : '정상결제'}</div>,
      content: (
        <div>
          {self.state.cancelType === 0
            ? '취소완료로 정보를 수정하시겠습니까?'
            : '정상결제로 정보를 수정하시겠습니까?'}
        </div>
      ),
      okText: '확인',
      cancelText: '취소',
      onOk() {
        self.state.cancelType === 0
          ? httpPost(httpUrl.identifyOrderCancel, [], {
              authType: 1,
              idx: data.idx,
              orderIdx: self.state.selectedFran.orderIdx,
              userIdx: self.state.selectedFran.userIdx,
            })
              .then((result) => {
                if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                  customAlert(
                    '취소완료로 수정',
                    '취소완료로 수정이 완료되었습니다.'
                  );
                  self.props.close();
                  self.props.callback();
                } else {
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
                }
              })
              .catch((error) => {
                // if (self.state.startDate === "")
                //   customError("추가 오류", "기간 제한을 입력해 주십시오.");
                // else
                //   customError(
                //     "추가 오류",
                //     "오류가 발생하였습니다. 다시 시도해 주십시오."
                //   );
              })
          : httpPost(httpUrl.identifyOrderCancel, [], {
              authType: 0,
              idx: data.idx,
              orderIdx: self.state.selectedFran.orderIdx,
              userIdx: self.state.selectedFran.userIdx,
            })
              .then((result) => {
                if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
                  customAlert(
                    '정상결제로 수정',
                    '정상결제로 수정이 완료되었습니다.'
                  );
                  self.props.close();
                  self.props.callback();
                } else
                  customError(
                    '추가 오류',
                    '오류가 발생하였습니다. 다시 시도해 주십시오.'
                  );
              })
              .catch((error) => {
                // if (self.state.startDate === "")
                //   customError("추가 오류", "기간 제한을 입력해 주십시오.");
                // else
                //   customError(
                //     "추가 오류",
                //     "오류가 발생하였습니다. 다시 시도해 주십시오."
                //   );
              });
      },
    });
  };

  render() {
    const { data, isOpen, close } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    return (
      <React.Fragment>
        {isOpen ? (
          <React.Fragment>
            <div className="Dialog-overlay" onClick={close} />
            <div className="taskWork-Dialog" style={{ textAlign: 'left' }}>
              <div className="taskWork-content">
                <Form
                  ref={this.formRef}
                  {...formItemLayout}
                  onFinish={this.handleSubmit}>
                  {/* 타이틀 */}
                  <div className="taskWork-title2">
                    <span>카드 결제내역 상세보기</span>
                    <img
                      //style={{marginLeft: "50px"}}
                      onClick={close}
                      style={{ display: 'inline' }}
                      src={CloseIcon}
                      className="taskWork-close"
                      alt="close"
                    />
                  </div>

                  {/* 컨텐츠 */}
                  <div className="taskWork-inner">
                    <div className="taskWork-list">
                      <span className="twl taskWork-list-01 twl-text">
                        주문번호 :
                        <Button onClick={this.openSearchMisOrderFranModal}>
                          {/* {data.orderIdx} */}
                          주문번호 검색
                        </Button>
                        <Input
                          value={
                            this.state.selectedFran
                              ? this.state.selectedFran.orderIdx
                              : ''
                          }
                          className="override-input"
                          placeholder="검색해주세요."
                          disabled
                        />
                      </span>

                      <div
                        className="detail-bg addr mobileoff"
                        // style={{ width: 625 }}
                      >
                        <div className="twl taskWork-list-01 twl-text">
                          고객주소 :
                          <ul className="narrow-line">
                            <li> 도로명 : {data.custAddr1} </li>
                            <li> 지번 : {data.custAddr3} </li>
                            <li> 상세주소 : {data.custAddr2} </li>
                          </ul>
                        </div>
                      </div>
                      <div className="twl taskWork-list-01 twl-text">
                        전화번호 : {data.custPhone}
                      </div>
                      <div
                        className="twl taskWork-list-01 twl-text"
                        // style={{ width: 680 }}
                      >
                        기사이름 :{' '}
                        <div>
                          {data.riderName !== null &&
                            `${data.riderName} ( ${data.riderPhone} )`}
                        </div>
                      </div>
                      <div
                        className="twl taskWork-list-01 twl-text"
                        // style={{ width: 680 }}
                      >
                        결제금액 :{comma(data.price + data.tax)}원
                      </div>

                      <div className="twl taskWork-list-01 twl-text">
                        카드사 :{JSON.parse(data.reserved1).message1}
                      </div>
                      <div className="twl taskWork-list-01 twl-text">
                        카드번호 :{JSON.parse(data.reserved1).cardNo}
                      </div>

                      <div
                        className="twl taskWork-list-01 twl-text"
                        // style={{ width: 680 }}
                      >
                        승인번호 :{JSON.parse(data.reserved1).authNum}
                      </div>

                      <div>
                        {this.state.openMisOrderListModal && (
                          <MisOrderListModal
                            frIdx={data.frIdx}
                            onSelect={(selectedFran) => {
                              this.setState({ selectedFran });
                            }}
                            close={this.closeSearchMisOrderFranModal}
                          />
                        )}
                      </div>

                      <div>
                        <FormItem
                          // style={{ float: "right", marginTop: "-10" }}
                          name="category"
                          className="selectItem2"
                          initialValue={this.state.cancelType === 0 ? 0 : 1}
                          rules={[
                            {
                              required: true,
                              message:
                                '주문번호 검색 후 취소완료 / 정상결제를 선택해주세요.',
                            },
                          ]}>
                          <Radio.Group
                            value={this.state.cancelType}
                            defaultValue={0}
                            onChange={(e) => {
                              this.setState({ cancelType: e.target.value });
                            }}>
                            <Radio value={0}>취소완료</Radio>
                            <Radio value={1}>정상결제</Radio>
                          </Radio.Group>
                        </FormItem>
                      </div>
                    </div>
                  </div>
                  <div className="taskWork-btn-01">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="tabBtn taskWork-btn">
                      {data ? '수정' : ''}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

export default CardDetailModal;
