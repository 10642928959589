import { Button, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { httpPost, httpUrl } from '../../api/httpClient';
import { updateComplete, updateError } from '../../api/Modals';
import CloseIcon from '../../img/close.png';
import { bankCode } from '../../lib/util/codeUtil';

const Option = Select.Option;
const FormItem = Form.Item;

class RiderAccountDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      staffAuth: 1,
      feeManner: 1,
      userGroup: 1,
      riderLevel: null,
      withdrawLimit: 100000,

      // selectedBike: null,
      // selectedRider: null,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // this.getList()
    console.log('넘겨받음!!!!');
    console.log(this.props.data);
  }

  handleSubmit = () => {
    let self = this;
    let { data } = this.props;
    Modal.confirm({
      title: '계좌 수정',
      content: '계좌 정보를 수정하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        if (data) {
          console.log('계좌수정' + JSON.stringify(data, null, 4));
          console.log({
            ...self.formRef.current.getFieldsValue(),
            userIdx: data.userIdx,
            id: data.userId,
            phone: data.riderPhone,
          });
          const formData = self.formRef.current.getFieldsValue();
          httpPost(httpUrl.updateRider, [], {
            ...formData,
            bankAccount: formData.bankAccount.trim(),
            userIdx: data.userIdx,
            id: data.userId,
            phone: data.riderPhone,
          })
            .then((res) => {
              console.log(JSON.stringify(res.data, null, 4));
              if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
                updateComplete();
              } else if (res.data === 'WRONG_ACCOUNT_INFO') {
                Modal.info({
                  title: '등록실패',
                  content: '잘못된 계좌정보입니다.',
                });
                return;
              } else {
                updateError();
                return;
              }
              self.props.close();
            })
            .catch((e) => {
              updateError();
            });
        }
      },
    });
  };

  render() {
    const { close, data } = this.props;
    console.log(data);

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="registRider-Dialog">
          <div className="registRider-content">
            <div className="registRider-title">계좌 수정</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="registRider-close"
              alt="close"
            />

            <Form ref={this.formRef} onFinish={this.handleSubmit}>
              <div className="registRiderLayout">
                <div className="registRiderBox">
                  <div className="registRiderWrapper">
                    <div className="contentBlock">
                      <div className="mainTitle">기사명</div>
                      <FormItem
                        name="riderName"
                        className="selectItem"
                        rules={[
                          {
                            required: true,
                            message: '직원명을 입력해주세요',
                          },
                        ]}
                        initialValue={data ? data.riderName : ''}>
                        <Input
                          placeholder={data.riderName}
                          value={data.riderName}
                          className="override-input"
                          disabled
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">아이디</div>
                      <FormItem
                        name="id"
                        className="selectItem"
                        initialValue={data ? data.id : ''}>
                        <Input
                          placeholder={data.userId}
                          value={data.userId}
                          className="override-input"
                          disabled={data && true}
                        />
                      </FormItem>
                    </div>
                    <div className="contentBlock">
                      <div className="mainTitle">전화번호</div>
                      <FormItem
                        name="phone"
                        className="selectItem"
                        initialValue={data ? data.phone : ''}>
                        <Input
                          placeholder={data.riderPhone}
                          value={data.riderPhone}
                          className="override-input"
                          disabled
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="registRiderWrapper sub">
                    {data ? (
                      <>
                        <div className="contentBlock">
                          <div className="mainTitle">은행</div>
                          <FormItem
                            name="bank"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '은행을 선택해주세요',
                              },
                            ]}
                            initialValue={data ? data.bank : '기업은행,003'}>
                            <Select>
                              {Object.keys(bankCode).map((key) => {
                                return (
                                  <Option value={key + ',' + bankCode[key]}>
                                    {key}
                                  </Option>
                                );
                              })}
                            </Select>
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">계좌번호</div>
                          <FormItem
                            name="bankAccount"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '계좌번호를 입력해주세요',
                              },
                            ]}
                            initialValue={data ? data.bankAccount : ''}>
                            <Input
                              placeholder="계좌번호를 입력해 주세요."
                              className="override-input"
                            />
                          </FormItem>
                        </div>
                        <div className="contentBlock">
                          <div className="mainTitle">예금주</div>
                          <FormItem
                            name="depositor"
                            className="selectItem"
                            rules={[
                              {
                                required: true,
                                message: '예금주를 입력해주세요',
                              },
                            ]}
                            initialValue={data ? data.depositor : ''}>
                            <Input
                              placeholder="예금주를 입력해 주세요."
                              className="override-input"
                            />
                          </FormItem>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="submitBlock">
                      <Button type="primary" htmlType="submit">
                        등록하기
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderAccountDialog;
