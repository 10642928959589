import { Button, DatePicker, Input, Modal, Radio, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import DepositDialog from '../../components/dialog/DepositDialog';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class DepositPrecharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      depositDialogOpen: false, //예치금 지급
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 50000,
      },
      list: [],
      searchText: '',
      hoBalance: 0,
      hoAccountBank: '',
      hoAccountNum: '',
      inputOpen: false,
      preMode: true,
    };
    this.columns = [
      {
        title: '가맹점명',
        dataIndex: 'userName',
        className: 'table-column-center',
      },
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '지급금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '지급일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
      },
    ];
  }

  componentDidMount() {
    // this.getInfo();
    // this.getAccountNum();
    this.getList();
    // this.getExcelList();
  }

  openDepositDialogModal = () => {
    this.setState({ depositDialogOpen: true });
  };
  closeDepositDialogModal = () => {
    this.setState({ depositDialogOpen: false });
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let userId = this.state.searchText;
    let userType = 2;
    let branchIdx = this.props.loginInfo.branchIdx;
    httpGet(
      httpUrl.depositList,
      [branchIdx, pageNum, pageSize, userId, userType],
      {}
    ).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.payments,
        pagination,
      });
    });
  };

  // 본사 계좌, 잔액 정보
  getInfo = () => {
    httpGet(httpUrl.hoInfo, [], {}).then((res) => {
      this.setState({
        hoAccountBank: res.data.vaccountBank
          ? res.data.vaccountBank.split(',')[0]
          : '',
        hoAccountNum: res.data.vaccountNumber,
        hoBalance: res.data.ncash,
      });
    });
  };

  onSearch = (value) => {
    this.setState(
      {
        searchText: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        userId: '아이디',
        ncashDelta: '지급금액',
        createDate: '지급일시',
        memo: '메모',
      },
    ];
    data.forEach((item) => {
      result.push({
        userId: item.userId,
        ncashDelta: item.ncashDelta,
        createDate: item.createDate,
        memo: item.memo,
      });
    });

    return result;
  };

  onDownload = () => {
    let pageNum = this.state.paginationExcel.current;
    let pageSize = this.state.paginationExcel.pageSize;
    let userId = this.state.searchText;
    let userType = 2;
    let branchIdx = this.props.loginInfo.branchIdx;
    httpGet(
      httpUrl.depositList,
      [branchIdx, pageNum, pageSize, userId, userType],
      {}
    ).then((res) => {
      const excelJson = this.parseExcelJson(res.data.payments);
      const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
      const wb = xlsx.utils.book_new();

      ws['!cols'] = [];
      ws['!cols'][0] = { width: 15 };
      ws['!cols'][2] = { width: 20 };
      ws['!cols'][3] = { width: 30 };
      xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
      xlsx.writeFile(wb, '예치금지급.xlsx');
    });
  };

  createDeposit = async (formData, i, failedIdx, failedId) => {
    console.log(`${i} start`);
    try {
      const res = await httpPost(httpUrl.depositSend, [], formData);
      if ((res.result === 'SUCCESS', res.data === 'SUCCESS')) {
      } else {
        failedIdx.push(i + 1);
        failedId.push(formData.id);
        console.log(failedIdx);
      }
    } catch (e) {
      failedIdx.push(i + 1);
      failedId.push(formData.userId);
      console.log(failedIdx);
      // throw e;
    }
    console.log(`${i} done`);
  };

  handleExcelRegist = async () => {
    if (this.state.data) {
      let failedIdx = [];
      let failedId = [];
      for (let i = 0; i < this.state.data.length; i++) {
        const data = this.state.data[i];

        const formData = {
          // // EXCEL 로 받는 데이터
          userId: data['아이디'],
          ncashAmount: data['지급금액(원)'],
          kind: data['구분'],
          ncashMemo: data['메모'],
        };

        console.log(formData);

        await this.createDeposit(formData, i, failedIdx, failedId);
      }
      if (failedIdx.length > 0) {
        Modal.info({
          title: `${failedIdx.length}개의 요청 실패`,
          content: `${failedIdx} 번째 지급이 실패했습니다. \n
            ${failedId}의 지급이 실패했습니다. `,
        });
      } else {
        Modal.info({
          title: '지급 성공',
          content: '모든 예치금이 지급되었습니다.',
        });
      }
    } else {
      Modal.info({
        title: '지급 오류',
        content: '엑셀파일을 등록해주세요.',
      });
    }
  };

  readExcel = async (e) => {
    let self = this;
    let input = e.target;
    let reader = new FileReader();
    reader.onload = () => {
      let data = reader.result;
      let workBook = xlsx.read(data, { type: 'binary' });
      var rows = xlsx.utils.sheet_to_json(
        workBook.Sheets[workBook.SheetNames[0]]
      );
      console.log(rows);
      self.setState({ data: rows });
    };
    if (!input.files[0]) return;
    reader.readAsBinaryString(input.files[0]);
  };

  render() {
    return (
      <>
        <Search
          placeholder="가맹점 검색"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginBottom: 20,
          }}
        />

        {this.state.depositDialogOpen && (
          <DepositDialog
            close={this.closeDepositDialogModal}
            getList={this.getList}
            balance={this.getInfo}
            branchIdx={this.props.loginInfo.branchIdx}
            preMode={this.state.preMode}
          />
        )}
        <Button
          style={{ marginBottom: 20, marginLeft: 20 }}
          onClick={this.openDepositDialogModal}>
          예치금 선충전
        </Button>
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => {
              this.onDownload(this.state.listExcel);
            }}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}

        <a href="/admin_deposit.ods" download>
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={{}}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            양식 다운로드
          </Button>
        </a>
        <Button
          className="download-btn"
          style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
          onClick={() => this.setState({ inputOpen: !this.state.inputOpen })}>
          <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
          엑셀 업로드
        </Button>

        {this.state.inputOpen && (
          <div>
            <div
              className="depositPayment-wrapper"
              style={{ marginTop: '-8px', width: '413px' }}>
              <Input type="file" onChange={this.readExcel} />
              <Button
                style={{ height: '40px' }}
                onClick={() => this.handleExcelRegist()}>
                등록
              </Button>
            </div>
          </div>
        )}

        <Table
          rowKey={(record) => record.idx}
          rowClassName={(record) =>
            record.status === 'COMPLETE' ? 'table-disabled' : ''
          }
          dataSource={this.state.list}
          columns={this.columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.login.loginInfo,
  };
};

export default withRouter(connect(mapStateToProps, null)(DepositPrecharge));
