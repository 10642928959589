import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, DatePicker, Input, Select, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { comma } from '../../lib/util/numberUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import axios from 'axios';
import Const from '../../const';
import {
  agencyOrderSettlementFrColumns,
  agencyOrderSettlementRiderColumns,
  allBranchList,
} from '../../lib/util/agencyUtil';

const AgencyOrderSettlement = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const [branchList] = useState(allBranchList());
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const today = moment().format('YYYY-MM-DD');
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [list, setList] = useState([]);
  const [userType, setUserType] = useState(2);
  const [startDate, setStartDate] = useState(
    moment(today).format('YYYY-MM-01')
  );
  const [endDate, setEndDate] = useState(today);
  const [search, setSearch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectBranchCompanyId, setSelectBranchCompanyId] = useState(
    loginInfo.branch.companyId
  );
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });

  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [pagination.current, selectBranchCompanyId]);

  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };

  const getData = async (paging, uType) => {
    if (isLoading) return;
    setIsLoading(true);
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let usertype = userType;
    if (uType) {
      usertype = uType;
    }
    let url =
      usertype === 1
        ? '/headquarter-dashboard/order-statistics/rider'
        : '/headquarter-dashboard/order-statistics/franchise';

    let companyId = [];
    let paramCompanyId = '';
    // 제휴본사일 경우 소속 대리점 모두 파라미터로
    if (loginInfo.branch.companyId === selectBranchCompanyId) {
      if (Object.keys(agencyBranch).length !== 0) {
        let branch = [...agencyBranch];
        branch.forEach((value, index) => {
          companyId.push(value.companyId);
        });
      } else companyId.push(selectBranchCompanyId);
      paramCompanyId = companyId.join();
    } else paramCompanyId = selectBranchCompanyId;
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: paramCompanyId,
      keyword: search,
      page: currPage,
      size: pagination.pageSize,
    };
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      const res = await axios.get(url, {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
      console.log(res);
      if (res.data) {
        setList(res.data.contents);
        setPagination({
          ...pagination,
          current: currPage,
          total: res.data.totalCount,
        });
      }
    } catch (e) {
      console.error('getting orderSettlement-list is fail: ' + e);
    }
    setIsLoading(false);
  };
  const onChangeUserType = async (value) => {
    if (isLoading) return;
    setUserType(() => {
      return value;
    });
    await getData(true, value);
  };

  return (
    <div>
      <h1 className={'agency-page-title'}>주문통계</h1>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={'대리점 전체'}
            style={{
              flex: 1,
            }}
            onChange={(value) => {
              setSelectBranchCompanyId(value);
              setPagination({
                ...pagination,
                current: 1,
              });
            }}>
            {branchList.map((value) => {
              return (
                <Select.Option key={value.idx} value={value.companyId}>
                  {value.branchName}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
      </div>
      <div className={'ncl-ctg-box'}>
        <div
          onClick={() => onChangeUserType(2)}
          className={userType === 2 ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'}>
          가맹점
        </div>
        <div
          onClick={() => onChangeUserType(1)}
          className={userType === 1 ? 'ncl-ctn-btn active' : 'ncl-ctn-btn'}>
          라이더
        </div>
        <div className={'ncl-input-box'} style={{ width: 240, marginLeft: 10 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'가맹점명/라이더명'}
            style={{
              border: 'none',
              paddingLeft: 10,
              fontSize: 12,
              width: 200,
            }}
          />
          <SearchOutlined
            style={{ padding: '0 10px', cursor: 'pointer' }}
            onClick={() => getData(true)}
          />
        </div>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={
            userType === 2
              ? agencyOrderSettlementFrColumns
              : agencyOrderSettlementRiderColumns
          }
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyOrderSettlement;
