import React, { useRef } from "react";
import CloseIcon from "../../img/close.png";
import { Button, Input, Modal } from "antd";
import { httpPost, httpUrl } from "../../api/httpClient";

const LoanAuraWithdrawDialog = ({ getList, balance, getBalance, close }) => {
  const inputRef = useRef();
  const handleWithdraw = async () => {
    if (inputRef.current.input.value > balance + 300) {
      Modal.warn({
        title: "출금실패",
        content: "예치금 잔액이 부족합니다."
      })
      return;
    }
    const res = await httpPost(
      httpUrl.auraWithdraw,
      [],
      inputRef.current.input.value
    );
    console.log(res);
    if (res.data.resultCd === "0000") {
      Modal.info({
        title:"출금성공",
        content:"출금에 성공했습니다."
      });
      await getList();
      await getBalance();
      close();
    } else {
      Modal.warn({
        title:"출금실패",
        content: `출금에 실패했습니다. 관리자에게 문의하세요. ${res.data.resultMsg}`
      })
    }
  }

  return (<>
    <div className="Dialog-overlay" onClick={close} />
    <div className="deposit-Dialog">
      <div className="deposit-content">
        <div className="deposit-title">예치금 출금</div>
        <img
          onClick={close}
          style={{ display: 'inline' }}
          src={CloseIcon}
          className="dialog-close"
          alt="img"
        />
        <div className="deposit-inner">
          <div className="contentBlock">
            <div className="mainTitle">출금금액</div>
            <div className="formItem">
              <Input
                ref={inputRef}
                controls={false}
                type="number"
                placeholder={'출금을 원하시는 금액을 입력해주세요'}
              />
            </div>
          </div>
          <div style={{display:"flex", justifyContent:"center", alignItems:"center", marginTop: 20}}>
            <Button onClick={() => handleWithdraw()} type={"primary"}>출금요청</Button>
          </div>
        </div>
      </div>
    </div>
  </>);
};


export default LoanAuraWithdrawDialog;
