import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Select, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { SearchOutlined } from '@ant-design/icons';
import { httpGet, httpUrl } from '../../api/httpClient';
const AgencySearchFrDialog = (props) => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const [search, setSearch] = useState('');
  const [list, setList] = useState([]);
  const [selectBranch, setSelectBranch] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 5,
  });
  const getData = async (page) => {
    let current = pagination.current;
    if (page > 0) current = page;
    const res = await httpGet(
      httpUrl.getAgencySearchFrList,
      [selectBranch, search, pagination.pageSize, current],
      {}
    );
    if (res.data) {
      setList(res.data.franchises);
      setPagination({
        ...pagination,
        current: current,
        total: res.data.totalCount,
      });
    }
  };
  useEffect(() => {
    if (agencyBranch.length > 0) {
      let insert = {
        idx: '',
        branchName: '대리점 전체',
        companyId: 'All',
      };
      let arrB = [...agencyBranch];
      arrB.unshift(insert);
      setBranchList(arrB);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [selectBranch]);

  const onSelect = (data) => {
    if (props.callback) {
      props.callback(data);
    }
    props.close();
  };

  const columns = [
    {
      title: '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
      render: (data, row) => (
        <div style={{ cursor: 'pointer' }} onClick={() => onSelect(row)}>
          {data}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="Dialog-overlay" onClick={props.close} />
      <div className="agency-batchwork-Dialog">
        <div className="agency-batchwork-content" style={{ width: 600 }}>
          <div className="agency-batchwork-title">
            <h1>가맹점 조회</h1>
            <Button
              onClick={props.close}
              icon={<CloseOutlined style={{ fontSize: 24 }} />}
              type="text"
              shape="circle"
            />
          </div>
          <div className={'ncl-menu-wrap'} style={{ marginTop: 16 }}>
            <div className={'ncl-select-box'}>
              <Select
                defaultValue={'대리점 전체'}
                style={{
                  flex: 1,
                }}
                onChange={(value, item) => {
                  setSelectBranch([value]);
                }}>
                {branchList.map((value) => {
                  return (
                    <Select.Option
                      key={value.idx}
                      value={value.idx}
                      name={value.branchName}>
                      {value.branchName}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className={'ncl-input-box'} style={{ width: 300 }}>
              <Input
                onPressEnter={()=> {
                  getData(1);
                }}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={'가맹점명을 검색해주세요'}
                style={{
                  border: 'none',
                  paddingLeft: 10,
                  width: 260,
                }}
              />
              <SearchOutlined
                style={{ padding: '0 10px', cursor: 'pointer' }}
              />
            </div>
          </div>
          <div className={'ncl-table'}>
            <Table
              rowKey={(record) => record.idx}
              dataSource={list}
              columns={columns}
              pagination={pagination}
              rowClassName={(record, index) =>
                index % 2 !== 0 && 'ncl-table-row'
              }
              onChange={(pagination) =>
                getData(pagination.current)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AgencySearchFrDialog;
