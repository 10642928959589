import { Button, Form, Input, Modal, Select } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { httpPost, httpUrl, makeUrl } from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import '../../css/main.css';
import { apiResponseCode } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class ModifyNoticeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 1,
      },
      noticeImage: '',
      thumbnail: null,
      category: 1,
      deleted: 0,
      uploadFileIdxList: [],

      noticeFiles: [],
      noticeFilesUrls: [],
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    // this.getList()
    if (this.props.data) {
      console.log(this.props.data);
      this.setState({
        noticeFiles: this.props.data.uploadFiles,
      });
    }
  }

  handleSubmit = () => {
    let self = this;
    let { data } = this.props;
    console.log(
      'asdfasdfaasdfsa ' +
        JSON.stringify(
          {
            ...self.formRef.current.getFieldsValue(),
            category: self.state.category,
            deleted: self.state.deleted,
          },
          null,
          4
        )
    );
    console.log(data);
    Modal.confirm({
      title: <div> {data ? '공지 수정' : '공지 등록'} </div>,
      content: (
        <div>
          {' '}
          {data
            ? '공지사항을 수정하시겠습니까?'
            : '새 공지사항을 등록하시겠습니까?'}{' '}
        </div>
      ),
      okText: '확인',
      cancelText: '취소',
      async onOk() {
        try {
          let failedFiles = [];
          let thumbnail;

          if (self.state.thumbnail) {
            const thumbFile = self.state.thumbnail;
            const thumbFileUpload = await self.handleThumbnail(thumbFile);
            console.log('fileUploadRes');
            console.log(thumbFileUpload);
            thumbnail = self.state.thumbnailIdx;
            console.log('ASdfasdfasdfsadasasfasf' + thumbnail);
          }
          if (self.state.noticeFiles.length > 0) {
            for (let i = 0; i < self.state.noticeFiles.length; i++) {
              const file = self.state.noticeFiles[i];
              const fileUploadRes = await self.handleFileUpload(file);
              console.log('fileUploadRes');
              console.log(fileUploadRes);
              if (!fileUploadRes.data.data.result) failedFiles.push(file);
            }
          }

          if (failedFiles.length > 0) {
            Modal.warn({
              title: '파일 업로드 실패',
              content: `${failedFiles.map(
                (file) => file.name
              )} 파일 업로드에 실패했습니다.`,
            });
            return;
          }

          const submitRes = data
            ? await httpPost(httpUrl.updateNotice, [], {
                ...self.formRef.current.getFieldsValue(),
                idx: data.idx,
                uploadFileIdx: self.state.uploadFileIdxList,
                thumbnailIdx: thumbnail,
              })
            : await httpPost(httpUrl.registNotice, [], {
                ...self.formRef.current.getFieldsValue(),
                category: self.state.category,
                deleted: self.state.deleted,
                uploadFileIdx: self.state.uploadFileIdxList,
                thumbnailIdx: thumbnail,
              });

          if (submitRes.data === apiResponseCode.SUCCESS) {
            customAlert(
              '완료',
              `공지사항이 ${data ? '수정' : '등록'}되었습니다.`
            );
            self.props.close();
          } else
            customError(
              '실패',
              `공지사항 ${data ? '수정' : '등록'}에 실패했습니다.`
            );
        } catch (e) {
          console.error(e);
        }
      },
    });
  };

  handleFileUpload = async (file) => {
    if (!file) return;
    if (file.idx) {
      this.setState({
        uploadFileIdxList: this.state.uploadFileIdxList.concat(file.idx),
      });
      return {
        result: 'SUCCESS',
        data: {
          data: { result: true },
        },
      };
    }
    // for (let i = 0; i < file.length; i++) {
    var formData = new FormData();
    formData.append('file', file);
    console.log(file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      this.setState({
        uploadFileIdxList: this.state.uploadFileIdxList.concat(
          res.data.data.idx
        ),
      });
      return res;
    } else {
      // Modal.warn({
      //   title: "파일 업로드 실패",
      //   content: "파일 업로드에 실패했습니다. 관리자에게 문의하세요.",
      // });
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  handleThumbnail = async (file) => {
    if (!file) return;
    if (file.idx) {
      this.setState({
        thumbnailIdx: file.idx,
      });
      return;
    }
    // for (let i = 0; i < file.length; i++) {
    var formData = new FormData();
    formData.append('file', file);
    console.log(file);

    const res = await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
      withCredentials: true,
    });
    if (
      res.status === 200 &&
      res.data.result === 'SUCCESS' &&
      res.data.data.result
    ) {
      this.setState({
        thumbnailIdx: res.data.data.idx,
      });
      return res;
    } else {
      // Modal.warn({
      //   title: "파일 업로드 실패",
      //   content: "파일 업로드에 실패했습니다. 관리자에게 문의하세요.",
      // });
      return {
        result: 'SUCCESS',
        data: {
          data: false,
        },
      };
    }
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        {' '}
        <div className="Dialog-overlay" onClick={close} />
        <div className="modifyfran-Dialog">
          <div className="modifyfran-content">
            <div className="modifyfran-title">
              {data ? ' 공지 수정' : ' 공지 등록'}
            </div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
            <div className="modifyfran-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="modifyfran-innerbox">
                  <div className="contentBlock">
                    <div className="mainTitle">제목</div>
                    <div className="formItem">
                      <FormItem
                        name="title"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[
                          {
                            required: true,
                            message: '제목을 입력해주세요.',
                          },
                        ]}
                        initialValue={data && data.title}>
                        <Input
                          placeholder="제목을 입력하세요"
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">대표사진</div>

                    <div className="formItem">
                      <FormItem
                        name="thumbnailIdx"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}>
                        <Input
                          style={{ height: 38, width: 300 }}
                          className="override-input"
                          type="file"
                          accept="image/*"
                          onChange={(e) =>
                            this.setState(
                              {
                                thumbnail: e.target.files[0],
                              },
                              () => console.log(this.state.thumbnail)
                            )
                          }
                        />
                      </FormItem>
                      <div className="fileName">
                        {data && (
                          <div
                            style={{
                              color: 'red',
                              marginTop: '-15px',
                            }}>
                            {' '}
                            *수정할 시에만 파일을 선택해주세요.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">사진</div>

                    <div className="formItem">
                      <FormItem
                        name="noticeImage"
                        enctype="multipart/form-data"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}>
                        <Input
                          style={{ height: 38, width: 300 }}
                          className="override-input"
                          type="file"
                          name="fileName"
                          accept="image/*"
                          multiple
                          onChange={(e) => {
                            let newNoticeFiles = [];
                            // let newNoticeFilesUrls = [];
                            for (let i = 0; i < e.target.files.length; i++) {
                              newNoticeFiles.push(e.target.files.item(i));
                              // newNoticeFilesUrls.push({
                              //   // url: URL.createObjectURL(
                              //   //   e.target.files.item(i)
                              //   // ),
                              //   fileName: e.target.files.item(i).name,
                              // });
                            }
                            this.setState(
                              {
                                noticeFiles: newNoticeFiles,
                                // noticeFilesUrls: newNoticeFilesUrls,
                              },
                              () => console.log(this.state.noticeFiles)
                            );
                            // this.handleFileUpload(
                            //   e.target.files,
                            //   "noticeImage"
                            // );
                          }}
                        />
                      </FormItem>

                      <div className="fileName">
                        <div style={{ color: 'red', marginTop: '-15px' }}>
                          {' '}
                          *여러사진을 첨부할 경우 한번에 선택해주세요.
                        </div>
                        {this.state.noticeFiles.map((file) => {
                          return (
                            <>
                              <div>
                                <div className="circle" />
                                {file.name}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">내용</div>
                    <div className="formItem">
                      <FormItem
                        name="content"
                        className="selectItem"
                        style={{
                          marginLeft: 20,
                        }}
                        rules={[
                          {
                            required: true,
                            message: '내용을 입력해주세요.',
                          },
                        ]}
                        initialValue={data && data.content}>
                        <Input.TextArea
                          placeholder="내용을 입력하세요"
                          style={{ height: 200, width: 300 }}
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">노출순위</div>
                    <div className="formItem">
                      <FormItem
                        name="sortOrder"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[
                          {
                            required: true,
                            message: '노출순위를 입력해주세요.',
                          },
                        ]}
                        initialValue={data && data.sortOrder}>
                        <Input
                          placeholder="숫자가 클수록 위쪽에 공지됩니다."
                          className="override-input"
                          style={{ width: 300 }}
                        />
                      </FormItem>
                    </div>
                  </div>
                </div>
                <Button
                  style={{ float: 'right', marginTop: 10 }}
                  htmlType="submit">
                  저장하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModifyNoticeDialog;
