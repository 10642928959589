import { reactLocalStorage } from 'reactjs-localstorage';
import { LOGIN, LOGOUT } from '../actions/loginAction';
import con from '../const';

const loginInitialState = {
  isLogin: false,
  loginInfo: {
    createDate: '',
    idx: 0,
    ipAddress: '',
    mobile: '',
    name: '',
    password: '',
    superAdmin: 0,
    userId: '',
    authList: [],
    adminType: 0,
    otpKey: '',
    otpUrl: '',
    otpVerified: 0,
    branch: {},
  },
};

const login = (state = loginInitialState, action) => {
  // console.log(action.type);
  // console.log(action);
  switch (action.type) {
    case LOGIN:
      reactLocalStorage.setObject(con.appName + '#adminUser', action.loginInfo);
      return Object.assign({}, state, {
        isLogin: true,
        loginInfo: action.loginInfo,
      });
    case LOGOUT:
      reactLocalStorage.remove(con.appName + '#adminUser');
      //유저가 제휴본사일때 저장해둔 대리점 브랜치들 로그아웃시 제거
      reactLocalStorage.remove(con.appName + '#agencyBranch');
      return Object.assign({}, state, {
        isLogin: false,
        loginInfo: {
          createDate: '',
          idx: 0,
          ipAddress: '',
          mobile: '',
          name: '',
          password: '',
          superAdmin: 0,
          userId: '',
          authList: [],
          adminType: 0,
          otpKey: '',
          otpUrl: '',
          otpVerified: 0,
        },
      });
    default:
      return state;
  }
};

export default login;
