import { Button, DatePicker, Image, Input, Modal, Select, Table } from 'antd';
import locale from "antd/es/date-picker/locale/ko_KR";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {httpGet, httpPost, httpUrl, imageUrl, makeUrl} from '../../api/httpClient';
import ModifyFranDialog from '../../components/dialog/ModifyFranDialog';
import {
  apiResponseCode,
  dwServiceTypeCode,
  mapPaystoryVacntResultCd
} from '../../lib/util/codeUtil';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

const RegistVANList = () => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
    frName: '',
  });
  const [modifyFranDialogOpen, setModifyFranDialogOpen] = useState(false)
  const [franchiseData, setFranchiseData] = useState([])
  const [branchName, setBranchName] = useState("")
  const [frName, setFrName] = useState("")
  const [businessNumber, setBusinessNumber] = useState("")
  const [status, setStatus] = useState(null)
  const [startDate, setStartDate] = useState(moment().add(-1, "M").format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const getList = async () => {
    const res = await httpGet(
      httpUrl.getRegistVANList,
      [pagination.current, pagination.pageSize, branchName, frName, businessNumber, status, startDate, moment(endDate).add(1, "d").format("YYYY-MM-DD")],
      {}
    );
    if (res.result !== apiResponseCode.SUCCESS) {
      Modal.info({
        title: '요청 실패',
        content: '요청에 실패했습니다. 관리자에게 문의하세요.',
      });
      return;
    }
    console.log(res.data.vans);
    setList(res.data.vans);
    setPagination({
      ...pagination,
      total: res.data.totalCount,
    });
  };

  const handleTableChange = async (nextPagination) => {
    const pager = {
      ...pagination,
    };
    pager.current = nextPagination.current;
    pager.pageSize = nextPagination.pageSize;
    setPagination(pager);
  };

  const mapPaystoryVacnt = (fr) => {
    console.log(fr);
    Modal.confirm({
      title: '가상계좌발급',
      content: `${fr.frName} 가맹점에 가상계좌를 발급하시겠습니까?`,
      onOk: async () => {
        const res = await httpPost(httpUrl.mapPaystoryVacnt, [fr.userIdx], {});

        console.log(res);

        if (res.data.result !== 'SUCCESS') {
          Modal.warn({
            title: '가상계좌 발급 실패',
            content: mapPaystoryVacntResultCd[res.data.result],
          });
        } else {
          Modal.info({
            title: '가상계좌 발급 성공',
            content: mapPaystoryVacntResultCd[res.data.result],
            afterClose: () => {
              console.log('result info afterClose');
            },
          });
        }
        getList();
      },
    });
  };

  const onSearchFranchise = (value) => {
    setPagination({...pagination, current: 1})
    setFrName(value)
  };

  const onSearchBranch = (value) => {
    setPagination({...pagination, current: 1})
    setBranchName(value)
  };

  const onSearchBusinessNumber = (value) => {
    setPagination({...pagination, current: 1})
    setBusinessNumber(value)
  };

  // 수정 dialog
  const openModifyFranDialogModal = (row) => {
    setModifyFranDialogOpen(true)
    setFranchiseData(row)
  };
  const closeModifyFranDialogModal = () => {
    setModifyFranDialogOpen(false)
  };

  useEffect(() =>{
    getList()
  }, [pagination.current, frName, branchName, businessNumber, status, startDate, endDate]);

  const columns = [
    {
      title: '상태',
      className: 'table-column-center',
      render: (row) => <div>{row.frVAccountNumber && row.tidNormal ? '완료' : '신청'}</div>
    },
    {
      title: '신청일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      render: (data) => <div>{moment(data).format('YYYY-MM-DD HH:mm')}</div>
    },
    {
      title: '지점명',
      dataIndex: 'branchName',
      className: 'table-column-center',
    },
    {
      title: '가맹점명',
      dataIndex: 'frName',
      className: 'table-column-center',
    },
    {
      title: '가맹점번호',
      dataIndex: 'frPhone',
      className: 'table-column-center',
      render: (data) => <div>{data ? data : '-'}</div>,
    },
    {
      title: '사업자번호',
      dataIndex: 'businessNumber',
      className: 'table-column-center',
    },
    {
      title: '가맹점주소',
      // dataIndex: "franAddr",
      className: 'table-column-center',
      render: (data, row) => <div>{row.addr1 + ' ' + row.addr2}</div>,
    },
    {
      title: '이메일',
      dataIndex: "userEmail",
      className: 'table-column-center',
    },
    {
      title: '휴대폰번호',
      dataIndex: "userPhone",
      className: 'table-column-center',
    },
    {
      title: '사업자등록증',
      dataIndex: 'userIdx',
      className: 'table-column-center',
      render: (userIdx) =>
        userIdx ? <Image src={makeUrl(
            httpUrl.vanFileView,
            [userIdx],
            {}
        )} alt="사업자등록증" style={{width: 50, height: 50}} /> : '-',
    },
    {
      title: '생년월일',
      dataIndex: "birthday",
      className: 'table-column-center',
      render: (data, row) => <div style={{whiteSpace: "pre-line"}}>{data || ""}</div>
    },
    {
      title: '수정',
      dataIndex: 'update',
      className: 'table-column-center',
      render: (data, row) => (
        <Button onClick={() => openModifyFranDialogModal(row)}>
          수정
        </Button>
      ),
    },
    {
      title: '가상계좌발급',
      dataIndex: 'frVAccountNumber',
      className: 'table-column-center',
      render: (data, row) => {
        if (row.dwServiceType === dwServiceTypeCode.KWANGWON)
          return <div>광원 이용지점</div>;

        return data ? (
          <div>{`${row.frVAccountBank} / ${data}`}</div>
        ) : (
          <Button
            onClick={() => {
              mapPaystoryVacnt(row);
            }}>
            가상계좌발급
          </Button>
        );
      },
    },
  ];
  const expandedRowRender = (record) => {
    const dropColumns = [
      {
        title: '사업자등록증',
        dataIndex: 'businessRegistration',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="사업자등록증" style={{width: 50, height: 50}} /> : '-',
      },
      // {
      //   title: '대표자신분증',
      //   dataIndex: 'representativeRegistrationNumber',
      //   className: 'table-column-center',
      //   render: (fileIdx) =>
      //     fileIdx ? <Image src={imageUrl(fileIdx)} alt="대표자신분증" style={{width: 50, height: 50}} /> : '-',
      // },
      // {
      //   title: '통장사본',
      //   dataIndex: 'accountCopy',
      //   className: 'table-column-center',
      //   render: (fileIdx) =>
      //     fileIdx ? <Image src={imageUrl(fileIdx)} alt="통장사본" style={{width: 50, height: 50}} /> : '-',
      // },
      {
        title: '영업신고증',
        dataIndex: 'businessCertification',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="영업신고증" /> : '-',
      },
      {
        title: '매장외부',
        dataIndex: 'outsidePhoto',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="매장외부" /> : '-',
      },
      {
        title: '매장내부',
        dataIndex: 'insidePhoto',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="매장내부" /> : '-',
      },

      {
        title: '법인등기부등본',
        dataIndex: 'businessCertification',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? (
            <Image src={imageUrl(fileIdx)} alt="법인등기부등본" />
          ) : (
            '-'
          ),
      },

      {
        title: '법인인감증명서',
        dataIndex: 'businessCertification',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? (
            <Image src={imageUrl(fileIdx)} alt="법인인감증명서" />
          ) : (
            '-'
          ),
      },

      {
        title: '주주명부',
        dataIndex: 'shareholderRegister',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="주주명부" /> : '-',
      },

      {
        title: '사용인감계',
        dataIndex: 'useSeal',
        className: 'table-column-center',
        render: (fileIdx) =>
          fileIdx ? <Image src={imageUrl(fileIdx)} alt="사용인감계" /> : '-',
      },
    ];
    return (
      <Table
        className="subTable"
        rowKey={(record) => `expandedRow: ${record.idx}`}
        columns={dropColumns}
        dataSource={[record]}
        pagination={false}
      />
    );
  };

  return (
    <>
      <Select
        onChange={(value) => {
          setPagination({...pagination, current: 1});
          setStatus(value)
        }}
        defaultValue={null}
      >
        {[{idx: null, label: "전체"}, {idx: 0, label: "신청"}, {idx: 1, label: "완료"}].map((item) => {
          return (
            <Select.Option value={item.idx}>
              {item.label}
            </Select.Option>
          );
        })}
      </Select>
      <Search
        placeholder="지점명 검색"
        enterButton
        allowClear
        onSearch={onSearchBranch}
        style={{
          width: 220,
          marginLeft: 20,
        }}
      />
      <Search
        placeholder="가맹점 검색"
        enterButton
        allowClear
        onSearch={onSearchFranchise}
        style={{
          width: 220,
          marginLeft: 20,
          marginBottom: 20,
        }}
      />
      <Search
        placeholder="사업자번호 검색"
        enterButton
        allowClear
        onSearch={onSearchBusinessNumber}
        style={{
          width: 220,
          marginLeft: 20,
        }}
      />

      <RangePicker
        locale={locale}
        style={{ width: 300, float: 'right', marginRight: 10 }}
        placeholder={['시작일', '종료일']}
        format={"YYYY-MM-DD"}
        value={[
          moment(startDate, "YYYY-MM-DD"),
          moment(endDate, "YYYY-MM-DD"),
        ]}
        onChange={(_, dateStrings) => {
          setStartDate(dateStrings[0])
          setEndDate(dateStrings[1])
          setPagination({...pagination, current: 1})
        }}
      />
      {modifyFranDialogOpen && (
        <ModifyFranDialog
          data={franchiseData}
          close={closeModifyFranDialogModal}
          getList={getList}
        />
      )}
      <Table
        rowKey={(record) => record.idx}
        rowClassName={(record) =>
          record.frVAccountNumber && record.tidNormal ? 'table-disabled' : ''
        }
        dataSource={list}
        columns={columns}
        // expandedRowRender={(record) => expandedRowRender(record)}
        // expandRowByClick={true}
        pagination={pagination}
        onChange={(nextPagination) => {
          handleTableChange(nextPagination);
        }}
      />
    </>
  );
};

export default RegistVANList;
