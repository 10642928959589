import { AutoComplete, Input, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { statusString } from '../../lib/util/codeUtil';
import CloseIcon from '../../img/close.png';
import debouncedCallback from '../../lib/util/debouncedCallback';

const Search = Input.Search;
const today = new Date();

class SearchRiderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: today,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },
      branchList: [],
      options: [],
      branchIdx: '',

      dataIdxs: [],
      riderName: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList(true);
    this.getAllBranchList();
  }

  // 라이더 검색
  onSearchRiders = (value) => {
    this.setState(
      {
        riderName: value,
        pagination: {
          total: 0,
          current: 1,
          pageSize: 5,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let riderName = this.state.riderName;
    let riderPhone = '';
    let branchIdx =
      this.state.branchIdx !== '' ? this.state.branchIdx : this.props.branchIdx;
    httpGet(
      httpUrl.riderList,
      [branchIdx, riderName, riderPhone, pageNum, pageSize],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.riders,
        pagination: pagination,
      });
    });
  };

  getAllBranchList = () => {
    httpGet(httpUrl.getBranchListAll, []).then((res) => {
      console.log(res.data);
      this.setState({
        branchList: res.data,
      });
      if (res?.data != null) {
        this.resetBranchListOptions(res?.data);
      }
    });
  };

  resetBranchListOptions = (branchList) => {
    this.setState(
      {
        options: branchList.map((branch) => {
          return { value: branch.branchName, key: branch.idx };
        }),
        branchIdx: '',
      },
      () => {
        this.getList();
      }
    );
  };

  onSubmit = () => {
    // console.log("click")
    if (this.props.callback) {
      this.props.callback(this.state.selectedRowKeys);
    }
    this.props.close();
  };

  onSelect = (data) => {
    // console.log(data)
    if (this.props.callback) {
      this.props.callback(data);
    }
    this.props.close();
  };

  onBranchSearch = (searchText) => {
    if (searchText) {
      this.setState({
        options: this.state.branchList
          .filter((branch) => branch.branchName.includes(searchText))
          .map((b) => {
            return {
              value: b.branchName,
              key: b.idx,
            };
          }),
      });
    } else {
      this.resetBranchListOptions(this.state.branchList);
    }
  };

  debouncedBranchSearch = debouncedCallback(this.onBranchSearch, 200);

  render() {
    const columns = [
      {
        title: '순번',
        dataIndex: 'userIdx',
        className: 'table-column-center',
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        render: (data, row) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (this.props.onSelect) {
                this.props.onSelect(row);
              }
              this.onSelect(row);
            }}>
            {data}
          </div>
        ),
      },
      {
        title: '상태',
        dataIndex: 'userStatus',
        className: 'table-column-center',
        render: (data, row) => <div>{statusString[data]}</div>,
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">라이더 조회</div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              {this.props.msgObject && (
                <this.props.msgObject
                  searchCategory={this.props.searchCategory}
                  setSearchCategory={this.props.setSearchCategory}
                />
              )}
              <Search
                placeholder="라이더명 검색"
                className="searchRider-Input"
                enterButton
                allowClear
                onSearch={this.onSearchRiders}
              />
              <AutoComplete
                options={this.state.options}
                style={{
                  width: 220,
                  marginLeft: 10,
                }}
                onSelect={(data, option) => {
                  console.log(option);
                  this.setState(
                    {
                      branchIdx: option.key,
                      pagination: {
                        total: 0,
                        current: 1,
                        pageSize: 5,
                      },
                    },
                    () => {
                      this.getList();
                    }
                  );
                }}
                onSearch={this.debouncedBranchSearch}>
                <Input.Search placeholder="지점명 검색" enterButton />
              </AutoComplete>
              <Table
                className="searchRider-table"
                rowKey={(record) => record.idx}
                rowClassName={(record) =>
                  record.userStatus === 1 ? '' : 'disabled-row'
                }
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};

export default connect(mapStateToProps, null)(SearchRiderDialog);
