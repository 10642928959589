import React, { Component } from 'react';
import { Form, Input, DatePicker, Button, Modal, Select, Checkbox } from 'antd';
import '../../css/main.css';
import { connect } from 'react-redux';
import { httpUrl, httpPost, httpGet } from '../../api/httpClient';
import SearchRiderDialog from '../../components/dialog/SearchRiderDialog';
import moment from 'moment';
import CloseIcon from '../../img/close.png';

const Option = Select.Option;
const FormItem = Form.Item;
const today = moment().format('YYYY-MM-DD');

class ModifyDuesSettingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      branchDataCurrent: 0,
      branchDataEnd: false,
      checked: this.props.data.dues === -1 ? true : false,
      admin: this.props.branchIdx !== 1 ? true : false,
      recommender: this.props.data.recommender || '',
      recommenderIdx: this.props.data.recommenderIdx || '',
      chargeDate: this.props.data.chargeDate || '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      checked: !this.state.checked,
    });
  };
  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };
  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };
  selectedRider = (rider) => {
    this.setState({
      recommender: rider.riderName,
      recommenderIdx: rider.userIdx,
    });
  };

  onChangeDate = (value) => {
    if (value < 13) {
      this.setState({
        chargeDate: moment(this.props.data.registDate).add(value, 'M'),
      });
    } else {
      this.setState({
        chargeDate: moment('2999-01-01'),
      });
    }
  };

  handleSubmit = () => {
    const duesResult = this.state.checked
      ? -1
      : this.formRef.current.getFieldsValue().dues;
    httpPost(httpUrl.updateFranchise, [], {
      ...this.formRef.current.getFieldsValue(),
      addr2: this.props.data.addr2,
      recommenderIdx: this.state.recommenderIdx,
      dues: duesResult,
      userIdx: this.props.data.userIdx,
      chargeDate: moment(this.state.chargeDate).format('YYYY-MM-DD'),
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          Modal.info({
            title: '변경 성공',
            content: '변경에 성공했습니다.',
          });
          this.props.close();
          this.props.getList();
        } else {
          Modal.error({
            title: '변경 실패',
            content: '수정에 실패했습니다. 관리자에게 문의하세요.',
            onOk() {},
          });
          return;
        }
        this.props.close();
      })
      .catch((e) => {
        Modal.error({
          title: '변경 실패',
          content: '수정에 실패했습니다. 관리자에게 문의하세요.',
          onOk() {},
        });
      });
  };

  render() {
    const { close, data } = this.props;

    let monthList = [];
    for (let i = 1; i < 14; i++) {
      monthList.push(i);
    }

    const oldDate = moment(data.chargeDate);
    const registDate = moment(data.registDate);
    const defaultMonth = Math.floor(oldDate.diff(registDate, 'days') / 30);

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="modifyfran-Dialog">
          <div className="modifyfran-content">
            <div className="modifyfran-title">가맹비 설정 편집</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
            <div className="modifyfran-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="modifyDueSetting-innerbox">
                  <div className="contentBlock">
                    <div className="mainTitle">상점명</div>
                    <div className="formItem">{data.frName}</div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">영업 담당자</div>
                    <div className="formItem">
                      {this.state.modalOpen && (
                        <SearchRiderDialog
                          callback={(rider) => this.selectedRider(rider)}
                          close={this.closeModal}
                        />
                      )}
                      <Input
                        onFocus={this.openModal}
                        style={{ width: 180 }}
                        placeholder="영업담당자를 선택하세요"
                        className="override-input"
                        value={this.state.recommender || data.recommender}
                        disabled={this.state.admin}
                      />
                    </div>
                    <Button
                      onClick={this.openModal}
                      disabled={this.state.admin}
                      style={{ width: 60 }}>
                      검색
                    </Button>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">최초 가맹비</div>
                    <div className="formItem" style={{ display: 'flex' }}>
                      <Select
                        style={{ width: 100 }}
                        defaultValue={
                          defaultMonth > 4000
                            ? '무제한'
                            : defaultMonth < 1
                            ? ''
                            : defaultMonth
                        }
                        disabled={oldDate <= today}
                        onChange={(value) => this.onChangeDate(value)}>
                        {monthList.map((value) => {
                          if (this.state.admin && value > 3) return <></>;
                          return (
                            <Option value={value} style={{ width: 100 }}>
                              {value < 13 ? value + '개월 후' : '무제한'}
                            </Option>
                          );
                        })}
                      </Select>
                      <Input
                        className="override-input"
                        style={{ width: 140 }}
                        disabled={true}
                        value={
                          moment(this.state.chargeDate).format('YYYY') > 2900
                            ? '무제한'
                            : moment(this.state.chargeDate).format('YYYY-MM-DD')
                        }
                      />

                      {oldDate <= today && (
                        <p
                          style={{
                            margin: 0,
                            fontSize: 12,
                            color: '#888',
                          }}>
                          *가맹비 납부가 진행되고 있어 수정이 불가합니다
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="contentBlock">
                    <div className="mainTitle">가맹비</div>
                    <div className="formItem">
                      <FormItem
                        name="dues"
                        className="selectItem"
                        style={{ marginBottom: 0, width: 180 }}
                        initialValue={
                          data.dues === -1 ? '자동' : data.dues || ''
                        }>
                        <Input
                          placeholder="가맹비를 입력하세요"
                          className="override-input"
                          disabled={
                            this.state.admin ? true : this.state.checked
                          }
                        />
                      </FormItem>
                    </div>
                    <Checkbox
                      checked={this.state.checked}
                      style={{ marginBottom: 0, marginLeft: 10 }}
                      onChange={this.handleToggle}>
                      자동
                    </Checkbox>
                  </div>

                  <div className="contentBlock2">
                    <div className="mainTitle" style={{ marginTop: 8 }}>
                      사유
                    </div>
                    <div className="formItem">
                      <FormItem
                        name="duesMemo"
                        className="selectItem"
                        style={{ width: 240 }}
                        initialValue={data.duesMemo || ''}>
                        <Input.TextArea
                          allowClear
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          placeholder="사유를 입력하세요"
                          className="override-input"
                          disabled={this.state.admin}
                        />
                      </FormItem>
                    </div>
                  </div>
                </div>

                <Button
                  style={{ marginTop: 10 }}
                  type="primary"
                  htmlType="submit">
                  저장하기
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(ModifyDuesSettingDialog);
