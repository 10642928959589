import { Button, Form, Input, Modal, Table } from 'antd';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import CloseIcon from '../../img/close.png';

const FormItem = Form.Item;
const columns = [
  {
    title: '순번',
    dataIndex: 'idx',
    className: 'table-column-center',
  },
  {
    title: '대상',
    dataIndex: 'name',
    className: 'table-column-center',
  },
  {
    title: '예금주',
    dataIndex: 'depositor',
    className: 'table-column-center',
  },
  {
    title: '은행명',
    dataIndex: 'bankName',
    className: 'table-column-center',
  },
  {
    title: '은행코드',
    dataIndex: 'bank',
    className: 'table-column-center',
  },
  {
    title: '계좌번호',
    dataIndex: 'bankAccount',
    className: 'table-column-center',
  },
];

class TranferCompanyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.formRef = React.createRef();
  }
  async componentDidMount() {
    await this.getList();
  }
  getList = async () => {
    try {
      const res = await httpGet(httpUrl.transferCompanyList, [], {});
      console.log(res);
      this.setState({
        list: res.data,
      });
    } catch (e) {
      Modal.warn({
        title: '조회실패',
        content: '계좌목록 조회에 실패했습니다. 다시 시도해주세요.',
      });
    }
  };

  handleSubmit = async () => {
    console.log(this.formRef.current.getFieldsValue());
    const formData = this.formRef.current.getFieldsValue();
    Modal.confirm({
      title: '송금 확인',
      content: `대상순번 ${formData.idx}번에게 ${formData.amt}원을 송금하시겠습니까?`,
      onOk: async () => {
        try {
          const res = await httpPost(
            httpUrl.transferCompany,
            [],
            this.formRef.current.getFieldsValue()
          );
          console.log(res);
          if (res.data.resultCd === '0000') {
            Modal.info({
              title: '송금성공',
              content: '송금에 성공했습니다.',
            });

            this.props.getList();
            this.props.close();
          } else {
            Modal.warn({
              title: '송금실패',
              content: `${res.data.resultCd} 로 송금에 실패했습니다.`,
            });
          }
        } catch (e) {
          console.log(e.response.data);
          Modal.warn({
            title: '송금실패',
            content: `서버 에러로 송금에 실패했습니다.`,
          });
        }
      }
    })
  };

  render() {
    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={this.props.close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">본사출금</div>
              <img
                onClick={this.props.close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="deposit-inner" style={{ flex: 5 }}>
                <Table
                  className="searchRider-table"
                  rowKey={(record) => record.idx}
                  dataSource={this.state.list}
                  pagination={false}
                  columns={columns}
                  onRow={(row) => {
                    return {
                      onClick: () => {
                        this.formRef.current.setFieldsValue({
                          idx: row.idx,
                        });
                      },
                    };
                  }}
                  rowClassName={'cursor-pointer'}
                />
              </div>
              <div className="deposit-inner" style={{ flex: 2 }}>
                <Form
                  ref={this.formRef}
                  onFinish={this.handleSubmit}
                  className="create-control-form">
                  <div className="create-control-form-item-title">대상순번</div>
                  <FormItem name="idx" className="create-control-form-item">
                    <Input disabled={true} className="create-control-input" />
                  </FormItem>
                  <div className="create-control-form-item-title">송금액</div>
                  <FormItem name="amt" className="create-control-form-item">
                    <Input
                      type={'number'}
                      className="create-control-input"
                      min={'0'}
                      max={'1000000000'}
                      controls={false}
                    />
                  </FormItem>

                  <div className="create-control-form-item-title">메모</div>
                  <FormItem name="memo" className="create-control-form-item">
                    <Input className="create-control-input" />
                  </FormItem>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="create-control-submit-btn"
                    style={{ width: '100%' }}>
                    송금하기
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TranferCompanyDialog;
