import { Button, DatePicker, Form, Input, Select, Table, Modal, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetRenewMakeUrl, httpUrl, httpPost } from '../../api/httpClient';
import '../../css/main.css';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import moment from "moment";
import locale from "antd/es/date-picker/locale/ko_KR";
import ProgressBar from '../../components/ProgressBar';


const { Option } = Select;
const Search = Input.Search;
const now = new Date();
const { RangePicker } = DatePicker;

const AdjDuesMonth = ({ props }) => {
    const initialPaginationErp = {
        total: Number.MAX_VALUE,
        current: 1,
        pageSize: 1000,
    };
    const formRef = React.createRef();
    const [list, setList] = useState([]);
    const [branchList, setbranchList] = useState([]);
    const [pagination, setPagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
    });
    const [frName, setFrName] = useState("");
    const [date, setDate] = useState({
        start: moment(now).format("YYYY-MM"),
        end: moment(now).format("YYYY-MM")
    });
    const [erpInterface, setErpInterface] = useState(1);
    const [progressBarOn, setProgressBarOn] = useState(false);
    const [progressBarFlag, setProgressBarFlag] = useState(true);
    const [paginationErp, setPaginationErp] = useState(
        initialPaginationErp
    );




    useEffect(async () => {
        await getAdjDuesList();
    }, [erpInterface, pagination.current, pagination.pageSize, frName, date]);

    const getAdjDuesList = async () => {
        let params = {};
        params['endDate'] = moment(date.end).add(1, 'M').format("YYYYMM");
        params['erpInterface'] = erpInterface;
        params['pageNum'] = pagination.current;
        params['pageSize'] = pagination.pageSize;
        params['startDate'] = moment(date.start).format("YYYYMM");
        params['frName'] = frName;
        try {
            const res = await httpGetRenewMakeUrl(httpUrl.adjDuesMonthList, params, {})
            if (!res.data) {
                console.log(`getListAsync failed. result: ${res.data.result}`);
                return;
            }
            setList(res.data.month);
            setPagination({
                ...pagination,
                current: res.data.currentPage,
                total: res.data.totalCount
            })
        } catch (e) {
            console.error(e);
        }
    };
    const handleTableChange = (page) => {
        if (page.current !== pagination.current) {
            setPagination({
                ...pagination,
                current: page.current
            });
        } else if (page.pageSize !== pagination.pageSize) {
            setPagination({
                ...pagination,
                current: 1,
                pageSize: page.pageSize
            });
        }

    };
    const onSearch = (value) => {
        setFrName(value);
        setPagination({
            ...pagination,
            current: 1
        });
    };
    const onChangeRangePicker = async (e, selectDate, value) => {
        setDate({
            start: selectDate[0],
            end: selectDate[1]
        })
        await getAdjDuesList(value);

    }
    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    };

    const update = async (data) => {
        const res = await httpPost(httpUrl.adjMonthUpdate, [], {
            logDate: data.logDate,
            userIdx: data.userIdx,
            memo: data.memo,
            ncashCustom: data.ncashCustom,
        });
        if (!res) {
            Modal.info({
                title: '수정 실패',
                content:
                    '수정을 실패했습니다. 관리자에게 문의해주세요.',
            });
        } else {
            Modal.info({
                title: '수정 완료',
                content: '수정을 완료했습니다.',
            });
            getAdjDuesList();
        }
    }

    const openMemoModal = (data) => {
        Modal.confirm({
            title: '수정할 내용을 입력해주세요',
            content: (
                <div>
                    <Input.TextArea
                        placeholder="내용을 입력하세요"
                        allowClear
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        style={{ height: 200, width: 300 }}
                        onChange={(e) => { data.memo = e.target.value }}
                    />
                </div>
            ),
            onOk() {
                update(data);
            },
            onCancel() { }
        });
    }
    const openNcashModal = (data, err) => {
        Modal.confirm({
            title: '수정할 금액을 입력해주세요',
            content: (
                <div>
                    조정금액 :
                    <Input
                        placeholder="금액을 입력하세요"
                        style={{ width: 200, marginLeft: 12 }}
                        onChange={(e) => { data.ncashCustom = e.target.value }}
                    />
                    {err && <div>*숫자만 입력해주세요</div>}
                </div>
            ),
            onOk() {
                const regex = /^[0-9]/g;
                if (regex.test(data.ncashCustom)) {
                    update(data);
                } else { openNcashModal(data, true); }
            },
            onCancel() { }
        });
    }

    const erpInterfaceHandleChange = () => {
        setErpInterface(!erpInterface)
        setPagination({
            ...pagination,
            current: 1
        });
    }

    const getInterfaceList = async () => {
        let params = {};

        params['endDate'] = moment(date.end).add(1, 'M').format("YYYYMM");
        params['erpInterface'] = 0;
        params['pageNum'] = 1;
        params['pageSize'] = 1000000;
        params['startDate'] = moment(date.start).format("YYYYMM");
        params['frName'] = frName;

        const res = await httpGetRenewMakeUrl(httpUrl.adjDuesMonthList, params, []);


        return res.data.month;
    }
    const allInterface = async (row) => {
        setProgressBarOn(true);
        const data = await getInterfaceList();
        if (!data) {
            return
        }
        else {
            setPaginationErp({
                total: data.length,
                current: 0,
            })
            for (let i = 0; i < data.length; i++) {
                console.log("!!!!!!!!!!!!!!!!!! " + JSON.stringify(data[i]))
                if (data[i].erpInterface === 0) {
                    sendToErp(data[i], true)
                }
                setPaginationErp({
                    total: data.length,
                    current: i + 1,
                })
            }
            setTimeout(() => getAdjDuesList(), 1000)
        }
        setProgressBarOn(false);
        setPaginationErp(initialPaginationErp);


    }
    const quitProgressBar = () => {
        setProgressBarFlag(false);
        setProgressBarOn(false);
        setPaginationErp(initialPaginationErp);
    };

    const columns = [
        {
            title: '일자',
            dataIndex: 'logDate',
            className: 'table-column-center',
            render: (data) => <div>{moment(data).format("YYYY-MM")}</div>
        },
        {
            title: '상점명',
            dataIndex: 'frName',
            className: 'table-column-center',
        },
        {
            title: '사업자번호',
            dataIndex: 'businessNumber',
            className: 'table-column-center',
        },
        {
            title: '대표자명',
            dataIndex: 'ownerName',
            className: 'table-column-center',
        },
        {
            title: '가맹비',
            dataIndex: 'ncash',
            className: 'table-column-center',
            render: (data) => <div>{comma(data)}원</div>,
        },
        {
            title: '조정 금액',
            dataIndex: 'ncashCustom',
            className: 'table-column-center',
            render: (data, row) => <Button onClick={() => openNcashModal(row, false)}>{comma(data)}원</Button>,
        },
        {
            title: '최종 가맹비',
            dataIndex: 'totalNcash',
            className: 'table-column-center',
            render: (data, row) => <div>{comma(row.ncash + row.ncashCustom)}원</div>,
        },
        {
            title: '연동',
            dataIndex: 'erpInterface',
            className: 'table-column-center',
            render: (data, row) =>
                data === 0 ?
                    <Button onClick={() => { sendToErp(row, false) }}>연동</Button>
                    : <div style={{ color: 'orange' }}>연동 완료</div>
        },
        {
            title: '메모',
            dataIndex: 'memo',
            className: 'table-column-center',
        },
        {
            title: '메모수정',
            dataIndex: '',
            className: 'table-column-center',
            render: (row) => (
                <Button onClick={() => openMemoModal(row)}
                >수정</Button>
            )
        },

    ];

    const parseExcelJson = (data) => {
        let result = [];
        let headerJson = {};
        columns.forEach((column) => {
            headerJson[column.dataIndex] = column.title;
        });
        result.push(headerJson);

        data.forEach((item) => {
            let copiedItem = { ...item };
            Object.keys(item).forEach((key) => {
                if (columns.findIndex((column) => column.dataIndex === key) < 0) {
                    delete copiedItem[key];
                }
            });

            result.push({
                ...copiedItem,
                branchIdx: branchList.find((branch) => branch.idx === item.branchIdx)
                    ? branchList.find((branch) => branch.idx === item.branchIdx)
                        .branchName
                    : '-',
                totalNcash: item.ncash + item.ncashCustom,
                erpInterface: item.erpInterface === 1 ? "연동완료" : ""
            });
        });
        return result;
    };

    const onDownload = async () => {
        let params = {};
        params['endDate'] = moment(date.end).add(1, 'M').format("YYYYMM");
        params['erpInterface'] = erpInterface;
        params['pageNum'] = 1;
        params['pageSize'] = 1000000;
        params['startDate'] = moment(date.start).format("YYYYMM");
        params['frName'] = frName;
        console.log(params)
        const res = await httpGetRenewMakeUrl(httpUrl.adjDuesMonthList, params, {});
        console.log(res);

        const excelJson = parseExcelJson(res.data.month);
        const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, '월별_가맹비_정산');
        xlsx.writeFile(wb, '월별_가맹비_정산.xlsx');
    };

    const sendToErp = (row, noalert) => {
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@")

        const frDt = moment(row.logDate + "01", "YYYYMMDD").add(1, "M").add(-1, "d").format("YYYYMMDD")
        httpPost(httpUrl.adjDuesErp, [], {
            logDate: row.logDate,
            userIdx: row.userIdx,
            frDt: frDt,
            inDt: frDt,
            regNb: row.businessNumber,
            rmkDc: "가맹비",
            amount: row.ncash + row.ncashCustom - Math.floor((row.ncash + row.ncashCustom) / 11),
            vat: Math.floor((row.ncash + row.ncashCustom) / 11)
        }).then((result) => {
            if (!noalert) {
                if (result.data == "SUCCESS") {
                    getAdjDuesList();
                }
                else if (result.data == "NO_USER") alert('사용자를 찾을 수 없습니다.');
                else if (result.data == "NO_DEPT") alert('지점부서정보 개발팀 등록필요');
                else if (result.data == "NO_TRADE") alert('지점거래처정보 개발팀 등록필요');
                else if (result.data == "NO_PJT") alert('지점가맹비프로젝트정보 개발팀 등록필요');
                else if (result.data == "ERP_ERROR") alert('ERP서버접속 오류');
                else alert('알수없는오류');
            }
        }).catch((e) => {
            if (!noalert) {
                alert('ERP에서 사업자번호를 조회할 수 없습니다.');
            }
        });
    }

    return (
        <>
            {progressBarOn && (
                <ProgressBar
                    current={paginationErp.current}
                    total={paginationErp.total}
                    quit={quitProgressBar}
                />
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>

                    <Search
                        placeholder="상점명으로 검색"
                        className="search-input"
                        enterButton
                        allowClear
                        onSearch={onSearch}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Form.Item name="date">
                        <RangePicker
                            locale={locale}
                            style={{ width: 280, justifyContent: 'flex-end' }}
                            format={"YYYY-MM"}
                            disabledDate={disabledDate}
                            allowClear={false}
                            picker="month"
                            value={[
                                moment(date.start, "YYYYMM"),
                                moment(date.end, "YYYYMM"),
                            ]}
                            onChange={onChangeRangePicker}
                        />

                    </Form.Item>
                </Form>
                <div style={{ float: 'right' }}>
                    <Checkbox
                        style={{ marginTop: 10 }}
                        onClick={erpInterfaceHandleChange}
                    >
                        미연동 목록 보기
                    </Checkbox>
                    < Button
                        className="download-btn"
                        style={{ marginLeft: 10, marginBottom: 20 }}
                        onClick={() => allInterface()}
                    >
                        {/* <img style={{ display: 'inline' }} src={ExcelIcon} alt="" /> */}
                        전체연동
                    </Button>
                    {Const.excelEnable && (
                        <Button
                            className="download-btn"
                            style={{ marginLeft: 10, marginBottom: 20 }}
                            onClick={() => onDownload()}>
                            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
                            엑셀 다운로드
                        </Button>
                    )}
                </div>
            </div>

            <Table
                rowKey={(record) => record.idx}
                dataSource={list}
                columns={columns}
                pagination={pagination}
                onChange={handleTableChange}
            />
        </>
    );
};

export default AdjDuesMonth;
