import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import util from 'util';
import Const from '../const';

let loadingCount = 0;

global.language = 'ko';
global.lanList = ['ko', 'en', 'ja', 'zh'];

const serverUrl =
  Const.serverProtocol + '://' + Const.serverIp + ':' + Const.serverPort;

const makeUrl = (url, params) => {
  var result = serverUrl + url;
  if (params === null) return result;
  params.forEach((param) => {
    result = util.format(result, param);
  });
  return result;
};

const renewMakeUrl = (url, params) => {
  var result = serverUrl + url;
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      result += '?' + key + '=' + params[key];
    } else {
      result += '&' + key + '=' + params[key];
    }
  });
  return result;
};

const httpExec = (method, url, data) => {
  loadingCount++;
  if (loadingCount === 1)
    global.document.getElementById('loadingSpinner').style.display = 'block';

  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // if (
        //   url === serverUrl + httpUrl.login ||
        //   url === serverUrl + httpUrl.logout
        // ) {
        // } else {
        //   if (
        //     (method === 'PUT' || method === 'POST' || method === 'DELETE') &&
        //     response.data.result === 'SUCCESS'
        //   ) {
        //     alert('완료');
        //   }
        // }
        resolve(response.data);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById('loadingSpinner').style.display =
            'none';
      })
      .catch((error) => {
        console.log(JSON.stringify(error, null, 4));
        if (error.message.includes('401')) {
          alert('로그인이 만료되었습니다. 다시 로그인해주세요');
          reactLocalStorage.remove('adminUser');
          global.location.href = '/';
        }
        // if (error.response.data.message === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('장기간 미사용으로 자동 로그아웃 되었습니다.');
        //     global.location.href = '/';
        //   }
        // } else if (error.response.data.data === 'E10003') {
        //   if (!isAlertOpened) {
        //     isAlertOpened = true;
        //     alert('접근 권한이 없습니다. 로그인 해주세요.');
        //     global.location.href = '/';
        //   }
        // }
        // alert(JSON.stringify(error));
        reject(error);
        loadingCount--;
        if (loadingCount === 0)
          global.document.getElementById('loadingSpinner').style.display =
            'none';
      });
  });
};

const httpExecWithoutLoading = (method, url, data) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.message.includes('401')) {
          alert('로그인이 만료되었습니다. 다시 로그인해주세요');
          reactLocalStorage.remove('adminUser');
          global.location.href = '/';
        }
        reject(error);
      });
  });
};

const httpGet = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExec('GET', makeUrl(url, params), data);
};

const httpGetWithoutLoading = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExecWithoutLoading('GET', makeUrl(url, params), data);
};

const httpPostWithoutLoading = (url, params, data) => {
  console.log(makeUrl(url, params));
  return httpExecWithoutLoading('POST', makeUrl(url, params), data);
};

const httpGetRenewMakeUrl = (url, params, data) => {
  console.log(renewMakeUrl(url, params));
  return httpExec('GET', renewMakeUrl(url, params), data);
};

const httpGetRenewMakeUrlWithoutLoading = (url, params, data) => {
  console.log(renewMakeUrl(url, params));
  return httpExecWithoutLoading('GET', renewMakeUrl(url, params), data);
};

const httpPut = (url, params, data) => {
  return httpExec('PUT', makeUrl(url, params), data);
};

const httpPost = (url, params, data) => {
  return httpExec('POST', makeUrl(url, params), data);
};

const httpDelete = (url, params, data) => {
  return httpExec('DELETE', makeUrl(url, params), data);
};

const httpDownload = (url, params, data) => {
  return new Promise((resolve, reject) => {
    Axios({
      method: 'GET',
      url: makeUrl(url, params),
      data: data,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    })
      .then((response) => {
        var blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        resolve(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const httpFileUpload = async (file, hideLoading) => {
  if (
    file === null ||
    typeof file !== 'object' ||
    typeof file.name === 'undefined' ||
    typeof file.type === 'undefined' ||
    typeof file.uri === 'undefined'
  ) {
    return {
      result: 'INVALID_FILE',
      message: '',
    };
  }
  const url = makeUrl(httpUrl.fileUpload);
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await fetch(url, {
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data; ',
      },
    });
    return await res.json();
  } catch (e) {
    console.warn(
      `httpFileUpload, raised exception. e: ${e}, url: ${url}, file: ${JSON.stringify(
        file
      )}`
    );
    return {
      result: 'EXCEPTION',
      message: e,
    };
  } finally {
  }
};
const imageUrl = (idx) => {
  return (
    Const.serverProtocol +
    '://' +
    Const.serverIp +
    ':' +
    Const.serverPort +
    Const.serverContext +
    '/file/' +
    idx
  );
};

const excelFileUrl = (refTable) => {
  return (
    Const.serverProtocol +
    '://' +
    Const.serverIp +
    ':' +
    Const.serverPort +
    Const.serverContext +
    '/file/excel/download/' +
    refTable
  );
};

// const downloadFile = async (refTable) => {
//   const res = await httpGet(excelFileUrl(refTable), {
//     responseType: "blob",
//   });
//   return res;
// };

const httpUrl = {
  idPwdCheck: '/idPwCheck',
  login: '/login',
  otpLogin: '/otpLogin',
  logout: '/logout',

  //게시판
  // inquiryList: '/inquiry/list?pageSize=%s&pageNum=%s',
  // inquiryUpdate: '/inquiry/modify',

  // 배달내역
  deliveryList:
    '/delivery/all/list?branchIdx=%s&frName=%s&frPhone=%s&pageNum=%s&pageSize=%s&riderName=%s&riderLevels=%s&startDate=%s&endDate=%s&extCode=%s&extCodeFilter=%s&orderIdx=%s&withTotalPage=%s&branchName=%s',
  deliveryCancelList:
    '/delivery/cancel/list?branchIdx=%s&frName=%s&frPhone=%s&pageNum=%s&pageSize=%s&riderName=%s&riderLevels=%s&startDate=%s&endDate=%s&extCode=%s&extCodeFilter=%s',
  riderDeliveryList:
    '/delivery/rider/list?branchIdx=%s&pageNum=%s&pageSize=%s&riderName=%s&riderPhone=%s&searchMonth=%s',
  staffDeliveryList:
    '/delivery/staff/list?branchIdx=%s&categories=2&categories=3&categories=5&pageNum=%s&pageSize=%s&searchMonth=%s&staffName=%s&staffPhone=%s',

  cardPaymentList:
    '/delivery/cardPayment/list?frName=%s&pageNum=%s&pageSize=%s&riderName=%s&startDate=%s&endDate=%s',

  cardPaymentRasList:
    '/delivery/ras/list?frName=%s&pageNum=%s&pageSize=%s&riderName=%s&startDate=%s&endDate=%s',

  deleteCardPayment: '/delivery/delete/cardPayment',

  // 가맹점
  franchiseList:
    '/fr/list?branchIdx=%s&branchName=%s&frName=%s&businessNumber=%s&pageNum=%s&pageSize=%s',
  updateFranchise: '/fr/update',
  registFranchisePG: '/fr/regist/pg/%s',
  // franchiseFeeHistory: "/fr/cash/list?branchIdx=%s&pageNum=%s&pageSize=%s",
  franchiseChargeHistory: '/fr/charge/list?branchIdx=%s&pageNum=%s&pageSize=%s',
  frAccountCreate: '/fr/account/create',
  frAccountModify: '/fr/account/modify',
  franchiseDuesHistory:
    '/fr/dues/list?pageNum=%s&pageSize=%s&frName=%s&branchName=%s&startDate=%s&endDate=%s',
  franchiseDuesAggregation: '/fr/dues/aggregation',
  getFrOrderAgencyByUserIdx: '/fr/frOrderAgency/%s',
  deleteFrOrderAgency: '/fr/frOrderAgency/%s/%s',

  // 라이더
  riderList:
    '/rider/list?branchIdx=%s&riderName=%s&riderPhone=%s&pageNum=%s&pageSize=%s&branchName=%s',
  riderUnUseList: '/rider/list/unUse?pageNum=%s&pageSize=%s',
  riderUploadFile: '/rider/uploadFile/list?userIdx=%s',
  riderNotUploadFile: '/rider/notUploadFile/list?pageNum=%s&pageSize=%s',
  updateRider: '/rider/modifyAccount',
  riderAccountModifyList:
    '/rider/modifyAccount/list?userIdx=%s&pageNum=%s&pageSize=%s',

  //바이크
  bikeFixHistoryList: '/bike/maintenance/list?pageNum=%s&pageSize=%s',
  deleteFixList: '/bike/maintenance/delete',
  registFixList: '/bike/maintenance/create',
  updateFixList: '/bike/maintenance/update',
  sendBikeRepair: '/bike/bikeRepair/send',
  getBikeRepairList:
    '/bike/bikeRepair/list?pageNum=%s&pageSize=%s&riderName=%s',
  // getBikeList: "/bike/list?pageNum=%s&pageSize=%s&bikeStatus=%s&bikeNumber=%s&modelName=%s&insuranceAge=%s",
  getBikeList:
    '/bike/list?pageNum=%s&pageSize=%s&bikeStatus=%s&bikeNumber=%s&modelName=%s',
  RentFeeList: '/bike/rentFee/list?pageNum=%s&pageSize=%s',
  RentFeeCreate: '/bike/rentFee/create',
  RentFeeModify: '/bike/rentFee/update',
  getHasRentFeeBikeList:
    '/bike/getHasRentFeeBikeList?pageNum=%s&pageSize=%s&bikeNumber=%s&modelName=%s&insuranceAge=%s',
  getBikeNameYear: '/bike/name/list',
  updateBike: '/bike/update',
  createBike: '/bike/create',
  getBikeHistoryList: '/bike/historyList?bikeIdx=%s&pageNum=%s&pageSize=%s',
  getBikeReleaseList: '/bike/bikeRelease/list?pageNum=%s&pageSize=%s',
  bikeAssign: '/bike/release/assign',
  bikeStatusChange: '/bike/release/change',
  bikeDelete: '/bike/delete',
  bikeLocationInfo: '/bike/getBikeLocationInfo',

  //공지사항
  noticeList:
    '/noticeHomepage/list?endDate=%s&pageNum=%s&pageSize=%s&startDate=%s&title=%s&deleted=%s',
  updateNotice: '/noticeHomepage/update',
  registNotice: '/noticeHomepage/create',

  //  공지사항- 파일업로드
  FileUpload: '/file/upload',
  //지점
  centerList: '/centerHomepage/list?deleted=%s&name=%s&pageNum=%s&pageSize=%s',
  updateCenter: '/centerHomepage/update',
  registCenter: '/centerHomepage/create',
  // 문의사항
  inquiryList: '/inquiry/list?pageNum=%s&pageSize=%s',
  updateInquiry: '/inquiry/update',

  // 예치금
  depositList:
    '/ncash/deposit/list?branchIdx=%s&pageNum=%s&pageSize=%s&userId=%s&userType=%s',
  depositSend: '/ncash/create',
  depositWithdrawList:
    '/ncash/withdraw/list?pageNum=%s&pageSize=%s&userId=%s&userType=%s',
  depositAllList: '/ncash/all/list',
  depositAllListExcel: '/ncash/all/list',
  depositConnect9List: '/ncash/connect9/list',
  depositHeadOfficeList: '/ncash/headoffice/list',
  depositConnect9ListExcel: '/ncash/connect9/list',
  hoInfo: '/ncash/connect9',
  hoBalance: '/ncash/connect9/balance',
  getRdnrList: '/ncash/daily/rider/ncashResult',
  getFdnrList: '/ncash/daily/franchise/ncashResult',
  depositNotRecoveredList:
    '/ncash/notRecovered/list?branchIdx=%s&pageNum=%s&pageSize=%s&userId=%s&userType=%s',
  depositNotRecoveredMongoList:
    '/ncash/notRecoveredMongo/list?branchIdx=%s&pageNum=%s&pageSize=%s&userId=%s&userType=%s',
  bankCharge: '/ncash/bankCharge/list',
  agencyNcash: '/ncash/agency/balance/%s',
  // 일차감
  ncashDailyList:
    '/ncash/daily/list?kind=%s&pageNum=%s&pageSize=%s&userId=%s&startDate=%s&endDate=%s',
  riderBatchWorkList: '/rider/batchWork/list',
  riderBatchWorkCreate: '/rider/batchWork/create',
  riderBatchWorkDelete: '/rider/batchWork/delete',
  userBatchWorkCreate: '/rider/userBatchWork/create',
  userBatchWorkDelete: '/rider/userBatchWork/delete',

  // 가맹비
  duesList:
    '/ncash/dues/list?pageNum=%s&pageSize=%s&userId=%s&startDate=%s&endDate=%s',
  duesRestore: '/ncash/dues/restore',

  // 정산관리
  NcashFee:
    '/settlement/ncash/fee/list?pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  dailyOrderStat: '/settlement/daily/order/stat',
  staticsCostList:
    '/settlement/statics/cost/list?pageNum=%s&pageSize=%s&branchIdx=%s&businessNumber=%s&category=%s&frName=%s&riderName=%s&registrationNumber=%s&startDate=%s&endDate=%s',
  staticsCostCnt:
    '/settlement/statics/cost/cnt?branchIdx=%s&businessNumber=%s&frName=%s&riderName=%s&registrationNumber=%s&startDate=%s&endDate=%s',

  adjDuesDayList: '/adj/dues/list',
  adjUpdate: '/adj/dues/update',
  adjDuesMonthList: '/adj/dues/month/list',
  adjMonthUpdate: '/adj/dues/month/update',
  adjDuesErp: '/adj/dues/erp',

  adjDeliveryList: '/adj/delivery/list',
  adjDeliveryUpdate: '/adj/delivery/update',
  adjDeliveryMonthList: '/adj/delivery/month/list',
  adjDeliveryMonthUpdate: '/adj/delivery/month/update',

  adjBalanceList: '/adj/balance/list',

  // van 등록 조회
  getRegistVANList:
    '/van/list?pageNum=%s&pageSize=%s&branchName=%s&frName=%s&businessNumber=%s&status=%s&startDate=%s&endDate=%s',
  vanFileView: '/van/file/view?userIdx=%s',

  // 관제요원 생성
  createControlAgent: '/user/control/create',
  updateControlAgent: '/user/control/update',
  getBranchList: '/branch/list?pageNum=%s&pageSize=%s',
  getControlUser: '/user/control/list',

  // 출금요청
  riderWithdraw: '/payment/rider/withdraw',
  frWithdraw: '/payment/fr/withdraw',
  approveWithdraw: '/payment/approve/withdraw',
  rejectWithdraw: '/payment/reject/withdraw',
  requestWithdrawApproval: '/ncash/request/withdraw/approval',

  // 접속제한 해제
  releaseLoginLimit: '/user/login/limit/release',

  //운영자관리
  adminUserList: '/admin/list?branchIdx=%s&id=%s&pageNum=%s&pageSize=%s',
  createAdminUser: '/admin/create',
  updateAdminUser: '/admin/update',

  // 메뉴권한설정
  menuList: '/menu/menuList',
  adminWithAuthList: '/admin/withAuth/list?adminUserIdx=%s',
  adminAuthCreate: '/admin/withAuth/create',
  agencyAuthCreate: '/admin/agency/auth/create',
  getAllMenu: '/menu/all',
  createMenuList: '/menu/create',
  deleteMenuList: '/menu/delete',
  getAdminAuth: '/menu/adminAuth',
  updateAdminUserMenuAuth: '/admin/menu/auth',

  //내 정보
  otpChange: '/admin/otp/change/key',
  otpReset: '/admin/otp/reset/key',
  getSmList: '/statistics/settlement/list',
  getEdsList: '/statistics/eds/list',
  getEmsList: '/statistics/ems/list',

  //기타
  orderList:
    '/delivery/register?pageNum=%s&pageSize=%s&startDate=%s&endDate=%s&orderIdx=%s',

  cardPaymentDetail: '/delivery/register/cardDetail?orderIdx=%s&frIdx=%s',
  updateCardRegister: '/delivery/register/cardPay',
  getPgTypeByFrIdx: '/delivery/register/getPgType',

  getMallProductList: '/mall/product/list',
  getMallCategoryList: '/mall/category/list',
  updateMallProduct: '/mall/update/product',
  createMallProduct: '/mall/create/product',
  deleteProduct: '/mall/product',

  clearTaxSupport: '/rider/taxSupport/clear/%s',
  clearSignupDeviceId: '/user/clear/signupDeviceId',

  getTaxSupportLog:
    '/rider/taxSupport/log?pageNum=%s&pageSize=%s&category=%s&searchName=%s&startDate=%s&endDate=%s',

  getBranchListAll: '/branch/all',
  getBranchListWithBranchSetting:
    '/branch/list/withBranchSetting?branchIdx=%s&pageNum=%s&pageSize=%s',
  updateBranchWithBranchSetting: '/branch/update/withBranchSetting',
  getDeliveryPriceBasic:
    '/branch/list/deliveryPriceBasic?branchName=%s&groupIdx=%s',
  registDeliveryPriceBasic: '/branch/register/deliveryPriceBasic',
  deleteDeliveryPriceBasic: '/branch/delete/deliveryPriceBasic',

  mapPaystoryVacnt: '/paystory/vacnt/map/%s',

  getApprovalList:
    '/branch/approval/list?category=%s&pageNum=%s&pageSize=%s&searchString=%s',
  updateApproval: '/branch/approval/update',
  dpeRain: '/branch/deliveryPriceExtra/rain?pageNum=%s&pageSize=%s',

  staticsNcash:
    '/statistics/ncash/list?pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  staticsDues:
    '/duesPayStat/list?pageNum=%s&pageSize=%s&startDate=%s&endDate=%s',
  staticsFrTotal:
    '/duesPayStat/fr/list?pageNum=%s&pageSize=%s&frName=%s&branchIdx=%s',
  branchListInfo: '/branch/getBranchListInfo',

  //라이더 레벨 관리
  getRiderLevelList:
    '/rider/point/level/history?pageNum=%s&pageSize=%s&beginDate=%s&endDate=%s&branchIdx=%s&pointType=%s&riderId=%s&riderName=%s&isForExcel=%s',
  getRiderRainCallList:
    '/rider/raincall/setting/history?pageNum=%s&pageSize=%s&beginDate=%s&endDate=%s&branchIdx=%s',
  getRiderLevelSupport:
    '/rider/supportFound/history?pageNum=%s&pageSize=%s&beginDate=%s&endDate=%s&branchIdx=%s&riderId=%s&riderName=%s',
  updateRiderPoint: '/rider/point/giveBalance',
  getRiderCurrentPoint: '/rider/point/current/%s',
  getRiderGroup: '/rider/settingGroup/list?branchIdx=%s&pageNum=%s&pageSize=%s',
  updateRiderGroup: '/rider/settingGroup/update',
  createRiderGroup: '/rider/settingGroup/create',
  getAccidentInsuranceList:
    '/rider/accident-insurance/list?pageNum=%s&pageSize=%s&requestType=%s&startDate=%s',

  //지점설정관리 221108 hms
  getBranchListSetting:
    '/branch/list/getBranchListSetting?pageNum=%s&pageSize=%s',
  createBranch: '/branch/create',
  updateBranch: '/branch/update',

  approveRider: '/rider/approve',

  //라이더_가맹점 스킨_팝업
  getSkinPopupList:
    '/skin/list?pageNum=%s&pageSize=%s&startDate=%s&endDate=%s&category=%s&title=%s&branchIdx=%s',
  registSkinPopup: '/skin/create',
  updateSkinPopup: '/skin/update',
  deleteSkinPopup: '/skin/delete',
  updateDeliveryPriceFee: '/ncash/update/deliveryPriceFee',

  updateBank: '/user/update/bank',

  getBranchSettlementList:
    '/branchsettlement/settlementList?startDate=%s&endDate=%s&branchIdx=%s&pageNum=%s&pageSize=%s',
  getBranchSettlementInfo:
    '/branchsettlement/settlementInfo?startDate=%s&endDate=%s&dateRange=%s&pageNum=%s&pageSize=%s',
  getAutoCalcBranchSettlement:
    '/branchsettlement/autoCalcInfo?startDate=%s&endDate=%s&dateRange=%s&branchIdx=%s&pageNum=%s&pageSize=%s',
  getBranchSettlementIdx:
    '/branchsettlement/getBranchSettlementIdx?startDate=%s&endDate=%s&dateRange=%s&branchIdx=%s&pageNum=%s&pageSize=%s',
  modifyBranchSettlementElement: '/branchsettlement/modifyElement',
  branchSettlementComplete: '/branchsettlement/modifyCompleted',
  riderListByLevel:
    '/rider/listByLevel?pageSize=%s&pageNum=%s&riderName=%s&userStatus=%s&riderLevel=%s&branchIdx=%s',
  getDetailHistory:
    '/branchsettlement/getDetailHistory?branchSettlementIdx=%s&type=%s&pageNum=%s&pageSize=%s',

  manualDeposit: '/payment/manual/deposit',
  manualWithdraw: '/payment/manual/withdraw',
  manualDwList:
    '/payment/manual/deposit/list?pageNum=%s&pageSize=%s&searchString=%s&category=%s',

  // 가입/탈퇴 통계
  getJoinExitStatDateList:
    '/statistics/joinExitStat/getStatDateList?dateRange=%s&branchIdx=%s&userType=%s',
  getJoinExitStatList:
    '/statistics/joinExitStat/getStatList?dateRange=%s&branchIdx=%s&userType=%s&startDate=%s&endDate=%s',

  manualMapping: '/broadcast/manual-mapping',

  // 모든 지점 매출 정보 조회
  getBranchSettlementAllList:
    '/branchsettlement/settlementAllList?targetDate=%s&pageNum=%s&pageSize=%s',
  // 모든 지점 가맹점/라이더 인증여부 조회
  getFrRiderAuthStatus: '/user/getFrRiderAuthStatus?pageNum=%s&pageSize=%s',

  getAuraBalance: '/aura/balance',
  auraWithdraw: '/aura/withdraw',
  getAuraWithdrawLogs:
    '/aura/withdraw/logs?startDate=%s&endDate=%s&pageSize=%s&pageNum=%s&withPagination=%s',

  transferCompanyList: '/paystory/other/transfer/list',
  transferCompany: '/paystory/other/transfer',

  getBranchHeadOfficeInfo:
    '/branch/getBranchHeadOfficeInfo?pageSize=%s&pageNum=%s',
  createBranchHeadOffice: '/branch/branchHeadOfficeInfo/create',
  updateBranchHeadOffice: '/branch/branchHeadOfficeInfo/update',
  getBranchHeadOfficeHistory:
    '/branch/getBranchHeadOfficeHistory?branchIdx=%s&pageSize=%s&pageNum=%s',
  updateBranchSetting: '/branch/setting/update',
  branchHeadOfficeReqWithdraw: '/branch/branchHeadOffice/reqWithdraw',

  noticeAdminList: '/notice/admin/list',
  updateAdminNotice: '/notice/update',
  createAdminNotice: '/notice/create',
  deleteAdminNotice: '/notice/delete',

  // 제휴본사 관련 API
  getBranchOfAgency: '/agency/getBranchBelongToHeadOffice',
  checkBranchHeadOffice: '/branch/check/agency/%s',
  getAgencySearchFrList:
    '/agency/getFrListOfBranch?branchIdx=%s&searchForModal=%s&pageSize=%s&pageNum=%s&status=%s&search=%s',
  getAgencyBranchList:
    '/agency/serach/getBranchBelongToHeadOffice?search=%s&pageSize=%s&pageNum=%s',
  createAgencyBatchWorkNcash: '/agency/create/batchWorkNcash',
  updateAgencyBatchWorkNcash: '/agency/update/batchWorkNcash',
  deletedAgencyBatchWorkNcash: '/agency/delete/batchWorkNcash',
  getAgencyBatchWorkList:
    '/agency/getBatchWorkNcashList?pageNum=%s&pageSize=%s&kind=%s&search=%s',
  createAgencyNcashSendByAdmin: '/agency/create/ncashSendByAdmin',
  getAgencyDashboardBatchWorkData:
    '/agency/dashboard/getMisuUserBatchWork?companyId=%s&endDate=%s&startDate=%s',

  //세금
  getBranchForTaxBook: '/branch/getBranchListForTaxInvoice?search=%s&pageSize=%s&pageNum=%s',

  // 라이더 실시간 위치정보 서비스 제공및 조회 사실 조회
  getSearchRiderLocationInfo: '/user/get/searchRiderLocationInfo?adminId=%s&pageSize=%s&pageNum=%s&startDate=%s&endDate=%s',
  getProvideRiderLocationInfo: '/user/get/provideRiderLocationInfo?userId=%s&pageSize=%s&pageNum=%s&startDate=%s&endDate=%s',
};

const imageType = ['image/jpeg', 'image/png', 'image/bmp'];

const purchaseName = [
  '미선택',
  '비씨',
  'KB국민',
  '하나(외환)',
  '삼성',
  '----',
  '신한',
  '현대',
  '롯데',
  '한미',
  '신세계한미',
  '씨티',
  'NH채움',
  '수협',
  '신협',
  '우리',
  '하나',
  '동남(주택)',
  '주택',
  '조흥(강원)',
  '축협(농협)',
  '광주',
  '전북',
  '제주',
  '산은캐피탈',
  '해외비자',
  '해외마스터',
  '해외다이너스',
  '해외AMX',
  '해외JCB',
  '----',
  'SK-OKCashBag',
  '우체국',
  '저축은행',
  '은련',
  '새마을금고',
  'KDB산업',
  '카카오뱅크',
  '케이뱅크',
  'PAYCO 포인트',
  '카카오머니',
  'SSG머니',
  '네이버페이포인트',
];

const makeUrlWithObjectParam = (url, params) => {
  let result = serverUrl + url;
  let flag = 0;
  Object.entries(params).forEach(([key, value]) => {
    if (value === null || value === undefined) return;
    if (flag === 0) {
      result += `?${key}=${value}`;
      flag += 1;
    } else result += `&${key}=${value}`;
  });
  return result;
};

const httpGetWithObjectParam = (url, params, data) => {
  console.log(makeUrlWithObjectParam(url, params));
  return httpExec('GET', makeUrlWithObjectParam(url, params), data);
};

export {
  excelFileUrl,
  httpDelete,
  httpDownload,
  httpExec,
  httpFileUpload,
  httpGet,
  httpGetRenewMakeUrl,
  httpGetRenewMakeUrlWithoutLoading,
  httpGetWithObjectParam,
  httpGetWithoutLoading,
  httpPost,
  httpPostWithoutLoading,
  httpPut,
  httpUrl,
  imageType,
  imageUrl,
  makeUrl,
  // downloadFile,
  makeUrlWithObjectParam,
  purchaseName,
  serverUrl,
};
