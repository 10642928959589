import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  DatePicker,
  Input,
  Select,
  Checkbox,
  Table,
  Modal,
} from 'antd';
import { comma } from '../../lib/util/numberUtil';
import { SearchOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  batchWorkCategoryForAgency,
  batchWorkCategoryForBranch,
  batchWorkDetailCategoryForAgency,
  batchWorkKindForAgencyCode,
  kindString,
} from '../../lib/util/codeUtil';
import { reactLocalStorage } from 'reactjs-localstorage';
import con from '../../const';
import { agencySelfList } from '../../lib/util/agencyUtil';
import axios from 'axios';
import Const from '../../const';
import FileSaver from 'file-saver';
import { value } from 'lodash/seq';

const today = moment().format('YYYY-MM-DD');
const Option = Select.Option;
const AgencyBatchWorkList = () => {
  const agencyBranch = reactLocalStorage.getObject(
    con.appName + '#agencyBranch'
  );
  const loginInfo = reactLocalStorage.getObject(con.appName + '#adminUser');
  const [belongBranch] = useState(agencySelfList());
  const [list, setList] = useState([]);
  const [selectBranchCompanyId, setSelectBranchCompanyId] = useState(
    loginInfo.branch.companyId
  );
  const CancelToken = axios.CancelToken;
  const source = useRef(null);
  const [totalMisu, setTotalMisu] = useState(0);
  const [totalActualCollectAmount, setTotalActualCollectAmount] = useState(0);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [tabType, setTabType] = useState(null);
  const [checkType, setCheckType] = useState([0, 1]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 30,
  });
  useEffect(() => {
    getData();
    return () => {
      if (source.current !== null) {
        source.current.cancel();
      }
    };
  }, [
    selectBranchCompanyId,
    tabType,
    pagination.current,
    pagination.pageSize,
    checkType,
  ]);
  const onChangeDate = (type, _, date) => {
    if (type === 'start') {
      setStartDate(moment(date).format('YYYY-MM-DD'));
    } else {
      setEndDate(moment(date).format('YYYY-MM-DD'));
    }
  };
  const getData = async (paging) => {
    if (isLoading) return;
    setIsLoading(true);
    let currPage = pagination.current;
    if (paging) {
      currPage = 1;
    }
    let retrieved =
      checkType.length === 1 ? (checkType[0] === 0 ? false : true) : null;
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: selectBranchCompanyId,
      keyword: search,
      isAgencyHeadquarter: loginInfo.branch.companyId === selectBranchCompanyId,
      batchWorkKind: tabType,
      retrieved: retrieved,
      page: currPage,
      size: pagination.pageSize,
    };
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      const res = await axios.get('/headquarter-dashboard/batch-work-details', {
        baseURL: Const.settlementUrl,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: customParams,
        cancelToken: source.current.token,
      });
      if (res.data) {
        setList(res.data.contents);
        setPagination({
          ...pagination,
          current: currPage,
          total: res.data.totalCount,
        });
        setTotalMisu(res.data.totalMisu);
        setTotalActualCollectAmount(res.data.totalActualCollectAmount);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
      console.error('getting batch-work-details-list is fail: ' + e);
    }
    setIsLoading(false);
  };
  const onDownloadExcel = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // let branch = [...agencyBranch];
    let retrieved =
      checkType.length === 1 ? (checkType[0] === 0 ? false : true) : null;
    let customParams = {
      startDate: startDate,
      endDate: endDate,
      companyId: selectBranchCompanyId,
      keyword: search,
      isAgencyHeadquarter: loginInfo.branch.companyId === selectBranchCompanyId,
      batchWorkKind: tabType,
      retrieved: retrieved,
      page: pagination.current,
      size: pagination.pageSize,
    };
    console.log(startDate)
    if (source.current !== null) {
      // already exists request
      source.current.cancel();
    }
    source.current = CancelToken.source();
    try {
      const res = await axios.get(
        '/headquarter-dashboard/batch-work-details/excel',
        {
          baseURL: Const.settlementUrl,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
          },
          params: customParams,
          cancelToken: source.current.token,
          responseType: 'arraybuffer',
        }
      );
      if (res.data) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        let filename = '일차감 세부내역';
        FileSaver.saveAs(
          blob,
          `${filename}_${moment(startDate).format('YYMMDD')}.xlsx`
        );
        Modal.info({
          title: '다운로드 성공',
          content: '엑셀 다운로드가 완료되었습니다.',
        });
        setIsLoading(false);
      } else {
        Modal.error({
          title: '다운로드 실패',
          content: '엑셀 다운로드가 실패하였습니다.',
        });
        setIsLoading(false);
      }
    } catch (e) {
      console.error('getting batcj-work-details/excel is fail: ' + e);
      setIsLoading(false);
    }
  };
  const changTab = (category, value) => {
    if (category === 'tab') {
      if (value === 22) {
        setTabType(value);
        setCheckType([1]);
      } else {
        setTabType(value);
      }
    } else if (category === 'check') {
      setCheckType(value);
    } else if (category === 'search') {
      setSearch(value);
    }
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  const checkTab = [
    '미회수',
    '회수 ',
    // { label: '미회수', value: 0 },
    // { label: '회수', value: 1 },
  ];
  const columns = [
    {
      title: '일시',
      dataIndex: 'createDate',
      className: 'table-column-center',
      width: 200,
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '대분류',
      dataIndex: 'batchWorkKind',
      className: 'table-column-center',
      render: (data) => kindString[batchWorkKindForAgencyCode[data]],
    },
    loginInfo.branch.companyId === selectBranchCompanyId
      ? {
          title: '가맹점명/대리점명',
          dataIndex: 'riderOrFrName',
          className: 'table-column-center',
        }
      : {
          title: '가맹점명/라이더명',
          dataIndex: 'riderOrFrName',
          className: 'table-column-center',
        },
    {
      title: '상태',
      dataIndex: 'retrieved',
      className: 'table-column-center',
      render: (data) =>
        data ? (
          <span style={{ color: 'blue' }}>회수</span>
        ) : (
          <span style={{ color: 'red' }}>미회수</span>
        ),
    },
    {
      title: '회수 원금',
      dataIndex: 'needToCollectAmount',
      className: 'table-column-center',
      render: (data, row) => (data === 0 ? 0 : comma(data)),
    },
    {
      title: '회수 금액',
      dataIndex: 'actualCollectAmount',
      className: 'table-column-center',
      render: (data, row) => (data === 0 ? 0 : comma(data)),
    },
    {
      title: '미회수 금액',
      dataIndex: 'misu',
      className: 'table-column-center',
      render: (data, row) => (
        <span style={{ color: data !== 0 ? 'red' : 'black' }}>
          {comma(data)}
        </span>
      ),
    },
  ];
  return (
    <div>
      <h1 className={'agency-page-title'}>일차감 세부내역</h1>
      <div className={'ncl-menu-wrap'}>
        <div className={'ncl-menu-totalsum'}>
          <p style={{ color: '#FA2020', marginRight: 16 }}>
            미회수 합계: {comma(totalMisu)}
          </p>
          <p style={{ color: '#2036FA' }}>
            회수 합계: {comma(totalActualCollectAmount)}
          </p>
        </div>
        <div className={'ncl-select-box'}>
          <Select
            defaultValue={loginInfo.branch.branchName}
            style={{
              flex: 1,
            }}
            onChange={(value) => setSelectBranchCompanyId(value)}>
            {belongBranch.map((value) => {
              return (
                <Option key={value.idx} value={value.companyId}>
                  {value.branchName}
                </Option>
              );
            })}
          </Select>
        </div>

        <div className={'ncl-input-box'} style={{ width: 140 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('start', _, date)}
            value={moment(startDate)}
            allowClear={false}
          />
        </div>
        <span style={{ marginRight: 10 }}>-</span>
        <div className={'ncl-input-box'} style={{ width: 140, marginLeft: 0 }}>
          <DatePicker
            style={{ border: 'none', flex: 1 }}
            onChange={(_, date) => onChangeDate('end', _, date)}
            value={moment(endDate)}
            allowClear={false}
          />
        </div>
        <Button
          style={{
            height: 40,
            borderColor: '#c1c1c1',
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => getData(true)}>
          검색
        </Button>
      </div>
      <div className={'ncl-ctg-box'}>
        {selectBranchCompanyId === loginInfo.branch.companyId
          ? batchWorkDetailCategoryForAgency.map((item) => {
              return (
                <div
                  key={item.value}
                  value={item.value}
                  onClick={() => changTab('tab', item.value)}
                  className={
                    tabType === item.value
                      ? 'ncl-batch-btn active'
                      : 'ncl-batch-btn'
                  }>
                  {item.label}
                </div>
              );
            })
          : batchWorkCategoryForBranch.map((item) => {
              return (
                <div
                  onClick={() => changTab('tab', item.value)}
                  key={item.value}
                  value={item.value}
                  className={
                    tabType === item.value
                      ? 'ncl-batch-btn active'
                      : 'ncl-ctn-btn'
                  }>
                  {item.label}
                </div>
              );
            })}
        <div className={'ncl-input-box'} style={{ width: 240, marginLeft: 10 }}>
          <Input
            onPressEnter={() => getData(true)}
            onChange={(e) => changTab('search', e.target.value)}
            placeholder={'검색어를 입력해주세요'}
            style={{
              border: 'none',
              paddingLeft: 10,
              fontSize: 12,
              width: 200,
            }}
          />
          <SearchOutlined
            onClick={() => getData(true)}
            style={{ padding: '0 10px', cursor: 'pointer' }}
          />
        </div>
        <Checkbox.Group
          className={'ncl-checkbox'}
          style={{ marginLeft: 5 }}
          options={checkTab.map((item, index) => ({
            label: item,
            value: index,
          }))}
          defaultValue={checkType}
          value={checkType}
          disabled={tabType === 22}
          onChange={(value) => changTab('check', value)}
        />
        <div className={'ncl-excel-box'}>
          <div className={'ncl-excel-btn'} onClick={onDownloadExcel}>
            엑셀다운로드
            <VerticalAlignBottomOutlined />
          </div>
        </div>
      </div>
      <div className={'ncl-table'}>
        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          rowClassName={(record, index) => index % 2 !== 0 && 'ncl-table-row'}
          onChange={(pagination) => setPagination(pagination)}
        />
      </div>
    </div>
  );
};
export default AgencyBatchWorkList;
