import { DatePicker, Radio, Table } from 'antd';
import locale from 'antd/lib/date-picker/locale/en_US';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import React, { Component } from 'react';
import { httpGet, httpPost, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import '../../css/main.css';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';

const dateFormat = 'YYYY-MM-DD';
const today = moment(new Date()).format(dateFormat);
const oneYearAgo = moment(new Date()).subtract(1, 'years').format(dateFormat);
const oneMonthAgo = moment(new Date()).subtract(1, 'months').format(dateFormat);

class RiderWorkWhileRaining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        beginDate: today,
        endDate: today,
      },
      branchNameList: { 0: '전체지점' },
      branchNameValue: 0,
      sumPoint: 0,
    };
  }

  componentDidMount() {
    this.getBranchInfo();
    this.getList();
  }

  getBranchInfo = async () => {
    let branchNameList = this.state.branchNameList;
    await httpPost(httpUrl.branchListInfo, [], {}).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log('res');
        console.log(res.data);
        res.data.branch.map(
          (item) => (branchNameList[item.idx] = item.branchName)
        );
        this.setState(
          {
            branchNameList: branchNameList,
          },
          () => {
            console.log('branchNameList');
            console.log(this.state.branchNameList);
          }
        );
      }
    });
  };

  getList = () => {
    const pagination = this.state.pagination;
    let pageNum = pagination.current;
    let pageSize = pagination.pageSize;
    let beginDate = pagination.beginDate;
    let endDate = pagination.endDate;
    let branchIdx = this.state.branchNameValue;

    httpGet(
      httpUrl.getRiderRainCallList,
      [pageNum, pageSize, beginDate, endDate, branchIdx],
      {}
    ).then((res) => {
      console.log(res);
      if (res.result === 'SUCCESS') {
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          pagination: pagination,
          list: res.data.historyList,
          sumPoint: res.data.sumPoint,
        });
      }
    });
  };

  onRadioChange = (e) => {
    let value = e.target.value;
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          beginDate:
            value === 1 ? oneYearAgo : value === 2 ? oneMonthAgo : today,
          endDate: today,
        },
      },
      () => this.getList()
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onChangeDatePickerStart = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.endDate) - new Date(dateString) > 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
            },
          },
          () => this.getList()
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: pagination.endDate,
              endDate: dateString,
            },
          },
          () => this.getList()
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList()
      );
    }
  };

  onChangeDatePickerEnd = (_, dateString) => {
    const { pagination } = this.state;
    if (dateString) {
      if (new Date(pagination.beginDate) - new Date(dateString) < 0) {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              endDate: dateString,
            },
          },
          () => this.getList()
        );
      } else {
        this.setState(
          {
            pagination: {
              ...pagination,
              current: 1,
              beginDate: dateString,
              endDate: pagination.beginDate,
            },
          },
          () => this.getList()
        );
      }
    } else {
      this.setState(
        {
          pagination: {
            ...pagination,
            current: 1,
            beginDate: today,
            endDate: today,
          },
        },
        () => this.getList()
      );
    }
  };

  render() {
    const columns = [
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '우천콜 활성화 일시',
        dataIndex: 'createTime',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '적용 시간',
        dataIndex: 'type' /**type(0: 상시, 1: 기간) */,
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {data == 0
              ? row.beginDate + ' ~ ' + row.endDate
              : '매일 ' +
                moment(row.beginDate).format('HH:mm') +
                ' ~ ' +
                moment(row.endDate).format('HH:mm')}
          </div>
        ),
      },
      {
        title: '활성화 담당자',
        dataIndex: 'adminId',
        className: 'table-column-center',
      },
      {
        title: '지급된 우천콜 점수',
        dataIndex: 'point',
        className: 'table-column-center',
      },
    ];

    const { branchNameList, pagination, list } = this.state;
    return (
      <>
        <div>
          <SelectBox
            value={branchNameList[this.state.branchNameValue]}
            code={Object.keys(branchNameList)}
            codeString={branchNameList}
            onChange={(value) => {
              if (parseInt(value) !== this.state.branchNameValue) {
                this.setState({ branchNameValue: parseInt(value) }, () =>
                  this.getList()
                );
              }
            }}
          />
        </div>

        <div>
          <Radio.Group
            defaultValue={3}
            onChange={this.onRadioChange}
            style={{ marginTop: '15px', marginBottom: '15px' }}>
            <Radio value={1}>1년</Radio>
            <Radio value={2}>한달</Radio>
            <Radio value={3}>오늘</Radio>
          </Radio.Group>

          <DatePicker
            placeholder={'시작일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.beginDate, dateFormat)}
            onChange={this.onChangeDatePickerStart}
          />
          <DatePicker
            placeholder={'종료일'}
            locale={locale}
            style={{
              height: '32px',
              borderRadius: '6px',
              marginTop: '5px',
              marginBottom: '5px',
              marginLeft: '5px',
            }}
            size="middle"
            format={dateFormat}
            value={moment(pagination.endDate, dateFormat)}
            onChange={this.onChangeDatePickerEnd}
          />
        </div>

        <Table
          rowKey={(record) => record.idx}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
          기간한정 우천콜 점수 합계:{'     '} {comma(this.state.sumPoint)}
        </div>
      </>
    );
  }
}

export default RiderWorkWhileRaining;
