import { Button, DatePicker, Input, Modal, Select, Table } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import {
  httpPost,
  httpPostWithoutLoading,
  httpUrl
} from '../../api/httpClient';
import { customAlert, customError } from '../../api/Modals';
import SelectBox from '../../components/input/SelectBox';
import ProgressBar from '../../components/ProgressBar';
import Const from '../../const';
import '../../css/main.css';
import ExcelIcon from '../../img/excel.png';
import {
  adminSendKindString,
  categoryStatus,
  kindString
} from '../../lib/util/codeUtil';
import { formatDate } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import TranferCompanyDialog from "../../components/dialog/TranferCompanyDialog";

const RangePicker = DatePicker.RangePicker;
const Option = Select.Option;
const Search = Input.Search;

const initialPaginationExcel = {
  total: Number.MAX_VALUE,
  current: 1,
  pageSize: 1000,
};

const today = new moment();
const dateFormat = 'YYYY-MM-DD';
const todayString = today.format(dateFormat);

class DepositConnectHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: initialPaginationExcel,
      list: [],
      searchId: '',
      searchName: '',
      searchType: 1,
      category: '',
      endDate: todayString,
      startDate: todayString,
      memo: '',

      progressBarOn: false,

      transferCompanyDialogOpen: false,
    };
    this.progressBarFlag = true;
  }

  componentDidMount() {
    this.getList();
  }

  duesRestore = (ncashDelta, idx, receivedUserIdx, receivedUserId) => {
    let self = this;
    Modal.confirm({
      title: '반환금 지급',
      content:
        ' 가맹점에 ' +
        ' 반환금 ' +
        comma(ncashDelta) +
        ' 원을 지급하시겠습니까?',
      okText: '확인',
      cancelText: '취소',
      onOk() {
        httpPost(httpUrl.duesRestore, [], {
          idx: idx,
          receiveUserIdx: parseInt(receivedUserIdx),
          userId: receivedUserId,
          ncashAmount: ncashDelta,
        })
          .then((result) => {
            console.log('result.result : ' + result.result);
            console.log('result.data : ' + result.data);
            if (result.result === 'SUCCESS' && result.data === 'SUCCESS') {
              customAlert(
                '반환금 지급 완료',
                '해당 가맹점에 반환금을 지급하였습니다.'
              );
            } else if (
              result.result === 'SUCCESS' &&
              result.data === 'ALREADY_RESTORE_USER'
            ) {
              customAlert('반환금 지급 에러', '이미 반환 처리된 가맹점입니다');
            } else {
              customError(
                '예치금 지급 에러',
                '서버 에러로 인해 예치금 지급에 실패하였습니다'
              );
            }
          })
          .catch((e) => {
            customError(
              '예치금 지급 에러',
              '서버 에러로 인해 예치금 지급에 실패하였습니다'
            );
          });
      },
    });
  };

  getList = () => {
    let category = this.state.category;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let memo = this.state.memo;
    let withTotalPage = false;

    console.log(startDate, endDate);

    const startMoment = new moment(startDate);
    const endMoment = new moment(endDate);
    const dateDiff = moment.duration(endMoment.diff(startMoment)).asDays();
    if (dateDiff > 30)
      Modal.warn({
        title: '조회기간 설정',
        content: '조회기간을 30일 이내로 설정해주세요.',
      });

    if (!startDate || !endDate) {
      Modal.warn({
        title: '조회기간 미지정',
        content: '조회기간을 지정해주세요',
      });
      return;
    }

    httpPost(httpUrl.depositConnect9List, [], {
      pageNum,
      pageSize,
      category,
      startDate,
      endDate,
      memo,
      withTotalPage,
    }).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.ncash,
        pagination,
      });
    });
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  onSearch = (value) => {
    this.setState(
      {
        searchId: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onSearchMemo = (value) => {
    this.setState(
      {
        memo: value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        category: value === 'NONE' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
      }
    );
  };

  parseExcelJson = (data) => {
    let result = [
      {
        userId: '아이디',
        userName: '이름',
        branchName: '지점명',
        category: '카테고리',
        memo: '메모',
        createDate: '출금일시',
        ncashDelta: '금액',
        ncash: '잔액',
        target: '대상',
        targetBranchName: '대상지점',
        orderIdx: '주문번호',
        deliveryPrice: '배달비',
        deliveryPriceVat: '부가세',
        deliveryPriceFee: '수수료',
        riderCancelPenaltyAmount: '취소페널티',
        duesDeliveryPrice: '건별가맹비',
        deliveryPriceFeeByDpBasic: '건별수수료',
        businessNumber: '사업자번호',
        maxLogIdx: '취소/복원여부',
        updateContent: '상세내용',
      },
    ];
    data.forEach((item) => {
      let deliveryPrice = item.deliveryPrice ? parseInt(item.deliveryPrice) : 0;
      let deliveryPriceVat = item.deliveryPrice
        ? parseInt(item.deliveryPrice * 0.1)
        : 0;
      let deliveryPriceFee = item.deliveryPriceFee;
      let riderCancelPenaltyAmount = item.riderCancelPenaltyAmount;
      let deliveryPriceFeeByDpBasic = item.deliveryPriceFeeByDpBasic;

      riderCancelPenaltyAmount = 0;

      // 경영지원팀 요청에 따라 제거
      // if (item.ncashDelta == deliveryPriceVat + deliveryPriceFee)
      //   deliveryPrice = 0; //라이더 배송 케이스

      const maxLogIdx =
        (item.category == 'DELIVERY_PRICE_RECEIVE' ||
          item.category == 'DELIVERY_PRICE_SEND') &&
        item.maxLogIdx
          ? '복원'
          : item.orderStatus == 5
          ? '취소'
          : '';

      const minusFlag =
        item.category === 'DELIVERY_PRICE_RECEIVE_RESTORE' ||
        item.category === 'DELIVERY_PRICE_SEND_RESTORE'
          ? -1
          : 1;

      result.push({
        userId: item.userId,
        userName: item.userName,
        branchName: item.relationTargetBranchName,
        category:
          categoryStatus[item.category] +
          (item.category == 'NCASH_SEND_BY_ADMIN'
            ? '-' + adminSendKindString[item.kind]
            : ''),
        memo:
          item.category === 'BATCH_WORK_NCASH'
            ? item.batchTitle
              ? kindString[item.kind] + '[' + item.batchTitle + ']'
              : '-'
            : item.memo,
        createDate: item.createDate,
        ncashDelta: item.ncashDelta,
        ncash: item.afterNcash,
        target: item.relationTarget ? item.relationTarget : '-',
        targetBranchName:
          item.category === 'BATCH_WORK_NCASH' ? item.targetBranchName : '-',
        orderIdx: item.orderIdx,
        deliveryPrice: minusFlag * deliveryPrice,
        deliveryPriceVat: minusFlag * deliveryPriceVat,
        deliveryPriceFee: minusFlag * deliveryPriceFee,
        riderCancelPenaltyAmount: minusFlag * riderCancelPenaltyAmount,
        duesDeliveryPrice: item.duesDeliveryPrice,
        deliveryPriceFeeByDpBasic: item.deliveryPriceFeeByDpBasic,
        businessNumber: item.relationBusinessNumber,
        maxLogIdx: maxLogIdx,
        updateContent: item.updateContent,
      });
    });

    return result;
  };

  onDownload = async () => {
    if (!this.state.startDate || !this.state.endDate) {
      Modal.warn({
        title: '조회기간 미지정',
        content: '조회기간을 지정해주세요',
      });
      return null;
    }

    const endDatePlus1Day = moment(this.state.endDate).clone().add('1', 'day');

    this.setState(
      {
        paginationExcel: {
          current: moment(this.state.startDate),
          total: endDatePlus1Day - moment(this.state.startDate),
        },
        progressBarOn: true,
      },
      async () => {
        const data = await this.getExcelList();

        if (!data) return;


        const maxItemCount = 50000;
        const splitCount = parseInt(data.length / maxItemCount) + 1;
        console.log(splitCount);
        for (let sp = 1; sp <= splitCount; sp++) {
          const excelJson = this.parseExcelJson(data.splice(0, maxItemCount));

          console.log('excelJson');

          const ws = xlsx.utils.json_to_sheet(excelJson, {
            skipHeader: true,
          });

          console.log('ws');
          // console.log(ws);

          const wb = xlsx.utils.book_new();

          ws['!cols'] = [];
          ws['!cols'][1] = { width: 15 };
          ws['!cols'][3] = { width: 23 };
          ws['!cols'][4] = { width: 20 };

          xlsx.utils.book_append_sheet(wb, ws, `예치금내역(본사)_${sp}`);
          await xlsx.writeFile(wb, `예치금내역(본사)_${sp}.xlsx`);
        }

      }
    );
  };

  getExcelList = async () => {
    let category = this.state.category;
    let userId = this.state.searchId;
    let memo = this.state.memo;

    this.progressBarFlag = true;

    const loopGetList = async () => {
      try {
        const res = await httpPostWithoutLoading(
          httpUrl.depositConnect9ListExcel,
          [],
          {
            category,
            userId,
            startDate:
              this.state.paginationExcel.current.format('YYYY-MM-DD HH:mm'),
            endDate: this.state.paginationExcel.current
              .clone()
              .add('10', 'm')
              .format('YYYY-MM-DD HH:mm'),
            memo,
            withPagination: false,
          }
        );
        console.log(res);
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    };

    let totalRes = [];
    let tryCnt = 0;

    let failedPages = [];

    const endDatePlus1Day = moment(this.state.endDate).clone().add('1', 'day');

    while (this.progressBarFlag) {
      if (moment(this.state.paginationExcel.current) >= endDatePlus1Day) break;

      try {
        const loopRes = await loopGetList();
        if (loopRes.data.ncash instanceof Array) {
          this.setState(
            {
              paginationExcel: {
                ...this.state.paginationExcel,
                current: this.state.paginationExcel.current
                  .clone()
                  .add('10', 'm')
              },
            },
            () => console.log(this.state.paginationExcel.current)
          );

          totalRes = totalRes.concat(loopRes.data.ncash);
          tryCnt = 0;
        } else {
          tryCnt++;
        }
      } catch (e) {
        console.error(e);
        tryCnt++;
      }
      if (tryCnt > 5) break;
    }

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });

    if (tryCnt > 5) {
      Modal.info({
        title: '오류',
        content: <div>다운로드 오류</div>,
        onOk() {},
      });
      return null;
    }
    else {
      totalRes.sort((a, b) => a.idx - b.idx);
      return totalRes;
    }
  };

  quitProgressBar = () => {
    this.progressBarFlag = false;

    this.setState({
      progressBarOn: false,
      paginationExcel: initialPaginationExcel,
    });
  };

  render() {
    const columns = [
      {
        title: '아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '이름',
        dataIndex: 'userName',
        className: 'table-column-center',
      },
      {
        title: '지점명',
        dataIndex: 'relationTargetBranchName',
        className: 'table-column-center',
      },
      {
        title: '카테고리',
        dataIndex: 'category',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {categoryStatus[data] +
              (data == 'NCASH_SEND_BY_ADMIN'
                ? '-' + adminSendKindString[row.kind]
                : '')}
          </div>
        ),
      },
      {
        title: '메모',
        dataIndex: 'memo',
        className: 'table-column-center',
        render: (data, row) => (
          <div>
            {row.category === 'BATCH_WORK_NCASH'
              ? row.batchTitle
                ? kindString[row.kind] + '[' + row.batchTitle + ']'
                : '-'
              : data}
          </div>
        ),
      },
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDate(data)}</div>,
      },
      {
        title: '금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data - row.ncashDeltaMisu)}원</div>,
      },
      {
        title: '잔액',
        dataIndex: 'afterNcash',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(data)}원</div>,
      },
      {
        title: '미수금증감분',
        dataIndex: 'ncashDeltaMisu',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
      {
        title: '미수금',
        dataIndex: 'misu',
        className: 'table-column-center',
        render: (data, row) => <div>{comma(row.misu1 + row.misu2 + row.misu3)}원</div>,
      },
      {
        title: '대상',
        dataIndex: 'relationTarget',
        className: 'table-column-center',
      },
      {
        title: '비고',
        dataIndex: 'idx',
        className: 'table-column-center',
        render: (data, row) => {
          if (row.category == 'DELIVERY_PRICE_RECEIVE') {
            let deliveryPrice = parseInt(row.deliveryPrice);
            let deliveryPriceVat = parseInt(row.deliveryPrice * 0.1);
            let deliveryPriceFee = row.deliveryPriceFee
              ? parseInt(row.deliveryPriceFee)
              : 0;
            let deliveryPriceFeeByDpBasic = row.deliveryPriceFeeByDpBasic;
            return (
              '주문번호:' +
              row.orderIdx +
              ',배달비:' +
              deliveryPrice +
              ',부가세:' +
              deliveryPriceVat +
              ',수수료:' +
              deliveryPriceFee +
              ',건별가맹비:' +
              row.duesDeliveryPrice +
            ',건별수수료:' +
            (row.deliveryPriceFeeByDpBasic || 0)

          );
          }
          if (row.category == 'DUES_PAY') {
            return row.relationBusinessNumber;
          }
          if (row.category == 'BATCH_WORK_NCASH') {
            return row.targetBranchName;
          }
        },
      },
      {
        title: '취소/복원여부',
        dataIndex: 'maxLogIdx',
        className: 'table-column-center',
        render: (data, item) => (
          <div>
            {(item.category == 'DELIVERY_PRICE_RECEIVE' ||
              item.category == 'DELIVERY_PRICE_SEND') &&
            item.maxLogIdx
              ? '복원'
              : item.orderStatus == 5
              ? '취소'
              : ''}
          </div>
        ),
      },
      {
        title: '반환',
        dataIndex: 'duesRestore',
        className: 'table-column-center',
        render: (data, row) => {
          let receivedUserId = '';
          let receivedUserIdx = 0;

          //   row.relationLogs.forEach((value) => {
          //     if (value.userId !== "connect9") {
          //       receivedUserId = value.userId;
          //       receivedUserIdx = value.userIdx;
          //     }
          //   });

          if (row.category == 'DUES_PAY') {
            return data ? (
              <div>{data}</div>
            ) : (
              <Button
                onClick={() =>
                  this.duesRestore(
                    row.ncashDelta,
                    row.idx,
                    receivedUserIdx,
                    receivedUserId
                  )
                }>
                반환
              </Button>
            );
          }
        },
      },
      {
        title: '상세내용',
        dataIndex: 'updateContent',
        className: 'table-column-center',
      },
    ];
    return (
      <>
        {
          this.state.transferCompanyDialogOpen &&
          <TranferCompanyDialog
            close={() => this.setState({
              transferCompanyDialogOpen:false,
            })}
            getList={this.getList}
          />
        }
        {this.state.progressBarOn && (
          <ProgressBar
            current={
              (this.state.paginationExcel.current -
                moment(this.state.startDate)) /
                600000
            }
            total={this.state.paginationExcel.total / 600000}
            quit={this.quitProgressBar}
          />
        )}

        <SelectBox
          placeholder={'전체'}
          style={{ width: 200, marginBottom: 20 }}
          value={categoryStatus[this.state.category]}
          code={Object.keys(categoryStatus)}
          codeString={categoryStatus}
          onChange={(value) => {
            if (parseInt(value) !== this.state.category) {
              this.onChangeStatus(value);
            }
          }}
        />
        <Search
          placeholder="아이디,이름 검색"
          enterButton
          allowClear
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginLeft: 10,
            marginBottom: 20,
          }}
        />
        <Search
          placeholder="메모 검색"
          enterButton
          allowClear
          onSearch={this.onSearchMemo}
          style={{
            width: 220,
            marginLeft: 10,
            marginBottom: 20,
          }}
        />
        <Button style={{marginLeft:20}} onClick={() => this.setState({
          transferCompanyDialogOpen: true
        })}>
          본사출금기능
        </Button>

        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}

        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          defaultValue={[today, today]}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            } else {
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                }
              );
            }
          }}
        />
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={false}
        />
        <div className="page-btn">
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.pagination.current <= 1) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current - 1;
              this.setState({ pagination }, this.getList);
            }}>
            이전
          </Button>
          <Button
            style={{ margin: '10px' }}
            onClick={() => {
              if (this.state.list.length == 0) return;

              const pagination = { ...this.state.pagination };
              pagination.current = pagination.current + 1;
              this.setState({ pagination }, this.getList);
            }}>
            다음
          </Button>
        </div>
      </>
    );
  }
}

export default DepositConnectHistory;
