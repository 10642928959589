// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from '@nivo/line';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const LineGraph = ({ data, close /* see data tab */ }) => {
  const exData = [
    {
      id: 'japan',
      color: 'hsl(158, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 61,
        },
        {
          x: 1,
          y: 208,
        },
        {
          x: 2,
          y: 119,
        },
        {
          x: 3,
          y: 153,
        },
        {
          x: 4,
          y: 235,
        },
        {
          x: 5,
          y: 212,
        },
        {
          x: 6,
          y: 68,
        },
        {
          x: 7,
          y: 37,
        },
        {
          x: 8,
          y: 190,
        },
        {
          x: 9,
          y: 288,
        },
        {
          x: 10,
          y: 227,
        },
        {
          x: 11,
          y: 169,
        },
        {
          x: 12,
          y: 174,
        },
        {
          x: 13,
          y: 7,
        },
        {
          x: 14,
          y: 207,
        },
        {
          x: 15,
          y: 6,
        },
        {
          x: 16,
          y: 97,
        },
        {
          x: 17,
          y: 63,
        },
        {
          x: 18,
          y: 178,
        },
        {
          x: 19,
          y: 298,
        },
        {
          x: 20,
          y: 97,
        },
        {
          x: 21,
          y: 227,
        },
        {
          x: 22,
          y: 19,
        },
        {
          x: 23,
          y: 25,
        },
        {
          x: 24,
          y: 109,
        },
        {
          x: 25,
          y: 54,
        },
        {
          x: 26,
          y: 191,
        },
        {
          x: 27,
          y: 295,
        },
        {
          x: 28,
          y: 136,
        },
        {
          x: 29,
          y: 31,
        },
        {
          x: 30,
          y: 86,
        },
        {
          x: 31,
          y: 38,
        },
        {
          x: 32,
          y: 131,
        },
        {
          x: 33,
          y: 151,
        },
        {
          x: 34,
          y: 263,
        },
        {
          x: 35,
          y: 49,
        },
        {
          x: 36,
          y: 252,
        },
        {
          x: 37,
          y: 23,
        },
        {
          x: 38,
          y: 259,
        },
        {
          x: 39,
          y: 220,
        },
        {
          x: 40,
          y: 285,
        },
        {
          x: 41,
          y: 103,
        },
        {
          x: 42,
          y: 295,
        },
        {
          x: 43,
          y: 59,
        },
        {
          x: 44,
          y: 269,
        },
        {
          x: 45,
          y: 246,
        },
        {
          x: 46,
          y: 45,
        },
        {
          x: 47,
          y: 197,
        },
        {
          x: 48,
          y: 117,
        },
        {
          x: 49,
          y: 28,
        },
        {
          x: 50,
          y: 106,
        },
        {
          x: 51,
          y: 46,
        },
        {
          x: 52,
          y: 259,
        },
        {
          x: 53,
          y: 69,
        },
        {
          x: 54,
          y: 245,
        },
        {
          x: 55,
          y: 107,
        },
        {
          x: 56,
          y: 79,
        },
        {
          x: 57,
          y: 148,
        },
        {
          x: 58,
          y: 277,
        },
        {
          x: 59,
          y: 63,
        },
        {
          x: 60,
          y: 116,
        },
        {
          x: 61,
          y: 41,
        },
        {
          x: 62,
          y: 280,
        },
        {
          x: 63,
          y: 53,
        },
        {
          x: 64,
          y: 276,
        },
        {
          x: 65,
          y: 80,
        },
        {
          x: 66,
          y: 146,
        },
        {
          x: 67,
          y: 37,
        },
        {
          x: 68,
          y: 291,
        },
        {
          x: 69,
          y: 217,
        },
        {
          x: 70,
          y: 206,
        },
        {
          x: 71,
          y: 45,
        },
        {
          x: 72,
          y: 121,
        },
        {
          x: 73,
          y: 157,
        },
        {
          x: 74,
          y: 65,
        },
        {
          x: 75,
          y: 87,
        },
        {
          x: 76,
          y: 86,
        },
        {
          x: 77,
          y: 119,
        },
        {
          x: 78,
          y: 275,
        },
        {
          x: 79,
          y: 286,
        },
        {
          x: 80,
          y: 165,
        },
        {
          x: 81,
          y: 192,
        },
        {
          x: 82,
          y: 239,
        },
        {
          x: 83,
          y: 233,
        },
        {
          x: 84,
          y: 75,
        },
        {
          x: 85,
          y: 131,
        },
        {
          x: 86,
          y: 205,
        },
        {
          x: 87,
          y: 1,
        },
        {
          x: 88,
          y: 226,
        },
        {
          x: 89,
          y: 226,
        },
        {
          x: 90,
          y: 286,
        },
        {
          x: 91,
          y: 58,
        },
        {
          x: 92,
          y: 66,
        },
        {
          x: 93,
          y: 273,
        },
        {
          x: 94,
          y: 53,
        },
        {
          x: 95,
          y: 229,
        },
        {
          x: 96,
          y: 219,
        },
        {
          x: 97,
          y: 171,
        },
        {
          x: 98,
          y: 67,
        },
        {
          x: 99,
          y: 146,
        },
        {
          x: 100,
          y: 167,
        },
        {
          x: 101,
          y: 94,
        },
        {
          x: 102,
          y: 254,
        },
        {
          x: 103,
          y: 180,
        },
        {
          x: 104,
          y: 141,
        },
        {
          x: 105,
          y: 227,
        },
        {
          x: 106,
          y: 295,
        },
        {
          x: 107,
          y: 229,
        },
        {
          x: 108,
          y: 263,
        },
        {
          x: 109,
          y: 103,
        },
        {
          x: 110,
          y: 19,
        },
        {
          x: 111,
          y: 198,
        },
        {
          x: 112,
          y: 50,
        },
        {
          x: 113,
          y: 185,
        },
        {
          x: 114,
          y: 106,
        },
        {
          x: 115,
          y: 58,
        },
        {
          x: 116,
          y: 201,
        },
        {
          x: 117,
          y: 59,
        },
        {
          x: 118,
          y: 259,
        },
        {
          x: 119,
          y: 145,
        },
        {
          x: 120,
          y: 203,
        },
      ],
    },
    {
      id: 'brazil',
      color: 'hsl(250, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 108,
        },
        {
          x: 1,
          y: 199,
        },
        {
          x: 2,
          y: 275,
        },
        {
          x: 3,
          y: 72,
        },
        {
          x: 4,
          y: 278,
        },
        {
          x: 5,
          y: 58,
        },
        {
          x: 6,
          y: 37,
        },
        {
          x: 7,
          y: 0,
        },
        {
          x: 8,
          y: 63,
        },
        {
          x: 9,
          y: 78,
        },
        {
          x: 10,
          y: 299,
        },
        {
          x: 11,
          y: 230,
        },
        {
          x: 12,
          y: 194,
        },
        {
          x: 13,
          y: 61,
        },
        {
          x: 14,
          y: 117,
        },
        {
          x: 15,
          y: 157,
        },
        {
          x: 16,
          y: 153,
        },
        {
          x: 17,
          y: 123,
        },
        {
          x: 18,
          y: 230,
        },
        {
          x: 19,
          y: 165,
        },
        {
          x: 20,
          y: 280,
        },
        {
          x: 21,
          y: 229,
        },
        {
          x: 22,
          y: 216,
        },
        {
          x: 23,
          y: 142,
        },
        {
          x: 24,
          y: 60,
        },
        {
          x: 25,
          y: 89,
        },
        {
          x: 26,
          y: 1,
        },
        {
          x: 27,
          y: 266,
        },
        {
          x: 28,
          y: 256,
        },
        {
          x: 29,
          y: 207,
        },
        {
          x: 30,
          y: 231,
        },
        {
          x: 31,
          y: 282,
        },
        {
          x: 32,
          y: 237,
        },
        {
          x: 33,
          y: 188,
        },
        {
          x: 34,
          y: 206,
        },
        {
          x: 35,
          y: 92,
        },
        {
          x: 36,
          y: 244,
        },
        {
          x: 37,
          y: 44,
        },
        {
          x: 38,
          y: 34,
        },
        {
          x: 39,
          y: 75,
        },
        {
          x: 40,
          y: 66,
        },
        {
          x: 41,
          y: 84,
        },
        {
          x: 42,
          y: 230,
        },
        {
          x: 43,
          y: 29,
        },
        {
          x: 44,
          y: 135,
        },
        {
          x: 45,
          y: 156,
        },
        {
          x: 46,
          y: 3,
        },
        {
          x: 47,
          y: 26,
        },
        {
          x: 48,
          y: 179,
        },
        {
          x: 49,
          y: 135,
        },
        {
          x: 50,
          y: 246,
        },
        {
          x: 51,
          y: 262,
        },
        {
          x: 52,
          y: 230,
        },
        {
          x: 53,
          y: 145,
        },
        {
          x: 54,
          y: 174,
        },
        {
          x: 55,
          y: 2,
        },
        {
          x: 56,
          y: 258,
        },
        {
          x: 57,
          y: 188,
        },
        {
          x: 58,
          y: 114,
        },
        {
          x: 59,
          y: 196,
        },
        {
          x: 60,
          y: 132,
        },
        {
          x: 61,
          y: 46,
        },
        {
          x: 62,
          y: 55,
        },
        {
          x: 63,
          y: 266,
        },
        {
          x: 64,
          y: 115,
        },
        {
          x: 65,
          y: 228,
        },
        {
          x: 66,
          y: 160,
        },
        {
          x: 67,
          y: 158,
        },
        {
          x: 68,
          y: 0,
        },
        {
          x: 69,
          y: 68,
        },
        {
          x: 70,
          y: 241,
        },
        {
          x: 71,
          y: 185,
        },
        {
          x: 72,
          y: 63,
        },
        {
          x: 73,
          y: 201,
        },
        {
          x: 74,
          y: 196,
        },
        {
          x: 75,
          y: 3,
        },
        {
          x: 76,
          y: 49,
        },
        {
          x: 77,
          y: 178,
        },
        {
          x: 78,
          y: 191,
        },
        {
          x: 79,
          y: 260,
        },
        {
          x: 80,
          y: 99,
        },
        {
          x: 81,
          y: 67,
        },
        {
          x: 82,
          y: 208,
        },
        {
          x: 83,
          y: 41,
        },
        {
          x: 84,
          y: 232,
        },
        {
          x: 85,
          y: 89,
        },
        {
          x: 86,
          y: 106,
        },
        {
          x: 87,
          y: 176,
        },
        {
          x: 88,
          y: 221,
        },
        {
          x: 89,
          y: 127,
        },
        {
          x: 90,
          y: 157,
        },
        {
          x: 91,
          y: 264,
        },
        {
          x: 92,
          y: 79,
        },
        {
          x: 93,
          y: 284,
        },
        {
          x: 94,
          y: 191,
        },
        {
          x: 95,
          y: 158,
        },
        {
          x: 96,
          y: 237,
        },
        {
          x: 97,
          y: 207,
        },
        {
          x: 98,
          y: 218,
        },
        {
          x: 99,
          y: 89,
        },
        {
          x: 100,
          y: 56,
        },
        {
          x: 101,
          y: 271,
        },
        {
          x: 102,
          y: 145,
        },
        {
          x: 103,
          y: 94,
        },
        {
          x: 104,
          y: 100,
        },
        {
          x: 105,
          y: 20,
        },
        {
          x: 106,
          y: 54,
        },
        {
          x: 107,
          y: 209,
        },
        {
          x: 108,
          y: 94,
        },
        {
          x: 109,
          y: 206,
        },
        {
          x: 110,
          y: 138,
        },
        {
          x: 111,
          y: 272,
        },
        {
          x: 112,
          y: 100,
        },
        {
          x: 113,
          y: 56,
        },
        {
          x: 114,
          y: 185,
        },
        {
          x: 115,
          y: 109,
        },
        {
          x: 116,
          y: 194,
        },
        {
          x: 117,
          y: 61,
        },
        {
          x: 118,
          y: 283,
        },
        {
          x: 119,
          y: 185,
        },
        {
          x: 120,
          y: 263,
        },
      ],
    },
    {
      id: 'france',
      color: 'hsl(155, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 261,
        },
        {
          x: 1,
          y: 148,
        },
        {
          x: 2,
          y: 132,
        },
        {
          x: 3,
          y: 158,
        },
        {
          x: 4,
          y: 68,
        },
        {
          x: 5,
          y: 193,
        },
        {
          x: 6,
          y: 283,
        },
        {
          x: 7,
          y: 267,
        },
        {
          x: 8,
          y: 20,
        },
        {
          x: 9,
          y: 202,
        },
        {
          x: 10,
          y: 225,
        },
        {
          x: 11,
          y: 68,
        },
        {
          x: 12,
          y: 2,
        },
        {
          x: 13,
          y: 72,
        },
        {
          x: 14,
          y: 48,
        },
        {
          x: 15,
          y: 180,
        },
        {
          x: 16,
          y: 14,
        },
        {
          x: 17,
          y: 233,
        },
        {
          x: 18,
          y: 280,
        },
        {
          x: 19,
          y: 102,
        },
        {
          x: 20,
          y: 170,
        },
        {
          x: 21,
          y: 135,
        },
        {
          x: 22,
          y: 182,
        },
        {
          x: 23,
          y: 138,
        },
        {
          x: 24,
          y: 25,
        },
        {
          x: 25,
          y: 163,
        },
        {
          x: 26,
          y: 122,
        },
        {
          x: 27,
          y: 288,
        },
        {
          x: 28,
          y: 298,
        },
        {
          x: 29,
          y: 235,
        },
        {
          x: 30,
          y: 170,
        },
        {
          x: 31,
          y: 215,
        },
        {
          x: 32,
          y: 67,
        },
        {
          x: 33,
          y: 160,
        },
        {
          x: 34,
          y: 10,
        },
        {
          x: 35,
          y: 55,
        },
        {
          x: 36,
          y: 78,
        },
        {
          x: 37,
          y: 140,
        },
        {
          x: 38,
          y: 139,
        },
        {
          x: 39,
          y: 144,
        },
        {
          x: 40,
          y: 148,
        },
        {
          x: 41,
          y: 177,
        },
        {
          x: 42,
          y: 240,
        },
        {
          x: 43,
          y: 81,
        },
        {
          x: 44,
          y: 150,
        },
        {
          x: 45,
          y: 65,
        },
        {
          x: 46,
          y: 1,
        },
        {
          x: 47,
          y: 282,
        },
        {
          x: 48,
          y: 109,
        },
        {
          x: 49,
          y: 59,
        },
        {
          x: 50,
          y: 177,
        },
        {
          x: 51,
          y: 76,
        },
        {
          x: 52,
          y: 255,
        },
        {
          x: 53,
          y: 88,
        },
        {
          x: 54,
          y: 33,
        },
        {
          x: 55,
          y: 85,
        },
        {
          x: 56,
          y: 248,
        },
        {
          x: 57,
          y: 94,
        },
        {
          x: 58,
          y: 107,
        },
        {
          x: 59,
          y: 241,
        },
        {
          x: 60,
          y: 179,
        },
        {
          x: 61,
          y: 129,
        },
        {
          x: 62,
          y: 75,
        },
        {
          x: 63,
          y: 280,
        },
        {
          x: 64,
          y: 40,
        },
        {
          x: 65,
          y: 124,
        },
        {
          x: 66,
          y: 89,
        },
        {
          x: 67,
          y: 95,
        },
        {
          x: 68,
          y: 241,
        },
        {
          x: 69,
          y: 5,
        },
        {
          x: 70,
          y: 103,
        },
        {
          x: 71,
          y: 217,
        },
        {
          x: 72,
          y: 9,
        },
        {
          x: 73,
          y: 297,
        },
        {
          x: 74,
          y: 28,
        },
        {
          x: 75,
          y: 218,
        },
        {
          x: 76,
          y: 205,
        },
        {
          x: 77,
          y: 240,
        },
        {
          x: 78,
          y: 89,
        },
        {
          x: 79,
          y: 273,
        },
        {
          x: 80,
          y: 269,
        },
        {
          x: 81,
          y: 169,
        },
        {
          x: 82,
          y: 163,
        },
        {
          x: 83,
          y: 260,
        },
        {
          x: 84,
          y: 237,
        },
        {
          x: 85,
          y: 164,
        },
        {
          x: 86,
          y: 298,
        },
        {
          x: 87,
          y: 56,
        },
        {
          x: 88,
          y: 35,
        },
        {
          x: 89,
          y: 151,
        },
        {
          x: 90,
          y: 48,
        },
        {
          x: 91,
          y: 105,
        },
        {
          x: 92,
          y: 30,
        },
        {
          x: 93,
          y: 19,
        },
        {
          x: 94,
          y: 196,
        },
        {
          x: 95,
          y: 290,
        },
        {
          x: 96,
          y: 69,
        },
        {
          x: 97,
          y: 57,
        },
        {
          x: 98,
          y: 261,
        },
        {
          x: 99,
          y: 78,
        },
        {
          x: 100,
          y: 114,
        },
        {
          x: 101,
          y: 209,
        },
        {
          x: 102,
          y: 255,
        },
        {
          x: 103,
          y: 200,
        },
        {
          x: 104,
          y: 119,
        },
        {
          x: 105,
          y: 6,
        },
        {
          x: 106,
          y: 182,
        },
        {
          x: 107,
          y: 25,
        },
        {
          x: 108,
          y: 128,
        },
        {
          x: 109,
          y: 120,
        },
        {
          x: 110,
          y: 245,
        },
        {
          x: 111,
          y: 90,
        },
        {
          x: 112,
          y: 249,
        },
        {
          x: 113,
          y: 127,
        },
        {
          x: 114,
          y: 193,
        },
        {
          x: 115,
          y: 16,
        },
        {
          x: 116,
          y: 180,
        },
        {
          x: 117,
          y: 1,
        },
        {
          x: 118,
          y: 17,
        },
        {
          x: 119,
          y: 227,
        },
        {
          x: 120,
          y: 150,
        },
      ],
    },
    {
      id: 'us',
      color: 'hsl(42, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 124,
        },
        {
          x: 1,
          y: 297,
        },
        {
          x: 2,
          y: 230,
        },
        {
          x: 3,
          y: 195,
        },
        {
          x: 4,
          y: 97,
        },
        {
          x: 5,
          y: 90,
        },
        {
          x: 6,
          y: 22,
        },
        {
          x: 7,
          y: 273,
        },
        {
          x: 8,
          y: 72,
        },
        {
          x: 9,
          y: 86,
        },
        {
          x: 10,
          y: 112,
        },
        {
          x: 11,
          y: 132,
        },
        {
          x: 12,
          y: 16,
        },
        {
          x: 13,
          y: 295,
        },
        {
          x: 14,
          y: 271,
        },
        {
          x: 15,
          y: 2,
        },
        {
          x: 16,
          y: 139,
        },
        {
          x: 17,
          y: 204,
        },
        {
          x: 18,
          y: 129,
        },
        {
          x: 19,
          y: 163,
        },
        {
          x: 20,
          y: 26,
        },
        {
          x: 21,
          y: 250,
        },
        {
          x: 22,
          y: 39,
        },
        {
          x: 23,
          y: 74,
        },
        {
          x: 24,
          y: 169,
        },
        {
          x: 25,
          y: 171,
        },
        {
          x: 26,
          y: 255,
        },
        {
          x: 27,
          y: 241,
        },
        {
          x: 28,
          y: 206,
        },
        {
          x: 29,
          y: 37,
        },
        {
          x: 30,
          y: 252,
        },
        {
          x: 31,
          y: 251,
        },
        {
          x: 32,
          y: 22,
        },
        {
          x: 33,
          y: 76,
        },
        {
          x: 34,
          y: 194,
        },
        {
          x: 35,
          y: 101,
        },
        {
          x: 36,
          y: 253,
        },
        {
          x: 37,
          y: 196,
        },
        {
          x: 38,
          y: 204,
        },
        {
          x: 39,
          y: 299,
        },
        {
          x: 40,
          y: 104,
        },
        {
          x: 41,
          y: 47,
        },
        {
          x: 42,
          y: 212,
        },
        {
          x: 43,
          y: 266,
        },
        {
          x: 44,
          y: 53,
        },
        {
          x: 45,
          y: 2,
        },
        {
          x: 46,
          y: 253,
        },
        {
          x: 47,
          y: 298,
        },
        {
          x: 48,
          y: 124,
        },
        {
          x: 49,
          y: 265,
        },
        {
          x: 50,
          y: 252,
        },
        {
          x: 51,
          y: 115,
        },
        {
          x: 52,
          y: 228,
        },
        {
          x: 53,
          y: 282,
        },
        {
          x: 54,
          y: 33,
        },
        {
          x: 55,
          y: 122,
        },
        {
          x: 56,
          y: 104,
        },
        {
          x: 57,
          y: 196,
        },
        {
          x: 58,
          y: 185,
        },
        {
          x: 59,
          y: 157,
        },
        {
          x: 60,
          y: 69,
        },
        {
          x: 61,
          y: 214,
        },
        {
          x: 62,
          y: 14,
        },
        {
          x: 63,
          y: 130,
        },
        {
          x: 64,
          y: 213,
        },
        {
          x: 65,
          y: 32,
        },
        {
          x: 66,
          y: 45,
        },
        {
          x: 67,
          y: 219,
        },
        {
          x: 68,
          y: 293,
        },
        {
          x: 69,
          y: 93,
        },
        {
          x: 70,
          y: 218,
        },
        {
          x: 71,
          y: 191,
        },
        {
          x: 72,
          y: 153,
        },
        {
          x: 73,
          y: 162,
        },
        {
          x: 74,
          y: 242,
        },
        {
          x: 75,
          y: 11,
        },
        {
          x: 76,
          y: 234,
        },
        {
          x: 77,
          y: 205,
        },
        {
          x: 78,
          y: 220,
        },
        {
          x: 79,
          y: 209,
        },
        {
          x: 80,
          y: 230,
        },
        {
          x: 81,
          y: 134,
        },
        {
          x: 82,
          y: 252,
        },
        {
          x: 83,
          y: 286,
        },
        {
          x: 84,
          y: 75,
        },
        {
          x: 85,
          y: 287,
        },
        {
          x: 86,
          y: 83,
        },
        {
          x: 87,
          y: 34,
        },
        {
          x: 88,
          y: 79,
        },
        {
          x: 89,
          y: 49,
        },
        {
          x: 90,
          y: 44,
        },
        {
          x: 91,
          y: 113,
        },
        {
          x: 92,
          y: 280,
        },
        {
          x: 93,
          y: 291,
        },
        {
          x: 94,
          y: 157,
        },
        {
          x: 95,
          y: 44,
        },
        {
          x: 96,
          y: 165,
        },
        {
          x: 97,
          y: 192,
        },
        {
          x: 98,
          y: 50,
        },
        {
          x: 99,
          y: 66,
        },
        {
          x: 100,
          y: 292,
        },
        {
          x: 101,
          y: 38,
        },
        {
          x: 102,
          y: 97,
        },
        {
          x: 103,
          y: 71,
        },
        {
          x: 104,
          y: 241,
        },
        {
          x: 105,
          y: 239,
        },
        {
          x: 106,
          y: 196,
        },
        {
          x: 107,
          y: 92,
        },
        {
          x: 108,
          y: 133,
        },
        {
          x: 109,
          y: 281,
        },
        {
          x: 110,
          y: 170,
        },
        {
          x: 111,
          y: 35,
        },
        {
          x: 112,
          y: 190,
        },
        {
          x: 113,
          y: 190,
        },
        {
          x: 114,
          y: 121,
        },
        {
          x: 115,
          y: 173,
        },
        {
          x: 116,
          y: 258,
        },
        {
          x: 117,
          y: 254,
        },
        {
          x: 118,
          y: 186,
        },
        {
          x: 119,
          y: 90,
        },
        {
          x: 120,
          y: 48,
        },
      ],
    },
    {
      id: 'germany',
      color: 'hsl(214, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 130,
        },
        {
          x: 1,
          y: 87,
        },
        {
          x: 2,
          y: 129,
        },
        {
          x: 3,
          y: 268,
        },
        {
          x: 4,
          y: 247,
        },
        {
          x: 5,
          y: 136,
        },
        {
          x: 6,
          y: 125,
        },
        {
          x: 7,
          y: 126,
        },
        {
          x: 8,
          y: 197,
        },
        {
          x: 9,
          y: 198,
        },
        {
          x: 10,
          y: 84,
        },
        {
          x: 11,
          y: 298,
        },
        {
          x: 12,
          y: 212,
        },
        {
          x: 13,
          y: 57,
        },
        {
          x: 14,
          y: 193,
        },
        {
          x: 15,
          y: 149,
        },
        {
          x: 16,
          y: 248,
        },
        {
          x: 17,
          y: 161,
        },
        {
          x: 18,
          y: 165,
        },
        {
          x: 19,
          y: 105,
        },
        {
          x: 20,
          y: 119,
        },
        {
          x: 21,
          y: 155,
        },
        {
          x: 22,
          y: 178,
        },
        {
          x: 23,
          y: 155,
        },
        {
          x: 24,
          y: 286,
        },
        {
          x: 25,
          y: 148,
        },
        {
          x: 26,
          y: 245,
        },
        {
          x: 27,
          y: 141,
        },
        {
          x: 28,
          y: 26,
        },
        {
          x: 29,
          y: 70,
        },
        {
          x: 30,
          y: 19,
        },
        {
          x: 31,
          y: 202,
        },
        {
          x: 32,
          y: 47,
        },
        {
          x: 33,
          y: 241,
        },
        {
          x: 34,
          y: 154,
        },
        {
          x: 35,
          y: 203,
        },
        {
          x: 36,
          y: 151,
        },
        {
          x: 37,
          y: 170,
        },
        {
          x: 38,
          y: 293,
        },
        {
          x: 39,
          y: 75,
        },
        {
          x: 40,
          y: 110,
        },
        {
          x: 41,
          y: 192,
        },
        {
          x: 42,
          y: 104,
        },
        {
          x: 43,
          y: 247,
        },
        {
          x: 44,
          y: 73,
        },
        {
          x: 45,
          y: 149,
        },
        {
          x: 46,
          y: 263,
        },
        {
          x: 47,
          y: 5,
        },
        {
          x: 48,
          y: 151,
        },
        {
          x: 49,
          y: 226,
        },
        {
          x: 50,
          y: 152,
        },
        {
          x: 51,
          y: 154,
        },
        {
          x: 52,
          y: 31,
        },
        {
          x: 53,
          y: 60,
        },
        {
          x: 54,
          y: 273,
        },
        {
          x: 55,
          y: 190,
        },
        {
          x: 56,
          y: 125,
        },
        {
          x: 57,
          y: 287,
        },
        {
          x: 58,
          y: 136,
        },
        {
          x: 59,
          y: 209,
        },
        {
          x: 60,
          y: 158,
        },
        {
          x: 61,
          y: 174,
        },
        {
          x: 62,
          y: 26,
        },
        {
          x: 63,
          y: 276,
        },
        {
          x: 64,
          y: 1,
        },
        {
          x: 65,
          y: 20,
        },
        {
          x: 66,
          y: 53,
        },
        {
          x: 67,
          y: 240,
        },
        {
          x: 68,
          y: 235,
        },
        {
          x: 69,
          y: 249,
        },
        {
          x: 70,
          y: 264,
        },
        {
          x: 71,
          y: 252,
        },
        {
          x: 72,
          y: 215,
        },
        {
          x: 73,
          y: 197,
        },
        {
          x: 74,
          y: 192,
        },
        {
          x: 75,
          y: 97,
        },
        {
          x: 76,
          y: 0,
        },
        {
          x: 77,
          y: 15,
        },
        {
          x: 78,
          y: 207,
        },
        {
          x: 79,
          y: 244,
        },
        {
          x: 80,
          y: 1,
        },
        {
          x: 81,
          y: 58,
        },
        {
          x: 82,
          y: 190,
        },
        {
          x: 83,
          y: 124,
        },
        {
          x: 84,
          y: 104,
        },
        {
          x: 85,
          y: 124,
        },
        {
          x: 86,
          y: 219,
        },
        {
          x: 87,
          y: 207,
        },
        {
          x: 88,
          y: 262,
        },
        {
          x: 89,
          y: 221,
        },
        {
          x: 90,
          y: 154,
        },
        {
          x: 91,
          y: 56,
        },
        {
          x: 92,
          y: 108,
        },
        {
          x: 93,
          y: 220,
        },
        {
          x: 94,
          y: 271,
        },
        {
          x: 95,
          y: 52,
        },
        {
          x: 96,
          y: 175,
        },
        {
          x: 97,
          y: 55,
        },
        {
          x: 98,
          y: 21,
        },
        {
          x: 99,
          y: 88,
        },
        {
          x: 100,
          y: 149,
        },
        {
          x: 101,
          y: 72,
        },
        {
          x: 102,
          y: 135,
        },
        {
          x: 103,
          y: 157,
        },
        {
          x: 104,
          y: 56,
        },
        {
          x: 105,
          y: 226,
        },
        {
          x: 106,
          y: 21,
        },
        {
          x: 107,
          y: 13,
        },
        {
          x: 108,
          y: 226,
        },
        {
          x: 109,
          y: 47,
        },
        {
          x: 110,
          y: 127,
        },
        {
          x: 111,
          y: 172,
        },
        {
          x: 112,
          y: 95,
        },
        {
          x: 113,
          y: 158,
        },
        {
          x: 114,
          y: 133,
        },
        {
          x: 115,
          y: 82,
        },
        {
          x: 116,
          y: 40,
        },
        {
          x: 117,
          y: 199,
        },
        {
          x: 118,
          y: 134,
        },
        {
          x: 119,
          y: 278,
        },
        {
          x: 120,
          y: 176,
        },
      ],
    },
    {
      id: 'norway',
      color: 'hsl(113, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 96,
        },
        {
          x: 1,
          y: 246,
        },
        {
          x: 2,
          y: 201,
        },
        {
          x: 3,
          y: 180,
        },
        {
          x: 4,
          y: 269,
        },
        {
          x: 5,
          y: 110,
        },
        {
          x: 6,
          y: 127,
        },
        {
          x: 7,
          y: 165,
        },
        {
          x: 8,
          y: 239,
        },
        {
          x: 9,
          y: 280,
        },
        {
          x: 10,
          y: 256,
        },
        {
          x: 11,
          y: 159,
        },
        {
          x: 12,
          y: 178,
        },
        {
          x: 13,
          y: 276,
        },
        {
          x: 14,
          y: 283,
        },
        {
          x: 15,
          y: 36,
        },
        {
          x: 16,
          y: 88,
        },
        {
          x: 17,
          y: 229,
        },
        {
          x: 18,
          y: 243,
        },
        {
          x: 19,
          y: 178,
        },
        {
          x: 20,
          y: 122,
        },
        {
          x: 21,
          y: 282,
        },
        {
          x: 22,
          y: 194,
        },
        {
          x: 23,
          y: 218,
        },
        {
          x: 24,
          y: 183,
        },
        {
          x: 25,
          y: 83,
        },
        {
          x: 26,
          y: 31,
        },
        {
          x: 27,
          y: 176,
        },
        {
          x: 28,
          y: 273,
        },
        {
          x: 29,
          y: 287,
        },
        {
          x: 30,
          y: 188,
        },
        {
          x: 31,
          y: 80,
        },
        {
          x: 32,
          y: 278,
        },
        {
          x: 33,
          y: 51,
        },
        {
          x: 34,
          y: 228,
        },
        {
          x: 35,
          y: 211,
        },
        {
          x: 36,
          y: 173,
        },
        {
          x: 37,
          y: 264,
        },
        {
          x: 38,
          y: 210,
        },
        {
          x: 39,
          y: 78,
        },
        {
          x: 40,
          y: 199,
        },
        {
          x: 41,
          y: 168,
        },
        {
          x: 42,
          y: 223,
        },
        {
          x: 43,
          y: 24,
        },
        {
          x: 44,
          y: 79,
        },
        {
          x: 45,
          y: 290,
        },
        {
          x: 46,
          y: 107,
        },
        {
          x: 47,
          y: 284,
        },
        {
          x: 48,
          y: 75,
        },
        {
          x: 49,
          y: 280,
        },
        {
          x: 50,
          y: 113,
        },
        {
          x: 51,
          y: 164,
        },
        {
          x: 52,
          y: 19,
        },
        {
          x: 53,
          y: 126,
        },
        {
          x: 54,
          y: 15,
        },
        {
          x: 55,
          y: 69,
        },
        {
          x: 56,
          y: 16,
        },
        {
          x: 57,
          y: 159,
        },
        {
          x: 58,
          y: 252,
        },
        {
          x: 59,
          y: 262,
        },
        {
          x: 60,
          y: 244,
        },
        {
          x: 61,
          y: 300,
        },
        {
          x: 62,
          y: 214,
        },
        {
          x: 63,
          y: 280,
        },
        {
          x: 64,
          y: 168,
        },
        {
          x: 65,
          y: 151,
        },
        {
          x: 66,
          y: 187,
        },
        {
          x: 67,
          y: 226,
        },
        {
          x: 68,
          y: 8,
        },
        {
          x: 69,
          y: 117,
        },
        {
          x: 70,
          y: 52,
        },
        {
          x: 71,
          y: 100,
        },
        {
          x: 72,
          y: 184,
        },
        {
          x: 73,
          y: 74,
        },
        {
          x: 74,
          y: 222,
        },
        {
          x: 75,
          y: 184,
        },
        {
          x: 76,
          y: 241,
        },
        {
          x: 77,
          y: 278,
        },
        {
          x: 78,
          y: 82,
        },
        {
          x: 79,
          y: 199,
        },
        {
          x: 80,
          y: 26,
        },
        {
          x: 81,
          y: 40,
        },
        {
          x: 82,
          y: 234,
        },
        {
          x: 83,
          y: 178,
        },
        {
          x: 84,
          y: 252,
        },
        {
          x: 85,
          y: 137,
        },
        {
          x: 86,
          y: 299,
        },
        {
          x: 87,
          y: 159,
        },
        {
          x: 88,
          y: 228,
        },
        {
          x: 89,
          y: 112,
        },
        {
          x: 90,
          y: 204,
        },
        {
          x: 91,
          y: 285,
        },
        {
          x: 92,
          y: 137,
        },
        {
          x: 93,
          y: 194,
        },
        {
          x: 94,
          y: 185,
        },
        {
          x: 95,
          y: 276,
        },
        {
          x: 96,
          y: 24,
        },
        {
          x: 97,
          y: 4,
        },
        {
          x: 98,
          y: 34,
        },
        {
          x: 99,
          y: 136,
        },
        {
          x: 100,
          y: 165,
        },
        {
          x: 101,
          y: 61,
        },
        {
          x: 102,
          y: 8,
        },
        {
          x: 103,
          y: 251,
        },
        {
          x: 104,
          y: 146,
        },
        {
          x: 105,
          y: 37,
        },
        {
          x: 106,
          y: 276,
        },
        {
          x: 107,
          y: 75,
        },
        {
          x: 108,
          y: 252,
        },
        {
          x: 109,
          y: 263,
        },
        {
          x: 110,
          y: 260,
        },
        {
          x: 111,
          y: 95,
        },
        {
          x: 112,
          y: 92,
        },
        {
          x: 113,
          y: 176,
        },
        {
          x: 114,
          y: 69,
        },
        {
          x: 115,
          y: 132,
        },
        {
          x: 116,
          y: 70,
        },
        {
          x: 117,
          y: 188,
        },
        {
          x: 118,
          y: 62,
        },
        {
          x: 119,
          y: 29,
        },
        {
          x: 120,
          y: 51,
        },
      ],
    },
    {
      id: 'china',
      color: 'hsl(21, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 257,
        },
        {
          x: 1,
          y: 176,
        },
        {
          x: 2,
          y: 2,
        },
        {
          x: 3,
          y: 24,
        },
        {
          x: 4,
          y: 68,
        },
        {
          x: 5,
          y: 50,
        },
        {
          x: 6,
          y: 77,
        },
        {
          x: 7,
          y: 72,
        },
        {
          x: 8,
          y: 4,
        },
        {
          x: 9,
          y: 174,
        },
        {
          x: 10,
          y: 231,
        },
        {
          x: 11,
          y: 199,
        },
        {
          x: 12,
          y: 164,
        },
        {
          x: 13,
          y: 216,
        },
        {
          x: 14,
          y: 260,
        },
        {
          x: 15,
          y: 176,
        },
        {
          x: 16,
          y: 198,
        },
        {
          x: 17,
          y: 83,
        },
        {
          x: 18,
          y: 188,
        },
        {
          x: 19,
          y: 74,
        },
        {
          x: 20,
          y: 205,
        },
        {
          x: 21,
          y: 169,
        },
        {
          x: 22,
          y: 236,
        },
        {
          x: 23,
          y: 182,
        },
        {
          x: 24,
          y: 3,
        },
        {
          x: 25,
          y: 144,
        },
        {
          x: 26,
          y: 296,
        },
        {
          x: 27,
          y: 274,
        },
        {
          x: 28,
          y: 88,
        },
        {
          x: 29,
          y: 263,
        },
        {
          x: 30,
          y: 70,
        },
        {
          x: 31,
          y: 68,
        },
        {
          x: 32,
          y: 103,
        },
        {
          x: 33,
          y: 4,
        },
        {
          x: 34,
          y: 131,
        },
        {
          x: 35,
          y: 66,
        },
        {
          x: 36,
          y: 216,
        },
        {
          x: 37,
          y: 144,
        },
        {
          x: 38,
          y: 141,
        },
        {
          x: 39,
          y: 206,
        },
        {
          x: 40,
          y: 256,
        },
        {
          x: 41,
          y: 236,
        },
        {
          x: 42,
          y: 224,
        },
        {
          x: 43,
          y: 283,
        },
        {
          x: 44,
          y: 149,
        },
        {
          x: 45,
          y: 224,
        },
        {
          x: 46,
          y: 154,
        },
        {
          x: 47,
          y: 4,
        },
        {
          x: 48,
          y: 166,
        },
        {
          x: 49,
          y: 232,
        },
        {
          x: 50,
          y: 223,
        },
        {
          x: 51,
          y: 252,
        },
        {
          x: 52,
          y: 211,
        },
        {
          x: 53,
          y: 157,
        },
        {
          x: 54,
          y: 197,
        },
        {
          x: 55,
          y: 298,
        },
        {
          x: 56,
          y: 261,
        },
        {
          x: 57,
          y: 17,
        },
        {
          x: 58,
          y: 135,
        },
        {
          x: 59,
          y: 87,
        },
        {
          x: 60,
          y: 175,
        },
        {
          x: 61,
          y: 104,
        },
        {
          x: 62,
          y: 8,
        },
        {
          x: 63,
          y: 281,
        },
        {
          x: 64,
          y: 156,
        },
        {
          x: 65,
          y: 27,
        },
        {
          x: 66,
          y: 28,
        },
        {
          x: 67,
          y: 243,
        },
        {
          x: 68,
          y: 76,
        },
        {
          x: 69,
          y: 80,
        },
        {
          x: 70,
          y: 161,
        },
        {
          x: 71,
          y: 274,
        },
        {
          x: 72,
          y: 16,
        },
        {
          x: 73,
          y: 273,
        },
        {
          x: 74,
          y: 28,
        },
        {
          x: 75,
          y: 256,
        },
        {
          x: 76,
          y: 190,
        },
        {
          x: 77,
          y: 143,
        },
        {
          x: 78,
          y: 41,
        },
        {
          x: 79,
          y: 213,
        },
        {
          x: 80,
          y: 47,
        },
        {
          x: 81,
          y: 214,
        },
        {
          x: 82,
          y: 232,
        },
        {
          x: 83,
          y: 92,
        },
        {
          x: 84,
          y: 99,
        },
        {
          x: 85,
          y: 213,
        },
        {
          x: 86,
          y: 258,
        },
        {
          x: 87,
          y: 238,
        },
        {
          x: 88,
          y: 164,
        },
        {
          x: 89,
          y: 78,
        },
        {
          x: 90,
          y: 279,
        },
        {
          x: 91,
          y: 213,
        },
        {
          x: 92,
          y: 181,
        },
        {
          x: 93,
          y: 29,
        },
        {
          x: 94,
          y: 48,
        },
        {
          x: 95,
          y: 82,
        },
        {
          x: 96,
          y: 67,
        },
        {
          x: 97,
          y: 237,
        },
        {
          x: 98,
          y: 220,
        },
        {
          x: 99,
          y: 130,
        },
        {
          x: 100,
          y: 161,
        },
        {
          x: 101,
          y: 285,
        },
        {
          x: 102,
          y: 246,
        },
        {
          x: 103,
          y: 177,
        },
        {
          x: 104,
          y: 166,
        },
        {
          x: 105,
          y: 11,
        },
        {
          x: 106,
          y: 67,
        },
        {
          x: 107,
          y: 10,
        },
        {
          x: 108,
          y: 142,
        },
        {
          x: 109,
          y: 283,
        },
        {
          x: 110,
          y: 19,
        },
        {
          x: 111,
          y: 83,
        },
        {
          x: 112,
          y: 25,
        },
        {
          x: 113,
          y: 156,
        },
        {
          x: 114,
          y: 26,
        },
        {
          x: 115,
          y: 15,
        },
        {
          x: 116,
          y: 269,
        },
        {
          x: 117,
          y: 160,
        },
        {
          x: 118,
          y: 109,
        },
        {
          x: 119,
          y: 249,
        },
        {
          x: 120,
          y: 288,
        },
      ],
    },
    {
      id: 'algeria',
      color: 'hsl(164, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 299,
        },
        {
          x: 1,
          y: 266,
        },
        {
          x: 2,
          y: 121,
        },
        {
          x: 3,
          y: 148,
        },
        {
          x: 4,
          y: 222,
        },
        {
          x: 5,
          y: 120,
        },
        {
          x: 6,
          y: 75,
        },
        {
          x: 7,
          y: 249,
        },
        {
          x: 8,
          y: 29,
        },
        {
          x: 9,
          y: 11,
        },
        {
          x: 10,
          y: 134,
        },
        {
          x: 11,
          y: 11,
        },
        {
          x: 12,
          y: 187,
        },
        {
          x: 13,
          y: 249,
        },
        {
          x: 14,
          y: 72,
        },
        {
          x: 15,
          y: 163,
        },
        {
          x: 16,
          y: 71,
        },
        {
          x: 17,
          y: 282,
        },
        {
          x: 18,
          y: 36,
        },
        {
          x: 19,
          y: 166,
        },
        {
          x: 20,
          y: 243,
        },
        {
          x: 21,
          y: 180,
        },
        {
          x: 22,
          y: 278,
        },
        {
          x: 23,
          y: 146,
        },
        {
          x: 24,
          y: 270,
        },
        {
          x: 25,
          y: 238,
        },
        {
          x: 26,
          y: 43,
        },
        {
          x: 27,
          y: 126,
        },
        {
          x: 28,
          y: 101,
        },
        {
          x: 29,
          y: 146,
        },
        {
          x: 30,
          y: 193,
        },
        {
          x: 31,
          y: 228,
        },
        {
          x: 32,
          y: 203,
        },
        {
          x: 33,
          y: 213,
        },
        {
          x: 34,
          y: 217,
        },
        {
          x: 35,
          y: 282,
        },
        {
          x: 36,
          y: 206,
        },
        {
          x: 37,
          y: 86,
        },
        {
          x: 38,
          y: 52,
        },
        {
          x: 39,
          y: 55,
        },
        {
          x: 40,
          y: 137,
        },
        {
          x: 41,
          y: 230,
        },
        {
          x: 42,
          y: 150,
        },
        {
          x: 43,
          y: 292,
        },
        {
          x: 44,
          y: 175,
        },
        {
          x: 45,
          y: 263,
        },
        {
          x: 46,
          y: 29,
        },
        {
          x: 47,
          y: 134,
        },
        {
          x: 48,
          y: 262,
        },
        {
          x: 49,
          y: 175,
        },
        {
          x: 50,
          y: 174,
        },
        {
          x: 51,
          y: 282,
        },
        {
          x: 52,
          y: 148,
        },
        {
          x: 53,
          y: 250,
        },
        {
          x: 54,
          y: 197,
        },
        {
          x: 55,
          y: 239,
        },
        {
          x: 56,
          y: 295,
        },
        {
          x: 57,
          y: 232,
        },
        {
          x: 58,
          y: 78,
        },
        {
          x: 59,
          y: 198,
        },
        {
          x: 60,
          y: 78,
        },
        {
          x: 61,
          y: 205,
        },
        {
          x: 62,
          y: 269,
        },
        {
          x: 63,
          y: 284,
        },
        {
          x: 64,
          y: 236,
        },
        {
          x: 65,
          y: 18,
        },
        {
          x: 66,
          y: 104,
        },
        {
          x: 67,
          y: 192,
        },
        {
          x: 68,
          y: 289,
        },
        {
          x: 69,
          y: 206,
        },
        {
          x: 70,
          y: 74,
        },
        {
          x: 71,
          y: 83,
        },
        {
          x: 72,
          y: 232,
        },
        {
          x: 73,
          y: 127,
        },
        {
          x: 74,
          y: 229,
        },
        {
          x: 75,
          y: 76,
        },
        {
          x: 76,
          y: 116,
        },
        {
          x: 77,
          y: 70,
        },
        {
          x: 78,
          y: 159,
        },
        {
          x: 79,
          y: 27,
        },
        {
          x: 80,
          y: 40,
        },
        {
          x: 81,
          y: 74,
        },
        {
          x: 82,
          y: 109,
        },
        {
          x: 83,
          y: 105,
        },
        {
          x: 84,
          y: 122,
        },
        {
          x: 85,
          y: 212,
        },
        {
          x: 86,
          y: 217,
        },
        {
          x: 87,
          y: 127,
        },
        {
          x: 88,
          y: 235,
        },
        {
          x: 89,
          y: 96,
        },
        {
          x: 90,
          y: 117,
        },
        {
          x: 91,
          y: 210,
        },
        {
          x: 92,
          y: 147,
        },
        {
          x: 93,
          y: 259,
        },
        {
          x: 94,
          y: 284,
        },
        {
          x: 95,
          y: 103,
        },
        {
          x: 96,
          y: 34,
        },
        {
          x: 97,
          y: 108,
        },
        {
          x: 98,
          y: 144,
        },
        {
          x: 99,
          y: 286,
        },
        {
          x: 100,
          y: 81,
        },
        {
          x: 101,
          y: 169,
        },
        {
          x: 102,
          y: 82,
        },
        {
          x: 103,
          y: 32,
        },
        {
          x: 104,
          y: 171,
        },
        {
          x: 105,
          y: 127,
        },
        {
          x: 106,
          y: 299,
        },
        {
          x: 107,
          y: 15,
        },
        {
          x: 108,
          y: 131,
        },
        {
          x: 109,
          y: 268,
        },
        {
          x: 110,
          y: 197,
        },
        {
          x: 111,
          y: 44,
        },
        {
          x: 112,
          y: 196,
        },
        {
          x: 113,
          y: 205,
        },
        {
          x: 114,
          y: 114,
        },
        {
          x: 115,
          y: 46,
        },
        {
          x: 116,
          y: 269,
        },
        {
          x: 117,
          y: 299,
        },
        {
          x: 118,
          y: 111,
        },
        {
          x: 119,
          y: 14,
        },
        {
          x: 120,
          y: 206,
        },
      ],
    },
    {
      id: 'mexico',
      color: 'hsl(47, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 143,
        },
        {
          x: 1,
          y: 229,
        },
        {
          x: 2,
          y: 69,
        },
        {
          x: 3,
          y: 129,
        },
        {
          x: 4,
          y: 209,
        },
        {
          x: 5,
          y: 162,
        },
        {
          x: 6,
          y: 168,
        },
        {
          x: 7,
          y: 119,
        },
        {
          x: 8,
          y: 138,
        },
        {
          x: 9,
          y: 222,
        },
        {
          x: 10,
          y: 221,
        },
        {
          x: 11,
          y: 88,
        },
        {
          x: 12,
          y: 160,
        },
        {
          x: 13,
          y: 165,
        },
        {
          x: 14,
          y: 16,
        },
        {
          x: 15,
          y: 17,
        },
        {
          x: 16,
          y: 190,
        },
        {
          x: 17,
          y: 258,
        },
        {
          x: 18,
          y: 242,
        },
        {
          x: 19,
          y: 22,
        },
        {
          x: 20,
          y: 172,
        },
        {
          x: 21,
          y: 221,
        },
        {
          x: 22,
          y: 81,
        },
        {
          x: 23,
          y: 272,
        },
        {
          x: 24,
          y: 151,
        },
        {
          x: 25,
          y: 26,
        },
        {
          x: 26,
          y: 278,
        },
        {
          x: 27,
          y: 68,
        },
        {
          x: 28,
          y: 154,
        },
        {
          x: 29,
          y: 113,
        },
        {
          x: 30,
          y: 208,
        },
        {
          x: 31,
          y: 119,
        },
        {
          x: 32,
          y: 281,
        },
        {
          x: 33,
          y: 300,
        },
        {
          x: 34,
          y: 217,
        },
        {
          x: 35,
          y: 215,
        },
        {
          x: 36,
          y: 125,
        },
        {
          x: 37,
          y: 175,
        },
        {
          x: 38,
          y: 118,
        },
        {
          x: 39,
          y: 74,
        },
        {
          x: 40,
          y: 55,
        },
        {
          x: 41,
          y: 34,
        },
        {
          x: 42,
          y: 96,
        },
        {
          x: 43,
          y: 65,
        },
        {
          x: 44,
          y: 40,
        },
        {
          x: 45,
          y: 23,
        },
        {
          x: 46,
          y: 249,
        },
        {
          x: 47,
          y: 156,
        },
        {
          x: 48,
          y: 35,
        },
        {
          x: 49,
          y: 275,
        },
        {
          x: 50,
          y: 102,
        },
        {
          x: 51,
          y: 245,
        },
        {
          x: 52,
          y: 205,
        },
        {
          x: 53,
          y: 173,
        },
        {
          x: 54,
          y: 133,
        },
        {
          x: 55,
          y: 268,
        },
        {
          x: 56,
          y: 254,
        },
        {
          x: 57,
          y: 174,
        },
        {
          x: 58,
          y: 13,
        },
        {
          x: 59,
          y: 5,
        },
        {
          x: 60,
          y: 141,
        },
        {
          x: 61,
          y: 98,
        },
        {
          x: 62,
          y: 22,
        },
        {
          x: 63,
          y: 25,
        },
        {
          x: 64,
          y: 7,
        },
        {
          x: 65,
          y: 41,
        },
        {
          x: 66,
          y: 73,
        },
        {
          x: 67,
          y: 165,
        },
        {
          x: 68,
          y: 193,
        },
        {
          x: 69,
          y: 4,
        },
        {
          x: 70,
          y: 63,
        },
        {
          x: 71,
          y: 185,
        },
        {
          x: 72,
          y: 238,
        },
        {
          x: 73,
          y: 63,
        },
        {
          x: 74,
          y: 10,
        },
        {
          x: 75,
          y: 140,
        },
        {
          x: 76,
          y: 278,
        },
        {
          x: 77,
          y: 173,
        },
        {
          x: 78,
          y: 11,
        },
        {
          x: 79,
          y: 189,
        },
        {
          x: 80,
          y: 266,
        },
        {
          x: 81,
          y: 261,
        },
        {
          x: 82,
          y: 91,
        },
        {
          x: 83,
          y: 297,
        },
        {
          x: 84,
          y: 286,
        },
        {
          x: 85,
          y: 7,
        },
        {
          x: 86,
          y: 68,
        },
        {
          x: 87,
          y: 263,
        },
        {
          x: 88,
          y: 152,
        },
        {
          x: 89,
          y: 35,
        },
        {
          x: 90,
          y: 188,
        },
        {
          x: 91,
          y: 73,
        },
        {
          x: 92,
          y: 17,
        },
        {
          x: 93,
          y: 280,
        },
        {
          x: 94,
          y: 59,
        },
        {
          x: 95,
          y: 209,
        },
        {
          x: 96,
          y: 92,
        },
        {
          x: 97,
          y: 61,
        },
        {
          x: 98,
          y: 154,
        },
        {
          x: 99,
          y: 153,
        },
        {
          x: 100,
          y: 278,
        },
        {
          x: 101,
          y: 218,
        },
        {
          x: 102,
          y: 55,
        },
        {
          x: 103,
          y: 224,
        },
        {
          x: 104,
          y: 159,
        },
        {
          x: 105,
          y: 17,
        },
        {
          x: 106,
          y: 279,
        },
        {
          x: 107,
          y: 284,
        },
        {
          x: 108,
          y: 218,
        },
        {
          x: 109,
          y: 160,
        },
        {
          x: 110,
          y: 252,
        },
        {
          x: 111,
          y: 262,
        },
        {
          x: 112,
          y: 240,
        },
        {
          x: 113,
          y: 32,
        },
        {
          x: 114,
          y: 22,
        },
        {
          x: 115,
          y: 21,
        },
        {
          x: 116,
          y: 246,
        },
        {
          x: 117,
          y: 29,
        },
        {
          x: 118,
          y: 99,
        },
        {
          x: 119,
          y: 200,
        },
        {
          x: 120,
          y: 204,
        },
      ],
    },
    {
      id: 'finland',
      color: 'hsl(29, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 236,
        },
        {
          x: 1,
          y: 32,
        },
        {
          x: 2,
          y: 189,
        },
        {
          x: 3,
          y: 203,
        },
        {
          x: 4,
          y: 31,
        },
        {
          x: 5,
          y: 32,
        },
        {
          x: 6,
          y: 180,
        },
        {
          x: 7,
          y: 145,
        },
        {
          x: 8,
          y: 193,
        },
        {
          x: 9,
          y: 81,
        },
        {
          x: 10,
          y: 269,
        },
        {
          x: 11,
          y: 274,
        },
        {
          x: 12,
          y: 238,
        },
        {
          x: 13,
          y: 27,
        },
        {
          x: 14,
          y: 55,
        },
        {
          x: 15,
          y: 188,
        },
        {
          x: 16,
          y: 265,
        },
        {
          x: 17,
          y: 58,
        },
        {
          x: 18,
          y: 73,
        },
        {
          x: 19,
          y: 47,
        },
        {
          x: 20,
          y: 247,
        },
        {
          x: 21,
          y: 228,
        },
        {
          x: 22,
          y: 108,
        },
        {
          x: 23,
          y: 32,
        },
        {
          x: 24,
          y: 98,
        },
        {
          x: 25,
          y: 293,
        },
        {
          x: 26,
          y: 216,
        },
        {
          x: 27,
          y: 114,
        },
        {
          x: 28,
          y: 105,
        },
        {
          x: 29,
          y: 279,
        },
        {
          x: 30,
          y: 142,
        },
        {
          x: 31,
          y: 105,
        },
        {
          x: 32,
          y: 176,
        },
        {
          x: 33,
          y: 79,
        },
        {
          x: 34,
          y: 95,
        },
        {
          x: 35,
          y: 46,
        },
        {
          x: 36,
          y: 132,
        },
        {
          x: 37,
          y: 39,
        },
        {
          x: 38,
          y: 208,
        },
        {
          x: 39,
          y: 297,
        },
        {
          x: 40,
          y: 133,
        },
        {
          x: 41,
          y: 73,
        },
        {
          x: 42,
          y: 78,
        },
        {
          x: 43,
          y: 115,
        },
        {
          x: 44,
          y: 30,
        },
        {
          x: 45,
          y: 135,
        },
        {
          x: 46,
          y: 1,
        },
        {
          x: 47,
          y: 42,
        },
        {
          x: 48,
          y: 263,
        },
        {
          x: 49,
          y: 214,
        },
        {
          x: 50,
          y: 201,
        },
        {
          x: 51,
          y: 87,
        },
        {
          x: 52,
          y: 47,
        },
        {
          x: 53,
          y: 116,
        },
        {
          x: 54,
          y: 148,
        },
        {
          x: 55,
          y: 293,
        },
        {
          x: 56,
          y: 295,
        },
        {
          x: 57,
          y: 114,
        },
        {
          x: 58,
          y: 192,
        },
        {
          x: 59,
          y: 254,
        },
        {
          x: 60,
          y: 88,
        },
        {
          x: 61,
          y: 259,
        },
        {
          x: 62,
          y: 165,
        },
        {
          x: 63,
          y: 4,
        },
        {
          x: 64,
          y: 220,
        },
        {
          x: 65,
          y: 196,
        },
        {
          x: 66,
          y: 242,
        },
        {
          x: 67,
          y: 108,
        },
        {
          x: 68,
          y: 108,
        },
        {
          x: 69,
          y: 25,
        },
        {
          x: 70,
          y: 242,
        },
        {
          x: 71,
          y: 0,
        },
        {
          x: 72,
          y: 105,
        },
        {
          x: 73,
          y: 187,
        },
        {
          x: 74,
          y: 2,
        },
        {
          x: 75,
          y: 122,
        },
        {
          x: 76,
          y: 118,
        },
        {
          x: 77,
          y: 157,
        },
        {
          x: 78,
          y: 280,
        },
        {
          x: 79,
          y: 41,
        },
        {
          x: 80,
          y: 185,
        },
        {
          x: 81,
          y: 3,
        },
        {
          x: 82,
          y: 174,
        },
        {
          x: 83,
          y: 15,
        },
        {
          x: 84,
          y: 269,
        },
        {
          x: 85,
          y: 210,
        },
        {
          x: 86,
          y: 72,
        },
        {
          x: 87,
          y: 16,
        },
        {
          x: 88,
          y: 177,
        },
        {
          x: 89,
          y: 179,
        },
        {
          x: 90,
          y: 290,
        },
        {
          x: 91,
          y: 135,
        },
        {
          x: 92,
          y: 77,
        },
        {
          x: 93,
          y: 286,
        },
        {
          x: 94,
          y: 209,
        },
        {
          x: 95,
          y: 246,
        },
        {
          x: 96,
          y: 259,
        },
        {
          x: 97,
          y: 165,
        },
        {
          x: 98,
          y: 91,
        },
        {
          x: 99,
          y: 213,
        },
        {
          x: 100,
          y: 10,
        },
        {
          x: 101,
          y: 107,
        },
        {
          x: 102,
          y: 69,
        },
        {
          x: 103,
          y: 275,
        },
        {
          x: 104,
          y: 140,
        },
        {
          x: 105,
          y: 140,
        },
        {
          x: 106,
          y: 148,
        },
        {
          x: 107,
          y: 89,
        },
        {
          x: 108,
          y: 168,
        },
        {
          x: 109,
          y: 46,
        },
        {
          x: 110,
          y: 128,
        },
        {
          x: 111,
          y: 88,
        },
        {
          x: 112,
          y: 187,
        },
        {
          x: 113,
          y: 238,
        },
        {
          x: 114,
          y: 264,
        },
        {
          x: 115,
          y: 63,
        },
        {
          x: 116,
          y: 225,
        },
        {
          x: 117,
          y: 87,
        },
        {
          x: 118,
          y: 42,
        },
        {
          x: 119,
          y: 284,
        },
        {
          x: 120,
          y: 25,
        },
      ],
    },
    {
      id: 'australia',
      color: 'hsl(300, 70%, 50%)',
      data: [
        {
          x: 0,
          y: 249,
        },
        {
          x: 1,
          y: 172,
        },
        {
          x: 2,
          y: 160,
        },
        {
          x: 3,
          y: 38,
        },
        {
          x: 4,
          y: 239,
        },
        {
          x: 5,
          y: 90,
        },
        {
          x: 6,
          y: 71,
        },
        {
          x: 7,
          y: 143,
        },
        {
          x: 8,
          y: 112,
        },
        {
          x: 9,
          y: 65,
        },
        {
          x: 10,
          y: 97,
        },
        {
          x: 11,
          y: 150,
        },
        {
          x: 12,
          y: 177,
        },
        {
          x: 13,
          y: 136,
        },
        {
          x: 14,
          y: 165,
        },
        {
          x: 15,
          y: 107,
        },
        {
          x: 16,
          y: 150,
        },
        {
          x: 17,
          y: 68,
        },
        {
          x: 18,
          y: 23,
        },
        {
          x: 19,
          y: 61,
        },
        {
          x: 20,
          y: 125,
        },
        {
          x: 21,
          y: 82,
        },
        {
          x: 22,
          y: 78,
        },
        {
          x: 23,
          y: 3,
        },
        {
          x: 24,
          y: 151,
        },
        {
          x: 25,
          y: 149,
        },
        {
          x: 26,
          y: 126,
        },
        {
          x: 27,
          y: 228,
        },
        {
          x: 28,
          y: 269,
        },
        {
          x: 29,
          y: 142,
        },
        {
          x: 30,
          y: 254,
        },
        {
          x: 31,
          y: 230,
        },
        {
          x: 32,
          y: 239,
        },
        {
          x: 33,
          y: 10,
        },
        {
          x: 34,
          y: 163,
        },
        {
          x: 35,
          y: 125,
        },
        {
          x: 36,
          y: 105,
        },
        {
          x: 37,
          y: 198,
        },
        {
          x: 38,
          y: 229,
        },
        {
          x: 39,
          y: 257,
        },
        {
          x: 40,
          y: 39,
        },
        {
          x: 41,
          y: 143,
        },
        {
          x: 42,
          y: 115,
        },
        {
          x: 43,
          y: 233,
        },
        {
          x: 44,
          y: 60,
        },
        {
          x: 45,
          y: 75,
        },
        {
          x: 46,
          y: 144,
        },
        {
          x: 47,
          y: 202,
        },
        {
          x: 48,
          y: 161,
        },
        {
          x: 49,
          y: 140,
        },
        {
          x: 50,
          y: 61,
        },
        {
          x: 51,
          y: 300,
        },
        {
          x: 52,
          y: 160,
        },
        {
          x: 53,
          y: 167,
        },
        {
          x: 54,
          y: 225,
        },
        {
          x: 55,
          y: 153,
        },
        {
          x: 56,
          y: 147,
        },
        {
          x: 57,
          y: 219,
        },
        {
          x: 58,
          y: 11,
        },
        {
          x: 59,
          y: 138,
        },
        {
          x: 60,
          y: 22,
        },
        {
          x: 61,
          y: 25,
        },
        {
          x: 62,
          y: 134,
        },
        {
          x: 63,
          y: 142,
        },
        {
          x: 64,
          y: 290,
        },
        {
          x: 65,
          y: 38,
        },
        {
          x: 66,
          y: 196,
        },
        {
          x: 67,
          y: 21,
        },
        {
          x: 68,
          y: 142,
        },
        {
          x: 69,
          y: 199,
        },
        {
          x: 70,
          y: 209,
        },
        {
          x: 71,
          y: 40,
        },
        {
          x: 72,
          y: 165,
        },
        {
          x: 73,
          y: 130,
        },
        {
          x: 74,
          y: 126,
        },
        {
          x: 75,
          y: 132,
        },
        {
          x: 76,
          y: 243,
        },
        {
          x: 77,
          y: 278,
        },
        {
          x: 78,
          y: 148,
        },
        {
          x: 79,
          y: 77,
        },
        {
          x: 80,
          y: 148,
        },
        {
          x: 81,
          y: 70,
        },
        {
          x: 82,
          y: 109,
        },
        {
          x: 83,
          y: 117,
        },
        {
          x: 84,
          y: 259,
        },
        {
          x: 85,
          y: 158,
        },
        {
          x: 86,
          y: 257,
        },
        {
          x: 87,
          y: 130,
        },
        {
          x: 88,
          y: 178,
        },
        {
          x: 89,
          y: 294,
        },
        {
          x: 90,
          y: 32,
        },
        {
          x: 91,
          y: 233,
        },
        {
          x: 92,
          y: 33,
        },
        {
          x: 93,
          y: 228,
        },
        {
          x: 94,
          y: 124,
        },
        {
          x: 95,
          y: 16,
        },
        {
          x: 96,
          y: 123,
        },
        {
          x: 97,
          y: 125,
        },
        {
          x: 98,
          y: 19,
        },
        {
          x: 99,
          y: 162,
        },
        {
          x: 100,
          y: 78,
        },
        {
          x: 101,
          y: 55,
        },
        {
          x: 102,
          y: 85,
        },
        {
          x: 103,
          y: 233,
        },
        {
          x: 104,
          y: 254,
        },
        {
          x: 105,
          y: 43,
        },
        {
          x: 106,
          y: 231,
        },
        {
          x: 107,
          y: 253,
        },
        {
          x: 108,
          y: 184,
        },
        {
          x: 109,
          y: 288,
        },
        {
          x: 110,
          y: 150,
        },
        {
          x: 111,
          y: 200,
        },
        {
          x: 112,
          y: 171,
        },
        {
          x: 113,
          y: 123,
        },
        {
          x: 114,
          y: 57,
        },
        {
          x: 115,
          y: 38,
        },
        {
          x: 116,
          y: 137,
        },
        {
          x: 117,
          y: 76,
        },
        {
          x: 118,
          y: 150,
        },
        {
          x: 119,
          y: 31,
        },
        {
          x: 120,
          y: 187,
        },
      ],
    },
  ];
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        zIndex: 10000,
        position: 'fixed',
        top: 0,
        left: 0,
        background: 'white',
      }}
      onClick={() => close()}>
      <ResponsiveLine
        data={exData}
        margin={{ top: 50, right: 160, bottom: 50, left: 60 }}
        xScale={{ type: 'linear' }}
        yScale={{ type: 'linear', stacked: true, min: 0, max: 2500 }}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={{
          tickValues: [0, 500, 1000, 1500, 2000, 2500],
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: '.2s',
          legend: '',
          legendOffset: 0,
        }}
        axisBottom={{
          tickValues: [0, 20, 40, 60, 80, 100, 120],
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: '.2f',
          legend: 'price',
          legendOffset: 36,
          legendPosition: 'middle',
        }}
        axisLeft={{
          tickValues: [0, 500, 1000, 1500, 2000, 2500],
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: '.2s',
          legend: 'volume',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        enableGridX={false}
        colors={{ scheme: 'spectral' }}
        lineWidth={1}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={1}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        gridXValues={[0, 20, 40, 60, 80, 100, 120]}
        gridYValues={[0, 500, 1000, 1500, 2000, 2500]}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 140,
            translateY: 0,
            itemsSpacing: 2,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 12,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};
export default LineGraph;
