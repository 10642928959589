import { Button } from 'antd';
import { useEffect } from 'react';

const ProgressBar = ({ current, total, quit }) => {
  const styles = {
    barWrapper: {
      flex: 1,
      padding: '0.5rem',
      borderColor: 'grey',
      borderWidth: 3,
      borderStyle: 'double',
      marginTop: 20,
      borderRadius: 10,
    },
    bar: {
      width: '50vw',
      height: '3rem',
      display: 'flex',
      borderWidth: 1,
      borderColor: 'grey',
      borderStyle: 'solid',
    },
    done: {
      flex: 1,
      background: '#fdcc00',
    },
    rest: { flex: 1, background: 'grey', opacity: 0.2 },
    button: { marginTop: 30, width: 150, fontSize: '1rem' },
  };

  useEffect(() => console.log(current, total), [current, total]);

  const renderBar = () => {
    let bar = [];

    if (total === Number.MAX_VALUE) return;

    for (let i = 0; i < total; i++) {
      bar.push(<div style={current > i ? styles.done : styles.rest} />);
    }
    return bar;
  };

  return (
    <>
      <div className="flex-modal-overlay" />
      <div
        className="flex-wrapper"
        style={{
          background: 'black',
          color: '#fdcc00',
          borderRadius: 10,
          padding: '3rem',
          alignItems: 'center',
        }}>
        <div className="flex-header" style={{ justifyContent: 'center' }}>
          작업이 진행중입니다 ({Math.floor((current / total) * 10000) / 100}%)
        </div>
        <div style={styles.barWrapper}>
          <div style={styles.bar}>{renderBar()}</div>
        </div>
        <Button type="primary" style={styles.button} onClick={quit}>
          종료
        </Button>
      </div>
    </>
  );
};

export default ProgressBar;
