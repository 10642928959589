import { Button, DatePicker, Form, Input, Select, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpGetRenewMakeUrl, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { monthFormat } from '../../lib/util/dateUtil';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';

const EmsList = ({ props }) => {
  const formRef = React.createRef();
  const [list, setList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });
  useEffect(async () => {
    getBranchList();
  }, []);
  useEffect(async () => {
    await getList();
  }, [pagination.current, pagination.pageSize]);

  const getList = async () => {
    console.log(formRef.current.getFieldValue('date'));

    let params = {};
    if (formRef.current.getFieldValue('branchIdx'))
      params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
    if (formRef.current.getFieldValue('date')) {
      params['startDate'] = formRef.current
        .getFieldValue('date')[0]
        .format('YYYY-MM-DD');
      params['endDate'] = formRef.current
        .getFieldValue('date')[1]
        .format('YYYY-MM-DD');
    }
    if (formRef.current.getFieldValue('riderName'))
      params['riderName'] = formRef.current.getFieldValue('riderName');
    params['pageSize'] = pagination.pageSize;
    params['pageNum'] = pagination.current;

    const res = await httpGetRenewMakeUrl(httpUrl.getEmsList, params, {});
    console.log(res);

    setList(res.data.employeeMonthlyStatList);
    setPagination({ ...pagination, total: res.data.totalCount });
  };
  const getBranchList = async () => {
    const res = await httpGet(httpUrl.getBranchList, [1, 10000], {});
    setbranchList([{ idx: null, branchName: '전체' }, ...res.data.branch]);
  };
  const handleTableChange = (pagination) => setPagination(pagination);
  const columns = [
    {
      title: '일자',
      dataIndex: 'statDate',
      className: 'table-column-center',
      render: (data) => <div>{monthFormat(data)}</div>,
    },
    {
      title: '기사명',
      dataIndex: 'riderName',
      className: 'table-column-center',
    },
    {
      title: '지점명',
      dataIndex: 'branchIdx',
      className: 'table-column-center',
      render: (data) => (
        <div>
          {branchList.find((branch) => branch.idx === data)
            ? branchList.find((branch) => branch.idx === data).branchName
            : '-'}
        </div>
      ),
    },
    {
      title: '배달건수',
      dataIndex: 'deliveryCnt',
      className: 'table-column-center',
      render: (data) => <div>{data}건</div>,
    },
    {
      title: '배달비 총액',
      dataIndex: 'deliveryPriceSum',
      className: 'table-column-center',
      render: (data) => <div>{comma(data)}원</div>,
    },
    {
      title: '예치금 정산액',
      dataIndex: 'ncashResult',
      className: 'table-column-center',
      render: (data) => <div>{comma(data)}원</div>,
    },
  ];

  const parseExcelJson = (data) => {
    let result = [];
    let headerJson = {};
    columns.forEach((column) => {
      headerJson[column.dataIndex] = column.title;
    });
    result.push(headerJson);

    data.forEach((item) => {
      let copiedItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (columns.findIndex((column) => column.dataIndex === key) < 0) {
          delete copiedItem[key];
        }
      });

      result.push({
        ...copiedItem,
        branchIdx: branchList.find((branch) => branch.idx === item.branchIdx)
          ? branchList.find((branch) => branch.idx === item.branchIdx)
              .branchName
          : '-',
      });
    });

    return result;
  };

  const onDownload = async () => {
    let params = {};
    if (formRef.current.getFieldValue('branchIdx'))
      params['branchIdx'] = formRef.current.getFieldValue('branchIdx');
    if (formRef.current.getFieldValue('date')) {
      params['startDate'] = formRef.current
        .getFieldValue('date')[0]
        .format('YYYY-MM-DD');
      params['endDate'] = formRef.current
        .getFieldValue('date')[1]
        .format('YYYY-MM-DD');
    }
    if (formRef.current.getFieldValue('riderName'))
      params['riderName'] = formRef.current.getFieldValue('riderName');
    params['pageSize'] = 10000;
    params['pageNum'] = 1;

    const res = await httpGetRenewMakeUrl(httpUrl.getEmsList, params, {});
    const excelJson = parseExcelJson(res.data.employeeMonthlyStatList);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    xlsx.utils.book_append_sheet(wb, ws, '월별_정직원_정산');
    xlsx.writeFile(wb, '월별_정직원_정산.xlsx');
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form ref={formRef} style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item style={{ width: 200 }} name="branchIdx">
            <Select onChange={() => getList()} defaultValue={null}>
              {branchList.map((branch) => {
                return (
                  <Select.Option value={branch.idx}>
                    {branch.branchName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="riderName">
            <Input
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  getList();
                }
              }}
              placeholder={'기사명 검색'}
            />
          </Form.Item>
          <Form.Item name="date">
            <DatePicker.RangePicker
              onChange={() => getList()}
              placeholder={['시작일', '종료일']}
            />
          </Form.Item>
        </Form>
        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => onDownload()}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
      </div>

      <Table
        rowKey={(record) => record.idx}
        dataSource={list}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  );
};

export default EmsList;
