import { Input, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpUrl } from '../../api/httpClient';
import '../../css/main.css';
import { accountFormat } from '../../lib/util/numberUtil';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const today = new Date();

class MisOrderListModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: today,
      list: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 5,
      },

      dataIdxs: [],
      frName: '',
      riderName: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList(true);
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  getList = () => {
    let frName = this.state.frName;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    let riderName = this.state.riderName;
    let frIdx = this.props.frIdx;
    console.log('dafdasfadds ' + this.props.frIdx);
    httpGet(
      httpUrl.cardPaymentList,
      [frName, pageNum, pageSize, riderName, frIdx],
      {}
    ).then((res) => {
      const pagination = { ...this.state.pagination };
      pagination.current = res.data.currentPage;
      pagination.total = res.data.totalCount;
      this.setState({
        list: res.data.cardpayments,
        pagination,
      });
      console.log('                     ' + JSON.stringify(this.state.list));
    });
  };

  //   onSelect = (data) => {
  //     // console.log(data)
  //     if (this.props.callback) {
  //       this.props.callback(data);

  //     }
  //
  //   };

  render() {
    const columns = [
      {
        title: '주문번호',
        dataIndex: 'orderIdx',
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.props.onSelect(row);
              this.props.close();
            }}>
            {data}
          </div>
        ),
      },
      {
        title: '가맹점명',
        dataIndex: 'frName',
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => <div>{data}</div>,
      },
      {
        title: '라이더명',
        dataIndex: 'riderName',
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => <div>{data}</div>,
      },
      {
        title: '고객주소',
        dataIndex: 'custAddr1',
        className: 'table-column-center',
        width: '15%',
      },
      {
        title: '상세주소',
        dataIndex: 'custAddr2',
        className: 'table-column-center',
        width: '15%',
      },
      {
        title: '인증번호',
        dataIndex: 'reserved1',
        className: 'table-column-center',
        width: '15%',
        render: (data, row) => <div>{JSON.parse(data).authNum}</div>,
      },
      {
        title: '요금',
        dataIndex: 'price',
        className: 'table-column-center',
        render: (data, row) => <div>{accountFormat(row.price + row.tax)}</div>,
      },
    ];

    const { close } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog2">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">주문번호 조회</div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>
            <div className="deposit-inner">
              {this.props.msgObject && (
                <this.props.msgObject
                  searchCategory={this.props.searchCategory}
                  setSearchCategory={this.props.setSearchCategory}
                />
              )}

              <Table
                className="searchFran-table2"
                rowKey={(record) => record.idx}
                dataSource={this.state.list}
                columns={columns}
                pagination={this.state.pagination}
                onChange={this.handleTableChange}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    branchIdx: state.login.loginInfo.branchIdx,
  };
};
export default connect(mapStateToProps, null)(MisOrderListModal);
