const internationalAge = (inputBirth) => {
  let birth = inputBirth.trim();
  if (birth.length != 13) return birth;

  const today = new Date();
  const genType = birth.slice(6, 7);
  let birthYear = 0;
  if (2 < genType) {
    birthYear = 2000 + Number(birth.slice(0, 2));
  } else {
    birthYear = 1900 + Number(birth.slice(0, 2));
  }
  let age = today.getFullYear() - birthYear;
  let month = today.getMonth() + 1 - Number(birth.slice(2, 4));
  if (
    month < 0 ||
    (month === 0 && today.getDate() < Number(birth.slice(4, 6)))
  ) {
    return (age = age - 1);
  } else {
    return age;
  }
};
export { internationalAge };
