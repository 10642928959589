import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { httpPost, httpUrl } from './../api/httpClient';
import { Button, Form, Input, Modal } from 'antd';
import QRCode from 'react-qr-code';
import CloseIcon from '../img/close.png';

const FormItem = Form.Item;

class MyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModifyMode: false,
      isOpenModal: false,
      isOpenOtpModal: false,
      otpKey: '',
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {}

  // 유저 정보 변경 api
  modifyHandleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue().phone);
    console.log(this.formRef.current.getFieldsValue().emailAddr);
    const newPassword = this.formRef.current.getFieldsValue().password
      ? this.formRef.current.getFieldsValue().password
      : null;
    console.log(newPassword);
    httpPost(httpUrl.updateAdminUser, [], {
      idx: this.props.loginInfo.idx,
      password: newPassword,
      phone: this.formRef.current.getFieldsValue().phone,
      email: this.formRef.current.getFieldsValue().emailAddr,
    }).then((res) => {
      console.log(res);
      if (res.data === 'SUCCESS') {
        Modal.info({
          title: '변경 성공',
          content:
            '어드민(운영자) 정보 변경에 성공했습니다. \n로그아웃 후 다시 로그인 해주세요',
        });
        this.setState({ isModifyMode: false });
      } else {
        Modal.warn({
          title: '변경 실패',
          content: '어드민(운영자) 정보 변경에 실패했습니다.',
        });
      }
    });
  };

  comfirmPwHandler = () => {
    httpPost(httpUrl.otpChange, [], {
      password: this.formRef.current.getFieldsValue().password,
    }).then((res) => {
      console.log(res, res.data.result);
      if (res.data.result === true) {
        this.setState(
          { otpKey: res.data.key, isOpenOtpModal: true },
          console.log(res.data.key)
        );
      } else {
        Modal.warn({
          title: '비밀번호 확인 실패',
          content: '비밀번호가 다릅니다.',
        });
      }
    });
  };

  render() {
    const { close } = this.props;
    return (
      <React.Fragment>
        <div className="myinfo-content">
          <div className="myinfo-header">
            <div className="myinfo-header-title">
              {this.props.loginInfo.id} 님
            </div>
            <Button
              onClick={() => {
                this.setState({
                  isOpenModal: !this.state.isOpenModal,
                });
              }}>
              OTP 생성
            </Button>
            {this.state.isModifyMode ? (
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  this.modifyHandleSubmit();
                }}>
                저장하기
              </Button>
            ) : (
              <Button
                onClick={() => {
                  this.setState({
                    isModifyMode: !this.state.isModifyMode,
                  });
                }}>
                수정하기
              </Button>
            )}
          </div>
          <div className="myinfo-inner">
            <Form
              ref={this.formRef}
              onFinish={this.modifyHandleSubmit}
              autoComplete="none">
              <div className="myinfo-warp">
                <div className="myinfo-title">
                  <div>아이디</div>
                  <div>비밀번호</div>
                  <div>핸드폰</div>
                  <div>이메일</div>
                  <div>OTP KEY</div>
                </div>
                {this.state.isModifyMode ? (
                  <div className="myinfo-data">
                    <div>{this.props.loginInfo.id}</div>
                    <div>
                      <FormItem name="password">
                        <Input
                          type="password"
                          placeholder="새 비밀번호를 입력해주세요."
                        />
                      </FormItem>
                    </div>
                    <div>
                      <FormItem
                        name="phone"
                        initialValue={this.props.loginInfo.phone}>
                        <Input
                          type="phone"
                          placeholder="핸드폰번호를 입력해주세요."
                        />
                      </FormItem>
                    </div>
                    <div>
                      <FormItem
                        name="emailAddr"
                        initialValue={this.props.loginInfo.email}>
                        <Input
                          type="emailAddr"
                          placeholder="이메일을 입력해주세요."
                        />
                      </FormItem>
                    </div>
                    <div>
                      {this.state.otpKey === null || this.state.otpKey === ''
                        ? this.props.loginInfo.otpKey
                        : this.state.otpKey}
                    </div>
                  </div>
                ) : (
                  <div className="myinfo-data">
                    <div>{this.props.loginInfo.id}</div>
                    <div>
                      <div>비밀번호는 표시되지 않습니다.</div>
                    </div>
                    <div>
                      <div>{this.props.loginInfo.phone}</div>
                    </div>
                    <div>
                      <div>{this.props.loginInfo.email}</div>
                    </div>
                    <div>
                      {this.state.otpKey === null || this.state.otpKey === ''
                        ? this.props.loginInfo.otpKey
                        : this.state.otpKey}
                    </div>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>

        {/* otp 모달 */}

        {this.state.isOpenModal && (
          <div>
            <div
              className="Dialog-overlay"
              onClick={() => {
                this.setState({ isOpenModal: false });
              }}
            />
            <div className="otpKey-content">
              <div className="systemKey-title">
                {this.state.isOpenOtpModal ? 'OTP KEY 확인' : '비밀번호 확인'}
              </div>
              <img
                onClick={() => {
                  this.setState({ isOpenModal: false });
                }}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="dialog-close"
                alt="img"
              />
              {!this.state.isOpenOtpModal ? (
                <div className="systemKey-inner">
                  <Form ref={this.formRef} onFinish={this.comfirmPwHandler}>
                    <div className="otpKey-wrap">
                      <p className="otpKey-title">비밀번호 입력</p>
                      <FormItem
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: '현재 사용중인 비밀번호를 입력해주세요',
                          },
                        ]}>
                        <Input
                          type="password"
                          placeholder="현재 비밀번호를 입력해주세요."
                        />
                      </FormItem>
                    </div>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="create-control-submit-btn"
                      style={{ width: '100%' }}>
                      확인
                    </Button>
                  </Form>
                </div>
              ) : (
                <div className="systemKey-inner">
                  <p className="otpKey-title">OTP KEY</p>
                  <div style={{ textAlign: 'center' }}>
                    <QRCode
                      value={
                        'otpauth://totp/nyamnyambox?secret=' + this.state.otpKey
                      }
                    />
                  </div>
                  <div className="otpKey-key">{this.state.otpKey}</div>
                  <p style={{ textAlign: 'center' }}>
                    *위 키를 복사하여 구글OTP앱에서 키를 수동으로 추가하거나
                    구글OTP앱에서 QR을 촬영하여 추가해주세요.
                  </p>
                  <Button
                    type="primary"
                    className="create-control-submit-btn"
                    style={{ width: '100%' }}
                    onClick={() => {
                      this.setState({ isOpenModal: false });
                    }}>
                    확인
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginInfo: state.login.loginInfo };
};
export default withRouter(connect(mapStateToProps)(MyInfo));
