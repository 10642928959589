import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpDelete, httpGet, httpUrl } from '../../../api/httpClient';
import { agencyNameToPosCode } from '../../../lib/util/codeUtil';
const FrOrderAgencyDialog = ({ userIdx, close }) => {
  const [list, setList] = useState([]);

  const getList = async () => {
    const res = await httpGet(httpUrl.getFrOrderAgencyByUserIdx, [userIdx], {});
    console.log(res);
    if (res.data.result === 'SUCCESS') setList(res.data.frOrderAgency);
    else
      Modal.warn({
        title: '조회실패',
        content: res.data.resultMessage,
      });
  };

  const posCodeToAgencyName = (posCode) => {
    const agencyName = Object.keys(agencyNameToPosCode).find(
      (key) => agencyNameToPosCode[key] === posCode
    );

    if (agencyName === null) return '잘못된 연동사 코드 입니다.';
    else return agencyName;
  };

  const confirmDeleteFrOrderAgency = async (foa) => {
    Modal.confirm({
      title: '연동해제',
      content: `${foa.posCode} / ${foa.posStoreCode} 해당 연동설정을 해제하시겠습니까?`,
      onOk: async () => {
        await deleteFrOrderAgency(foa);
      },
    });
  };

  const deleteFrOrderAgency = async (foa) => {
    const res = await httpDelete(
      httpUrl.deleteFrOrderAgency,
      [foa.userIdx, foa.posCode],
      {}
    );
    if (res.data.result === 'SUCCESS') {
      Modal.info({
        title: '연동해제 성공',
        content: res.data.resultMessage,
      });
      await getList();
    } else {
      Modal.warn({
        title: '연동해제 실패',
        content: res.data.resultMessage,
      });
    }
  };

  useEffect(async () => {
    await getList();
  }, []);

  const columns = [
    {
      title: '순번',
      dataIndex: 'userIdx',
      className: 'table-column-center',
    },
    {
      title: '연동사',
      dataIndex: 'posCode',
      className: 'table-column-center',
      render: (data) => <div>{posCodeToAgencyName(data)}</div>,
    },
    {
      title: '연동코드',
      dataIndex: 'posStoreCode',
      className: 'table-column-center',
    },
    {
      title: '해지',
      dataIndex: '',
      className: 'table-column-center',
      render: (data, row) => (
        <Button
          onClick={async (e) => {
            e.stopPropagation();
            await confirmDeleteFrOrderAgency(row);
          }}>
          연동해제
        </Button>
      ),
    },
  ];
  return (
    <div onClick={() => close()} className="flex-modal-overlay">
      <div className="flex-wrapper">
        <div className="flex-header">매장연동설정</div>
        <div className="flex-inner">
          <Table
            rowKey={(record) => record.userIdx + record.posCode}
            dataSource={list}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
};

export default FrOrderAgencyDialog;
