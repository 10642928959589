import React, { Component } from 'react';
import { Table, Image, Button } from 'antd';
import {
  httpGet,
  httpUrl,
  httpDownload,
  httpPost,
  httpPut,
  imageUrl,
} from '../../api/httpClient';
import '../../css/main.css';
import { formatDates } from '../../lib/util/dateUtil';
import { CloudDownloadOutlined } from '@ant-design/icons';
import CloseIcon from '../../img/close.png';

class RiderUploadFileListDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
    this.formRef = React.createRef();
    this.columns = [
      {
        title: '배송위탁계약',
        dataIndex: 'consignmentDelivery',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="배송위탁계약서" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '정보보호',
        dataIndex: 'infoProtection',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="정보보호" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '개인정보동의',
        dataIndex: 'personalInfo',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="개인정보동의서" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '중대재해서약',
        dataIndex: 'seriousAccident',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="중대재해서약서" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '지입확약',
        dataIndex: 'promiseVehicle',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="지입확약서" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '오토바이임대차계약',
        dataIndex: 'leaseContract',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image
              width={120}
              src={imageUrl(data)}
              alt="오토바이임대차계약서"
            />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '최소노무제공수료증',
        dataIndex: 'workCertificate',
        className: 'table-column-center',
        render: (data) => (
          <div className="table-ele">
            <Image width={120} src={imageUrl(data)} alt="최소노무제공수료증" />
            <a href={imageUrl(data)} download className="tagA">
              다운로드 <CloudDownloadOutlined />
            </a>
          </div>
        ),
      },
      {
        title: '제출일',
        dataIndex: 'createDate',
        className: 'table-column-center',
        render: (data) => <div>{formatDates(data)}</div>,
      },
    ];
  }

  componentDidMount() {
    this.getList();
  }

  getList = () => {
    httpGet(httpUrl.riderUploadFile, [this.props.data.userIdx], {}).then(
      (res) => {
        this.setState({
          list: res.data.files,
        });
      }
    );
  };

  onSubmit = () => {
    // console.log("click")
    if (this.props.callback) {
      this.props.callback(this.state.selectedRowKeys);
    }
    this.props.close();
  };

  onSelect = (data) => {
    // console.log(data)
    if (this.props.callback) {
      this.props.callback(data);
    }
    this.props.close();
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="deposit-Dialog">
          <div className="deposit-content">
            <div>
              <div className="deposit-title">제출 서류 목록</div>
              <img
                onClick={close}
                style={{ display: 'inline' }}
                src={CloseIcon}
                className="deposit-close"
                alt="닫기"
              />
            </div>

            <div className="deposit-inner">
              <div className="riderFile-title">
                기사명 : <span>{data.riderName}</span>
              </div>
              <Table
                className="riderFile-table"
                rowKey={(record) => record.idx}
                // rowClassName={(record) =>
                //   record.userStatus == 1 ? "" : "disabled-row"
                // }
                dataSource={this.state.list}
                columns={this.columns}
                pagination={false}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RiderUploadFileListDialog;
