import { Button, DatePicker, Input, Table } from 'antd';
import React, { Component } from 'react';
import xlsx from 'xlsx';
import { httpGet, httpUrl } from '../../api/httpClient';
import SelectBox from '../../components/input/SelectBox';
import { batchCategory, kindString } from '../../lib/util/codeUtil';
import { comma } from '../../lib/util/numberUtil';
import Const from '../../const';
import ExcelIcon from '../../img/excel.png';
import moment from 'moment';

const Search = Input.Search;
const RangePicker = DatePicker.RangePicker;

class NCashDailyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      paginationExcel: {
        total: 0,
        current: 1,
        pageSize: 50000,
      },
      list: [],
      kind: '',
      branchName: '',
      userId: '',
      endDate: moment().format("YYYY-MM-DD"),
      startDate: moment().format("YYYY-MM-DD"),
    };
    // this.formRef = React.createRef();
  }

  componentDidMount() {
    this.getList();
    // this.getExcelList();
  }

  getList = () => {
    const pagination = this.state.pagination;
    httpGet(
      httpUrl.ncashDailyList,
      [
        this.state.kind,
        pagination.current,
        pagination.pageSize,
        this.state.userId,
        this.state.startDate,
        this.state.endDate,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        console.log(res.data.ncashDailies);
        this.setState({
          list: res.data.ncashDailies,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  getExcelList = () => {
    const pagination = this.state.paginationExcel;
    httpGet(
      httpUrl.ncashDailyList,
      [
        this.state.kind,
        pagination.current,
        pagination.pageSize,
        this.state.userId,
        this.state.startDate,
        this.state.endDate,
      ],
      {}
    ).then((res) => {
      if (res.result === 'SUCCESS') {
        this.setState({
          listExcel: res.data.ncashDailies,
          pagination: {
            ...this.state.pagination,
            current: res.data.currentPage,
            total: res.data.totalCount,
          },
        });
      }
    });
  };

  getExcelSearchList = () => {
    // const pagination = this.state.paginationExcel;
    // httpGet(
    //   httpUrl.deliverySearchList,
    //   [
    //     this.state.kind,
    //     pagination.current,
    //     pagination.pageSize,
    //   ],
    //   {}
    // ).then((res) => {
    //   if (res.result === "SUCCESS") {
    //     this.setState({
    //       listExcel: res.data.orders,
    //       pagination: {
    //         ...this.state.pagination,
    //         current: res.data.currentPage,
    //         total: res.data.totalCount,
    //       },
    //     });
    //   }
    // });
  };

  parseExcelJson = () => {
    let result = [
      {
        createDate: '일시',
        kind: '구분',
        branchName: '지점명',
        userId: '라이더아이디',
        userName: '라이더명',
        registrationNumber: '주민번호',
        phone: '연락처',
        ncashDelta: '차감금액',
      },
    ];
    this.state.listExcel.forEach((item) => {
      result.push({
        createDate: item.createDate,
        kind: kindString[item.kind],
        branchName: item.branchName,
        userId: item.userId,
        userName: item.userName,
        registrationNumber: item.registrationNumber,
        phone: item.phone,
        ncashDelta: item.ncashDelta,
      });
    });

    return result;
  };

  onDownload = (data) => {
    this.getExcelList();

    const excelJson = this.parseExcelJson(data);
    const ws = xlsx.utils.json_to_sheet(excelJson, { skipHeader: true });
    const wb = xlsx.utils.book_new();

    ws['!cols'] = [];
    ws['!cols'][0] = { width: 20 };
    ws['!cols'][2] = { width: 15 };
    ws['!cols'][3] = { width: 15 };
    ws['!cols'][4] = { width: 20 };
    ws['!cols'][5] = { width: 20 };
    xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
    xlsx.writeFile(wb, '일차감내역.xlsx');
  };

  onChangeStatus = (value) => {
    this.setState(
      {
        kind: value === '0' ? '' : value,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  handleTableChange = (pagination) => {
    const pager = {
      ...this.state.pagination,
    };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getList()
    );
  };

  //일차감 작업등록
  openTaskWorkModal = () => {
    this.setState({ taskWorkOpen: true });
  };
  closeTaskWorkModal = () => {
    this.setState({ taskWorkOpen: false });
  };

  //일차감 작업수정
  openUpdateTaskWorkModal = (row) => {
    this.setState({ updateTaskOpen: true, dialogData: row });
  };
  closeUpdateTaskWorkModal = () => {
    this.setState({ updateTaskOpen: false });
  };

  onSearch = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
        },
      },
      () => {
        this.getList();
        // this.getExcelList();
      }
    );
  };

  render() {
    const columns = [
      {
        title: '일시',
        dataIndex: 'createDate',
        className: 'table-column-center',
      },
      {
        title: '구분',
        dataIndex: 'kind',
        className: 'table-column-center',
        render: (data) => <div>{kindString[data]}</div>,
      },
      {
        title: '일/월차감구분',
        dataIndex: 'batchCategory',
        className: 'table-column-center',
        render: (data) => <div>{batchCategory[data]}</div>,
      },
      {
        title: '차감명',
        dataIndex: 'batchTitle',
        className: 'table-column-center',
        render: (data) => <div>{data ? data : '-'}</div>,
      },
      {
        title: '지점명',
        dataIndex: 'branchName',
        className: 'table-column-center',
      },
      {
        title: '사용자아이디',
        dataIndex: 'userId',
        className: 'table-column-center',
      },
      {
        title: '사용자이름',
        dataIndex: 'userName',
        className: 'table-column-center',
      },
      {
        title: '주민/사업자번호',
        dataIndex: 'registrationNumber',
        className: 'table-column-center',
      },
      {
        title: '연락처',
        dataIndex: 'phone',
        className: 'table-column-center',
      },
      {
        title: '차감금액',
        dataIndex: 'ncashDelta',
        className: 'table-column-center',
        render: (data) => <div>{comma(data)}원</div>,
      },
    ];

    return (
      <>
        <SelectBox
          // placeholder={'전체'}
          style={{ width: 200, marginBottom: 20 }}
          value={
            this.state.kind === '' ? kindString[0] : kindString[this.state.kind]
          }
          code={Object.keys(kindString)}
          codeString={kindString}
          onChange={(value) => {
            if (parseInt(value) !== this.state.kind) {
              this.onChangeStatus(value);
            }
          }}
        />
        <Search
          placeholder="아이디 검색"
          enterButton
          allowClear
          onChange={(e) => this.setState({ userId: e.target.value })}
          onSearch={this.onSearch}
          style={{
            width: 220,
            marginLeft: 20,
          }}
        />

        {Const.excelEnable && (
          <Button
            className="download-btn"
            style={{ float: 'right', marginLeft: 10, marginBottom: 20 }}
            onClick={() => this.onDownload(this.state.listExcel)}>
            <img style={{ display: 'inline' }} src={ExcelIcon} alt="" />
            엑셀 다운로드
          </Button>
        )}
        <RangePicker
          style={{ width: 300, float: 'right', marginRight: 10 }}
          placeholder={['시작일', '종료일']}
          value={[
            moment(this.state.startDate, 'YYYY-MM-DD'),
            moment(this.state.endDate, 'YYYY-MM-DD'),
          ]}
          onChange={(_, dateStrings) => {
            if (dateStrings[(0, 1)]) {
              this.setState(
                {
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                  // this.getExcelList();
                }
              );
            } else {
              // console.log('test')
              this.setState(
                {
                  startDate: '',
                  endDate: '',
                  pagination: {
                    current: 1,
                    pageSize: 10,
                  },
                },
                () => {
                  this.getList();
                  // this.getExcelList();
                }
              );
            }
          }}
        />
        <Table
          rowKey={(record) => record.idx}
          dataSource={this.state.list}
          columns={columns}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}

export default NCashDailyList;
