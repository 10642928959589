import React, { Component } from 'react';
import { Form, Input, Modal, Button, Select, Radio, Checkbox } from 'antd';
import { comma } from '../../lib/util/numberUtil';
import '../../css/main.css';
import { pgUseRate } from '../../lib/util/codeUtil';
import { httpUrl, httpPost, httpGet } from '../../api/httpClient';
import { updateComplete } from '../../api/Modals';
import CloseIcon from '../../img/close.png';

const Search = Input.Search;
const FormItem = Form.Item;
const Option = Select.Option;

class ModifyFranDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchList: [],
      branchDataCurrent: 0,
      branchDataEnd: false,
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    console.log(this.props.data);
    this.getBranchList();
  }

  getBranchList = () => {
    if (this.state.branchDataEnd) return;
    let pageNum = this.state.branchDataCurrent + 1;
    let pageSize = 20;
    httpGet(httpUrl.getBranchList, [pageNum, pageSize], {}).then((res) => {
      console.log(res);
      if (res.data.branch.length === 0) {
        this.setState({
          branchList: res.data.branch,
          branchDataEnd: true,
        });
        return;
      }
      if (this.state.branchDataCurrent === res.data.currentPage) {
        res.branch = [];
      }
      this.setState({
        branchList: this.state.branchList.concat(res.data.branch),
        branchDataCurrent: res.data.currentPage,
      });
    });
  };

  handleSubmit = () => {
    console.log(this.formRef.current.getFieldsValue());
    httpPost(httpUrl.updateFranchise, [], {
      ...this.formRef.current.getFieldsValue(),
      userIdx: this.props.data.userIdx,
    })
      .then((res) => {
        if (res.result === 'SUCCESS' && res.data === 'SUCCESS') {
          console.log(res);
          updateComplete();
          this.props.close();
          this.props.getList();
        } else if (res.data === 'ADDRESS_2_IS_NULL') {
          Modal.info({
            title: '수정 실패',
            content: '상세정보를 입력해주세요.',
          });
          return;
        } else {
          Modal.error({
            title: '수정 실패',
            content: '수정에 실패했습니다. 관리자에게 문의하세요.',
            onOk() {},
          });
          return;
        }
        this.props.close();
      })
      .catch((e) => {
        Modal.error({
          title: '변경 실패',
          content: '수정에 실패했습니다. 관리자에게 문의하세요.',
          onOk() {},
        });
      });
  };

  render() {
    const { close, data } = this.props;

    return (
      <React.Fragment>
        <div className="Dialog-overlay" onClick={close} />
        <div className="modifyfran-Dialog">
          <div className="modifyfran-content">
            <div className="modifyfran-title">수정</div>
            <img
              onClick={close}
              style={{ display: 'inline' }}
              src={CloseIcon}
              className="dialog-close"
              alt="img"
            />
            <div className="modifyfran-inner">
              <Form ref={this.formRef} onFinish={this.handleSubmit}>
                <div className="modifyfran-innerbox">
                  <div className="contentBlock">
                    <div className="mainTitle">지점명</div>
                    <div className="formItem">
                      <FormItem
                        name="branchIdx"
                        className="selectItem"
                        style={{ marginLeft: 20 }}>
                        <Select
                          id="fr-branch"
                          placeholder={data.branchName}
                          onChange={(value) => {
                            console.log('@@@' + value);
                            this.setState({ branchIdx: value });
                          }}>
                          {this.state.branchList.map((value, index) => {
                            return (
                              <Option value={value.idx}>
                                {value.branchName}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">가맹점명</div>
                    <div className="formItem">
                      <FormItem
                        name="frName"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.frName : ''}>
                        <Input
                          placeholder="가맹점명을 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">실가맹점명</div>
                    <div className="formItem">
                      <FormItem
                        name="frNameExtra"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.frNameExtra : ''}>
                        <Input
                          placeholder="실가맹점명을 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">대표자명</div>
                    <div className="formItem">
                      <FormItem
                        name="ownerName"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.ownerName : ''}>
                        <Input
                          placeholder="대표자명을 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">가맹점번호</div>
                    <div className="formItem">
                      <FormItem
                        name="frPhone"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.frPhone : ''}>
                        <Input
                          placeholder="가맹점번호를 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">가맹점주소</div>
                    <div className="formItem">
                      <FormItem
                        name="addr1"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.addr1 : ''}>
                        <Input
                          placeholder="가맹점주소를 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">상세주소</div>
                    <div className="formItem">
                      <FormItem
                        name="addr2"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={data ? data.addr2 : ''}>
                        <Input
                          placeholder="가맹점주소를 입력하세요"
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">배달비그룹번호</div>
                    <div className="formItem">
                      <FormItem
                        name="deliveryPriceBasicGroupIdx"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        rules={[{ required: true, message: '' }]}
                        initialValue={
                          data ? data.deliveryPriceBasicGroupIdx : ''
                        }>
                        <Input
                          placeholder="그룹번호를 입력하세요."
                          className="override-input"
                        />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">VAN</div>
                    <div className="formItem">
                      <FormItem
                        name="tidNormal"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        initialValue={data ? data.tidNormal : ''}>
                        <Input className="override-input" />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">PG</div>
                    <div className="formItem">
                      <FormItem
                        name="tidPrepay"
                        className="selectItem"
                        style={{ marginLeft: 20 }}
                        initialValue={data ? data.tidPrepay : ''}>
                        <Input className="override-input" />
                      </FormItem>
                    </div>
                  </div>
                  <div className="contentBlock">
                    <div className="mainTitle">PG 사용여부</div>
                    <FormItem
                      name="tidNormalRate"
                      style={{ display: 'inline-block', marginLeft: 20 }}>
                      <Radio.Group
                        style={{ marginLeft: 0 }}
                        defaultValue={
                          data ? parseInt(data.tidNormalRate) : 100
                        }>
                        {Object.keys(pgUseRate)
                          .reverse()
                          .map((key) => {
                            return (
                              <Radio value={parseInt(key)}>
                                {pgUseRate[key]}
                              </Radio>
                            );
                          })}
                      </Radio.Group>
                    </FormItem>
                  </div>
                </div>
                <div style={{display: "flex", justifyContent:"flex-end"}}>
                  <Button
                    type="primary"
                    htmlType="submit">
                    저장하기
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ModifyFranDialog;
